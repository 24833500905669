import { mapState, mapGetters } from 'vuex'
import { getDateFormat } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
const statuses = {
  valid: { id: '19,9,2,70', name_ukr: 'Дійсний', name_eng: 'Valid' },
  rejected: { id: '10,71', name_ukr: 'Відхилено', name_eng: 'Rejected' },
  invalid: { id: '37,13', name_ukr: 'Анульований', name_eng: 'Invalid' },
  inProcessing: { id: '21,5,14,72', name_ukr: 'В обробці', name_eng: 'In processing' },
  expired: { id: '37,13', name_ukr: 'термін дії закінчився', name_eng: 'Expired' }
}
const initialData = () => {
  return {
    // Dates and periods
    exactDate: null,
    periodStart: null,
    periodEnd: null,
    dateStart: null,
    dateStartFrom: null,
    dateStartTo: null,
    dateEnd: null,
    dateEndFrom: null,
    dateEndTo: null,
    dateBorn: null,
    meetingDateFrom: null,
    meetingDateTo: null,
    meetingDateEndFrom: null,
    meetingDateEndTo: null,
    changeToVerificationDateFrom: null,
    changeToVerificationDateTo: null,
    // Users
    id: null,
    user: null,
    fullName: null,
    sailorId: null,
    crewingManagerID: null,
    // Numbers
    number: null,
    numberProtocol: null,
    numberStatement: null,
    registrationNumber: null,
    serial: null,
    // Other
    company: null,
    distributionType: null,
    statementType: null,
    getDocumentStatus: null,
    protocolAvailability: null,
    otherPort: null,
    statementAvailability: null,
    resultEQC: null,
    educPassedExam: null,
    withoutDate: null,
    newDocument: null,
    isCadet: null,
    payment: null,
    firstFormSum: null,
    secondFormSum: null,
    firstFormParams: 'equal',
    secondFormParams: 'equal',
    accrualTypeDoc: [],
    typeDoc: [],
    submittedTypeDocument: [],
    educationExtent: [],
    protocolWay: [],
    affiliate: [],
    country: [],
    port: [],
    institution: [],
    qualificationLevel: [],
    cadetFaculty: [],
    qualification: [],
    specialty: [],
    specialization: [],
    certificateCourse: [],
    rank: [],
    position: [],
    headCommissioner: [],
    memberCommissioner: [],
    experience: [],
    crewingCompany: [],
    crewingGroups: [],
    author: [],
    solution: [],
    status: [],
    isContinue: null,
    typeUser: null,
    chooseCrewPlus: null,
    item_status: { id: 1, name_ukr: 'Всі заявки на посаду', value: null },
    support_code: null,
    inn: null,
    type_exam: null,
    type_delivery: null,
    payment_from_date: null,
    payment_to_date: null
  }
}

export default {
  name: 'ReportSearch',
  props: {
    sqcProtocol: Boolean,
    sqcApplication: Boolean,
    etiCertificate: Boolean,
    qualDocDiploma: Boolean,
    qualDocCert: Boolean,
    qualDocument: Boolean,
    qualApplication: Boolean,
    graduationCert: Boolean,
    education: Boolean,
    medical: Boolean,
    seafarerPassport: Boolean,
    citizenPassport: Boolean,
    reportCadet: Boolean,
    finance: Boolean,
    crewingManagerStatements: Boolean,
    srbStatements: Boolean,
    statementETI: Boolean,
    etiPayments: Boolean,
    userHistory: Boolean,
    statementAdvanceTraining: Boolean,
    newAccounts: Boolean,
    newAccountsStatistics: Boolean,
    sailorPassport: Boolean,
    submittedDocuments: Boolean,
    statementSP: Boolean,
    documentsForSinging: Boolean,
    photoVerification: Boolean,
    crewingVerification: Boolean,
    qualificationVerification: Boolean,
    educationVerification: Boolean,
    medicalVerification: Boolean,
    sailorPassportVerification: Boolean,
    serviceRecordVerification: Boolean,
    internationalPassportVerification: Boolean,
    civilPassportVerification: Boolean,
    lineServiceRecordVerification: Boolean,
    navigatorCertificateVerification: Boolean,
    distribution: Boolean,
    debtor: Boolean,
    verificationDocumentPhotos: Boolean,
    report: String,
    getReport: Function,
    getExcel: Function,
    accountManagement: Boolean,
    sailorDigitizationDocument: { type: Boolean, default: false },
    sailorDigitizationStatements: { type: Boolean, default: false },
    crewingConnectCompanies: { type: Boolean, default: false }
  },
  data () {
    return {
      dataForm: initialData(),
      payment_from_date_menu: false,
      payment_to_date_menu: false,
      payment_from_time: '22:00',
      payment_to_time: '22:00',
      isLoading: false,
      resultSearchTitle: [],
      searchResults: [],
      params: null,
      delaySearch: null,
      viewSearch: true,
      allowSaveExcel: true,
      isSailorSearchLoading: false,
      isCrewingSearchLoading: false,
      checkAccess,
      getDateFormat,
      time: '22:00',
      mappingBooleanOptions: [
        { id: 0, name: this.$i18n.t('yes'), value: true },
        { id: 1, name: this.$i18n.t('no'), value: false }
      ],
      mappingStatementTypes: [
        { id: 0, name: this.$i18n.t('continuing'), value: true },
        { id: 1, name: this.$i18n.t('creatingNew'), value: false }
      ],
      formParams: [
        { text: this.$i18n.t('equalSum'), value: 'equal' },
        { text: this.$i18n.t('moreOrEqual'), value: 'more' },
        { text: this.$i18n.t('lessOrEqual'), value: 'less' }
      ],
      mappingExperience: [
        { name_eng: 'None', name_ukr: 'Немає', value: false },
        { name_eng: 'Exist', name_ukr: 'Існує', value: true }
      ],
      mappingWay: [
        { name_eng: '-', name_ukr: 'С' },
        { name_eng: '-', name_ukr: 'М' },
        { name_eng: '-', name_ukr: 'РК' },
        { name_eng: '-', name_ukr: 'Р' },
        { name_eng: '-', name_ukr: 'Т' },
        { name_eng: '-', name_ukr: 'ПФ' }
      ],
      mappingSolution: [
        { value: 'assign', name: this.$i18n.t('assign') },
        { value: 'not_assign', name: this.$i18n.t('not_assign') },
        { value: 'confirm', name: this.$i18n.t('confirm') },
        { value: 'not_confirm', name: this.$i18n.t('not_confirm') },
        { value: 'give', name: this.$i18n.t('give') },
        { value: 'not_give', name: this.$i18n.t('not_give') }
      ],
      mappingProtocolAvailability: [
        { id: 1, name: this.$i18n.t('orProtocol'), value: null },
        { id: 2, name: this.$i18n.t('withProtocol'), value: true },
        { id: 3, name: this.$i18n.t('withoutProtocol'), value: false }
      ],
      mappingApplicationAvailability: [
        { id: 1, name: this.$i18n.t('orStatement'), value: null },
        { id: 2, name: this.$i18n.t('withStatement'), value: true },
        { id: 3, name: this.$i18n.t('withoutStatement'), value: false }
      ],
      mappingResultsEQC: [
        { id: 1, name: this.$i18n.t('orPassedEducationExam'), value: null },
        { id: 2, name: this.$i18n.t('passedEducationExam'), value: true },
        { id: 3, name: this.$i18n.t('noPassedEducationExam'), value: false }
      ],
      mappingEducExamPass: [
        { id: 1, name: this.$i18n.t('orEducationWithSQC'), value: null },
        { id: 2, name: this.$i18n.t('educationWithSQC'), value: true },
        { id: 3, name: this.$i18n.t('noEducationWithSQC'), value: false }
      ],
      mappingCadetType: [
        { id: 1, name: this.$i18n.t('orCadet'), value: null },
        { id: 2, name: this.$i18n.t('cadet'), value: true },
        { id: 3, name: this.$i18n.t('notCadet'), value: false }
      ],
      mappingDistributionType: [
        { id: 0, name: this.$i18n.t('sqcWithExp'), value: 'sqc', experience: true },
        { id: 1, name: this.$i18n.t('sqcWithoutExp'), value: 'sqc', experience: false },
        { id: 2, name: this.$i18n.t('sqcCadet'), value: 'sqc', cadet: true },
        { id: 3, name: this.$i18n.t('medicalInstitution'), value: 'medical' },
        { id: 4, name: this.$i18n.t('eti'), value: 'eti' },
        { id: 5, name: this.$i18n.t('diplomasQualification'), value: 'dpd' },
        { id: 6, name: this.$i18n.t('portal'), value: 'portal' },
        { id: 7, name: this.$i18n.t('crewingManagers'), value: 'crewing_manager', cadet: false },
        { id: 8, name: this.$i18n.t('crewingManagerCadet'), value: 'crewing_manager', cadet: true },
        { id: 9, name: this.$i18n.t('serviceCenter'), value: 'sc' },
        { id: 10, name: this.$i18n.t('advanceTrainingCourse'), value: 'adv_training' }
      ],
      listGetDocumentStatus: [
        { id: 0, name: this.$i18n.t('allDocs'), value: '' },
        { id: 1, name: this.$i18n.t('got'), value: 'Was bought' },
        { id: 2, name: this.$i18n.t('documentAdded'), value: 'DocumentNostrification added' },
        { id: 3, name: this.$i18n.t('notGot'), value: 'To buy' },
        { id: 4, name: this.$i18n.t('restorePayment'), value: 'Restore payment' },
        { id: 5, name: this.$i18n.t('canceled'), value: 'Canceled' }
      ],
      listSubmittedDocuments: [
        { id: 0, name: this.$i18n.t('model-ServiceRecord'), value: 'servicerecord' },
        { id: 1, name: this.$i18n.t('model-LineInServiceRecord'), value: 'lineinservicerecord' },
        { id: 2, name: this.$i18n.t('model-ExperienceDoc'), value: 'experienceline' },
        { id: 3, name: this.$i18n.t('model-MedicalCertificate'), value: 'medicalcertificate' },
        { id: 4, name: this.$i18n.t('model-Education'), value: 'higheducation' },
        { id: 5, name: this.$i18n.t('qualificationWorkerDiploma'), value: 'qualify_worker' },
        { id: 6, name: this.$i18n.t('advanceTrainingCertificate'), value: 'upper_qualify' },
        { id: 7, name: this.$i18n.t('workQualificationCertificate'), value: 'worker_qualify' },
        { id: 8, name: this.$i18n.t('model-SailorPassport'), value: 'sailorpassport' },
        { id: 9, name: this.$i18n.t('model-QualificationDocument'), value: 'qualificationdocument' },
        { id: 10, name: this.$i18n.t('model-ProofOfWorkDiploma'), value: 'proofofworkdiploma' },
        { id: 11, name: this.$i18n.t('internationalPassport.name'), value: 'foreignpassport' }
      ],
      typeStatements: [
        { name: 'Всі', value: null },
        { name: 'ДКК іспит', value: '0,1,2' },
        { name: 'ДКК стаж', value: '3' },
        { name: 'Іспит CES 6.0', value: true }
      ],
      arrayTypesUser: [
        { id: 1, type_user: 'secretary_sqc', name_ukr: this.$i18n.t('ITCS secretary') },
        { id: 2, type_user: 'commissioner', name_ukr: this.$i18n.t('Commissioner SQC') },
        { id: 3, type_user: 'verifier', name_ukr: this.$i18n.t('Verifier') },
        { id: 4, type_user: 'crewing_manager', name_ukr: this.$i18n.t('Crewing manager') },
        { id: 5, type_user: 'back_office', name_ukr: this.$i18n.t('Back office') },
        { id: 6, type_user: 'crewing_head', name_ukr: this.$i18n.t('Head of crewing') }
      ],
      arrayCrewOrNot: [
        { id: 1, name_ukr: this.$i18n.t('allStatement'), value: null },
        { id: 2, name_ukr: this.$i18n.t('sailorHasCrewing'), value: true },
        { id: 2, name_ukr: this.$i18n.t('sailorHasNotCrewing'), value: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['qualificationById', 'positionsByRankID', 'statusById', 'affiliateByID', 'statusChoose']),
    ...mapState({
      lang: state => state.main.lang,
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      labelValue: state => (state.main.lang === 'en') ? 'value_eng' : 'value',
      label: state => (state.main.lang === 'en') ? 'name_en' : 'name',
      userId: state => state.main.user.id,
      // mapping documents
      listRanks: state => state.directory.ranks,
      listCourses: state => state.directory.courses,
      listInstitutionsETI: state => state.directory.educationTraining,
      listEducationDocumentTypes: state => state.directory.typeDoc,
      listExtents: state => state.directory.extent,
      listEducationInstitutions: state => state.directory.institution,
      listSpecializations: state => state.directory.specialization,
      listProfessions: state => state.directory.profession,
      listEducationQualifications: state => state.directory.qualificationLevels,
      listFaculties: state => state.directory.faculties,
      listQualificationDocumentTypes: state => state.directory.typeDocQualification,
      listCountries: state => state.directory.country,
      listPorts: state => state.directory.ports,
      listAffiliate: state => state.directory.affiliate,
      listCommissioners: state => state.directory.commission,
      listAccrualDocumentTypes: state => state.directory.allAccrualTypeDoc,
      listPaymentStatuses: state => state.directory.paymentStatus,
      listUsers: state => state.user.userList,
      listCrewingCompanies: state => state.crewing.crewingCompanies.results,
      listStatuses: state => state.directory.statuses,
      listCrewingGroups: state => state.directory.crewingGroups,
      listVerificationDocumentTypes: state => state.directory.allDocumentTypes.filter(item => item.useInDocumentVerification),
      listDocumentTypes: state => state.directory.allDocumentTypes.filter(item => item.isDocument),
      listStatementTypes: state => state.directory.allDocumentTypes.filter(item => item.isStatement),
      listTypeExam: state => state.directory.listTypeExam,
      // permissions
      permissionsReport: state => state.main.permissionsReport,
      user: state => state.main.user,
      typeDeliveryList: state => state.delivery.typeDeliveryReport
    }),
    viewPortSelect () {
      return !this.dataForm.country || (this.dataForm.country && !this.dataForm.country.length) ||
        (this.dataForm.country && this.dataForm.country.length && this.dataForm.country.find(value => value.id === 2))
    },
    mappingStatuses () {
      // assembled array of statuses for back office
      let assembledStatusesArr = []
      const backOfficeStatusServices = ['Other', 'PacketDelete', 'BackOffice']
      assembledStatusesArr = backOfficeStatusServices.reduce((result, service) => {
        result = result.concat(this.statusChoose(service))
        return result
      }, [])

      let statusesArray = []
      switch (this.report) {
        case 'educationReport':
        case 'diplomasQualification':
          statusesArray = this.statusChoose(this.report === 'educationReport' ? 'ServiceRecord' : 'QualificationDoc')
          if (checkAccess('backOffice')) statusesArray = statusesArray.concat(assembledStatusesArr) // concat statuses for back office
          if ([106, 107].includes(this.userId)) statusesArray.push(this.statusById(34)) // add verification status for 2 users
          return statusesArray
        case 'statementETI':
          statusesArray = this.statusChoose('StatementETI')
          statusesArray.push(this.statusById(84))
          return statusesArray
        case 'crewingManagerStatements':
          statusesArray = this.statusChoose('StatementCrewingManagerSailor')
          return statusesArray
        case 'srbStatements':
          statusesArray = this.statusChoose('StatementServRecord')
          statusesArray.push(this.statusById(42))
          statusesArray.push(this.statusById(87))
          return statusesArray
        case 'statementAdvanceTraining':
          statusesArray = this.statusChoose('StatementAdvancedTraining')
          return statusesArray
        case 'statementSP':
          statusesArray = this.statusChoose('StatementSailorPassport')
          return statusesArray
        case 'sailorPassportReport':
          statusesArray = this.statusChoose('QualificationDoc')
          return statusesArray
        case 'submittedDocuments':
          statusesArray.push(this.statusById(34))
          statusesArray.push(this.statusById(74))
          Object.entries(statuses).forEach(([key, value]) => statusesArray.push(value))
          statusesArray.push({ id: 'other', name_ukr: 'Інші', name_eng: 'Other' })
          return statusesArray
        case 'newAccounts':
          statusesArray = this.statusChoose('User')
          statusesArray.push(this.statusById(34))
          return statusesArray
        case 'crewingVerification':
          statusesArray = this.statusChoose('StatementCrewing')
          statusesArray.push(this.statusById(34))
          return statusesArray
        case 'verificationDocumentPhotos':
          statusesArray = this.statusChoose('QualificationDoc')
          statusesArray.push(this.statusById(42))
          statusesArray.push(this.statusById(114))
          statusesArray.push(this.statusById(121))
          return statusesArray
        case 'photoVerification':
          [34, 36, 41].forEach(status => statusesArray.push(this.statusById(status)))
          return statusesArray
        case 'statementSQC':
          if (checkAccess('crewingManager') && !checkAccess('backOffice')) {
            [24, 25, 74].forEach(status => statusesArray.push(this.statusById(status)))
          } else {
            statusesArray = this.statusChoose('StatementDKK&Qual')
            if (checkAccess('backOffice')) statusesArray = statusesArray.concat(assembledStatusesArr) // concat statuses for back office
            if (!statusesArray.find(status => status.id === 42)) statusesArray.push(this.statusById(42)) // add status "created from PA"
          }
          return statusesArray
        case 'international-passport-verification':
        case 'civilPassport-verification':
        case 'sailor-passport-verification':
        case 'education-verification':
        case 'qualification-verification':
        case 'navigator-certificate-verification':
        case 'service-record-verification':
        case 'line-service-record-verification':
        case 'medical-verification':
          [34, 42, 74].forEach(status => statusesArray.push(this.statusById(status)))
          return statusesArray
        case 'certificatesReport':
          statusesArray = this.listStatuses.filter(item => [7, 19, 21, 20, 33, 17, 37, 18, 8, 34, 74, 89, 112, 122, 42].includes(item.id))
          // if (checkAccess('backOffice')) statusesArray.push(this.statusById(124))
          return statusesArray
        default:
          statusesArray = this.statusChoose('StatementDKK&Qual')
          if (checkAccess('backOffice')) statusesArray = statusesArray.concat(assembledStatusesArr) // concat statuses for back office
          if (!statusesArray.find(status => status.id === 42)) statusesArray.push(this.statusById(42)) // add status "created from PA"
          return statusesArray
      }
    },
    mappingAffiliate () {
      switch (this.report) {
        case 'protocolSQC':
          if (this.permissionsReport && this.permissionsReport.length) {
            const branches = []
            const branchIds = this.permissionsReport.filter(value => value.permission === 'reportSqcProtocol')
            branchIds[0].branch_office.forEach(branchId => {
              branches.push(this.affiliateByID(branchId))
            })
            return branches
          } else return []
        default:
          return this.listAffiliate
      }
    },
    showDateMeeting () {
      if (this.sqcApplication) {
        if (checkAccess('crewingManager') && !checkAccess('backOffice')) {
          return this.dataForm.status.length === 1 && this.dataForm.status[0].id === 24
        } else return true
      }
    },
    documentTypesList () {
      if (this.education || this.educationVerification) return this.listEducationDocumentTypes
      else if (this.qualDocument || this.documentsForSinging || this.qualificationVerification) return this.listQualificationDocumentTypes
      else if (this.verificationDocumentPhotos) return this.listVerificationDocumentTypes
      else return []
    },
    submittedDocumentsList () {
      if (this.submittedDocuments) return this.listSubmittedDocuments
      else if (this.sailorDigitizationStatements) return this.listStatementTypes
      else if (this.sailorDigitizationDocument) return this.listDocumentTypes
    },
    institutionList () {
      if (this.education || this.reportCadet || (this.statementAdvanceTraining && checkAccess('backOffice')) ||
        this.educationVerification) return this.listEducationInstitutions
      else if (this.etiCertificate || (checkAccess('backOffice') && (this.statementETI || this.etiPayments))) return this.listInstitutionsETI
      else return []
    },
    showSailorIDField () {
      return this.finance || this.crewingManagerStatements || this.statementETI || this.etiPayments ||
        this.statementAdvanceTraining || this.sailorPassport || this.statementSP || this.documentsForSinging ||
        this.photoVerification || this.srbStatements || this.qualificationVerification || this.educationVerification ||
        this.medicalVerification || this.sailorPassportVerification || this.internationalPassportVerification || this.civilPassportVerification ||
        this.serviceRecordVerification || this.lineServiceRecordVerification || this.navigatorCertificateVerification || this.verificationDocumentPhotos
    },
    showSailorFullNameField () {
      return (this.sqcApplication && checkAccess('hideSqcStatementReportFields')) || this.sqcProtocol ||
        this.etiCertificate || this.reportCadet || this.qualDocument || this.qualApplication || this.crewingManagerStatements ||
        this.srbStatements || this.statementETI || this.etiPayments || this.statementAdvanceTraining || this.newAccounts ||
        this.sailorPassport || this.statementSP || this.documentsForSinging || this.photoVerification || this.qualificationVerification ||
        this.educationVerification || this.medicalVerification || this.sailorPassportVerification || this.internationalPassportVerification || this.civilPassportVerification ||
        this.serviceRecordVerification || this.lineServiceRecordVerification || this.navigatorCertificateVerification || this.verificationDocumentPhotos
    },
    showStatusField () {
      return this.sqcApplication || this.qualDocDiploma || this.qualDocCert || this.graduationCert || this.education ||
        this.medical || this.seafarerPassport || this.citizenPassport || this.qualDocument || this.qualApplication ||
        this.crewingManagerStatements || this.srbStatements || this.statementETI || this.statementAdvanceTraining ||
        this.sailorPassport || this.submittedDocuments || this.statementSP || this.newAccounts || this.photoVerification ||
        this.crewingVerification || this.internationalPassportVerification || this.civilPassportVerification || this.sailorPassportVerification ||
        this.educationVerification || this.qualificationVerification || this.navigatorCertificateVerification ||
        this.serviceRecordVerification || this.lineServiceRecordVerification || this.medicalVerification ||
        this.verificationDocumentPhotos || this.etiCertificate
    }
  },
  watch: {
    report () {
      this.dataForm = initialData()
      this.resultSearchTitle = []
    },
    dataForm: {
      handler () {
        this.allowSaveExcel = true
      },
      deep: true
    },
    listStatuses (value) {
      if (value.length) this.statusesAutocomplete()
    }
  },
  mounted () {
    if (this.listStatuses.length) this.statusesAutocomplete()
    this.fieldsAutocomplete()
  },
  methods: {
    changeRank () {
      if (this.dataForm.position.length) {
        this.dataForm.position = this.dataForm.position.filter(item => {
          return this.dataForm.rank.some(rank => rank.id === item.rank)
        })
      }
    },

    getPositionsList () {
      let positions = []
      this.dataForm.rank && this.dataForm.rank.forEach(item => {
        positions = positions.concat(this.positionsByRankID(item.id))
      })
      return positions
    },

    setParams (type) {
      this.resultSearchTitle = []
      this.params = new URLSearchParams({
        page_size: 20
      })
      if (this.dataForm.payment_from_date) this.params.set('payment_from_datetime', `${this.dataForm.payment_from_date} ${this.payment_from_time}`)
      if (this.dataForm.payment_to_date) this.params.set('payment_to_datetime', `${this.dataForm.payment_to_date} ${this.payment_to_time}`)
      if (this.dataForm.type_exam) this.params.set('type_exam', this.dataForm.type_exam.value)
      this.verificationDocumentPhotos && this.dataForm.chooseCrewPlus?.value && this.params.set('has_crewing', this.dataForm.chooseCrewPlus.value)
      if (this.dataForm.typeUser) {
        this.params.set('type_user', this.dataForm.typeUser.type_user)
      }
      if (this.dataForm.serial || this.dataForm.inn) {
        this.dataForm.serial && this.params.set('serial', this.dataForm.serial)
        this.dataForm.inn && this.params.set('inn', this.dataForm.inn)
      }
      if (this.dataForm.support_code) this.params.set('support_code', this.dataForm.support_code)
      if (this.dataForm.isContinue) {
        let isContinue = this.dataForm.isContinue
        if (typeof isContinue === 'boolean') {
          this.params.set('ces_exam', isContinue)
        } else {
          this.params.set('is_continue', isContinue)
          this.params.set('ces_exam', false)
        }
      }
      if (this.dataForm.item_status && this.dataForm.item_status.value) {
        this.params.set('item_status', this.dataForm.item_status.value)
      }
      if (this.dataForm.periodStart) {
        this.params.set('from_date', this.dataForm.periodStart)
        this.resultSearchTitle.push(`${this.$i18n.t('periodStart')}: ${getDateFormat(this.dataForm.periodStart)}`)
      }
      if (this.dataForm.periodEnd) {
        this.params.set('to_date', this.dataForm.periodEnd)
        this.resultSearchTitle.push(`${this.$i18n.t('periodEnd')}: ${getDateFormat(this.dataForm.periodEnd)}`)
      }
      if (this.dataForm.exactDate) {
        this.params.set('date_create', this.dataForm.exactDate)
        this.resultSearchTitle.push(`${this.$i18n.t('exactDate')}: ${getDateFormat(this.dataForm.exactDate)}`)
      }
      if (this.dataForm.distributionType) {
        if (this.dataForm.distributionType.hasOwnProperty('experience')) this.params.set(`with_exp`, this.dataForm.distributionType.experience)
        else if (this.dataForm.distributionType.hasOwnProperty('cadet')) this.params.set(`is_cadet`, this.dataForm.distributionType.cadet)
        this.resultSearchTitle.push(`${this.$i18n.t('type')}: ${this.dataForm.distributionType.name}`)
      }
      if (this.dataForm.getDocumentStatus && !!this.dataForm.getDocumentStatus.value) {
        this.params.set('item_status', this.dataForm.getDocumentStatus.value)
        this.resultSearchTitle.push(`${this.$i18n.t('getDocStatus')}: ${this.dataForm.getDocumentStatus.name}`)
      }
      if (this.dataForm.dateStart) {
        this.params.set('date_start', this.dataForm.dateStart)
        this.resultSearchTitle.push(`${this.$i18n.t('dateIssue')}: ${getDateFormat(this.dataForm.dateStart)}`)
      }
      if (this.dataForm.dateEnd) {
        this.params.set('date_end', this.dataForm.dateEnd)
        this.resultSearchTitle.push(`${this.$i18n.t('dateTermination')}: ${getDateFormat(this.dataForm.dateEnd)}`)
      }
      if (this.dataForm.dateStartFrom) {
        this.params.set('date_start__gte', this.dataForm.dateStartFrom)
        this.resultSearchTitle.push(`${this.$i18n.t('periodStartReceipt')}: ${getDateFormat(this.dataForm.dateStartFrom)}`)
      }
      if (this.dataForm.dateEndFrom) {
        this.params.set('date_end__gte', this.dataForm.dateEndFrom)
        this.resultSearchTitle.push(`${this.$i18n.t('dateEndFrom')}: ${getDateFormat(this.dataForm.dateEndFrom)}`)
      }
      if (this.dataForm.dateStartTo) {
        this.params.set('date_start__lte', this.dataForm.dateStartTo)
        this.resultSearchTitle.push(`${this.$i18n.t('periodEndReceipt')}: ${getDateFormat(this.dataForm.dateStartTo)}`)
      }
      if (this.dataForm.dateEndTo) {
        this.params.set('date_end__lte', this.dataForm.dateEndTo)
        this.resultSearchTitle.push(`${this.$i18n.t('dateEndTo')}: ${this.dataForm.dateEndTo}`)
      }
      if (this.dataForm.numberProtocol && (this.sqcProtocol || (this.sqcApplication && this.dataForm.protocolAvailability.value !== false))) {
        const splitProtocolNumber = this.dataForm.numberProtocol.split('/')
        if (splitProtocolNumber.length > 1) {
          this.params.set('protocol_number', splitProtocolNumber[0])
          this.params.set('protocol_year', splitProtocolNumber[1])
          this.resultSearchTitle.push(`${this.$i18n.t('numberProtocol')}/${this.$i18n.t('year')}: ${this.dataForm.numberProtocol}`)
        }
      }
      if (this.dataForm.protocolWay.length) {
        let way = this.dataForm.protocolWay.map(value => value.name_ukr)
        this.params.set('direction_abbr', way.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('way')}: ${way.join(', ')}`)
      }
      if (this.dataForm.numberStatement) {
        const splitStatementNumber = this.dataForm.numberStatement.split('/')
        if (splitStatementNumber.length > 1) {
          this.params.set('statement_number', splitStatementNumber[0])
          this.params.set('statement_year', splitStatementNumber[1])
          this.resultSearchTitle.push(`${this.$i18n.t('numberStatement')}/${this.$i18n.t('year')}: ${this.dataForm.numberStatement}`)
        }
      }

      if (this.dataForm.affiliate.length && (this.sqcProtocol ||
        (this.sqcApplication && this.dataForm.protocolAvailability.value !== false) || this.serviceRecordVerification)) {
        let affiliateID = this.dataForm.affiliate.map(item => item.id)
        let affiliateName = this.dataForm.affiliate.map(item => item[this.labelName])
        this.params.set(`${this.sqcProtocol ? 'statement_branch_office' : 'branch_office'}`, affiliateID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('affiliate')}: ${affiliateName.join(', ')}`)
      }
      if (this.dataForm.sailorId) {
        this.params.set('sailor_id', this.dataForm.sailorId)
        this.resultSearchTitle.push(`${this.$i18n.t('sailorId')}: ${this.dataForm.sailorId}`)
      }
      if (this.dataForm.fullName) {
        this.params.set('sailor_name', this.dataForm.fullName)
        this.resultSearchTitle.push(`${this.$i18n.t('sailor')}: ${this.dataForm.fullName}`)
      }
      if (this.dataForm.dateBorn) {
        this.params.set('sailor_birth', this.dataForm.dateBorn)
        this.resultSearchTitle.push(`${this.$i18n.t('dateBorn')}: ${getDateFormat(this.dataForm.dateBorn)}`)
      }
      if (this.dataForm.typeDoc.length) {
        let typeDocumentID = this.dataForm.typeDoc.map(item => item.id)
        let typeDocumentName = this.dataForm.typeDoc.map(item => item[this.labelName])
        this.params.set('type_document', typeDocumentID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('typeDoc')}: ${typeDocumentName.join(', ')}`)
      }
      if (this.dataForm.accrualTypeDoc.length) {
        let typeDocumentID = this.dataForm.accrualTypeDoc.map(item => item.id)
        let typeDocumentName = this.dataForm.accrualTypeDoc.map(item => item.value)
        this.params.set('type_document', typeDocumentID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('typeDoc')}: ${typeDocumentName.join(', ')}`)
      }
      if (this.dataForm.number) {
        this.params.set('number', this.dataForm.number)
        this.resultSearchTitle.push(`${this.$i18n.t('number')}: ${this.dataForm.number}`)
      }
      if (this.dataForm.firstFormSum) {
        switch (this.dataForm.firstFormParams) {
          case 'equal':
            this.params.set('price_f1_eq', this.dataForm.firstFormSum)
            break
          case 'more':
            this.params.set('price_f1_gte', this.dataForm.firstFormSum)
            break
          case 'less':
            this.params.set('price_f1_lte', this.dataForm.firstFormSum)
            break
        }
        this.resultSearchTitle.push(`${this.$i18n.t('price')} ${this.$i18n.t('firstForm')}: ${this.dataForm.firstFormSum}`)
      }
      if (this.dataForm.secondFormSum) {
        switch (this.dataForm.secondFormParams) {
          case 'equal':
            this.params.set('price_f2_eq', this.dataForm.secondFormSum)
            break
          case 'more':
            this.params.set('price_f2_gte', this.dataForm.secondFormSum)
            break
          case 'less':
            this.params.set('price_f2_lte', this.dataForm.secondFormSum)
            break
        }
        this.resultSearchTitle.push(`${this.$i18n.t('price')} ${this.$i18n.t('secondForm')}: ${this.dataForm.secondFormSum}`)
      }
      if (this.dataForm.country.length) {
        let countryID = this.dataForm.country.map(item => item.id)
        let countryName = this.dataForm.country.map(item => item[this.labelValue])
        this.params.set('country', countryID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('country')}: ${countryName.join(', ')}`)
      }
      if (this.dataForm.port.length && this.viewPortSelect) {
        let portID = this.dataForm.port.map(item => item.id)
        let portName = this.dataForm.port.map(item => item[this.labelName])
        this.params.set('port', portID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('port')}: ${portName.join(', ')}`)
      }
      if (this.dataForm.otherPort && !this.viewPortSelect) {
        this.params.set('other_port', this.dataForm.otherPort)
        this.resultSearchTitle.push(`${this.$i18n.t('port')}: ${this.dataForm.otherPort}`)
      }
      if (this.dataForm.registrationNumber) {
        this.params.set('registry_number', this.dataForm.registrationNumber)
        this.resultSearchTitle.push(`${this.$i18n.t('registrationNumber')}: ${this.dataForm.registrationNumber}`)
      }
      if (this.dataForm.serial) {
        this.params.set('serial', this.dataForm.serial)
        this.resultSearchTitle.push(`${this.$i18n.t('serial')}: ${this.dataForm.serial}`)
      }
      if (this.dataForm.company) {
        this.params.set('company_name', this.dataForm.company)
        this.resultSearchTitle.push(`${this.$i18n.t('crewingCompanyName')}: ${this.dataForm.company}`)
      }
      if (this.dataForm.educationExtent.length) {
        let extentsID = this.dataForm.educationExtent.map(item => item.id)
        let extentsName = this.dataForm.educationExtent.map(item => item[this.labelName])
        this.params.set('extent', extentsID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('educationExtent')}: ${extentsName.join(', ')}`)
      }
      if (this.dataForm.institution && this.dataForm.institution.length) {
        let institutionID = this.dataForm.institution.map(item => item.id)
        let institutionName = this.dataForm.institution.map(item => item[this.labelName])
        this.params.set('education_institution', institutionID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('nameInstitution')}: ${institutionName.join(', ')}`)
      }
      if (this.dataForm.qualificationLevel.length) {
        let qualificationID = this.dataForm.qualificationLevel.map(item => item.id)
        let qualificationName = this.dataForm.qualificationLevel.map(item => item[this.labelName])
        this.params.set('level_qualification', qualificationID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('qualification')}: ${qualificationName.join(', ')}`)
      }
      if (this.dataForm.cadetFaculty.length) {
        let facultyID = this.dataForm.cadetFaculty.map(item => item.id)
        let facultyName = this.dataForm.cadetFaculty.map(item => item[this.labelName])
        this.params.set('faculty', facultyID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('way')}: ${facultyName.join(', ')}`)
      }
      if (this.dataForm.qualification.length) {
        let qualificationID = this.dataForm.qualification.map(item => item.id)
        let qualificationName = this.dataForm.qualification.map(item => item[this.labelName])
        this.params.set('qualification', qualificationID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('qualification')}: ${qualificationName.join(', ')}`)
      }
      if (this.dataForm.specialty.length) {
        let specialtyID = this.dataForm.specialty.map(item => item.id)
        let specialtyName = this.dataForm.specialty.map(item => item[this.labelName])
        this.params.set('speciality', specialtyID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('specialty')}: ${specialtyName.join(', ')}`)
      }
      if (this.dataForm.specialization.length) {
        let specializationID = this.dataForm.specialization.map(item => item.id)
        let specializationName = this.dataForm.specialization.map(item => item[this.labelName])
        this.params.set('specialization', specializationID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('specialization')}: ${specializationName.join(', ')}`)
      }
      if (this.dataForm.certificateCourse.length) {
        let courseID = this.dataForm.certificateCourse.map(item => item.id)
        let courseName = this.dataForm.certificateCourse.map(item => item[this.labelName])
        this.params.set('course', courseID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('course')}: ${courseName.join(', ')}`)
      }
      if (this.dataForm.user) {
        this.params.set('user', this.dataForm.user.id)
        this.resultSearchTitle.push(`${this.$i18n.t('user')}: ${this.dataForm.user.userFullName}`)
      }
      if (this.dataForm.sailor) {
        this.params.set('sailor_key', this.dataForm.sailor.id)
        this.resultSearchTitle.push(`${this.$i18n.t('sailor')}: ${this.dataForm.sailor.sailorFullName}`)
      }
      if (this.dataForm.rank.length) {
        let rankID = this.dataForm.rank.map(item => item.id)
        let rankName = this.dataForm.rank.map(item => item[this.labelName])
        this.params.set('rank', rankID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('rank')}: ${rankName.join(', ')}`)
      }
      if (this.dataForm.position.length) {
        let positionID = this.dataForm.position.map(item => item.id)
        let positionName = this.dataForm.position.map(item => item[this.labelName])
        this.params.set('position', positionID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('position')}: ${positionName.join(', ')}`)
      }
      if (this.dataForm.meetingDateFrom) {
        this.params.set('from_date_meeting', this.dataForm.meetingDateFrom)
        this.resultSearchTitle.push(`${this.$i18n.t('dateStartEduFrom')}: ${getDateFormat(this.dataForm.meetingDateFrom)}`)
      }
      if (this.dataForm.meetingDateTo) {
        this.params.set('to_date_meeting', this.dataForm.meetingDateTo)
        this.resultSearchTitle.push(`${this.$i18n.t('dateStartEduTo')}: ${getDateFormat(this.dataForm.meetingDateTo)}`)
      }
      if (this.dataForm.meetingDateEndFrom) {
        this.params.set('from_date_end_meeting', this.dataForm.meetingDateEndFrom)
        this.resultSearchTitle.push(`${this.$i18n.t('dateEndEduFrom')}: ${getDateFormat(this.dataForm.meetingDateEndFrom)}`)
      }
      if (this.dataForm.meetingDateEndTo) {
        this.params.set('to_date_end_meeting', this.dataForm.meetingDateEndTo)
        this.resultSearchTitle.push(`${this.$i18n.t('dateEndEduTo')}: ${getDateFormat(this.dataForm.meetingDateEndTo)}`)
      }
      if (this.dataForm.headCommissioner.length) {
        let headCommissionersName = this.dataForm.headCommissioner.map(item => item.name)
        this.params.set('committe_head', headCommissionersName.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('headCommission')}: ${headCommissionersName.join(', ')}`)
      }
      if (this.dataForm.memberCommissioner.length) {
        let memberCommissionerName = this.dataForm.memberCommissioner.map(item => item.name)
        this.params.set('commissioner', memberCommissionerName.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('membersCommission')}: ${memberCommissionerName.join(', ')}`)
      }
      if (this.dataForm.experience.length) {
        let experienceID = this.dataForm.experience.map(item => item.value)
        let experienceName = this.dataForm.experience.map(item => item[this.labelName])
        this.params.set('experience_required', experienceID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('requirementExperience')}: ${experienceName.join(', ')}`)
      }
      if (this.dataForm.solution.length) {
        let solutionID = this.dataForm.solution.map(item => item.value)
        let solutionName = this.dataForm.solution.map(item => item.name)
        this.params.set('document_property', solutionID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('solution')}: ${solutionName.join(', ')}`)
      }
      if (this.dataForm.statementAvailability && this.dataForm.statementAvailability.value !== null) {
        this.params.set('has_statement', this.dataForm.statementAvailability.value)
        this.resultSearchTitle.push(`${this.$i18n.t('statementAvailability')}: ${this.dataForm.statementAvailability.name}`)
      }
      if ((this.dataForm.protocolAvailability && this.dataForm.protocolAvailability.value !== null && this.sqcApplication) ||
        (this.dataForm.protocolAvailability && this.dataForm.protocolAvailability.value !== null &&
        this.dataForm.statementAvailability && this.dataForm.statementAvailability.id === 2)) {
        this.params.set('has_protocol', this.dataForm.protocolAvailability.value)
        this.resultSearchTitle.push(`${this.$i18n.t('statementType')}: ${this.dataForm.protocolAvailability.name}`)
      }
      if (this.dataForm.resultEQC && this.dataForm.resultEQC.value !== null) {
        this.params.set('passed_educ_exam', this.dataForm.resultEQC.value)
        this.resultSearchTitle.push(`${this.$i18n.t('resultEKK')}: ${this.dataForm.resultEQC.name}`)
      }
      if (this.dataForm.educPassedExam && this.dataForm.educPassedExam.value !== null) {
        this.params.set('educ_with_dkk', this.dataForm.educPassedExam.value)
        this.resultSearchTitle.push(`${this.$i18n.t('decisionEKK')}: ${this.dataForm.educPassedExam.name}`)
      }
      if (this.dataForm.withoutDate) {
        this.params.set('has_crewing', this.dataForm.withoutDate.value)
        this.resultSearchTitle.push(`${this.$i18n.t('crewingManagerDocuments')}: ${this.dataForm.withoutDate.name}`)
      }
      if (this.dataForm.crewingManagerID) {
        this.params.set('manager_id', this.dataForm.crewingManagerID)
        this.resultSearchTitle.push(`${this.$t('crewingManagerID')}: ${this.dataForm.crewingManagerID}`)
      }
      if (this.dataForm.newDocument) {
        this.params.set('is_new_document', this.dataForm.newDocument.value)
        this.resultSearchTitle.push(`${this.$i18n.t('newDocument')}: ${this.dataForm.newDocument.name}`)
      }
      if (this.dataForm.statementType) {
        this.params.set('is_continue', this.dataForm.statementType.value)
        this.resultSearchTitle.push(`${this.$i18n.t('statementType')}: ${this.dataForm.statementType.name}`)
      }
      if (this.dataForm.submittedTypeDocument.length) {
        let typeDocID = this.dataForm.submittedTypeDocument.map(item => item.value)
        let typeDocName = this.dataForm.submittedTypeDocument.map(item => item.name)

        if (this.sailorDigitizationStatements || this.sailorDigitizationDocument) {
          typeDocID = this.dataForm.submittedTypeDocument.map(item => item.content_type)
          typeDocName = this.dataForm.submittedTypeDocument.map(item => item[this.labelName])
        }

        this.params.set('content_type', typeDocID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('typeDoc')}: ${typeDocName.join(', ')}`)
      }
      if (this.dataForm.crewingCompany.length) {
        let crewingCompanyID = this.dataForm.crewingCompany.map(item => item.id)
        let crewingCompanyName = this.dataForm.crewingCompany.map(item => item.name_ukr)
        this.params.set('crewing', crewingCompanyID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('crewingCompany')}: ${crewingCompanyName.join(', ')}`)
      }
      if (this.dataForm.author.length) {
        let authorID = this.dataForm.author.map(item => item.id)
        let authorName = this.dataForm.author.map(item => item.fullName)
        this.params.set('author', authorID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('author')}: ${authorName.join(', ')}`)
      }
      if (this.dataForm.changeToVerificationDateFrom) {
        this.params.set('from_set_verification_at', this.dataForm.changeToVerificationDateFrom)
        this.resultSearchTitle.push(`${this.$i18n.t('changeToVerificationDateFrom')}: ${getDateFormat(this.dataForm.changeToVerificationDateFrom)}`)
      }
      if (this.dataForm.changeToVerificationDateTo) {
        this.params.set('to_set_verification_at', this.dataForm.changeToVerificationDateTo)
        this.resultSearchTitle.push(`${this.$i18n.t('changeToVerificationDateTo')}: ${getDateFormat(this.dataForm.changeToVerificationDateTo)}`)
      }
      if (this.dataForm.payment) {
        this.params.set('is_payed', this.dataForm.payment.status)
        this.resultSearchTitle.push(`${this.$i18n.t('payment')}: ${this.dataForm.payment.status ? this.$i18n.t('isPayed') : this.$i18n.t('notPayed')}`)
      }
      if (this.dataForm.type_delivery) {
        this.params.set('type_delivery', this.dataForm.type_delivery.type_delivery)
        this.resultSearchTitle.push(`${this.$i18n.t('delivery')}: ${this.dataForm.type_delivery.name_ukr}`)
      }
      if (this.dataForm.status.length) {
        let statusID = this.dataForm.status.map(item => item.id)
        let statusName = this.dataForm.status.map(item => item[this.labelName])
        this.params.set('status_document', statusID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('status')}: ${statusName.join(', ')}`)
      }
      if (this.dataForm.isCadet && this.dataForm.isCadet.value !== null) {
        this.params.set('is_cadet', this.dataForm.isCadet.value)
        this.resultSearchTitle.push(`${this.dataForm.isCadet.name}`)
      }
      if (this.dataForm.crewingGroups.length) {
        let crewingGroupID = this.dataForm.crewingGroups.map(item => item.id)
        let crewingGroupName = this.dataForm.crewingGroups.map(item => item.name)
        this.params.set('crewing_group', crewingGroupID.join(','))
        this.resultSearchTitle.push(`${this.$i18n.t('group')}: ${crewingGroupName.join(', ')}`)
      }
      if (this.dataForm.id) {
        this.params.set('id', this.dataForm.id)
        this.resultSearchTitle.push(`${this.$i18n.t('userID')}: ${this.dataForm.id}`)
      }

      if (type === 'report') {
        this.viewSearch = false
        this.getReport('', this.params)
      } else this.getExcel('', this.params)
    },

    startSearch (searchQuery) {
      clearTimeout(this.delaySearch)
      if (!searchQuery || searchQuery?.length < 3) return false
      this.delaySearch = setTimeout(() => {
        this.searchResults = []
        this.isSailorSearchLoading = true
        this.$api.post('api/v1/sailor/search_sailor/', { body: { query: searchQuery } }).then(response => {
          if (response?.code === 200) {
            response.data.map(item => {
              item.sailorFullName = `${item['last_' + this.labelName]} ${item['first_' + this.labelName]} ${item['middle_' + this.labelName] || ''}`
            })
            this.searchResults = response.data
          }
        }).finally(() => { this.isSailorSearchLoading = false })
      }, 1200)
    },

    startCrewingSearch (searchQuery) {
      clearTimeout(this.delaySearch)
      if (!searchQuery || searchQuery?.length < 3) return false

      this.delaySearch = setTimeout(() => {
        this.searchResults = []
        this.isCrewingSearchLoading = true
        const params = new URLSearchParams({ name: searchQuery, type_user: 'crewing_manager' })
        this.$api.get(`api/v1/auth/user/?${params}`).then(response => {
          if (response?.code === 200) {
            response.data.results.map(item => {
              item.fullName = `${item.last_name} ${item.first_name} ${item.userprofile.middle_name}`
            })
            this.searchResults = response.data.results
          }
        }).finally(() => { this.isCrewingSearchLoading = false })
      }, 1200)
    },

    resetFilteringParams () {
      this.$data.dataForm = initialData()
      this.fieldsAutocomplete()
    },

    statusesAutocomplete () {
      if (this.photoVerification || this.crewingVerification) this.dataForm.status.push(this.statusById(34))
      else if (this.srbStatements) [42, 48, 87].forEach(status => this.dataForm.status.push(this.statusById(status)))
      else if (this.verificationDocumentPhotos) {
        this.dataForm.status.push(this.statusById(42))
        this.dataForm.status.push(this.statusById(121))
      }
    },
    fieldsAutocomplete () {
      switch (true) {
        case this.distribution:
          this.dataForm.distributionType = this.mappingDistributionType[0]
          this.dataForm.getDocumentStatus = this.listGetDocumentStatus[0]
          break
        case this.reportCadet:
          this.dataForm.statementAvailability = this.mappingApplicationAvailability[0]
          this.dataForm.resultEQC = this.mappingResultsEQC[0]
          this.dataForm.educPassedExam = this.mappingEducExamPass[0]
          break
        case this.sqcApplication && checkAccess('hideSqcStatementReportFields'):
          this.dataForm.protocolAvailability = this.mappingProtocolAvailability[0]
          break
        case ![14488, 14365].includes(this.userId) && (this.sqcApplication || this.sqcProtocol):
          this.dataForm.isCadet = this.mappingCadetType[0]
          break
      }
    }
  }
}
