import { mapState } from 'vuex'
import { getDateFormat, getFilesFromData } from '@/mixins/main'
export default {
  name: 'MainSailorHistory',
  data () {
    return {
      cardLoader: true,
      sailors: [],
      getDateFormat,
      getFilesFromData
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    })
  },
  mounted () {
    this.getSailors()
  },
  methods: {
    getSailors () {
      this.$api.get('api/v1/auth/get_user_history/').then(response => {
        this.cardLoader = false
        if (response.code === 200) {
          this.sailors = response.data
          this.sailors.forEach(value => {
            if (value.photo) {
              if (value.photo.includes('default_profile.png')) {
                value.photo = '/img/default.0f11eabe.png'
              } else {
                let options = {
                  headers: {
                    Authorization: `${localStorage.getItem('TOKEN')}`
                  }
                }
                fetch(value.photo, options).then(async (photo) => {
                  let blob = await photo.blob()
                  value.photo = URL.createObjectURL(blob)
                })
              }
            }
          })
        }
      })
    }
  }
}
