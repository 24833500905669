export default [
  {
    path: '/crewing-registration',
    name: 'crewing-registration',
    component: () => import('@/views/Crewing/CrewingRegistration.vue')
  },
  {
    path: '/crewing-manager-statement-from-sailor',
    name: 'crewing-manager-statement-from-sailor',
    component: () => import('@/views/Statements/CrewingManager/Table.vue')
  },
  {
    path: '/crewing-manager-statement-from-sailor/:documentID',
    name: 'crewing-manager-statement-from-sailor-info',
    component: () => import('@/views/Statements/CrewingManager/Document.vue')
  },
  {
    path: '/crewing-manager-statement',
    name: 'crewing-manager-statement',
    component: () => import('@/views/NewCrewingManagers/NewCrewingManagers.vue')
  },
  {
    path: '/crewing-manager-statement/:documentID',
    name: 'crewing-manager-statement-info',
    component: () => import('@/views/NewCrewingManagers/NewCrewingManagersDocument/NewCrewingManagersDocument.vue')
  },
  {
    path: '/isc-package-statement',
    name: 'isc-package-statement',
    component: () => import('@/views/Statements/StatementPackageISC/StatementPackageISC.vue')
  },
  // {
  //   path: '/isc-package-statement/:id',
  //   name: 'isc-package-statement-info',
  //   component: () => import('@/views/Statements/StatementPackageISC/StatementPackageISCInfo.vue')
  // },
  {
    path: '/isc-package-statement/:id',
    name: 'isc-package-statement-info',
    component: () => import('@/views/Statements/StatementPackageISC/Document.vue')
  }
]
