import api from '@/plugins/api'
import { getUpdatedObject } from '@/mixins/api'
import { SUCCESS_CODE } from '@/configs/constants'
import { derictory, backoffice } from '@/configs/endpoints'
import i18n from '@/plugins/localization/index'
import { queryString, clearBody } from '@/mixins/main'
export const DIRECTORY = {
  state: () => ({
    isLoadingCity: false,
    isLoading: false,
    sex: [],
    positions: [],
    ranks: [],
    country: [],
    region: [],
    city: [],
    statuses: [],
    typeShip: [],
    modeShipping: [],
    typeGEU: [],
    ports: [],
    port_capitan: [],
    responsibility: [],
    positionsShip: [],
    typeDoc: [],
    typeDocQualification: [],
    extent: [],
    institution: [],
    profession: [],
    qualificationLevels: [],
    specialization: [],
    medInstitution: [],
    doctors: [],
    positionMedical: [],
    limitations: [
      { id: 1, name_ukr: 'Немає', name_eng: 'None' },
      { id: 2, name_ukr: 'Не дійсний для роботи в умовах тропічного клімату', name_eng: 'Not valid for use in tropical climates' },
      { id: 3, name_ukr: 'Необхідно використання окулярів або контактних лінз для виконання службових обов’язків', name_eng: 'You must use glasses or contact lenses to perform your duties' }
    ],
    languages: [],
    affiliate: [],
    agents: [],
    commission: [],
    paymentStatus: [
      { id: 1, status: true, name_ukr: 'Оплачено', name_eng: 'Paid' },
      { id: 2, status: false, name_ukr: 'Не оплачено', name_eng: 'No paid' }],
    educationTraining: [],
    courses: [],
    solutions: [],
    positionFunction: [],
    positionLimitation: [],
    deliveryCities: [],
    faculties: [],
    educationForm: [],
    responsibilityWorkBook: [],
    allCertificatesETI: [],
    allAccrualTypeDoc: [],
    agentsList: [],
    filteredETI: {},
    registrationPermissionsList: [],
    rejectionReasons: [],
    crewingGroups: [],
    shipGroups: [],
    navigatorCertificateTypes: [
      { value: 'ukrainian', name_ukr: 'Український зразок', name_eng: 'Ukrainian model' },
      { value: 'european', name_ukr: 'Європейський зразок', name_eng: 'European model' }
    ],
    navigatorCertificateCategories: ['A', 'B', 'C'],
    radioEquipment: [],
    issuingAuthority: [],
    rivers: [],
    unsignedReasons: [],
    certificateTypes: [
      { id: 0, name_ukr: 'Вулиця' },
      { id: 1, name_ukr: 'Посадовый' },
      { id: 2, name_ukr: 'Preview' }
    ],
    deliveryTypeList: [
      { id: 1, name_ukr: 'Нова пошта (самовивіз)', name_eng: 'Nova Poshta (self-pickup)', courier: false, value: 'novaposhta_pickup' },
      { id: 2, name_ukr: 'Нова пошта (кур’єр)', name_eng: 'Nova Poshta (courier)', courier: true, value: 'novaposhta_courier' },
      { id: 3, name_ukr: 'Cамовивіз із Моррічсервісу', name_eng: 'Self-pickup', courier: false, value: 'morrichservice' }
    ],
    institutionTypes: [
      { id: 0, name_ukr: 'Безкоштовний', name_eng: 'Free' },
      { id: 1, name_ukr: 'Платний', name_eng: 'Paid' },
      { id: 2, name_ukr: 'Плюс', name_eng: 'Plus' }
    ],
    institutionTypesEti: [
      { id: 0, name_ukr: 'Безкоштовний', name_eng: 'Free' },
      { id: 1, name_ukr: 'Плюс', name_eng: 'Plus' },
      { id: 2, name_ukr: 'Платний', name_eng: 'Paid' }
    ],
    passportTypes: [
      { id: 1, name_ukr: 'Паперовий', name_eng: 'Paper' },
      { id: 2, name_ukr: 'Біометрична ID карта', name_eng: 'ID Card' }
    ],
    userTypes: [
      { id: 'secretary_sqc', name_ukr: 'Секретар ДКК', name_eng: 'Secretary of the SQC' },
      { id: 'commissioner', name_ukr: 'Верифікація - секретар ДКК', name_eng: 'Verification - Secretary of the SQC' },
      { id: 'verifier', name_ukr: 'Верифікатор', name_eng: 'Verifier' },
      { id: 'crewing_manager', name_ukr: 'Крюінг-менеджер', name_eng: 'Crewing manager' },
      { id: 'sailor', name_ukr: 'Моряк', name_eng: 'Sailor' },
      { id: 'medical', name_ukr: 'Медичний працівник', name_eng: 'Medical worker' },
      { id: 'secretary_education', name_ukr: 'Секретар ВНЗ', name_eng: 'Secretary of the University' },
      { id: 'crewing_head', name_ukr: 'Голова крюінгу', name_eng: 'Crewing head' },
      { id: 'group_head', name_ukr: 'Голова групи', name_eng: 'Group leader' },
      { id: 'registry', name_ukr: 'Реєстр', name_eng: 'Register' },
      { id: 'dpd', name_ukr: 'Дипломно-паспортний відділ', name_eng: 'Diploma and passport department' },
      { id: 'back_office', name_ukr: 'Back office', name_eng: 'Back office' },
      { id: 'morrichservice', name_ukr: 'МоррічСервіс', name_eng: 'MorrichService' },
      { id: 'eti_employee', name_ukr: 'Представник НТЗ', name_eng: 'ETI representative' },
      { id: 'secretary_atc', name_ukr: 'Секретар КПК', name_eng: 'ATC Secretary' },
      { id: 'harbor_master', name_ukr: 'Капітан порту', name_eng: 'Port captain' },
      { id: 'issuer_service_record', name_ukr: 'Видача ПКМ', name_eng: 'Issuance of service record book' },
      { id: 'verifier_back_office_1', name_ukr: 'Верифікатор БО1', name_eng: 'BO1 verifier' },
      { id: 'verifier_back_office_2', name_ukr: 'Верифікатор БО2', name_eng: 'BO2 verifier' },
      { id: 'admin', name_ukr: 'Адміністратор', name_eng: 'Admin' },
      { id: 'analyst', name_ukr: 'Аналітик', name_eng: 'Analyst' },
      { id: 'support', name_ukr: 'Підтримка', name_eng: 'Support' },
      { id: 'head_itcs', name_ukr: 'Голова ІПМД', name_eng: 'ITCS Head' },
      { id: 'accountant', name_ukr: 'Бухгалтер', name_eng: 'Accountant' },
      { id: 'marad', name_ukr: 'Марад', name_eng: 'Marad' },
      { id: 'director_of_translators', name_ukr: 'Директор компанії перекладачів', name_eng: 'Translators director' },
      { id: 'translator', name_ukr: 'Перекладач', name_eng: 'Translator' },
      { id: 'director_of_mes', name_ukr: 'Директор MOH', name_eng: 'MES Director' },
      { id: 'secretary_mes', name_ukr: 'Секретар МОН', name_eng: 'MES Secretary' },
      { id: 'auth_official_srb', name_ukr: 'Уповноважена особа (ПКМ)', name_eng: 'Authorized person (SRB)' }
    ],
    allDocumentTypes: [
      { id: 1,
        name_ukr: 'Посвідчення особи моряка',
        name_eng: 'Sailor passport',
        content_type: 'sailorpassport',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 2,
        name_ukr: 'Громадянський паспорт',
        name_eng: 'Civil passport',
        content_type: 'passport',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 3,
        name_ukr: 'Закордонний паспорт',
        name_eng: 'International passport',
        content_type: 'foreignpassport',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 4,
        name_ukr: 'Освітній документ',
        name_eng: 'Education document',
        content_type: 'education',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 5,
        name_ukr: 'Кваліфікаційний документ',
        name_eng: 'Qualification document',
        content_type: 'qualificationdocument',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 6,
        name_ukr: 'Підтвердження робочого документу',
        name_eng: 'Proof of diploma',
        content_type: 'proofofworkdiploma',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 7,
        name_ukr: 'Посвідчення судноводія',
        name_eng: 'Certificate of navigator',
        content_type: 'certificateofnavigator',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 8,
        name_ukr: 'Сертифікат НТЗ',
        name_eng: 'Certificate ETI',
        content_type: 'certificateeti',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 9,
        name_ukr: 'Послужна книга моряка',
        name_eng: 'Service record book',
        content_type: 'servicerecord',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 10,
        name_ukr: 'Протокол ДКК',
        name_eng: 'Protocol SQC',
        content_type: 'protocolsqc',
        useInDocumentVerification: true },
      { id: 11,
        name_ukr: 'Медичний сертифікат',
        name_eng: 'Medical certificate',
        content_type: 'medicalcertificate',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 12,
        name_ukr: 'Запис до послужної книги',
        name_eng: 'Experience in service record',
        content_type: 'lineinservicerecord',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 13,
        name_ukr: 'Довідка про стаж',
        name_eng: 'Experience certificate',
        content_type: 'lineinservicerecord',
        useInDocumentVerification: true,
        isDocument: true },
      { id: 14,
        name_ukr: 'Заява ПКМ',
        name_eng: 'Record book statement',
        content_type: 'statementservicerecord',
        isStatement: true },
      { id: 15,
        name_ukr: 'Заява ДПВ',
        name_eng: 'Qualification statement',
        content_type: 'statementqualification',
        isStatement: true },
      { id: 16,
        name_ukr: 'Заява ДКК',
        name_eng: 'SQC statement',
        content_type: 'statementsqc',
        isStatement: true },
      { id: 17,
        name_ukr: 'Заява НТЗ',
        name_eng: 'ETI statement',
        content_type: 'statementeti',
        isStatement: true },
      { id: 18,
        name_ukr: 'Заява на отримання посвідчення особи моряка',
        name_eng: 'Sailor passport statement',
        content_type: 'statementsailorpassport',
        isStatement: true },
      { id: 19,
        name_ukr: 'Заява на отримання медичного сертифікату',
        name_eng: 'Medical statement',
        content_type: 'statementmedicalcertificate',
        isStatement: true },
      { id: 20,
        name_ukr: 'Заява СПК',
        name_eng: 'Advanced training statement',
        content_type: 'statementadvancedtraining',
        isStatement: true },
      { id: 21,
        name_ukr: 'Заява посаду',
        name_eng: 'Position statement',
        content_type: 'packetitem',
        isDocument: true },
      { id: 22,
        name_ukr: 'Заява на комісіонера',
        name_eng: 'Commissioner statement',
        content_type: 'commissionerstatement',
        isStatement: true },
      { id: 23,
        name_ukr: 'Верифікація фото',
        name_eng: 'Photo verification',
        content_type: 'sailorphoto' },
      { id: 24,
        name_ukr: 'Довідка про стаж',
        name_eng: 'Experience certificate',
        content_type: 'experienceline',
        useInDocumentVerification: true,
        isDocument: true },
      {
        id: 25,
        name_ukr: i18n.t('model-Education'),
        name_eng: i18n.t('model-Education'),
        content_type: 'higheducation' },
      {
        id: 26,
        name_ukr: i18n.t('qualificationWorkerDiploma'),
        name_eng: i18n.t('qualificationWorkerDiploma'),
        content_type: 'qualify_worker' },
      {
        id: 27,
        name_ukr: i18n.t('advanceTrainingCertificate'),
        name_eng: i18n.t('advanceTrainingCertificate'),
        content_type: 'upper_qualify' },
      {
        id: 28,
        name_ukr: i18n.t('workQualificationCertificate'),
        name_eng: i18n.t('workQualificationCertificate'),
        content_type: 'worker_qualify' }
    ],
    listTypeSQC: [
      { id: 0,
        name_ukr: 'ДКК іспит',
        value: '0',
        forStatementSQC: 0,
        is_continue: 0,
        ces_exam: false },
      { id: 1,
        name_ukr: 'ДКК іспит (присвоєння звання)',
        value: '0',
        forStatementSQC: 0,
        is_continue: 0,
        ces_exam: false },
      { id: 4,
        name_ukr: 'ДКК іспит (підтвердження звання)',
        value: '0',
        forStatementSQC: 1,
        is_continue: 1,
        ces_exam: false },
      { id: 5,
        name_ukr: 'ДКК іспит (підтвердження з присвоєнням нової посади)',
        value: '0',
        forStatementSQC: 2,
        is_continue: 2,
        ces_exam: false },
      { id: 2,
        name_ukr: 'ДКК стаж',
        value: '1',
        forStatementSQC: 3,
        is_continue: 3,
        ces_exam: false },
      { id: 3,
        name_ukr: 'Іспит CES 6.0',
        value: '2',
        forStatementSQC: 4,
        is_continue: null,
        ces_exam: true }
    ],
    verificationDocumentTypes: [
      { id: 1, name_ukr: 'Посвідчення особи моряка', name_eng: 'Sailor passport' },
      { id: 2, name_ukr: 'Громадянський паспорт', name_eng: 'Civil passport' },
      { id: 3, name_ukr: 'Закордонний паспорт', name_eng: 'International passport' },
      { id: 4, name_ukr: 'Освітній документ', name_eng: 'Education document' },
      { id: 5, name_ukr: 'Кваліфікаційний документ', name_eng: 'Qualification document' },
      { id: 6, name_ukr: 'Підтвердження робочого документу', name_eng: 'Proof of diploma' },
      { id: 7, name_ukr: 'Посвідчення судноводія', name_eng: 'Certificate of navigator' },
      { id: 8, name_ukr: 'Сертифікат НТЗ', name_eng: 'Certificate ETI' },
      { id: 9, name_ukr: 'Послужна книга моряка', name_eng: 'Service record book' },
      { id: 10, name_ukr: 'Протокол ДКК', name_eng: 'Protocol SQC' },
      { id: 11, name_ukr: 'Медичний сертифікат', name_eng: 'Medical certificate' },
      { id: 12, name_ukr: 'Запис до послужної книги', name_eng: 'Experience in service record' },
      { id: 13, name_ukr: 'Довідка про стаж плавання', name_eng: 'Experience certificate' }
    ],
    listConventionalTypeDoc: [
      {
        id: 14,
        name_ukr: 'Трудова книжка, довідка про фаховий стаж, ремонт, практику, тощо',
        name_eng: 'Employment record book, certificate of professional experience, repairs, practice, etc.'
      }
    ],
    decision: [
      { id: 1, name_ukr: 'Має належну кваліфікацію', name_eng: 'Is properly qualified' },
      { id: 2, name_ukr: 'Не має належної кваліфікації', name_eng: 'Not properly qualified' }
    ],
    direction: [],
    commissioner_types: [
      { id: 1, name_ukr: 'Голова комісії', name_eng: 'Head commission', abbreviation: 'HD' },
      { id: 2, name_ukr: 'Член комісії', name_eng: 'Сommission member', abbreviation: 'CH' }
    ],
    issuedByList: [
      { id: 1, name_ukr: 'Всі', name_eng: 'Always', value: null },
      { id: 2, name_ukr: 'Моррічсервіс', name_eng: 'Morrichservice', value: 'morrichservice' },
      { id: 3, name_ukr: 'Інспекція з підготовки та дипломування моряків', name_eng: 'THE INSPECTORATE FOR TRAINING AND CERTIFICATION OF SEAFARERS', value: 'itcs' }
    ],
    mappingApplicationAvailability: [
      { id: 1, name: i18n.t('orStatement'), value: null },
      { id: 2, name: i18n.t('withStatement'), value: true },
      { id: 3, name: i18n.t('withoutStatement'), value: false }
    ],
    mappingResultsEQC: [
      { id: 1, name: i18n.t('orPassedEducationExam'), value: null },
      { id: 2, name: i18n.t('passedEducationExam'), value: true },
      { id: 3, name: i18n.t('noPassedEducationExam'), value: false }
    ],
    mappingEducExamPass: [
      { id: 1, name: i18n.t('orEducationWithSQC'), value: null },
      { id: 2, name: i18n.t('educationWithSQC'), value: true },
      { id: 3, name: i18n.t('noEducationWithSQC'), value: false }
    ],
    statusProtocol: [
      { id: 0, name_ukr: 'Відхилено' },
      { id: 1, name_ukr: 'На верифікації' },
      { id: 2, name_ukr: 'На редагуванні' },
      { id: 3, name_ukr: 'Верифіковано' },
      { id: 4, name_ukr: 'Термін дії закінчився' },
      { id: 5, name_ukr: 'Анульовано' }
    ],
    listStatusPositionStatement: [
      { id: 1, name_ukr: 'Наявні', value: 'Was be' },
      { id: 3, name_ukr: 'Отримані', value: 'Was bought' }
    ],
    listDocumentStatus: [
      { id: 0, name_ukr: i18n.t('allDocs'), value: '' },
      { id: 1, name_ukr: i18n.t('got'), value: 'Was bought' },
      { id: 2, name_ukr: i18n.t('documentAdded'), value: 'DocumentNostrification added' },
      { id: 3, name_ukr: i18n.t('notGot'), value: 'To buy' },
      { id: 4, name_ukr: i18n.t('restorePayment'), value: 'Restore payment' },
      { id: 5, name_ukr: i18n.t('canceled'), value: 'Canceled' }
    ],
    processingStatuses: [
      { id: 0, name_ukr: 'Не потрібно', name_eng: 'Do not need' },
      { id: 1, name_ukr: 'Потрібно за 20 днів', name_eng: 'Needed in 20 days' },
      { id: 2, name_ukr: 'Потрібно за 7 днів', name_eng: 'Needed in 7 days' },
      { id: 3, name_ukr: 'Подовження за 20 днів', name_eng: 'Continued in 20 days' },
      { id: 4, name_ukr: 'Подовження за 7 днів', name_eng: 'Continued in 7 days' }
    ],
    listTypeExam: [
      { id: 1, name_ukr: 'Оффлайн', name_eng: 'Offline', label: 'Offline', value: '1' },
      { id: 2, name_ukr: 'Онлайн', name_eng: 'Online', label: 'Online', value: '2' }
    ],
    listCreatedBy: [
      { id: 'sailor', name_ukr: 'Cтворено з ЛК', name_eng: 'Created with personal cabinet' },
      { id: 'morrichservice', name_ukr: 'Створено МРС', name_eng: 'Created MRS' },
      { id: 'other', name_ukr: 'Створено іншим', name_eng: 'Created other' }
    ],
    listStatusesDocumentPositions: [
      { id: 1, name_ukr: 'Всі заявки на посаду', value: null },
      { id: 2, name_ukr: 'Подані заявки на посаду', value: 'without_restore_payment' },
      { id: 3, name_ukr: 'Повернення платежу', value: 'restore_payment' }
    ],
    translators: [],
    listDistributionType: [
      { id: 1, name_ukr: 'Заява на нострифікацію', value: 'statement' },
      { id: 2, name_ukr: 'Переклад', value: 'translation' },
      { id: 3, name_ukr: 'Нострифікація', value: 'mes' }
    ],
    listDictionaryType: [
      { id: 1, name_ukr: 'Курси підвищення кваліфікації', value: 'LevelQualification' },
      { id: 2, name_ukr: 'Курси НТЗ', value: 'EtiCourse' },
      { id: 3, name_ukr: 'Звання', value: 'Rank' },
      { id: 4, name_ukr: 'Посада', value: 'Position' },
      { id: 5, name_ukr: 'Мед. посада', value: 'MedPosition' },
      { id: 6, name_ukr: 'Мед. заклад', value: 'MedInstitution' },
      { id: 7, name_ukr: 'Філія', value: 'BranchOffice' },
      { id: 8, name_ukr: 'Крюїнг компанії', value: 'CrewingCompany' }
    ],
    selectedDictionary: [],
    listCauseOfFailure: [
      { id: 1, name_ukr: 'Швидка здача' },
      { id: 2, name_ukr: 'Вийшов час' },
      { id: 3, name_ukr: 'Немає різноманіття відповідей' },
      { id: 4, name_ukr: 'Загальне' }
    ],
    listAccessToFunctionalISC: [
      { id: 1, name_ukr: 'Обмежений функціонал', name_eng: 'Limited functionality', is_active: false },
      { id: 2, name_ukr: 'Функціонал ITCS', name_eng: 'Functionality ITCS', is_active: true },
      { id: 3, name_ukr: 'Функціонал ISC', name_eng: 'Functionality ISC', is_active: false },
      { id: 4, name_ukr: 'Обʼєднаний функціонал ITCS та ISC', name_eng: 'All functionality ITCS and ISC', is_active: false }
    ],
    listWay: [
      { name_eng: '-', name_ukr: 'С' },
      { name_eng: '-', name_ukr: 'М' },
      { name_eng: '-', name_ukr: 'РК' },
      { name_eng: '-', name_ukr: 'Р' },
      { name_eng: '-', name_ukr: 'Т' },
      { name_eng: '-', name_ukr: 'ПФ' }
    ],
    listExperience: [
      { name_eng: 'None', name_ukr: 'Немає', value: false },
      { name_eng: 'Exist', name_ukr: 'Існує', value: true }
    ],
    listSolution: [
      { value: 'assign', name_ukr: i18n.t('assign') },
      { value: 'not_assign', name_ukr: i18n.t('not_assign') },
      { value: 'confirm', name_ukr: i18n.t('confirm') },
      { value: 'not_confirm', name_ukr: i18n.t('not_confirm') },
      { value: 'give', name_ukr: i18n.t('give') },
      { value: 'not_give', name_ukr: i18n.t('not_give') }
    ],
    listCrewOrNot: [
      { id: 1, name_ukr: i18n.t('allStatement'), value: null },
      { id: 2, name_ukr: i18n.t('sailorHasCrewing'), value: true },
      { id: 2, name_ukr: i18n.t('sailorHasNotCrewing'), value: false }
    ],
    listBooleanOptions: [
      { id: 0, name_ukr: i18n.t('yes'), value: true },
      { id: 1, name_ukr: i18n.t('no'), value: false }
    ],
    listCadetType: [
      // { id: 1, name_ukr: i18n.t('orCadet'), value: null },
      { id: 2, name_ukr: i18n.t('cadet'), value: true },
      { id: 3, name_ukr: i18n.t('notCadet'), value: false }
    ],
    listTypeStatements: [
      { name_ukr: 'Всі', value: null },
      { name_ukr: 'ДКК іспит', value: '0,1,2' },
      { name_ukr: 'ДКК стаж', value: '3' },
      { name_ukr: 'Іспит CES 6.0', value: true }
    ],
    listProtocolAvailability: [
      { id: 1, name_ukr: i18n.t('orProtocol'), value: null },
      { id: 2, name_ukr: i18n.t('withProtocol'), value: true },
      { id: 3, name_ukr: i18n.t('withoutProtocol'), value: false }
    ],
    listStatementTypes: [
      { id: 0, name_ukr: i18n.t('continuing'), value: true },
      { id: 1, name_ukr: i18n.t('creatingNew'), value: false }
    ],
    typeStatements: [
      { name_ukr: 'Всі', value: null },
      { name_ukr: 'ДКК іспит', value: '0,1,2' },
      { name_ukr: 'ДКК стаж', value: '3' },
      { name_ukr: 'Іспит CES 6.0', value: true }
    ],
    listFormParams: [
      { label: i18n.t('equalSum'), value: 'equal' },
      { label: i18n.t('moreOrEqual'), value: 'more' },
      { label: i18n.t('lessOrEqual'), value: 'less' }
    ],
    listDistributionTypeUsual: [
      { id: 1, name_ukr: i18n.t('sqcWithExp'), value: 'sqc', with_exp: true },
      { id: 2, name_ukr: i18n.t('sqcWithoutExp'), value: 'sqc', with_exp: false },
      { id: 3, name_ukr: i18n.t('sqcCadet'), value: 'sqc', is_cadet: true },
      { id: 4, name_ukr: i18n.t('medicalInstitution'), value: 'medical' },
      { id: 5, name_ukr: i18n.t('eti'), value: 'eti' },
      { id: 6, name_ukr: i18n.t('diplomasQualification'), value: 'dpd' },
      { id: 7, name_ukr: i18n.t('portal'), value: 'portal' },
      { id: 8, name_ukr: i18n.t('crewingManagers'), value: 'crewing_manager', is_cadet: false },
      { id: 9, name_ukr: i18n.t('crewingManagerCadet'), value: 'crewing_manager', is_cadet: true },
      { id: 10, name_ukr: i18n.t('serviceCenter'), value: 'sc' },
      { id: 11, name_ukr: i18n.t('advanceTrainingCourse'), value: 'adv_training' }
    ]
  }),
  mutations: {
    setStateData (state, data) {
      state[data.type] = data.data
    },
    SET_DATA_STATE_DIRECTORY (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    },
    addStateData (state, data) {
      state[data.type].push(data.data)
    },
    clearFilteredEtiList (state) {
      state.filteredETI = {}
    },
    UPDATE_DATA_DIRECTORY (state, data) {
      const updatedObject = getUpdatedObject(data)
      const newArray = state[updatedObject.type].filter(item => item.id !== updatedObject.value.id)
      newArray.push(updatedObject.value)
      state[updatedObject.type] = newArray
    }
  },
  actions: {
    async getSex ({ commit }) {
      await api.get('api/v1/directory/sex/').then(response => {
        commit('setStateData', {
          type: 'sex',
          data: response.data
        })
      })
    },

    async getRanks ({ commit }) {
      await api.get('api/v1/directory/rank/').then(response => {
        commit('setStateData', {
          type: 'ranks',
          data: response.data
        })
      })
    },

    async getPositions ({ commit }) {
      await api.get('api/v1/directory/position/').then(response => {
        commit('setStateData', {
          type: 'positions',
          data: response.data
        })
      })
    },

    async getCountry ({ commit }) {
      await api.get('api/v1/directory/country/').then(response => {
        commit('setStateData', {
          type: 'country',
          data: response.data
        })
      })
    },

    getRegion ({ commit }, countryID) {
      let params = new URLSearchParams({})
      if (countryID) params.set('country', countryID)

      return api.get(`api/v1/directory/region/?${params}`).then(response => {
        if (response?.code) commit('setStateData', { type: 'region', data: response.data })
        return response
      })
    },

    getCity ({ commit }, regionID) {
      commit('SET_DATA_STATE_DIRECTORY', { city: [], isLoadingCity: true })

      let params = new URLSearchParams({})
      if (regionID) params.set('region', regionID)

      return api.get(`api/v1/directory/city/?${params}`).then(response => {
        if (response?.code) commit('SET_DATA_STATE_DIRECTORY', { city: response.data, isLoadingCity: false })
        return response
      })
    },

    async getStatusDocs ({ commit }) {
      await api.get('api/v1/directory/status_document/').then(response => {
        commit('setStateData', {
          type: 'statuses',
          data: response.data
        })
      })
    },

    async getTypeShip ({ commit }) {
      await api.get('api/v1/directory/type_vessel/').then(response => {
        commit('setStateData', {
          type: 'typeShip',
          data: response.data
        })
      })
    },

    async getModeShipping ({ commit }) {
      await api.get('api/v1/directory/mode_of_navigation/').then(response => {
        commit('setStateData', {
          type: 'modeShipping',
          data: response.data
        })
      })
    },

    async getTypeGEU ({ commit }) {
      await api.get('api/v1/directory/type_geu/').then(response => {
        commit('setStateData', {
          type: 'typeGEU',
          data: response.data
        })
      })
    },

    async getPorts ({ commit }) {
      await api.get('api/v1/directory/port/').then(response => {
        commit('setStateData', {
          type: 'ports',
          data: response.data
        })
      })
    },

    async getResponsibility ({ commit }) {
      await api.get('api/v1/directory/responsibility/').then(response => {
        commit('setStateData', {
          type: 'responsibility',
          data: response.data
        })
      })
    },

    async getPositionsOnShip ({ commit }) {
      await api.get('api/v1/directory/position_for_experience/').then(response => {
        commit('setStateData', {
          type: 'positionsShip',
          data: response.data
        })
      })
    },

    async getTypeDoc ({ commit }) {
      await api.get('api/v1/directory/type_document_nz/').then(response => {
        commit('setStateData', {
          type: 'typeDoc',
          data: response.data
        })
      })
    },

    async getTypeDocQual ({ commit }) {
      await api.get('api/v1/directory/type_document/').then(response => {
        commit('setStateData', {
          type: 'typeDocQualification',
          data: response.data
        })
      })
    },

    async getExtent ({ commit }) {
      await api.get('api/v1/directory/extent_nz/').then(response => {
        response.data.sort(function (a, b) {
          return (a.id - b.id)
        })
        commit('setStateData', {
          type: 'extent',
          data: response.data
        })
      })
    },

    async getNameInstitution ({ commit }, params) {
      const response = await api.get('api/v1/directory/name_nz/' + queryString(params))
      !params && commit('setStateData', { type: 'institution', data: response.data })
      return response
    },

    async getProfession ({ commit }) {
      await api.get('api/v1/directory/speciality/').then(response => {
        commit('setStateData', {
          type: 'profession',
          data: response.data
        })
      })
    },

    async getQualification ({ commit }) {
      await api.get('api/v1/directory/level_qualification/').then(response => {
        commit('setStateData', {
          type: 'qualificationLevels',
          data: response.data
        })
      })
    },

    async getSpecialization ({ commit }) {
      await api.get('api/v1/directory/specialization/').then(response => {
        commit('setStateData', {
          type: 'specialization',
          data: response.data
        })
      })
    },

    async getMedicalInstitution ({ commit }) {
      await api.get('api/v1/directory/medical_institution/').then(response => {
        commit('setStateData', {
          type: 'medInstitution',
          data: response.data
        })
      })
    },

    async getDoctors ({ commit }) {
      await api.get('api/v1/directory/doctor_in_medical/').then(response => {
        commit('setStateData', {
          type: 'doctors',
          data: response.data
        })
      })
    },

    async getPositionForMedical ({ commit }) {
      await api.get('api/v1/directory/position_for_medical/').then(response => {
        commit('setStateData', {
          type: 'positionMedical',
          data: response.data
        })
      })
    },

    async getAffiliate ({ commit }) {
      await api.get('api/v1/directory/branch_office/').then(response => {
        commit('setStateData', {
          type: 'affiliate',
          data: response.data
        })
      })
    },

    async getCrewingManagers ({ commit }) {
      await api.get('api/v1/directory/auth_users/').then(response => {
        commit('setStateData', {
          type: 'agents',
          data: response.data
        })
      })
    },

    async getCommissioners ({ commit }) {
      await api.get('api/v1/directory/commisioner_for_committe/').then(response => {
        response.data = response.data.filter(value => value.name)
        response.data.map(value => {
          value.signer = value.id
          value.user_fio_ukr = value.name
        })
        commit('setStateData', { type: 'commission', data: response.data })
      })
    },

    async getETI ({ commit }) {
      await api.get('api/v1/directory/ntz/').then(response => {
        commit('setStateData', {
          type: 'educationTraining',
          data: response.data
        })
      })
    },

    async getCourses ({ commit }) {
      await api.get('api/v1/directory/course_for_ntz/').then(response => {
        commit('setStateData', {
          type: 'courses',
          data: response.data
        })
      })
    },

    async getSolutions ({ commit }) {
      await api.get('api/v1/directory/decision/').then(response => {
        commit('setStateData', {
          type: 'solutions',
          data: response.data
        })
      })
    },

    async getPositionsFunctions ({ commit }) {
      await api.get('api/v1/directory/functions_for_position/').then(response => {
        commit('setStateData', {
          type: 'positionFunction',
          data: response.data
        })
      })
    },

    async getPositionsLimitations ({ commit }) {
      await api.get('api/v1/directory/limitation/').then(response => {
        commit('setStateData', {
          type: 'positionLimitation',
          data: response.data
        })
      })
    },

    async getDeliveryCity ({ commit }) {
      await api.get('api/v1/delivery/novaposhta_city/').then(response => {
        commit('setStateData', {
          type: 'deliveryCities',
          data: response.data
        })
      })
    },

    async getFaculties ({ commit }) {
      await api.get('api/v1/directory/faculty/').then(response => {
        commit('setStateData', {
          type: 'faculties',
          data: response.data
        })
      })
    },

    async getEducationForm ({ commit }) {
      await api.get('api/v1/directory/education_form/').then(response => {
        commit('setStateData', {
          type: 'educationForm',
          data: response.data
        })
      })
    },

    async getResponsibilityWorkBook ({ commit }) {
      await api.get('api/v1/directory/responsibility_work_book/').then(response => {
        commit('setStateData', {
          type: 'responsibilityWorkBook',
          data: response.data
        })
      })
    },

    async getAllCertificatesETI ({ commit }) {
      await api.get('api/v1/directory/NTZ/').then(response => {
        commit('setStateData', {
          type: 'allCertificatesETI',
          data: response.data
        })
      })
    },

    async getAllAccrualTypeDoc ({ commit }) {
      await api.get('api/v1/directory/type_of_accrual_rules/').then(response => {
        commit('setStateData', {
          type: 'allAccrualTypeDoc',
          data: response.data
        })
      })
    },

    async getAgentsList ({ commit }) {
      await api.get('api/v1/auth/user/?type_user=crewing_manager').then(response => {
        response.data.results.map(item => {
          item.fullName = `${item.last_name} ${item.first_name} ${item.userprofile.middle_name}`
        })

        commit('setStateData', {
          type: 'agentsList',
          data: response.data.results
        })
      })
    },

    getFilteredETI ({ commit, state }, searchQueries) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams({
          course: searchQueries.course,
          city: searchQueries.city
        })
        api.get(`api/v1/back_off/certificates/list_eti/?${params}`).then(response => {
          if ([200, 201].includes(response.code)) {
            response.data = response.data.filter(item => item.order !== null)
            response.data.map((item, key) => {
              item.institutionName = item.ntz[searchQueries.nameLang]
              switch (key) {
                case 0:
                  item.status = 'green-option'
                  break
                case 1:
                  item.status = 'grey-option'
                  break
                default:
                  item.status = 'red-option'
              }
            })
            state.filteredETI[searchQueries.arrayIndex] = response.data
            resolve(response)
          }
        })
      })
    },

    async getRegistrationPermissions ({ commit }) {
      await api.get('api/v1/auth/group/').then(response => {
        if (response?.code === 200) {
          response.data.map(item => {
            item.text = item.name
            delete item.name
          })
          response.data.sort((a, b) => {
            if (a.text < b.text) return -1
            else if (a.text > b.text) return 1
            else return 0
          })
          commit('setStateData', { type: 'registrationPermissionsList', data: response.data })
        }
      })
    },

    async getRejectionReasons ({ commit }) {
      await api.get('api/v1/directory/reason/').then(response => {
        commit('setStateData', { type: 'rejectionReasons', data: response.data })
      })
    },

    async getCrewingGroups ({ commit }, params) {
      commit('SET_DATA_STATE_DIRECTORY', { isLoading: true })
      await api.get('api/v1/directory/crewing_group/' + queryString(params)).then(response => {
        commit('setStateData', { type: 'crewingGroups', data: response.data })
        commit('SET_DATA_STATE_DIRECTORY', { isLoading: false })
      })
    },
    async getShipGroups ({ commit }) {
      await api.get('api/v1/directory/ship_group/').then(response => {
        if (response.code === 200) commit('setStateData', { type: 'shipGroups', data: response.data })
      })
    },
    async getRadioEquipment ({ commit }) {
      await api.get('api/v1/directory/radio_equipment/').then(response => {
        if (response.code === 200) commit('setStateData', { type: 'radioEquipment', data: response.data })
      })
    },
    async getIssuingAuthority ({ commit }) {
      await api.get('api/v1/directory/issuing_authority/').then(response => {
        if (response.code === 200) commit('setStateData', { type: 'issuingAuthority', data: response.data })
      })
    },
    async getRivers ({ commit }) {
      await api.get('api/v1/directory/river/').then(response => {
        if (response.code === 200) commit('setStateData', { type: 'rivers', data: response.data })
      })
    },
    async getPortCapitan (context) {
      await api.get('/api/v1/directory/port_capitan').then(response => {
        if (response.code === 200) context.commit('setStateData', { port_capitan: response.data })
      })
    },
    async getNameInstitutions (context, data) {
      let params = new URLSearchParams()
      Object.entries(data).forEach(([key, value]) => (params.set(key, value)))
      const response = await api.get(`api/v1/directory/name_nz/?${params}`)
      context.commit('setStateData', {
        type: 'institution',
        data: response.data
      })
      return response
    },
    async gdirection (context) {
      const response = await api.get(derictory.direction)
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_DIRECTORY', { direction: response.data })
    },
    async getMedicalInstitutionForPacket (context, id) {
      const response = await api.get(backoffice.medicalInstitutionById(id))
      if (SUCCESS_CODE.includes(response.code)) return response.data
    },
    getUnsignedDocumentReasons ({ commit }) {
      return api.get('api/v1/directory/unsigned_document_reason/').then(response => {
        if (response.code === 200) commit('setStateData', { type: 'unsignedReasons', data: response.data })
        return response
      })
    },
    async getLanguages ({ commit }) {
      await api.get('api/v1/directory/language/').then(response => {
        if (SUCCESS_CODE.includes(response.code)) commit('setStateData', { type: 'languages', data: response.data })
      })
    },
    async getTranslatorCompanies (contex, params) {
      const response = await api.get(derictory.translators + queryString(clearBody(params)))
      if (SUCCESS_CODE.includes(response.code)) contex.commit('setStateData', { type: 'translators', data: response.data })
    },
    async getListDictionary ({ commit }, data) {
      commit('SET_DATA_STATE_DIRECTORY', { isLoading: true })
      const response = await api.get(derictory.dictionary[data.dictionaryType] + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DIRECTORY', { selectedDictionary: response.data })
      commit('SET_DATA_STATE_DIRECTORY', { isLoading: false })
    },
    async getFreeSpacesAmount (context, data) {
      const response = await api.get(derictory.freePlaces(data.branchOffice, data.dateMeeting))
      if (SUCCESS_CODE.includes(response.code)) {
        return response
      }
    }
  },

  getters: {
    ranksSQC: state => state.ranks.filter(rank => rank.is_dkk && !rank.is_disable),
    availableRanksList: state => state.ranks.filter(rank => !rank.is_disable),
    positionsByRankID: state => id => state.positions.filter(position => position.rank === id),
    availablePositionsByID: state => id => {
      if (id) return state.positions.filter(position => position.rank === id && !position.is_disable)
      else return []
    },
    positionsByIdSQC: state => id => {
      if (id) return state.positions.filter(position => position.rank === id && position.is_dkk && !position.is_disable)
      else return []
    },
    regionById: state => id => state.region.filter(region => region.country === id),
    cityById: state => id => state.city.filter(city => city.region === id),
    cityByName: state => name => state.city.filter(city => city.name === name),
    statusChoose: state => serv => state.statuses.filter(status => status.for_service === serv),
    portsActual: state => actuality => state.ports.filter(port => port.is_disable === actuality),
    typeDocsForUpload: state => state.typeDoc.filter(typeDoc => typeDoc.id === 2 || typeDoc.id === 3),
    typeDocQualificationByService: state => service => state.typeDocQualification.filter(typeDoc => typeDoc.for_service === service),
    institutionByType: state => id => state.institution.filter(inst => inst.type_nz === id),
    professionById: state => id => state.profession.filter(profession => profession.type_document_nz === id),
    qualificationById: state => id => state.qualificationLevels.filter(qualification => qualification.type_NZ === id),
    specializationById: state => id => state.specialization.filter(specialization => specialization.speciality === id),
    doctorsByMedInstitutionID: state => id => state.doctors.filter(doctor => doctor.medical_institution === id),
    affiliateByName: state => name => state.affiliate.filter(affiliate => affiliate.name_ukr === name),
    paymentStatusByStatus: state => status => state.paymentStatus.filter(paymentStatus => paymentStatus.status === status),
    functionByPosition: state => id => state.positionFunction.filter(positionFunction => positionFunction.position === id),
    notDisabledPorts: state => state.ports.filter(port => !port.is_disable),
    getDirectoryObject: state => data => state[data.value].find(item => item.id === data.id),
    getDirectoryObjectByKey: state => data => state[data.state].find(item => item[data.key] === data[data.key]),

    institutionTypeByID: state => id => state.institutionTypes.find(item => item.id === id) || {},
    passportTypeByID: state => id => state.passportTypes.find(item => item.id === id) || {},
    userTypeByID: state => id => state.userTypes.find(item => item.id === id) || {},
    educationTrainingInstitutionByID: state => id => state.educationTraining.find(item => item.id === id) || {},
    documentByContentType: state => contentType => state.allDocumentTypes.find(item => item.content_type === contentType) || {},
    documentByID: state => id => state.allDocumentTypes.find(item => item.id === id) || {},
    typeDocQualificationByID: state => id => state.typeDocQualification.find(typeDoc => typeDoc.id === id) || {},
    rankByID: state => id => state.ranks.find(rank => rank.id === id) || {},
    positionByID: state => id => state.positions.find(position => position.id === id) || {},
    statusById: state => id => state.statuses.find(status => status.id === id) || {},
    portByID: state => id => state.ports.find(port => port.id === id) || {},
    qualificationLimitationByID: state => id => state.positionLimitation.find(limitation => limitation.id === id) || {},
    qualificationLimitationFunctionByID: state => id => state.positionFunction.find(limitation => limitation.id === id) || {},
    getTypeSQC: state => data => state[data.value].find(item => item.forStatementSQC === data.forStatementSQC),
    decisionByID: state => id => state.solutions.find(decision => decision.id === id) || {},
    educationTypeDocumentByID: state => id => state.typeDoc.find(type => type.id === id) || {},
    qualificationLevelByID: state => id => state.qualificationLevels.find(qualification => qualification.id === id) || {},
    educationProfessionByID: state => id => state.profession.find(profession => profession.id === id) || {},
    extentByID: state => id => state.extent.find(extent => extent.id === id) || {},
    institutionByID: state => id => state.institution.find(institution => institution.id === id) || {},
    specializationByID: state => id => state.specialization.find(specialization => specialization.id === id) || {},
    positionMedicalByID: state => id => state.positionMedical.find(position => position.id === id) || {},
    doctorByID: state => id => state.doctors.find(doctor => doctor.id === id) || {},
    medicalLimitationByID: state => id => state.limitations.find(limitation => limitation.id === id) || {},
    medicalInstitutionByID: state => id => state.medInstitution.find(institution => institution.id === id) || {},
    affiliateByID: state => id => state.affiliate.find(affiliate => affiliate.id === id) || {},
    rejectionReasonByID: state => id => state.rejectionReasons.find(reason => reason.id === id) || {},
    typeShipByID: state => id => state.typeShip.find(type => type.id === id) || {},
    navigationModeByID: state => id => state.modeShipping.find(mode => mode.id === id) || {},
    geuTypeByID: state => id => state.typeGEU.find(type => type.id === id) || {},
    experiencePositionByID: state => id => state.positionsShip.find(position => position.id === id) || {},
    responsibilityByID: state => id => state.responsibility.find(responsibility => responsibility.id === id) || {},
    getCommissionerTypes: state => abbreviation => state.commissioner_types.find(item => item.abbreviation === abbreviation) || {},
    getDirectoryObjectAboutUUID: state => (directory, uuid) => state[directory].find(item => item.uuid === uuid) || {}
  }
}
