import api from '@/plugins/api'
import notify from '@ananasbear/notify'
import i18n from '@/plugins/localization/index'
import { packageStatment as endpoints, sailor } from '@/configs/endpoints'
import { SUCCESS_CODE } from '@/configs/constants'

export const PACKET = {
  actions: {
    async cancelStatementInPaket ({ rootState }, data) {
      const response = await api.post(sailor.packet.cancelItem(data?.id || rootState.state.sailorId, data.packetId, data.documentId))
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success(`${i18n.t('statementWasCancel')}`)
      } else notify.error(`${response.data}`)
    },
    async getAvailableDocuments (context, data) {
      const response = await api.get(endpoints.availableDocuments(data.packetId, data.dependencyId))
      return response
    },
    async addDocumentInPackage (context, data) {
      const response = await api.post(endpoints.addDocument(data.packetId, data.dependencyId), { body: data.body })
      return response
    },
    updateFilesPositionStatement ({ rootState, dispatch }, data) {
      return api.post(sailor.paket.updateFile(data.id || rootState.sailor.sailorId, data.documentID, data.fileId),
        { body: data.body, responseType: 'blob' })
        .then(response => {
          if (SUCCESS_CODE.includes(response?.code)) dispatch('getPositionStatements')
          return response
        })
    },
    async uploadPositionStatementPaymentReceipt ({ rootState, dispatch }, data) {
      const response = await api.post(sailor.packet.uploadDocumentFile(data.id || rootState.sailor.sailorId, data.documentID, data.selectDocumentId),
        { body: data.body, responseType: 'blob' })
      return response
    },
    async createRestatementSQC (context, data) {
      const response = await api.post(sailor.packet.createStatementSQC(data.id, data.documentID))
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('createdStatement')
        return response
      }
      return {}
    }
  }
}
