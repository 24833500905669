<template lang="pug">
include ../../../configs/template
div
    div.ml-3(v-if="radioListItem && radioListItem.length")
      v-radio-group(v-model="radio" row)
        v-radio(
          v-for="n in radioListItem"
          :key="n.id"
          :label="$t(n.label)"
          :value="n.value")
    ReportSearchV2(
      v-if="!hideFilters && !isHiddenSearchParams"
      @querySearch="querySearch"
      :isLoading="isLoading"
      :pageSize="page_size"
      :defaultFilter="defaultFilter"
      @saveReport="saveReport")

    div(v-if="!isHiddenTableSearch || !isHiddenTableCount || (checkAccess(type, 'create') && $slots.AddBlock)").mt-4.d-flex.align-end.justify-end.border-bottom.border-top.pt-6
      div.w-100
        +field-validation('search', 'search', '["minLength"]')(v-if="!isHiddenTableSearch" :append-icon="'mdi-magnify'" :loading="isLoadSearch" @input="searchTable")
      div(v-if="!isHiddenTableCount" style="width: 70px")
       v-tooltip(top)
        template(#activator='{ on, attrs }')
          +select('page_size', 'pageSizeList', 'countTable', '"count"')(v-bind="attrs" v-on="on" item-value="count")
        span {{ $t('tooltip.countTableTooltip') }}
      div(
        v-if="checkAccess(type, 'create') && $slots.AddBlock"
        @click="isViewAddSlot = !isViewAddSlot"
      )
        v-tooltip(top)
          template(#activator='{ on, attrs }')
            v-icon(
              v-bind="attrs"
              v-on="on"
              size="40"
              class="cursor add ml-4 pb-4"
              :color="isViewAddSlot ? '#42627e' : 'green'"
            ) {{isViewAddSlot ? 'mdi-close' : 'mdi-plus'}}
          span {{ isViewAddSlot ? $t('tooltip.cancel') : $t('tooltip.addBlock') }}
    div.pt-4
      div(v-if="$slots.AddBlock && isViewAddSlot")
        div.flex-row-sb
          div {{ $t(labelKeyAdd).toUpperCase() }}
        slot(name="AddBlock")

    div(v-if="isCanMerge").text-left.ml-6
      v-btn(@click="startMergingDocuments" color="primary").mr-6 {{ documentMerging ? $t('stopMergingDocs') : $t('startMergingDocs') }}
      v-btn(
        v-if="documentMerging"
        :disabled="!allowContinueMerge"
        @click="handleContinueMerge"
        color="success") {{ $t('continueMergingDocs') }}

    v-data-table(
      v-model="selectedItems"
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      :loading="isLoading"
      :loading-text="$t('loading-text')"
      :no-results-text="$t('no-results-text')"
      :no-data-text="$t('no-data-text')"
      :custom-sort="customSort"
      hide-default-footer
      :show-expand="showExpand"
      :expanded.sync="expanded"
      :item-class="itemRowBackground")

      template(#header v-if="Object.keys(total).length" :headers="headers")
        tr(v-if="!isHiddenGlobalRow").light-blue.accent-2
          th(v-for="header in headers" :class="`text-${header?.align || 'start'}`")
            span(v-if="header.title").pl-1 {{ header.title.global }}
            span(v-if="header.total").pl-1 {{ total[header.total.global]?.toFixed(2) }}
        tr(v-if="!isHiddenPageRow").yellow.lighten-3
          th(v-for="header in headers" :class="`text-${header?.align || 'start'}`")
            span(v-if="header.title").pl-1 {{ header.title.page }}
            span(v-if="header.total").pl-1 {{ total[header.total.page]?.toFixed(2) }}

      template(#item.selectColumn="{item}")
        v-checkbox(
          :ripple="false"
          hide-details
          @change="selectDocument(item)"
          :disabled="!item.allowMerge || (selectedItems?.length === 2 && checkCheckedItems(item.id))")

      template(#item.qualificationNumber="{item}")
          v-icon(v-if="item.type_document === 16" style="transform: rotate(90deg)" large) mdi-subdirectory-arrow-left
          span(v-else) {{item.number }}

      template(#item.date_meeting="{item}") {{getDateFormat(item.date_meeting)}}

      template(#item.include_sailor_passport="{item}") {{ getDirectoryObject({ value: 'processingStatuses' id: item.include_sailor_passport })?.[nameLang] || '-' }}

      template(#item.type_exam="{item}") {{ getDirectoryObject({ value: 'listTypeExam', id: item.type_exam })?.[nameLang] || '-' }}

      template(#item.status="{item}" )
        span(:class="getStatus(item.status_document)") {{ $t(`status${item.status_document}`) }}

      template(#item.status_is_verification="{item}" )
        span(:class="getStatus(item.is_verification ? STATUSES_STATEMENT_CONSTANTS.VERIFICATION : 1)") {{ item.is_verification ? $t('notVerified') : $t('verified') }}

      template(#item.ces_exam="{item}") {{ item.ces_exam ? $t('yes') : $t('no') }}

      template(#item.selectItem="{item}")
        div.w-100
          div(v-if="item.type_document === 'Медичне свідоцтво'")
            +select('item.medical_position', 'item.available_medical_position', 'position', 'nameLang')(@input="getMedicalInstitutionList")
            +select('item.medical_institution', 'listMedicalInstitution', 'nameMedicalInstitution', '"value"')

          div(v-if="['Свідоцтво НТЗ', 'Сертифікат НТЗ'].includes(item.type_document)").w-100
            +select('item.institution', 'item.available_ntz', 'institution', 'nameLang')

      template(#item.payment_info="{item}")
        div(v-if="item.payment_url && checkAccess('crewingManager')")
          span(v-if="item.info_for_statement") {{ setPriceWithCommission(item.price) }}
          div
            v-tooltip(bottom)
              template(#activator="{ on }")
                v-btn(v-on="on" color="primary" x-small fab icon outlined @click="$emit('createPayment', item.payment_url)").ml-2
                  v-icon mdi-credit-card-outline
              span {{ $t('pay') }}
            v-tooltip(bottom)
              template(#activator="{ on }")
                v-btn(v-on="on" color="primary" x-small fab icon outlined @click="$emit('openPaymentReceiptModal', item)").ml-2
                  v-icon mdi-file-upload-outline
              span {{ $t('uploadReceipt') }}
        div(v-else-if="item.payment_info && item.status_document").text-left
          div
            b {{ $t('appointment') }}:
            span {{ item.payment_info.payment_due }}
          div
            b {{ $t('requisites') }}:
            span {{ item.payment_info.requisites }}
          div
            b {{ $t('price') }}:
            span {{ item.payment_info.amount }} {{ $t('uah') }}
            div(v-if="!isHiddenPaymentBtn")
              v-tooltip(bottom)
                template(#activator="{ on }")
                  v-btn(v-on="on" color="primary" x-small fab icon outlined @click="$emit('openPaymentReceiptModal', item)").ml-2
                    v-icon mdi-file-upload-outline
                span {{ $t('uploadReceipt') }}
        div(v-else) {{ $t('notRequirePayment') }}
        div(v-if="item?.info_for_statement?.is_international && !item.photo.some(el => el.file_type === 'international_certificate')").text-left
          b {{ $t('uploadFileCertificateEtiISC') }}:
          v-tooltip(bottom)
            template(#activator="{ on }")
              v-btn(v-on="on" color="primary" x-small fab icon outlined @click="$emit('openUploadCopyFileETIModal', item)").ml-2
                v-icon mdi-file-upload-outline
            span {{ $t('tooltip.uploadCopyFileCertificateETI') }}
      template(#item.is_continue="{item}") {{ item.is_continue === 3 ? $t('experience') : $t('exam') }}

      template(#item.statusExistsDocs="{item}")
        span(:class="getStatus(item.is_verification ? 34 : 1)") {{ item.is_verification ? $t('notVerified') : $t('verified') }}

      template(#item.statusExperience="{item}")
        span(:class="getStatus(item?.value ? 1 : 7)") {{ getExperienceStatus(item) }}

      template(#item.educationTypeDocument="{item}") {{ educationTypeDocumentByID(item.type_document)?.[nameLang] || '-' }}

      template(#item.navigatorCertificatesNumber="{item}") {{ item.number || item.other_number }}

      template(#item.issuing_authority="{item}") {{ getDirectoryObject({ value: 'issuingAuthority', id: item.issuing_authority })?.[nameLang] || '-' }}

      template(#item.speciality="{item}") {{ getDirectoryObject({id: item.speciality, value: 'profession'})?.[nameLang] || '-' }}

      template(#item.delivery="{item}") {{ item.pickup_from_morrichservice ? 'Моррічсервіс' : item.delivery ? item.delivery.post_service === 'NovaPoshta' ? 'Нова Пошта' : '' : '' }}

      template(#item.date_end_proxy="{item}") {{getDateFormat(item.date_end_proxy)}}

      template(#item.distribution_sum="{item}") {{item.distribution_sum.toFixed(2)}}

      template(#item.profit_sum="{item}") {{item.profit_sum.toFixed(2)}}

      template(#item.is_experience_required="{item}") {{ item.is_experience_required ? $t('exist') : $t('notExist') }}

      template(#item.change_date="{item}") {{getDateFormat(item.change_date)}}

      template(#item.date_end_meeting="{item}") {{getDateFormat(item.date_end_meeting)}}

      template(#item.educational_institution="{item}") {{item.educational_institution?.[nameLang] || getDirectoryObject({ id: item.educational_institution, value: 'institution' })?.[nameLang] || '-'}}

      template(#item.model="{item}") {{$t(item.model)}}

      template(#item.etiInstitutionName="{item}") {{item[nameLang]}}

      template(#item.eti_institution="{item}") {{ getDirectoryObject({id: item.institution, value: 'educationTraining'})?.[nameLang] }}

      template(#item.eti="{item}") {{ getDirectoryObject({id: item.eti?.id || item.eti, value: 'educationTraining'})?.[nameLang] }}

      template(#item.group="{item}") {{ getDirectoryObject({ id: item.group, value: 'crewingGroups' })?.name || '-' }}

      template(#item.course_training="{item}") {{ getDirectoryObject({ id: item.course_training, value: 'courses' })?.[nameLang] || '-' }}

      template(#item.ntz="{item}") {{ getDirectoryObject({ id: item.ntz, value: 'educationTraining' })?.[nameLang] || item.ntz }}

      template(#item.responsibility_work_book="{item}") {{ item.responsibility_work_book ? getDirectoryObject({ id: item.responsibility_work_book, value: 'responsibilityWorkBook' })?.[nameLang] : '' }}

      template(#item.type_receipt="{item}") {{ getProcessingStatus(item.type_receipt)?.[nameLang] }}

      template(#item.crewing_company="{item}") {{ typeof item.crewing_company === "string" ? item.crewing_company : crewingCompanyByID(item.crewing_company)?.[nameLang] }}

      template(#item.crewingManagerFullName="{item}") {{ item.manager.last_name }} {{ item.manager.first_name }} {{ item.manager?.userprofile?.middle_name || '' }}

      template(#item.crewingManagerFullNameOffice="{item}") {{ item.last_name }} {{ item.first_name }}

      template(#item.ratio="{item}") {{ (item.ratio) * 100 }} %

      template(#item.doctor="{item}") {{ item.doctor?.FIO || '-' }}

      template(#item.type_of_form="{item}") {{ item.type_of_form === 'First' ? $t('firstForm') : $t('secondForm') }}

      template(#item.type_user="{item}") {{ getDirectoryObject({ id: item.type_user, value:'userTypes' })?.[nameLang] || '-' }}

      template(#item.crewing="{item}") {{ companyName(item.crewing?.id || item.crewing) }}

      template(#item.manager="{item}") {{ crewingManagerName(item.manager) }}

      template(#item.type_company="{item}") {{ institutionTypeByID(item.type_company)?.[nameLang] }}

      template(#item.level_qualification="{item}") {{ item.level_qualification &&  typeof item.level_qualification !== 'number' ? item.level_qualification[nameLang] : getDirectoryObject({ value: 'qualificationLevels', id: item.level_qualification })?.[nameLang] }}

      template(#item.qualification="{item}") {{ item.qualification?.[nameLang] || getDirectoryObject({ value: 'qualificationLevels', id: item.qualification })?.[nameLang] || '-' }}

      template(#item.is_for_isc="{item}") {{ $t(item.is_for_isc ? 'yes' : 'no' ) }}

      template(#item.can_auto_sign="{item}") {{ $t(item.can_auto_sign ? 'yes' : 'no' ) }}

      template(#item.user_is_active="{item}") {{ $t(item.user_is_active ? 'yes' : 'no' ) }}

      template(#item.type_document="{item}") {{ item.type_document?.[nameLang] || getDirectoryObject({id: item.type_document, value:'typeDocQualification'})?.[nameLang] || item.type_document || '-' }}

      template(#item.port="{item}") {{ item.other_port || item.port && (item.port[nameLang] || getDirectoryObject({id: item.port, value:'ports'})?.[nameLang] || '') }}

      template(#item.access_type="{item}") {{typeof item.access_type === 'number' && getDirectoryObject({id: item.access_type, value:'institutionTypes'})?.[nameLang] || '' }}

      template(#item.old_type_company="{item}") {{typeof item.old_type_company === 'number' && getDirectoryObject({id: item.old_type_company, value:'institutionTypes'})?.[nameLang] || '' }}

      template(#item.new_type_company="{item}") {{typeof item.new_type_company === 'number' && getDirectoryObject({id: item.new_type_company, value:'institutionTypes'})?.[nameLang] || '' }}

      template(#item.old_institution_type="{item}") {{typeof item.old_institution_type === 'number' && getDirectoryObject({id: item.old_institution_type, value:'institutionTypes'})?.[nameLang] || item.old_institution_type }}

      template(#item.new_institution_type="{item}") {{typeof item.new_institution_type === 'number' && getDirectoryObject({id: item.new_institution_type, value:'institutionTypes'})?.[nameLang] || item.new_institution_type }}

      template(#item.country="{item}") {{ item.country?.value || getDirectoryObject({ value:'country', id: item.country})?.value || '-' }}

      template(#item.sailorFullName="{ item }") {{ item.sailor ? item.sailor[`full_${nameLang}`] : '-' }}

      template(#item.fullName="{ item }") {{ item.last_name }} {{ item.first_name }} {{ item.middle_name }}

      template(#item.fullNameUkr="{ item }") {{ item.last_name_ukr }} {{ item.first_name_ukr }} {{ item.middle_name_ukr }}

      template(#item.oldFullNameUkr="{ item }") {{ item.old_last_name_ukr }} {{ item.old_first_name_ukr }} {{ item.old_middle_name_ukr }}

      template(#item.fullNameEng="{ item }") {{ item.last_name_eng }} {{ item.first_name_eng }} {{ item.middle_name_eng }}

      template(#item.sailorFullNameEng="{ item }") {{ item.sailor ? item.sailor.full_name_eng : '-' }}

      template(#item.sailorID="{ item }") {{ item.sailor ? item.sailor.id : '-' }}

      template(#item.include_sailor_passport="{item}") {{ getDirectoryObject({id: item.include_sailor_passport, value: 'processingStatuses' })?.[nameLang] }}

      template(#item.unsigned_reason="{item}") {{ getDirectoryObject({ id: item.unsigned_reason, value: 'unsignedReasons' })?.[nameLang] }}

      template(#item.role="{item}") {{ getDirectoryObject({ id: item.role, value: 'userTypes' })?.[nameLang] }}

      template(#item.sailor_date_birth="{item}") {{ getDateFormat(item.sailor_date_birth) || '-' }}

      template(#item.date_start="{item}") {{ getDateFormat(item.date_start) || '-' }}

      template(#item.verificationDocumentTypes="{item}") {{ getDirectoryObject({id: item.type_document, value: 'verificationDocumentTypes' })?.[nameLang] || '-' }}

      template(#item.date_end="{item}") {{ getDateFormat(item.date_end) || '-' }}

      template(#item.content_type="{item}") {{ documentByContentType(item.content_type)?.[nameLang] || '' }}

      template(#item.branch_office="{item}") {{ getDirectoryObject({ value: 'affiliate', id: item.branch_office?.id || item.branch_office })?.[nameLang] || item.other_branch_office }}

      template(#item.userprofile.branch_office="{item}") {{ getDirectoryObject({ value: 'affiliate', id: item.userprofile.branch_office })?.[nameLang] || '' }}

      template(#item.sum_f1f2="{item}")
        div
          div {{ item.form1_sum.toFixed(2) }} ₴
          div {{ item.form2_sum.toFixed(2) }} $
      template(#item.userprofile.contact_info="{item}")
        div(v-for="record of item.userprofile.contact_info" :key="record.id")
          div(v-if="['1', 'phone_number', 1].includes(record.type_contact)")
            +data-info('phoneNumber', 'record.value')
          div(v-if="['4', 'telegram', 4].includes(record.type_contact)")
            +data-info('telegram', 'record.value')
          div(v-if="['5', 'viber', 3].includes(record.type_contact)")
            +data-info('viber', 'record.value')
          div(v-if="['2', 'email', 2].includes(record.type_contact)")
            +data-info('email', 'record.value')

      template(#item.status_protocol="{item}")
        span(:class="getStatus(item.status_protocol)") {{ getDirectoryObject({ id: item.status_protocol, value: 'statusProtocol' })?.[nameLang] }}

      template(#item.decision="{item}") {{ item.decision ? getDirectoryObject({ value: 'decision', id: item.decision })?.[nameLang] : '' }}

      template(#item.all_responsibility="{item}")
        div(v-for="(responsibility, index) in item.all_responsibility" :key="index")
          div(v-if="responsibility.responsibility").w-100.d-flex.flex-column.flex-wrap.px-0.mt-1
            div.w-100.px-0 {{ responsibility.responsibility[nameLang] }}{{ item.all_responsibility.length - 1 !== index ? ';' : ''  }}

      template(#item.direction="{item}") {{ getDirectoryObject({ value: 'direction', id: item.direction })?.[valueLang] || '' }}

      template(#item.commissioner_type="{item}") {{ getCommissionerTypes(item.commissioner_type)?.[nameLang] || '' }}

      template(#item.institution_type="{item}") {{ getDirectoryObject({ value: 'institutionTypes', id: item.institution_type })?.[nameLang] || '' }}

      template(#item.institutionTypesEti="{item}") {{ getDirectoryObject({ value: 'institutionTypesEti', id: item.institution_type })?.[nameLang] || '' }}

      template(#item.course="{item}") {{ typeof item.course === 'number' ? getDirectoryObject({ value: 'courses', id: item.course })?.[nameLang] : item.course[nameLang] || '' }}

      template(#item.institution="{item}") {{ typeof item.course === 'number' ? getDirectoryObject({ value: 'educationTraining', id: item.institution })?.[nameLang] : item.institution[nameLang] || '' }}

      template(#item.is_have_documents.protocol="{item}") {{ item.is_have_documents.protocol ? $t('withProtocol') : $t('withoutProtocol')}}

      template(#item.is_have_documents.statement="{item}") {{ item.is_have_documents.statement ? $t('withStatement') : $t('withoutStatement') }}

      template(#item.educ_with_dkk="{item}") {{ item.educ_with_dkk ? $t('educationWithSQC') : $t('noEducationWithSQC') }}

      template(#item.is_payed="{item}") {{ item.is_payed ? $t('isPayed') : $t('notPayed') }}

      template(#item.isPayedPosition="{item}")
        span(:class="getPaymentStatus(item.is_payed)") {{ item.is_payed ? $t('isPayed') : $t('notPayed') }}

      template(#item.is_payed_nostrification="{item}") {{ item.is_payed_nostrification ? $t('isPayed') : $t('notPayed') }}

      template(#item.is_payed_translate="{item}") {{ item.is_payed_translate ? $t('isPayed') : $t('notPayed') }}

      template(#item.rank="{item}") {{ item.rank?.[nameLang] || getDirectoryObject({id: item.rank, value: 'ranks'})?.[nameLang] || '' }}

      template(#item.medical_institution="{item}") {{ typeof item.medical_institution === 'string' && item.medical_institution || item.medical_institution?.[nameLang] || getDirectoryObject({id: item.medical_institution?.id || item.medical_institution, value: 'medInstitution'})?.value || '' }}

      template(#item.medical_position="{item}") {{  item.medical_position[nameLang] || getDirectoryObject({id: item.medical_position, value: 'positionMedical'})?.[nameLang] || '' }}

      template(#item.medicalPosition="{item}") {{ getDirectoryObject({id: item.position, value: 'positionMedical'})?.[nameLang] || '' }}

      template(#item.position="{item}")
        div(v-if="(typeof item.position !== 'object')")
          div(v-for="(position) in item.position" :key="`position-${position.id || position}`")
            span {{typeof position !== 'number' ? position[nameLang] : getDirectoryObject({ id: position, value: 'positions' })?.[nameLang]}};
        div(v-else) {{ getDirectoryObject({ id: item.position?.id || item.position, value: 'positions' })?.[nameLang] }}

      template(#item.list_positions="{item}")
        div(v-for="position in item.list_positions" :key="`position-${position}`")
          | {{getDirectoryObject({ id: position, value: 'positions' })?.[nameLang] || position?.[nameLang] || '-'}};

      template(#item.languages="{item}")
        span(v-for="language in item.languages" :key="language").mr-1 {{ getDirectoryObject({ id: language, value: 'languages' })?.[nameLang] }};

      template(#item.is_disable="{item}")
        span(:class="getPaymentStatus(!item.is_disable)") {{ item.is_disable ? $t('isDisable') : $t('isNotDisable')}}

      template(#item.status_document="{item}")
        span(:class="getStatus(item.status_document?.id || item.status_document )") {{ item.status_document ? getDirectoryObject({ value: 'statuses', id: item.status_document?.id || item.status_document })?.[nameLang] : '-' }}

      template(#item.status_line="{item}")
        span(:class="getStatus(item.status_line.id || item.status_line )") {{ item.status_line ? getDirectoryObject({ value: 'statuses', id: item.status_line.id || item.status_line })?.[nameLang] : '-' }}

      template(#item.nostrification_document_status="{item}")
        span(:class="getStatus(item.nostrification_document_status)") {{ item.nostrification_document_status ? getDirectoryObject({ value: 'statuses', id: item.nostrification_document_status })?.[nameLang] : '-' }}

      template(#item.event="{item}")
        ControlButton(:controlButtons="actions" :item="item").justify-center

      template( #item.data-table-expand="{item, isExpanded}")
        v-tooltip(v-if="item.isHiddenEdit !== undefined ? !item.isHiddenEdit : true" top)
          template(#activator='{ on, attrs }')
            v-icon(
              @click="handleExpansion(item, isExpanded)"
              v-bind='attrs'
              v-on='on'
              :color="isExpanded ? iconExpandedClose.color : iconExpandedOpen.color") {{ isExpanded ? iconExpandedClose.name : iconExpandedOpen.name }}
          span {{$t(checkTypeOf(iconExpandedClose.tooltip) ? iconExpandedClose.tooltip(item) : isExpanded ? iconExpandedClose.tooltip : iconExpandedOpen.tooltip)}}

      template(#expanded-item="{ headers, item }")
        td(v-if="componentInfo" :colspan="headers.length")
          component(
            :is="componentInfo"
            :row="item"
            name="Info")
        td(v-if="componentEdit" :colspan="headers.length")
          component(
            :is="componentEdit"
            :row="item"
            name="Edit" )

    div.d-flex.align-center.justify-space-between.pb-5
      div(v-if="!isHiddenPageCount") {{ $t('countDoc') }}: {{ pageCount }}
      v-pagination(
        v-if="Math.ceil(pageCount / page_size) > 1"
        v-model="page"
        :length="Math.ceil(pageCount / page_size)"
        :total-visible="9"
        @input="changeQuerySet").mt-3
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { getDateFormat, getStatus, clearBody, getPaymentStatus, getExperienceStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { SearchTable } from '@/mixins/validationRules'
import { getProcessingStatus } from '@/mixins/api'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'

export default {
  props: {
    headers: { type: Array, default: () => [] },
    total: { type: Object, default: () => ({}) },
    items: { type: Array, default: () => [] },
    pageCount: { type: Number, default: 1 },
    currentPage: { type: Number, default: 1 },
    detailedRoute: { type: String, default: '' },
    querySearch: { type: Function, default: () => ({}) },
    defaultFilter: { type: Object, default: () => ({ page: '1', page_size: '10' }) },
    isLoading: { type: Boolean, default: false },
    openingDocument: { type: Object, default: () => ({}) },
    actionIcon: { type: String, default: '' },
    itemsForMultiSign: { type: Array, default: () => [] },
    saveReport: { type: Function, default: () => ({}) },
    name: { type: String, default: 'mdi-arrow-right' },
    isHiddenTableSearch: { type: Boolean, default: false },
    isViewAdd: { type: Boolean, default: false },
    type: { type: String, default: '' },
    labelKeyAdd: { type: String, default: '' },
    actions: { type: Array, default: () => [] },
    itemRowBackground: { type: Function, default: () => ({}) },
    hideFilters: { type: Boolean, default: false },
    radioListItem: { type: Array, default: () => [] },
    isHiddenTableCount: { type: Boolean, default: false },
    isHiddenPageCount: { type: Boolean, default: false },
    componentInfo: { type: String, default: '' },
    componentEdit: { type: String, default: '' },
    showExpand: { type: Boolean, default: false },
    iconExpandedOpen: { type: Object, default: () => ({ name: 'mdi-information-outline', color: 'blue', tooltip: 'tooltip.info' }) },
    iconExpandedClose: { type: Object, default: () => ({ name: 'mdi-close', color: 'gray', tooltip: 'tooltip.close' }) },
    isHiddenSearchParams: { type: Boolean, default: false },
    isHiddenPageRow: { type: Boolean, default: false },
    isHiddenGlobalRow: { type: Boolean, default: false },
    isNotMounted: { type: Boolean, default: false },
    isCanMerge: { type: Boolean, default: false },
    isHiddenPaymentBtn: { type: Boolean, default: false }
  },
  components: {
    ReportSearchV2: () => import('@/components/molecules/ReportSearch/ReportSearchV2'),
    BackOfficeDocumentsPriceOnlineInfo: () => import('@/views/BackOffice/BackOfficeDocumentsPrice/Online/Info'),
    BackOfficeDocumentsPriceOnlineEdit: () => import('@/views/BackOffice/BackOfficeDocumentsPrice/Form'),
    BackOfficeDocumentsPriceOfflineInfo: () => import('@/views/BackOffice/BackOfficeDocumentsPrice/Offline/Info'),
    BackOfficeDocumentsPriceOfflineEdit: () => import('@/views/BackOffice/BackOfficeDocumentsPrice/Form'),
    BackOfficeMedicalTable: () => import('@/views/BackOffice/BackOfficeMedical/Table.vue'),
    BackOfficeMedicalEdit: () => import('@/views/BackOffice/BackOfficeMedical/Edit.vue'),
    TranslateAgencyPriceForm: () => import('@/views/BackOffice/TranslateAgency/PriceForm.vue'),
    NostrificationMesPriceForm: () => import('@/views/BackOffice/NostrificationAgency/PriceForm.vue'),
    NostrificationStatementPriceForm: () => import('@/views/BackOffice/PaymentStatementNostrification/PriceForm.vue'),
    BackOfficeCoursesList: () => import('@/views/BackOffice/BackOfficeCourses/BackOfficeCoursesList/BackOfficeCoursesList.vue'),
    BackOfficeVnzTable: () => import('@/views/BackOffice/BackOfficeVnz/Table'),
    BackOfficeVnzEdit: () => import('@/views/BackOffice/BackOfficeVnz/Edit'),
    ControlButton: () => import('@/components/atoms/ControlButtons.vue'),
    UserHistoryInfo: () => import('@/views/History/UserHistory/UserHistoryInfo/UserHistoryInfo.vue'),
    SailorRecordBookLineInfo: () => import('@/views/Sailor/SailorRecordBook/SailorRecordBookLine/Info.vue'),
    ReportFinanceInfo: () => import('@/views/Report/ReportFinance/Info.vue')
  },
  data () {
    return {
      STATUSES_STATEMENT_CONSTANTS,
      getExperienceStatus,
      page: this.currentPage,
      page_size: 10,
      timer: null,
      getDateFormat,
      getStatus,
      search: '',
      isLoadSearch: false,
      ordering: null,
      getPaymentStatus,
      checkAccess,
      radio: this.$route.name,
      isViewAddSlot: this.isViewAdd,
      expanded: [],
      getProcessingStatus,
      selectedItems: [],
      checkedItems: [],
      selected: [],
      documentMerging: false,
      allowContinueMerge: false,
      qualificationType: null,
      listMedicalInstitution: []
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      valueLang: state => state.main.lang === 'en' ? 'value_eng' : 'value_ukr',
      pageSizeList: state => state.main.page_size,
      crewings: state => state.crewing.crewingCompanies,
      crewingManagers: state => state.crewing.crewingManagers,
      finally: state => state.backoffice.finally
    }),
    ...mapGetters([
      'getDirectoryObject',
      'getCommissionerTypes',
      'institutionTypeByID',
      'crewingManagerByID',
      'crewingCompanyByID',
      'documentByContentType',
      'responsibilityByID',
      'educationTypeDocumentByID'
    ])
  },
  validations () { return SearchTable() },
  watch: {
    '$route.query' (newVal, oldVal) {
      if (newVal.page !== oldVal.page) this.page = +newVal.page
    },
    ordering (newVal, oldVal) {
      if (newVal !== oldVal) this.querySearch(this.checkQuery())
    },
    page_size (newVal, oldVal) {
      if (!isNaN(newVal) && newVal !== oldVal && +this.$route.query.page_size !== newVal) {
        this.setQuery({ ...this.$route.query, page_size: newVal.toString(), page: '1' })
        this.querySearch({ ...this.$route.query })
      }
    },
    radio (newVal, oldVal) {
      if (newVal !== oldVal) this.$router.push({ name: newVal })
    }
  },
  mounted () {
    const { query } = this.$route
    if (!this.isNotMounted) {
      if (Object.keys(query).length) {
        ['page', 'page_size', 'search'].forEach(item => (this[item] = ['page', 'page_size'].includes(item) ? +query[item] : query[item]))
        this.querySearch({ ...query, ...this.defaultFilter })
      } else {
        this.setQuery({ ...this.defaultFilter })
        this.querySearch({ ...this.defaultFilter })
      }
    }
  },
  methods: {
    ...mapActions(['getMedicalInstitutionForPacket']),
    crewingManagerName (item) {
      return this.crewingManagerByID(item?.id || item)?.crewingHeadFullName || item?.id || item
    },
    companyName (id) {
      return this.crewingCompanyByID(id)?.name_ukr
    },
    checkQuery () {
      let query = {
        ...this.$route.query,
        ...this.defaultFilter,
        search: this.search?.length > 2 ? this.search : null,
        ordering: this.ordering?.length ? this.ordering : null
      }
      query = clearBody(query)
      this.setQuery(query)
      return query
    },
    setQuery (query, params = {}) {
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query) ||
      JSON.stringify(params) !== JSON.stringify(this.$route.params)) {
        this.$router.replace({ query, params })
      }
    },
    changeQuerySet (page) {
      this.loading = true
      this.setQuery({ ...this.$route.query, page, page_size: this.page_size })
      this.querySearch({ ...this.$route.query })
    },
    searchTable (search) {
      this.isLoadSearch = true
      clearTimeout(this.timer)
      if (this.$v.$invalid) {
        this.isLoadSearch = false
        return this.$v.$touch()
      } else if (search?.length === 0) this.setTimeoutQuerySearch()
      else this.setTimeoutQuerySearch()
    },
    setTimeoutQuerySearch () {
      this.loadSearch = true
      this.timer = setTimeout(() => {
        this.querySearch(this.checkQuery())
        this.isLoadSearch = false
      }, 3000)
    },
    customSort (items, sortBy, sortDesc) {
      this.ordering = !sortDesc[0] ? sortBy.length ? sortBy[0] : null : `-${sortBy[0]}`
      return items
    },
    checkTypeOf (item) {
      return typeof item === 'function'
    },
    handleExpansion (item, state) {
      const itemIndex = this.expanded.indexOf(item)
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item)
    },
    startMergingDocuments () {
      this.documentMerging = !this.documentMerging

      this.setQuery({ ...this.$route.query, page_size: this.documentMerging ? '1000' : '10' })
      this.querySearch({ ...this.$route.query })

      if (this.documentMerging) this.headers.unshift({ value: 'selectColumn', text: '' })
      else this.headers.splice(0, 1)

      this.selectedItems = []
      this.checkedItems = []
    },
    selectDocument (item) {
      if (this.selectedItems.some(el => el === item.id)) {
        this.selectedItems = this.selectedItems.filter(el => el !== item.id)
      } else this.selectedItems.push(item.id)
      this.checkedItems = this.selectedItems
      this.allowContinueMerge = this.selectedItems?.length === 2
      // && (
      //   this.selectedItems[0]?.type_document === this.selectedItems[1]?.type_document
      // )
      this.qualificationType = item?.type_document || null
    },
    handleContinueMerge () {
      this.$router.push({
        name: 'documents-merging',
        params: {
          sailorID: this.$route.params.id,
          qualificationType: this.qualificationType,
          documents: this.selectedItems,
          type: this.$route.name }
      })
    },
    checkCheckedItems (item) {
      return !this.checkedItems.some(el => el.id === item)
    },
    setPriceWithCommission (price) { // Set document price with commission of 4%
      return `${(price + (price * 0.04)).toFixed(2)} ${this.$t('uah')}`
    },
    async getMedicalInstitutionList (id) {
      const response = await this.getMedicalInstitutionForPacket(id)
      this.listMedicalInstitution = response
    }

  }
}
</script>

<style lang="sass" scoped>
  th
    align-items: center !important
    justify-content: start !important
</style>
