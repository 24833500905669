<template lang="pug">
include ../../../../configs/template.pug
div.row.text-left
  div.col-12
    +data-info('numberPage', 'sailorDocument.number_page_book')

  h5.text-bold-600.col-12.my-4 {{ $t('infoShip') }}:
  div.col-sm-12.col-md-4
    +data-info('numShip', 'sailorDocument.number_vessel')
  div.col-sm-12.col-md-4
    +data-info('nameShip', 'sailorDocument.name_vessel')
  div.col-sm-12.col-md-4
    +data-info('typeShip', 'typeShipByID(sailorDocument.type_vessel)[nameLang]')
  div.col-sm-12.col-md-6
    +data-info('modeShipping', 'navigationModeByID(sailorDocument.mode_of_navigation)[nameLang]')
  div.col-sm-12.col-md-6
    +data-info('portShip', 'sailorDocument.port_of_registration')
  div.col-12
    +data-info('ownerShip', 'sailorDocument.ship_owner')
  div.col-12.mb-0
    +data-info('repairedShip', 'sailorDocument.is_repaired ? $t("yes") : $t("no")')
  div(v-if="sailorDocument.is_repaired").col-12.d-flex.flex-wrap
    small(v-if="sailorDocument.repair_date_from").mr-2 {{ $t('periodStart') }}: {{ getDateFormat(sailorDocument.repair_date_from) }};
    small(v-if="sailorDocument.repair_date_to") {{ $t('periodEnd') }}: {{ getDateFormat(sailorDocument.repair_date_to) }};
    small(v-else-if="sailorDocument.days_repair").ml-2 {{ $t('totalDays') }}: {{ sailorDocument.days_repair }};

  h5.text-bold-600.col-12.my-4 {{ $t('ttx') }}:
  div.col-sm-12.col-md-6
    +data-info('grossCapacity', 'sailorDocument.gross_capacity')
  div.col-sm-12.col-md-6
    +data-info('powerGEU', 'sailorDocument.propulsion_power')
  div.col-sm-12.col-md-6
    +data-info('coldProductivity', 'sailorDocument.refrigerating_power')
  div.col-sm-12.col-md-6
    +data-info('elEquipmentPower', 'sailorDocument.electrical_power')
  div.col-sm-12.col-md-6
    +data-info('typeGEU', 'geuTypeByID(sailorDocument.type_geu)[nameLang]')
  div.col-sm-12.col-md-6
    +data-info('levelRefrigerantPlant', 'sailorDocument.level_refriger_plant')
  div.col-sm-12.col-md-6
    +data-info('apparatusGMZLB', 'sailorDocument.equipment_gmzlb ? $t("present") : $t("missingFemale")')

  h5.text-bold-600.col-12.my-4 {{ $t('port') }}:
  div.col-sm-12.col-md-6
    +data-info('swimArea', 'sailorDocument.trading_area')
  div.col-sm-12.col-md-6
    +data-info('swimPorts', 'sailorDocument.ports_input')

  h5.text-bold-600.col-12.my-4 {{ $t('captain') }}:
  div.col-12
    +data-info('nameUK', 'sailorDocument.full_name_master')
  h5.text-bold-600.col-12.my-4 {{ $t('captainEng') }}:
  div.col-12
    +data-info('nameEN', 'sailorDocument.full_name_master_eng')

  h5.text-bold-600.col-12.my-4 {{ $t('employment') }}:
  div.col-12
    +data-info('bookPractical', 'sailorDocument.book_registration_practical ? $t("present") : $t("missingFemale")')
  div.col-sm-12.col-md-6
    +data-info('hirePlace', 'sailorDocument.place_start')
  div.col-sm-12.col-md-6
    +data-info('hireDate', 'getDateFormat(sailorDocument.date_start)')
  div.col-sm-12.col-md-6
    +data-info('firePlace', 'sailorDocument.place_end')
  div.col-sm-12.col-md-6
    +data-info('fireDate', 'getDateFormat(sailorDocument.date_end)')
  div.col-12
    +data-info('positionOnShip', 'experiencePositionByID(sailorDocument.position)[nameLang]')
  div.col-12
    div.row
      b.col-12 {{ $t('responsibility') }}:
      div(v-for="(responsibility, index) in sailorDocument.all_responsibility" :key="index").col-12.py-0
        div(v-if="responsibility.responsibility")
          div.col-12.px-0 - {{ responsibilityByID(responsibility.responsibility)[nameLang] }}
          small(v-if="responsibility.date_from").mx-2 {{ $t('periodStart') }}: {{ getDateFormat(responsibility.date_from) }};
          small(v-if="responsibility.date_to") {{ $t('periodEnd') }}: {{ getDateFormat(responsibility.date_to) }};
          small(v-else-if="responsibility.days_work").ml-2 {{ $t('totalDays') }}: {{ responsibility.days_work }};

  div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").col-sm-12.col-md-6
    +data-info('recordAuthor', 'sailorDocument.created_by.name')
  div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").col-sm-12.col-md-6
    +data-info('createDate', 'sailorDocument.created_by.date')
  div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").col-sm-12.col-md-6
    +data-info('verifier', 'sailorDocument.verificated_by.name')
  div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").col-sm-12.col-md-6
    +data-info('verificationDate', 'sailorDocument.verificated_by.date')
  div.col-12
    +data-info('isConfirmedExperience', 'sailorDocument.is_confirmed ? $t("yes") : $t("no")')
  div.col-12
    +data-info('status', 'statusById(sailorDocument.status_document)[nameLang]')(:class="getStatus(sailorDocument.status_document)")
  div(v-if="sailorDocument.comments?.length && statuses.includes(sailorDocument.status_document)").col-sm-12
    h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
    v-divider
    div(v-for="(comment, index) in sailorDocument.comments" :key="comment.id").ml-4
      div
        +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
      div(v-if="checkAccess('backOffice')").pt-2.py-3
        +data-info('createdBy', 'comment.created_by.name')
      div.pt-2.py-3
        +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
      div.pt-2.py-3
        +data-info('reasonComment', 'comment.comment')
      v-divider(v-if="index !== sailorDocument.comments.length-1")
</template>

<script>
import { getStatus, getDateFormat } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
export default {
  props: {
    line: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getStatus,
      checkAccess,
      getDateFormat,
      sailorDocument: {},
      statuses: [
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.REJECTED,
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.IN_PROCESSING
      ]
    }
  },
  computed: {
    ...mapGetters(['typeShipByID', 'navigationModeByID', 'geuTypeByID', 'experiencePositionByID', 'statusById',
      'rejectionReasonByID', 'responsibilityByID', 'getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      serviceRecordBookLine: state => state.sailor.serviceRecordBookLineById
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getRecordBookLineEntryByID']),
    async getData () {
      if (!Object.keys(this.line).length) {
        await this.getRecordBookLineEntryByID({ ...this.$route.params, lineID: this.row.id })
        this.sailorDocument = this.serviceRecordBookLine
      } else this.sailorDocument = this.line
    }
  }
}
</script>
