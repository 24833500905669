import api from '@/plugins/api'
import { sailor } from '@/configs/endpoints'
import { SUCCESS_CODE, STATUSES_STATEMENT_CONSTANTS, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { queryString, FromObjectToFormData, postFiles, removeFileFromObject } from '@/mixins/main'
import notify from '@ananasbear/notify'
export const stateEducationSection = {
  // --- EDUCATION DOCUMENTS --- //
  education: {},
  educationDocumentById: {},
  // --- STUDENT CARD --- //
  student: [],
  // --- ADVANCE TRAINING STATEMENTS --- //
  educationStatement: [],
  educationStatementById: {}
}
export const actionsEducationSection = {

  // -------------- EDUCATION DOCUMENTS --------------
  async getEducationDocs ({ state, commit }, data) {
    const response = await api.get(sailor.education.documents.list(data?.id || state.sailoId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.results.map((item) => {
        item.allowMerge = response.data.results.filter(val => val.number_document === item.number_document).length > 1
      })
      commit('SET_STATE_SAILOR', { education: response.data })
    }
    return response
  },
  async getEducationDocumentByID ({ state, commit, dispatch }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true, educationDocumentById: {} })
    const response = await api.get(sailor.education.documents.list(data?.id || state.sailoId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.EDUCATION.DOCUMENTS.REJECTED === response.data.status_document) {
        const responseComments = await dispatch('getCommentEducationDocument', data)
        if (SUCCESS_CODE.includes(responseComments.code)) response.data.comments = responseComments.data
      }
      commit('SET_STATE_SAILOR', { educationDocumentById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async setEducationDocument ({ state, commit, dispatch }, data) {
    const response = await api.post(sailor.education.documents.list(data?.id || state.sailoId), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) {
      if (data?.media?.files.length) {
        const responseMedia = await api.post(sailor.education.documents.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      commit('incrementBadgeCount', { child: 'passportCitizen', parent: 'passportAll' })
      dispatch('getEducationDocs', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async updateEducationDocument ({ state, commit, dispatch }, data) {
    const response = await api.patch(sailor.education.documents.list(data?.id || state.sailoId, data.documentID),
      { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.education.documents.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.EDUCATION.DOCUMENTS.REJECTED === response.data.status_document) {
        const responseComments = data.comments
          ? await dispatch('setCommentEducationDocumentById', data)
          : await dispatch('getCommentEducationDocument', data)
          // #TODO make added old commnet when update method
        if (SUCCESS_CODE.includes(responseComments.code)) response.data.comments = responseComments.data
      } else notify.success('editedEducationDoc')
      dispatch('getEducationDocumentByID', data)
    }
    return response
  },
  async removeEducationDocument ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.education.documents.list(data?.id || state.sailoId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.EDUCATION.DOCUMENTS.DELETED === response?.data?.status_document) {
        response.data.behavior = { viewInfoBlock: true }
        commit('decrementBadgeCount', { child: 'educationDocument', parent: 'educationAll' })
        commit('SET_STATE_SAILOR', { educationDocumentById: response.data })
      }
    } else dispatch('getEducationDocs', { ...data, params: { page: 1, page_size: 10 } })
    return response
  },
  async getCommentEducationDocument ({ state }, data) {
    const response = await api.get(sailor.education.documents.comment(data?.id || state.sailoId, data.documentID))
    return response
  },
  async setCommentEducationDocumentById ({ state }, data) {
    const response = await api.post(sailor.education.documents.comment(data.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) notify.success('documentReject')
    response.data = [response.data]
    return response
  },
  async removeFileEducationDocument ({ commit, state }, data) {
    const response = await api.delete(sailor.education.documents.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.educationDocumentById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { educationDocumentById: newData })
    return response
  },
  async updateFileEducationDocument ({ commit }, data) {
    const response = await api.patch(sailor.education.documents.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },

  // -------------- STUDENT CARD -------------- //

  async getStudentCard ({ state, commit }, data) {
    const response = await api.get(sailor.education.student.list(data?.id || state.sailorId) + queryString(data?.params))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.map((item) => {
        item.behavior = { viewInfoBlock: true }
      })
      commit('SET_STATE_SAILOR', { student: response.data })
    }
    return response
  },

  async setCadetCard ({ commit, dispatch }, data) {
    const response = await api.post(sailor.education.student.studentById(), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data.media?.files.length) {
        // #TODO waiting back-end api/v2
        // const responseMedia = await api.post(sailor.education.student.uploadFile(data.id, response.data.id),
        //   { body: FromObjectToFormData(data.media) })
        const responseMedia = await postFiles(data.media.files, data.media.file_type, response.data.id)
        if (SUCCESS_CODE.includes(responseMedia.code)) await dispatch('getStudentCard')
        else notify.error('errorAddFile')
      }
      notify.success('addedStudentCard')
      dispatch('getStudentCard')
    }
    commit('incrementBadgeCount', {
      child: 'studentCard',
      parent: 'educationAll'
    })
    return response
  },

  async updateCadetCard ({ commit, dispatch }, data) {
    const response = await api.patch(sailor.education.student.studentById(data.documentID), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data.media?.files.length) {
        // const responseMedia = await api.post(sailor.education.student.uploadFile(data.id, response.data.id),
        //   { body: FromObjectToFormData(data.media) })
        const responseMedia = await postFiles(data.media.files, data.media.file_type, response.data.id)
        if (SUCCESS_CODE.includes(responseMedia.code)) await dispatch('getStudentCard')
        else notify.error('errorAddFile')
      }
      notify.success('editedStudentCard')
    }
    dispatch('getStudentCard', data)
    return response
  },

  async deleteCadetCard ({ commit }, data) {
    const response = await api.delete(sailor.education.student.studentById(data.id), { responseType: '*/*' })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('deletedDocument')
      commit('deleteDataSailor', { type: 'student', value: data.sailorDocument })
      commit('decrementBadgeCount', { child: 'studentCard', parent: 'educationAll' })
    }
    return SUCCESS_CODE.includes(response.code)
  },
  async updateFileStudentCard ({ commit }, data) {
    const response = await api.patch(sailor.education.student.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },

  // -------------- ADVANCE TRAINING STATEMENTS --------------

  async getGraduationStatements ({ state, commit }, data) {
    const response = await api.get(sailor.education.statementATC.list(data?.id || state.sailoId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response?.code)) {
      commit('SET_STATE_SAILOR', { educationStatement: response.data })
    }
    return response
  },
  async setGraduationStatement ({ commit, state, dispatch }, data) {
    const response = await api.post(sailor.education.statementATC.list(data?.id || state.sailoId), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.education.statementATC.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      notify.success('advanceTrainingStatementAdded')
      commit('incrementBadgeCount', {
        child: 'educationStatement',
        parent: 'educationAll'
      })
      dispatch('getGraduationStatements', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async updateGraduationStatement ({ commit, state, dispatch }, data) {
    const response = await api.patch(sailor.education.statementATC.list(data?.id || state.sailoId, data.documentID), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.education.statementATC.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (STATUSES_STATEMENT_CONSTANTS.SAILOR.EDUCATION.STATEMENT_ATC.REJECTED === response.data.status_document) {
        const responseComments = data.comments
          ? await dispatch('setCommentGraduationStatementById', data)
          : await dispatch('getCommentGraduationStatement', data)
        if (SUCCESS_CODE.includes(responseComments.code)) response.data.comments = responseComments.data
      } else notify.success('advanceTrainingStatementEdited')
      response.data.behavior = { viewInfoBlock: true }
      dispatch('getGraduationStatementById', data)
    }
    return response
  },
  async getGraduationStatementById ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true, educationStatementById: {} })
    const response = await api.get(sailor.education.statementATC.list(data?.id || state.sailoId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      commit('SET_STATE_SAILOR', { educationStatementById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },

  async setCommentGraduationStatementById ({ state }, data) {
    const response = await api.post(sailor.education.statementATC.comment(data?.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) notify.success('documentReject')
    response.data = [response.data]
    return response
  },
  async getCommentGraduationStatement ({ state }, data) {
    const response = await api.get(sailor.education.statementATC.comment(data?.id || state.sailorId, data.documentID))
    return response
  },
  async deleteGraduationStatement ({ commit, dispatch, state }, data) {
    const response = await api.delete(sailor.education.statementATC.list(data?.id || state.sailoId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      if (response.data.status === STATUSES_STATEMENT_CONSTANTS.SAILOR.EDUCATION.STATEMENT_ATC.DELETED) {
        dispatch('getGraduationStatements', { params: { page: 1, page_size: 10 } })
        commit('decrementBadgeCount', {
          child: 'educationStatement',
          parent: 'educationAll'
        })
      } else commit('SET_STATE_SAILOR', { educationStatementById: response.data })
    }
    return response
  },
  async removeFileEducationStatement ({ commit, state }, data) {
    const response = await api.delete(sailor.education.statementATC.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.educationDocumentById }
    newData.photo = removeFileFromObject(newData.photo, data.fileId)
    commit('SET_STATE_SAILOR', { educationDocumentById: newData })
    return response
  },
  async createDocumentWithStatement ({ commit, dispatch }, data) {
    const response = await api.post(sailor.education.statementATC.createAdvancedTraining(data.id, data.documentID),
      { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      dispatch('getEducationDocs', { ...data, params: { page: 1, page_size: 10 } })
      commit('incrementBadgeCount', {
        child: 'educationDocument',
        parent: 'educationAll'
      })
    }
    return response
  },
  async updateFileEducationStatement ({ commit }, data) {
    const response = await api.patch(sailor.education.statementATC.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  }
}
