const SET_DATA = (state, data) => {
  state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
}

const ADD_DATA = (state, data) => {
  state[Object.entries(data)[0][0]].push(Object.entries(data)[0][1])
}

const UPDATE_DATA = (state, data) => {
  let i = 0
  let prop = Object.entries(data)[0][0]
  let val = Object.entries(data)[0][1]
  state[prop].forEach((item, index) => {
    if (item.id === val.id) i = index
  })
  state[prop].splice(i, 1)
  state[prop].push(val)
}

export default {
  SET_DATA,
  ADD_DATA,
  UPDATE_DATA
}
