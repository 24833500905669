export default [
  {
    path: '/history',
    name: 'history',
    redirect: '/history/user',
    component: () => import('@/views/History/HistoryPage.vue'),
    children: [
      {
        path: 'crewing',
        name: 'history-crewing',
        component: () => import('@/views/History/CrewingHistory/Table.vue')
      },
      {
        path: 'eti',
        name: 'history-eti',
        component: () => import('@/views/History/ETIHistory/Table.vue')
      },
      {
        path: 'user',
        name: 'history-user',
        component: () => import('@/views/History/UserHistory/UserHistory.vue')
      }
    ]
  }
]
