export default [
  {
    path: '/statement/sqc/',
    name: 'statement-sqc',
    component: () => import('@/views/Statements/StatementSQC/StatementSQC.vue'),
    redirect: { name: 'approved' },
    children: [
      {
        path: 'approved',
        name: 'approved',
        props: () => ({ status: 24 }),
        component: () => import('@/views/Statements/StatementSQC/StatementSQCTable.vue')
      },
      {
        path: 'processing',
        name: 'processing',
        props: () => ({ status: 25 }),
        component: () => import('@/views/Statements/StatementSQC/StatementSQCTable.vue')
      },
      {
        path: 'from-PA',
        name: 'fromPA',
        props: () => ({ status: 42 }),
        component: () => import('@/views/Statements/StatementSQC/StatementSQCTable.vue')
      }
    ]
  },
  {
    path: '/eti-statements',
    name: 'eti-statements',
    component: () => import('@/views/Statements/StatementETI/StatementETI.vue')
  },
  {
    path: '/statement/srb/all',
    name: 'srbAll',
    component: () => import('@/views/Statements/StatementSRB/StatementSRB.vue')
  },
  {
    path: '/advance-training-statements',
    name: 'advance-training-statements',
    component: () => import('@/views/Statements/StatementAdvanceTraining/StatementAdvanceTraining.vue')
  },
  {
    path: '/sailor-passport-statements',
    name: 'sailor-passport-statements',
    component: () => import('@/views/Statements/StatementSP/StatementSP.vue')
  },
  {
    path: '/package-qualification-statement',
    name: 'package-qualification-statement',
    component: () => import('@/views/Statements/QualificationStatementFromPackage.vue')
  },
  {
    path: '/statement-isc',
    name: 'statement-isc',
    component: () => import('@/views/Statements/newAccountISC/StatementISC.vue')
  },
  {
    path: '/statement-isc/:id',
    name: 'statement-isc-info',
    component: () => import('@/views/Statements/newAccountISC/StatementISCDocument.vue')
  },
  {
    path: '/statements-service-record-book-isc',
    name: 'statements-service-record-book-isc',
    component: () => import('@/views/Statements/RecordBookStatementISC/Table.vue')
  },
  {
    path: '/statements-service-record-book-isc/:documentID',
    name: 'experience-statements-isc-info',
    component: () => import('@/views/Statements/RecordBookStatementISC/Document.vue')
  },
  {
    path: '/statements-nostrification',
    name: 'nostrification',
    component: () => import('@/views/Statements/NostrificationStatement/Table.vue')
  },
  {
    path: '/statements-nostrification/:id',
    name: 'nostrification-statement',
    component: () => import('@/views/Statements/NostrificationStatement/Document.vue')
  },
  {
    path: '/crewing-statement',
    name: 'crewing-statement',
    component: () => import('@/views/BackOffice/BackOfficeCrewing/Statement/Statement.vue')
  },
  {
    path: '/crewing-statement/:id',
    name: 'crewing-statement-info',
    component: () => import('@/views/BackOffice/BackOfficeCrewing/Statement/Info.vue')
  },
  {
    path: '/clarification-of-data-statement',
    name: 'clarification-of-data',
    component: () => import('@/views/Statements/ClarificationOfData/Table.vue')
  },
  {
    path: '/clarification-of-data-statement/:documentID',
    name: 'clarification-of-data-info',
    component: () => import('@/views/Statements/ClarificationOfData/Document.vue')
  },
  {
    path: '/crewing-verification',
    name: 'crewing-verification',
    component: () => import('@/views/Statements/CrewingVerification/Table.vue')
  },
  {
    path: '/crewing-verification/:documentID/',
    name: 'crewing-verification-info',
    component: () => import('@/views/Statements/CrewingVerification/Document.vue')
  }
]
