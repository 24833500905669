<template lang="pug">
    Form(@save="updateVnz" :vnz="row.item")
</template>

<script>
import { mapActions } from 'vuex'
import Form from './Form'
import notify from '@ananasbear/notify'
export default {
  name: 'edit-vnz',
  components: {
    Form
  },
  props: {
    row: Object
  },
  methods: {
    ...mapActions(['updatePriceForAdvTraining']),
    updateVnz (body) {
      let deleteKey = ['behavior', '_showDetails']
      deleteKey.forEach(key => delete body[key])
      Object.entries(body).forEach(([key, value]) => {
        if (value === this.row.item[key]) delete body[key]
      })
      if (Object.keys(body).length) {
        return this.updatePriceForAdvTraining({ id: this.row.item.id, body: body })
      }
      notify.warning('notify.updateWarning')
    }
  }
}
</script>
