<template lang="pug">
div.main.w-100
  router-link(v-if="checkAccess('main-addSailor')" :to="{ name: 'new-sailor' }" replace).w-100.card.pa-8.align-center
    v-tooltip(top)
      template(#activator='{ on, attrs }')
        v-btn(icon color="success" v-bind='attrs' v-on='on' large)
          v-icon(color="success" x-large) mdi-plus-circle-outline
      span {{  $t('tooltip.addSailor') }}

  MainSailorHistory(v-if="checkAccess('main-sailorsList')")
  MainAgentHistory(v-else)
</template>

<script>
import MainSailorHistory from './MainSailorHistory/MainSailorHistory.vue'
import MainAgentHistory from './MainCrewingManagerHistory/MainCrewingManagerHistory.vue'
import { mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'Main',
  components: {
    MainSailorHistory,
    MainAgentHistory
  },
  data () {
    return {
      checkAccess
      // crewingManagerQR: null,
      // linkForSailor: null
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.main.user
    })
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'home', access: true })
  },
  mounted () {
    // if (checkAccess('crewingManager')) this.getQrCode()
  },
  methods: {
    // async getQrCode () {
    //   await this.$api.get('api/v1/crewing/qr_code/generate/').then(response => {
    //     if (response.code === 200) {
    //       this.linkForSailor = response.data.url
    //       this.crewingManagerQR = response.data.qr
    //     } else if (response.code === 400) {
    //       if (response.data[0] === 'Agent has reached the limit for the current month') {
    //         this.$notification.info(this.$i18n.t('crewingManagerUsersLimit'))
    //       }
    //     }
    //   })
    // },
    //
    // clipboardSuccessHandler () {
    //   this.$notification.success(this.$i18n.t('copied'))
    // }
  }
}

</script>
