import api from '@/plugins/api'
import { crewing } from '@/configs/endpoints'
import { queryString } from '@/mixins/main'
import { SUCCESS_CODE } from '@/configs/constants'
import i18n from '@/plugins/localization/index'

export const CREWING = {
  state: () => ({
    isLoading: false,
    crewingStatements: {},
    crewingCompanies: [],
    crewingManagers: [],
    statementsPackageISC: {},
    transferHistoryCrewing: {},
    crewingManagerById: {}
  }),
  mutations: {
    SET_CREWING_STATE_DATA (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    },
    ADD_CREWING_STATE_DATA (state, data) {
      state[data.type].results.push(data.data)
    }
  },
  actions: {
    // -------------- CONNECT STATEMENTS --------------
    async getCrewingStatementList ({ commit }, params) {
      commit('SET_CREWING_STATE_DATA', { crewingStatements: {} })
      const response = await api.get(crewing.connectStatement + queryString(params))
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.results = response.data.results.map(item => {
          if (item.sailor_key) {
            const sailor = item.sailor_key
            item.userFullName = `${sailor.last_name_ukr} ${sailor.first_name_ukr} ${sailor.middle_name_ukr}`
          } else if (item.sailor) item.userFullName = item.sailor.full_name_ukr
          return item
        })
        commit('SET_CREWING_STATE_DATA', { crewingStatements: response.data })
      }
    },
    async getCrewingDetachStatementList ({ commit }, params) {
      commit('SET_CREWING_STATE_DATA', { crewingStatements: {} })
      const response = await api.get(crewing.detachStatement + queryString(params))
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.results = response.data.results.map(item => {
          const sailor = item.sailor_key
          item.userFullName = `${sailor.last_name_ukr} ${sailor.first_name_ukr} ${sailor.middle_name_ukr}`
          return item
        })
        commit('SET_CREWING_STATE_DATA', { crewingStatements: response.data })
      }
    },
    getCrewingStatement (context, id) {
      return api.get(crewing.connectStatementById(id))
    },
    getDetachCrewingStatement (context, id) {
      return api.get(crewing.detachStatementById(id))
    },
    getCommentCrewingStatement (context, id) {
      return api.get(crewing.commentStatementById(id))
    },
    getCommentDetachCrewingStatement (context, id) {
      return api.get(crewing.commentDetachStatementById(id))
    },
    setCommentCrewingStatement (context, params) {
      return api.post(crewing.commentStatementById(params.id), { body: params.body })
    },
    setDetachCommentCrewingStatement (context, params) {
      return api.post(crewing.commentDetachStatementById(params.id), { body: params.body })
    },
    updateCrewingStatement (context, params) {
      return api.patch(crewing.connectStatementById(params.id), { body: params.body })
    },
    updateDetachCrewingStatement (context, params) {
      return api.patch(crewing.detachStatementById(params.id), { body: params.body })
    },

    // -------------- COMPANIES --------------
    getCrewingCompanies ({ commit }, params) {
      return api.get(`api/v1/crewing/crewing/${params ? `?${params}` : '?page_size=1000'}`).then(response => {
        if (SUCCESS_CODE.includes(response.code)) commit('SET_CREWING_STATE_DATA', { crewingCompanies: response.data })
        return response
      })
    },
    getCrewingCompaniesByID ({ state, commit }, id) {
      return api.get(`api/v1/crewing/crewing/${id}/`)
    },
    setCrewingCompany ({ state, commit }, body) {
      return api.post(`api/v1/crewing/crewing/`, { body }).then(response => {
        if (SUCCESS_CODE.includes(response.code)) commit('ADD_CREWING_STATE_DATA', { crewingCompanies: response.data })
        return response
      })
    },
    updateCrewingCompany ({ commit }, data) {
      return api.patch(`api/v1/crewing/crewing/${data.id}/`, { body: data.body })
    },

    // -------------- MANAGERS --------------
    async getCrewingCompanyManagers ({ commit }, params) {
      const response = await api.get(`api/v1/crewing/manager/?${params ? `${params}` : ''}`)
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.map(item => {
          item.crewingHeadFullName = `${item.last_name} ${item.first_name} ${item.userprofile?.middle_name}`
        })
        commit('SET_CREWING_STATE_DATA', { crewingManagers: response.data })
      }
      return response
    },
    async getCrewingCompanyManagerById ({ commit }, data) {
      commit('SET_CREWING_STATE_DATA', { crewingManagerById: {}, isLoading: true })
      const response = await api.get(crewing.manager.list(data.managerID))
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.behavior = { viewInfoBlock: true }
        response.data.managerType = i18n.t(`group-${response.data.type_user}`)
        commit('SET_CREWING_STATE_DATA', { crewingManagerById: response.data })
      }
      commit('SET_CREWING_STATE_DATA', { isLoading: false })
      return response
    },
    async removeCrewingManagerById (context, data) {
      const response = await api.delete(crewing.manager.remove(data.managerID))
      if (SUCCESS_CODE.includes(response.code)) return response
    },

    // -------------- ISC PACKAGE STATEMENTS --------------
    async getStatementsPackageISC ({ commit }, params) {
      const response = await api.get(crewing.statementsPackageISC() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_CREWING_STATE_DATA', { statementsPackageISC: response.data })
      return response
    },
    getStatementPackageISC (context, id) {
      return api.get(crewing.statementsPackageISC(id)).then(response => {
        return response
      })
    },
    updateStatementPackageISC (context, data) {
      return api.patch(crewing.statementsPackageISC(data.id), { body: data.body }).then(response => {
        return response
      })
    },
    getStatementPackageISCComment (context, id) {
      return api.get(crewing.statementsPackageISCComment(id)).then(response => {
        return response
      })
    },
    setStatementPackageISCComment (context, data) {
      return api.post(crewing.statementsPackageISCComment(data.id), { body: data.body }).then(response => {
        return response
      })
    }

  },
  getters: {
    crewingCompanyByID: state => id => state.crewingCompanies?.results?.find(item => item.id === id) || {},
    crewingManagerByID: state => id => state.crewingManagers?.results?.find(item => item.id === id) || {}
  }
}
