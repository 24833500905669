import api from '@/plugins/api'
import { getFiles } from '@/mixins/main'
import mutations from '@/store/mutations'

const CIFRA_API = process.env.VUE_APP_CIFRA

export const EDS = {
  state: () => ({
    keys: [],
    servers: []
  }),
  mutations: {
    ...mutations,

    UPDATE_EDS_KEYS (state, data) {
      let y = 0
      state.keys.forEach((item, i) => {
        if (item.type_signature === data.keys.type_signature) y = i
      })

      state.keys.splice(y, 1)
      state.keys.push(data.keys)
    }
  },
  actions: {
    async getServers ({ commit }) {
      return fetch(`${CIFRA_API}api/v1/directory/center_certification_key/`)
        .then(response => response.json())
        .then(response => {
          commit('SET_DATA', { servers: response })
        })
    },
    async getKeys ({ commit }) {
      return api.get(`api/v1/signature/signature/?is_actual=true`)
        .then(response => {
          response && commit('SET_DATA', { keys: response.data })
        })
    },
    async setKey ({ commit }, body) {
      return api.post(`api/v1/signature/signature/`, { body, responseType: 'blob' })
        .then(response => {
          if ([200, 201].includes(response.code)) {
            commit('UPDATE_EDS_KEYS', { keys: response.data })
          }
          return response
        })
    },
    async setSign ({ commit }, data) {
      return api.post(data.url, { body: data.body })
        .then(response => {
          return response
        })
    },

    async getDocument (context, data) {
      return api.get(data.url).then(response => {
        getFiles(response.data.url, data.fileName, data.contentType)
      })
    }
  },
  getters: {
    serverByAddress: state => address => state.servers.find(server => server.address === address)[0]
  }
}
