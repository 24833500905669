import api from '@/plugins/api'
import { history } from '@/configs/endpoints'
import { queryString } from '@/mixins/main'
import { SUCCESS_CODE } from '@/configs/constants'
export default {
  actions: {
    // CREWING TRANSFER HISTORY
    async getCrewingTransfer (context, params) {
      const response = await api.get(history.transferCrewing + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) return response.data
      return {}
    },
    // ETI TRANSFER HISTORY
    async getEtiTransfer (context, params) {
      const response = await api.get(history.transferEti + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) return response.data
      return {}
    }
  }
}
