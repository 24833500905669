import notify from '@ananasbear/notify'
import store from '@/store'

export default async function (code, error) {
  let text
  // if (code >= 400 && code <= 500) error = await error?.json()
  if (code < 400) return false
  else if (code === 418) window.location = '/404'
  else if (code === 403) {
    error = await error.json()
    return notify.error(`notify.${error.detail.replaceAll('.', '')}`)
  } else if (code === 419) return store.commit('SET_STATE_DATA_MAIN', { isContractNeeded: true })
  else if (code === 500) return notify.error(`notify.serverError`)
  if (Array.isArray(error)) { // If response error is Array
    text = error[0]
  } else { // If response error is Object
    error = await error.json()
    if (Array.isArray(error)) { // If response error is Array
      text = error[0]
    }
    if (error.password) text = error.password[0]
    else if (error.detail) text = error.detail.replaceAll('.', '')
    else if (error.status) text = 'googleAuthError'
    else if (Array.isArray(error.username)) text = `username - existing`
    else if (error.username) text = `username - ${error.username.replaceAll('.', '')}`
    else if (error.new_password) text = error.new_password[0].replaceAll('.', '')
    else if (error.old_password) text = `old_password - ${error.old_password[0].replaceAll('.', '')}`
    else if (error.non_field_errors) text = error.non_field_errors[0].replaceAll('.', '')
    else if (error.statement_dkk) text = error.statement_dkk[0]
    else if (error.username) text = error.username[0]
    else if (error.type_document) text = error.type_document
    else if (error.statement) text = error.statement
    else if (error.date_end) text = `date_end - ${error.date_end}`
    else if (error.date_start) text = `date_start - ${error.date_start[0].replaceAll('.', '')}`
    else if (error.date_meeting) text = `date_meeting - ${error.date_meeting[0].replaceAll('.', '')}`
    else if (error.change_date) text = `change_date - ${error.change_date[0].replaceAll('.', '')}`
    else if (error.date_end_educ) text = `date_end_educ - ${error.date_end_educ[0].replaceAll('.', '')}`
    else if (error.sailor_id) text = `sailor_id - ${error.sailor_id[0].replaceAll('.', '')}`
    else if (error.userprofile) text = `userprofile - ${error.userprofile[0].replaceAll('.', '')}`
    else if (error.type_user) text = `type_user - ${error.type_user[0].replaceAll('.', '')}`
    else if (error.medical_institution || error.medical_position) {
      (error.medical_institution && error.medical_position) && (text = `medical_institution - medical_position - ${error.medical_institution[0].replaceAll('.', '')}`)
      error.medical_institution && !error.medical_position && (text = `medical_institution - ${error.medical_institution[0].replaceAll('.', '')}`)
      error.medical_position && !error.medical_institution && (text = `medical_position - ${error.medical_position[0].replaceAll('.', '')}`)
    } else if (error.contact_info) {
      text = error.contact_info.error
      store.commit('setStateData', { type: 'errorsDetails', data: error.contact_info.detail })
    } else if (error.code === 'token_not_valid') text = error.detail
    else if (error.error === 'You have some document in another statement') return notify.error('notify.You have some document in another statement')
  }
  if ([400, 401, 404].includes(code)) {
    switch (text) {
      case 'Incorrect email or password.': return notify.error('noAccount')
      case 'Пароль надто схожий на ім\'я користувача':
        return notify.error(`notify.Пароль надто схожий`)
      case 'No approved foreign passport found':
      case 'Approved foreign passport is not found':
      case 'Sailor does not have foreign passport':
        return notify.error('noApprovedInternationalPassportFound')
      case 'Sailor does not have document with citizenship': return notify.error('noApprovedCivilPassportFound')
      case 'The sailor with such passport data is exist':
      case 'The fields serial, inn, country must make a unique set.':
      case 'A user with that username already exists.':
        return notify.error('sailorExist')
      case 'Statement exists':
      case 'Related statement sqc already exists':
        return notify.error('courseExist')
      case 'Statement used':
      case 'Qualification document with this statement exists':
        return notify.error('applicationInUse')
      case 'Invalid rank':
      case 'Unsupported position type':
        return notify.error('invalidDataFormat')
      case 'days longer': return notify.error('daysLonger')
      case 'wrong date intervals':
        return notify.error('wrongIntervals')
      case 'Must be only interval or days': return notify.error('usePeriodsOrTotal')
      case 'change date is incorrect': return notify.error('notLastDate')
      case 'not data': return notify.error('emptyField')
      case 'less date exists': return notify.error('notLastDate')
      case 'No sailor passport to renew': return notify.error('noPassportsToRenew')
      case 'Sailor passport cannot be renewed': return notify.error('usedSailorPassportApplication')
      case 'Not diploma of higher education': return notify.error('notFoundDiploma')
      case 'Package creation is closed this month': return notify.error('closedPackageCreating')
      case 'Signature or stamp not found': return notify.error('signLoadFirst')
      case 'Photo for document not found':
      case 'Photo for document does not exists':
        return notify.error('uploadPhotoToDocument')
      // case 'Related statements sqc is not found': return notify.error('documentNotFound')
      case 'Cant create new service record statement along with existing pending statements': return notify.error('recordBookStatementExist')
      case 'This field must be unique.': return notify.error('existProtocolSQC')
      case 'This protocol has a statement qual doc': return notify.error('associatedWithProtocol')
      case 'Document can only be deleted with the packet': return notify.error('deleteOnlyWithPackage')
      // case 'Statement related to protocol dkk': return notify.error('relatedStatement')
      case 'Statement can only be deleted with the packet': return notify.error('deleteOnlyWithPackage')
      case 'Document does not have related docs': return notify.error('noRelatedDocs')
      case 'There are statements on verification with this phone':
      case 'This phone number is taken!':
        return notify.error('phoneNumberIsInUse')
      case 'Sailor does not have enough confirmed experience': return notify.error('notEnoughConfirmedExperiences')
      case 'Statement related to medical certificate': return notify.error('relatedToDocument')
      case 'Statement is not valid': return notify.error('isNotValidStatement')
      case 'Date issued is greater than 01-01-2022': return notify.error('invalidDateIssue')
      case 'Sailor has no full name in English': return notify.error('emptySailorEngFullName')
      case 'This password is too common.': return notify.warning('passwordIsToCommon')
      case 'This password is entirely numeric.': return notify.warning('useNotOnlyDigits')
      case 'The password is too similar to the login':
      case 'The password is too similar to the username.': return notify.warning('passwordSimilarAsLogin')
      case 'First create protocol sqc': return notify.warning('NotFoundSQC')
      case 'Given token not valid for any token type': return notify.warning('expiredTwoFactorAuthentication')
      case 'Cannot generate existing service record': return notify.warning('enableDownloadExistServiceRecord')
      case 'cannot create document with disabled rank, position, or type document': return notify.warning('enableCreateDocumentWithDisabledRank')
      case 'Agent has reached the limit for the current month': return notify.warning('crewingManagerUsersLimit')
      case 'Statement related to advanced training': return notify.error('relatedToDocument')
      default:
        text ? notify.error(`notify.${text}`) : notify.error(`notify.${JSON.stringify(error)}`)
    }
  } else return notify.error('notify.title.error')
}
