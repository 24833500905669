import api from '@/plugins/api'
import { sailor } from '@/configs/endpoints'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS, STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
import { queryString, FromObjectToFormData, updateStatementObj, getFiles, removeFileFromObject } from '@/mixins/main'
import notify from '@ananasbear/notify'
export const stateSQCSection = {
  // -------------- SQC PROTOCOLS -------------- //
  protocolSQC: {},
  protocolSQCById: {},
  successProtocols: [],
  // -------------- SQC STATEMENTS -------------- //
  statementSQC: {},
  statementSQCById: {},
  successStatements: []
}
export const actionsSQCSection = {
  // -------------- SQC PROTOCOLS -------------- //
  async getProtocolsSQC ({ commit, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.sqc.protocol.list(data.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_SAILOR', { protocolSQC: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getProtocolsSQCByID ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.sqc.protocol.list(data.id || state.sailorId, data.documentID))
    response.data.behavior = { viewInfoBlock: true }
    if (SUCCESS_CODE.includes(response.code)) {
      if (response.data.commissioner_sign) {
        response.data.membersCommission = response.data.commissioner_sign.filter(value => value.commissioner_type === 'CH')
        response.data.headCommission = response.data.commissioner_sign.find(value => value.commissioner_type === 'HD')
        response.data.secretaryCommission = response.data.commissioner_sign.find(value => value.commissioner_type === 'SC')
      }
      commit('SET_STATE_SAILOR', { protocolSQCById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
  },
  async updateProtocolsSQC ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.patch(sailor.sqc.protocol.list(data.id || state.sailorId, data.documentID), { body: data.body })
    response.data.behavior = { viewInfoBlock: true }
    if (data?.media?.files?.length) {
      const responseMedia = await api.post(sailor.sqc.protocol.uploadFile(data.id, response.data.id),
        { body: FromObjectToFormData(data.media) })
      if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
    }
    if (SUCCESS_CODE.includes(response.code)) {
      if (response.data.commissioner_sign) {
        response.data.membersCommission = response.data.commissioner_sign.filter(value => value.commissioner_type === 'CH')
        response.data.headCommission = response.data.commissioner_sign.find(value => value.commissioner_type === 'HD')
        response.data.secretaryCommission = response.data.commissioner_sign.find(value => value.commissioner_type === 'SC')
      }
      notify.success('editProtocolSQC')
      dispatch('getProtocolsSQCByID', data)
    }
  },
  async setProtocolsSQC ({ commit, dispatch, state }, data) {
    const response = await api.post(sailor.sqc.protocol.list(data.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.sqc.protocol.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      dispatch('getProtocolsSQC', { ...data, params: { page: 1, page_size: 10 } })
      commit('incrementBadgeCount', {
        child: 'sqcDocument',
        parent: 'sqcAll'
      })
      commit('incrementUserNotification', 'documentsToSign')
      notify.success('addedProtocolSQC')
    }
    return response
  },
  async removeProtocolsSQC ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.sqc.protocol.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.PROTOCOL_SQC.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { protocolSQCById: response.data })
        commit('decrementBadgeCount', {
          child: 'sqcDocument',
          parent: 'sqcAll'
        })
      } else dispatch('getProtocolsSQC', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async removeFileProtocolSQC ({ commit, state }, data) {
    const response = await api.delete(sailor.sqc.protocol.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.protocolSQCById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { protocolSQCById: newData })
    return response
  },
  async getSuccessProtocolSQC ({ commit, state }, data) {
    const response = await api.get(sailor.sqc.protocol.success(data.id))
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.map(value => {
        value.list_positions = value.position
        return updateStatementObj(value)
      })
      commit('SET_STATE_SAILOR', { successProtocols: response.data })
    }

    return response
  },
  async updateFileProtocolSQC ({ commit }, data) {
    const response = await api.patch(sailor.sqc.protocol.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },

  async getProtocolWithConclusion (context, data) {
    const response = await api.post(sailor.sqc.protocol.authProtocolDkk, { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      await getFiles(sailor.sqc.protocol.generateConclusion(response.data.token), data?.number || 'new file')
      notify.success(data.notify)
    }
  },

  // -------------- SQC STATEMENTS -------------- //
  async getSQCStatements ({ commit, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.sqc.statement.list(data.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_SAILOR', { statementSQC: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getSQCStatementByID ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.sqc.statement.list(data.id || state.sailorId, data.documentID))
    response.data.behavior = { viewInfoBlock: true }
    if (SUCCESS_CODE.includes(response.code)) {
      if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.REJECTED) {
        const responseComment = await dispatch('getSQCStatementCommentsById', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      }
      commit('SET_STATE_SAILOR', { statementSQCById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
  },
  async updateSQCStatements ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.patch(sailor.sqc.statement.list(data.id || state.sailorId, data.documentID), { body: data.body })
    response.data.behavior = { viewInfoBlock: true }
    if (data?.media?.files?.length) {
      const responseMedia = await api.post(sailor.sqc.statement.uploadFile(data.id, response.data.id),
        { body: FromObjectToFormData(data.media) })
      if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
    }
    if (SUCCESS_CODE.includes(response.code)) {
      if (response.data.is_payed) {
        if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.IN_PROCESSING &&
            data.body.status_document !== STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.IN_PROCESSING) {
          commit('incrementUserNotification', 'processStatementsSQC')
        } else commit('decrementUserNotification', 'processStatementsSQC')

        if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.APPROVED &&
            data.body.status_document !== STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.APPROVED) {
          commit('decrementUserNotification', 'approvedStatementsSQC')
        } else commit('incrementUserNotification', 'approvedStatementsSQC')
      }

      if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.REJECTED) {
        const responseComment = data?.comments
          ? await dispatch('setCommentSQCStatementById', data)
          : await dispatch('getSQCStatementCommentsById', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      }
      dispatch('getSQCStatementByID', data)
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async setSQCStatements ({ commit, dispatch, state }, data) {
    const response = await api.post(sailor.sqc.statement.list(data.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) {
      if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.IN_PROCESSING) {
        commit('incrementUserNotification', 'processStatementsSQC')
      }
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.sqc.statement.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      dispatch('getSQCStatements', { ...data, params: { page: 1, page_size: 10 } })
      commit('incrementBadgeCount', {
        child: 'sqcStatement',
        parent: 'sqcAll'
      })
      commit('incrementUserNotification', 'documentsToSign')
      notify.success('addedStatementSQC')
    }
    return response
  },
  async removeSQCStatements ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.sqc.statement.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_STATEMENT_CONSTANTS.SAILOR.SQC.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { statementSQCById: response.data })
        commit('decrementBadgeCount', {
          child: 'sqcStatement',
          parent: 'sqcAll'
        })
      } else dispatch('getProtocolsSQC', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async removeFileStatementSQC ({ commit, state }, data) {
    const response = await api.delete(sailor.sqc.statement.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.statementSQCById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { statementSQCById: newData })
    return response
  },
  async getSuccessStatementsSQC ({ commit, state }, data) {
    const response = await api.get(sailor.sqc.statement.success(data.id))
    if (SUCCESS_CODE.includes(response.code)) {
      commit('SET_STATE_SAILOR', { successStatements: response.data })
    }
    return response
  },
  async setCommentSQCStatementById ({ state }, data) {
    const response = await api.post(sailor.sqc.statement.comment(data.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) notify.success('documentReject')
    response.data = [response.data]
    return response
  },
  async getSQCStatementCommentsById ({ state }, data) {
    const response = await api.get(sailor.sqc.statement.comment(data?.id || state.sailoId, data.documentID))
    return response
  },
  async replaceDocumentsInStatementSQC ({ state, dispatch }, data) {
    const response = await api.patch(sailor.qualification.replacementStatements.replaceDocuments(data.id || state.sailorId, data.documentID), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) dispatch('getSQCStatementByID', data)
    return response
  },
  async attachNewDocumentsToStatementSQC ({ state, dispatch }, data) {
    const response = await api.post(sailor.sqc.statement.attachDocuments(data?.id || state.sailorId, data.documentID), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) dispatch('getSQCStatementByID', data)
    return response
  },
  async updateFileStatementSQC ({ commit }, data) {
    const response = await api.patch(sailor.sqc.statement.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  async getStatementDocumentSQC (context, data) {
    const response = await api.post(sailor.sqc.statement.statementDocument, { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      await getFiles(sailor.sqc.statement.generate(response.data.token, data.number, data.type))
    }
  }
}
