import store from '@/store'
import router from '@/router'

import Vue from 'vue'
import i18n from '@/plugins/localization/index'
import { checkAccess } from '@/mixins/permissions'
import api from '@/plugins/api'
import notify from '@ananasbear/notify'

// id list statuses
const inProcess = [1, 5, 6, 11, 14, 18, 20, 21, 25, 31, 32, 48, 50, 51, 52, 63, 66, 69, 72, 77, 80, 82, 83, 88, 89, 94,
  99, 115, 120, 123, 125, 126, 132, 133, 140, 141]
const expired = [7, 8, 15, 30, 33, 53, 54, 56, 57, 73, 84, 86, 91, 97]
const warning = [3, 45, 131, 135, 138, 139, 137]
const reject = [4, 10, 13, 16, 17, 23, 36, 37, 44, 46, 49, 62, 65, 68, 71, 76, 79, 93, 98, 127, 130, 134]
const valid = [0, 2, 9, 12, 19, 24, 29, 39, 41, 43, 47, 55, 58, 61, 64, 67, 70, 75, 78, 81, 85, 90, 92, 95, 100, 116, 139]
const verification = [34, 60, 74, 86, 122]
const info = [42, 87, 121, 124, 128, 129]

export const getUpdatedFileds = (oldObj, newObj) => {
  const changesFields = Object.keys(oldObj).filter(key => oldObj[key] !== newObj[key])
  let result = {}
  changesFields.forEach(key => {
    result[key] = newObj[key]
  })
  return result
}

export const getStatus = (statusId) => {
  switch (true) {
    case (inProcess.includes(statusId)):
      return 'in-process'
    case (expired.includes(statusId)):
      return 'expired'
    case (warning.includes(statusId)):
      return 'warning'
    case (reject.includes(statusId)):
      return 'reject'
    case (valid.includes(statusId)):
      return 'valid'
    case (verification.includes(statusId)):
      return 'verification'
    case (info.includes(statusId)):
      return 'info'
  }
}

export const getPaymentStatus = (statusId) => {
  if (statusId) return 'payed'
  else return 'notPayed'
}

export const getDecisionStatus = (statusID) => {
  if (statusID === 1) return 'valid'
  else return 'in-process'
}

export function dateFormat (date = null) {
  let today = date || new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1
  let yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return yyyy + '-' + mm + '-' + dd
}

export const getDateFormat = (date) => {
  if (date) return date.split('T')[0].split('-').reverse().join('.')
}

export const viewDetailedBlock = (row, block) => {
  row.item.behavior = {}
  row.item.behavior[block] = true
}
export const viewDetailedComponent = (component, block) => {
  component.behavior = {}
  component.behavior[block] = true
}

export const showDetailed = (row) => {
  if (!row.detailsShowing) {
    row.toggleDetails()
  }
}

export const hideDetailed = (row) => {
  row.toggleDetails()
}

export const getFilesFromData = (files) => {
  let photoArr = []
  try {
    files && files.forEach(async (file) => {
      const fileUrl = file.original_photo || file.photo
      if (fileUrl) {
        let croppedPhoto, fileBlob
        if (file.cropped_photo) croppedPhoto = await fetch(file.cropped_photo)
        fileBlob = await fetch(fileUrl).then(response => response.blob())
        photoArr.push({
          id: file.id,
          name: fileUrl,
          url: fileUrl,
          original: fileUrl,
          cropped: croppedPhoto?.url || null,
          // type: file.photo ? file.photo.split('.').pop().toLowerCase() : null,
          type: file.photo ? file.photo.split('.').pop().split('?')[0].toLowerCase() : null,
          isDeleted: file.is_delete || file.is_deleted,
          isArchived: file.is_archived,
          status_document: file.status_document,
          showPhotoActions: false,
          base64: { origin: await blobToBase64(fileBlob) }
        })
      }
    })
  } catch (e) { console.warn(e) }
  return photoArr
}

export const getFileAll = async (files) => {
  if (!files || !Array.isArray(files)) return false
  const response = await Promise.all(
    files.map(async (file) => {
      const URL = file.original_photo || file.photo
      return fetch(URL).then(photo => {
        return photo.blob()
      })
    })
  )
  return response
}

export const getFileAllFromURLBlob = async (files) => {
  if (!files || !Array.isArray(files)) return false
  const response = await Promise.all(
    files.map(async (file) => {
      let original = file.original_photo ? await api.get(file.original_photo, { responseType: 'blob' }) : null
      let cropped = file.cropped_photo ? await api.get(file.cropped_photo, { responseType: 'blob' }) : null
      file.origin = URL.createObjectURL(original?.data)
      file.url = file.original_photo
      if (cropped) file.cropped = URL.createObjectURL(cropped?.data)
      return file
    })
  )
  return response
}

export const mappingQualification = (typeDocument, qualification) => {
  if (typeDocument) {
    let qualificationType
    switch (typeDocument) {
      case 1:
        qualificationType = 5
        return store.getters.qualificationById(qualificationType)
      case 3:
        qualificationType = 2
        return store.getters.qualificationById(qualificationType)
      case 2:
      case 4:
        qualificationType = 3
        return store.getters.qualificationById(qualificationType)
      default:
        qualification = null
        return []
    }
  } else {
    qualification = null
    return []
  }
}

export const mappingProfession = (typeDocument) => {
  if (typeDocument) {
    if (typeDocument === 2) return store.getters.professionById(4)
    else return store.getters.professionById(typeDocument)
  } else return store.state.directory.profession
}

export const clearPosition = (rank, positions) => {
  if (positions) {
    positions.splice(0, positions.length)
  }
}

export const setSearchDelay = (_this, searchQuery, delayProp, searchType) => {
  clearTimeout(_this.delayProp)
  _this.delayProp = setTimeout(() => {
    _this.goSearch(searchQuery, searchType)
  }, 1000)
}

export const goBack = () => {
  window.history.back()
}

export const mappingPositions = (rank, excludeDisabled) => {
  if (rank) {
    if (excludeDisabled) return store.getters.availablePositionsByID(rank.id || rank)
    else return store.getters.positionsByRankID(rank.id || rank)
  } else return []
}

export const deleteConfirmation = () => {
  return new Promise((resolve) => {
    Vue.prototype.$swal({
      title: i18n.t('warning'),
      text: i18n.t('confirmDeleting'),
      icon: 'info',
      buttons: [i18n.t('cancel'), i18n.t('confirm')],
      dangerMode: true
    }).then(confirmation => {
      resolve(confirmation)
    })
  })
}

export const regenerationConfirmation = () => {
  return new Promise((resolve) => {
    Vue.prototype.$swal({
      title: i18n.t('regenerationConfirm'),
      text: i18n.t('regenerationInfo'),
      icon: 'warning',
      buttons: [i18n.t('cancel'), i18n.t('confirm')],
      dangerMode: true
    }).then(confirmation => {
      resolve(confirmation)
    })
  })
}

export const getExperienceStatus = (row) => {
  let status
  if (row.value || row.item.value) {
    status = i18n.t('enough')
  } else {
    try {
      status = `${i18n.t('required')}: ${row.item.must_be_exp || row.item.must_be_exp} ${i18n.t('month')};
        ${i18n.t('received')}: ${row.sailor_month || row.item.sailor_month} ${i18n.t('month')} ${row.sailor_days || row.item.sailor_days} ${i18n.t('days')};
        ${i18n.t('left')}: ${row.month_left || row.item.month_left} ${i18n.t('month')} ${row.days_left || row.item.days_left} ${i18n.t('days')};`
    } catch {
      status = `${i18n.t('left')} ${row.month_left || row.item.month_left} ${i18n.t('month')} ${i18n.t('of')} ${row.must_be_exp || row.item.must_be_exp} ${i18n.t('month')}`
    }
  }
  return status
}

export const enterDoublePosition = (rank, positions) => {
  if (positions) positions.splice(0, positions.length)
  if (rank) {
    const doublePositions = [106, 121, 122, 123]
    if (doublePositions.includes(rank?.id || rank)) {
      const positionList = store.getters.positionsByIdSQC(rank?.id || rank)
      positionList.forEach(value => {
        positions.push(value?.id || value)
      })
    }
  }
}

export const back = (type) => {
  const link = store.state.route.from.name || type
  router.push({ name: link })
}

export const setLinksByContentType = (item) => {
  switch (item.content_type) {
    case 'servicerecord': return 'experience-records-info'
    case 'qualificationdocument': return 'qualification-documents-info'
    case 'proofofworkdiploma': return 'qualification-documents-proof-info'
    case 'education': return 'education-documents-info'
    case 'medicalcertificate': return 'medical-certificates-info'
    case 'sailorpassport': return 'passports-sailors-info'
    case 'foreignpassport': return 'passports-international-info'
    case 'certificateeti': return 'certification-certificates-info'
    case 'certificateofnavigator': return 'qualification-navigator-info'
    case 'statementservicerecord': return 'experience-statements-info'
    case 'statementqualification': return 'qualification-statements-info'
    case 'statementsqc':
    case 'commissionerstatement': return 'sqc-statements-info'
    case 'statementeti': return 'certification-statements-info'
    case 'statementsailorpassport': return 'passports-statements-info'
    case 'statementmedicalcertificate': return 'medical-statements-info'
    case 'statementadvancedtraining': return 'education-statements-info'
    case 'packetitem': return 'position-statements-info'
    case 'passport': return 'passports-citizen-info'
    case 'experienceline': return 'experience-reference-info'
    case 'protocolsqc': return 'sqc-protocols-info'
    case 'lineinservicerecord':
      if (item.service_record || item.is_service_record) return 'experience-records-line-info'
      else return 'experience-reference-info'
  }
}

export const disableDates = (date, disableDatesList) => {
  const weekday = new Date(date).getDay()
  const isWeekend = checkAccess('backOffice') ? false : weekday === 0 || weekday === 6

  if (checkAccess('backOffice')) {
    if (new Date(date) > getDateInPeriod(365, 'increase') || new Date(date) < getDateInPeriod(1, 'decrease')) return true
  } else {
    if (new Date(date) > getDateInPeriod(365, 'increase') || new Date(date) < new Date()) return true
  }

  if (!checkAccess('backOffice') && disableDatesList && disableDatesList.length) {
    const disableDatesPeriods = disableDatesList.map(value => {
      return (new Date(date) >= new Date(value[0]) && new Date(date) <= new Date(value[1])) || isWeekend
    })
    return disableDatesPeriods.includes(true)
  } else return isWeekend
}

export const getDateInPeriod = (days, type) => { // Get Date after/before N-days amount
  let date = new Date()
  if (type === 'increase') date.setDate(date.getDate() + days)
  else date.setDate(date.getDate() - days)
  return date
}

export const getDirectoryObject = (data) => {
  if (data.id) {
    if (store.getters.getDirectoryObject(data)) {
      return store.getters.getDirectoryObject(data)
    } else return {}
  } else return {}
}

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  if (!filename.includes('.')) {
    filename = filename.concat('.', mime.split('/')[1])
  }
  let file = new File([u8arr], filename, { type: mime })
  return file
}

export const canChangeStatus = (status) => { // Check if user can change document status with exist status id
  if (status) return store.getters.statusById(status).can_user_change
  else return false
}

export const postFiles = (documents, type, id, photoAttr) => {
  let dataPhoto = new FormData()
  dataPhoto.append('type_document', type)
  dataPhoto.append('id_document', id)
  if (photoAttr) dataPhoto.append('photo_attr', photoAttr)

  for (let photo of documents) {
    dataPhoto.append('photo', photo)
  }
  return api.post('api/v1/sailor/photo_uploader/', { body: dataPhoto, responseType: 'blob' })
}

export const getFiles = (url, fileName, contentType = 'application/pdf') => {
  return api.get(url, { responseType: 'blob' }).then(response => {
    if ([200, 201].includes(response.code)) {
      // const blob = new Blob([response], { type: contentType })
      const link = document.createElement('a')
      document.body.appendChild(link)
      // link.href = URL.createObjectURL(blob)
      link.href = URL.createObjectURL(response.data)
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
      document.body.removeChild(link)
    }
    return response
  })
}
export const ucFirst = (str) => {
  if (!str) return str

  return str[0].toUpperCase() + str.slice(1)
}

export const blobToBase64 = async (blob) => {
  const file = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = error => reject(error)
    reader.readAsDataURL(blob)
  })
  return file
}
export const FileToBase64 = async (file) => {
  const fileBase64 = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = error => reject(error)
    reader.readAsDataURL(file)
  })
  file.url = fileBase64
  return file
}
export const blobToFile = (blob, nameFile = 'file_name') => {
  return new File([blob], nameFile, { lastModified: new Date(), type: blob.type })
}

export const paramsAddLimit = (params, limit = 20) => {
  if (params && !params.has('page_size')) params.append('page_size', String(limit))
  else if (!params) {
    params = new URLSearchParams()
    params.append('page_size', String(limit))
  }
  return params
}

export const checkWhenWasLastRequest = (items, nameTimeStamp) => {
  let timeStamp = sessionStorage.getItem(nameTimeStamp)
  let minutes = 15 * 60 * 3600
  return !items.length || ((timeStamp + minutes) < +new Date())
}

export const queryString = (object, deleteKey = []) => {
  if (object && Object.keys(object).length) {
    deleteKey.forEach(key => delete object[key])
    let params = new URLSearchParams()
    Object.entries(object).forEach(([key, value]) => {
      if (Array.isArray(value)) params.set(key, value.join())
      else params.set(key, value)
    })
    return `?${params}`
  } else return ''
}

export const clearBody = (object, exclude = []) => {
  if (typeof object === 'object') {
    Object.entries(object).forEach(([key, value]) => {
      if ((value === null || value === undefined || value === '') && !exclude.includes(key)) delete object[key]
      else if (typeof value === 'object' && !Array.isArray(value) && !exclude.includes(key)) {
        if (!Object.keys(object[key]).length) delete object[key]
        else {
          object[key] = { ...clearBody(object[key], exclude) }
        }
      } else if (Array.isArray(value) && !exclude.includes(key)) {
        value = value.map(el => { return { ...clearBody(el, exclude) } })
      }
      if (value !== null && typeof value === 'object' && !Object.keys(value).length && !exclude.includes(key)) delete object[key]
    })
  }
  return object
}

export const downloadBlob = (blob, name, type = 'xlsx') => {
  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `report-${name}-${Math.random(0, 100)}.${type}`
  link.click()
}

export const replaceAllSymbols = (string, arrayReplaceSymbols = []) => {
  if (!string) return ''
  arrayReplaceSymbols.forEach(symbol => (string = string.replaceAll(symbol, '')))
  return string
}

export const copyToClipboard = text => {
  navigator.clipboard.writeText(text).then(() => {
    notify.success('notify.successNotify.The link a copy successful')
  })
}

export const addNeedFieldForViewFiles = (items) => {
  return items.map(file => {
    let checkType = file.file?.split('?')[0]?.split('.') || file.photo?.split('?')[0]?.split('.')
    file.url = file.file || file.photo
    file.name = file.file_type || file.file?.split('?')[0].split('/').at(-1) || file.photo?.split('?')[0].split('/').at(-1)
    file.type = checkType[checkType.length - 1]
    return file
  })
}
export const addNeedFieldForViewFilesV2 = async (items) => {
  let response = await Promise.all(items.map(async file => {
    let newFile = { }
    let checkType = file.file?.split('?')[0]?.split('.') || file?.photo?.split('?')[0]?.split('.') || file?.type?.split('/')[1]
    newFile.id = file?.id
    newFile.url = file?.file || file?.photo
    newFile.name = file?.file_type || file?.file?.split('?')[0].split('/').at(-1) || file.photo?.split('?')[0].split('/')?.at(-1)
    newFile.type_file = checkType[checkType?.length - 1]
    newFile.is_delete = file?.is_delete || false
    newFile.blob = blobToFile(new Blob([file], { type: file.type }), newFile.name.split('.')[0])
    return newFile
  }))
  return response
}

export const addNameFile = (str) => {
  if (str) {
    let arrayStr = str?.split('?')[0].split('/')
    return arrayStr[arrayStr.length - 1]
  }
  return '-'
}

export const checkFieldsWhichChanged = (newBody, prevBody) => {
  Object.entries(newBody).forEach(([key, value]) => {
    if (prevBody[key] === value) delete newBody[key]
  })
  return newBody
}

export const FromObjectToFormData = (obj) => {
  let formData = new FormData()
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) value.forEach(el => formData.append(key, el))
    else formData.append(key, value)
  })
  return formData
}
export const updateStatementObj = (data) => {
  let positionUrk = []
  let positionEng = []
  data.list_positions.map(value => {
    positionUrk.push(value.name_ukr)
    positionEng.push(value.name_eng)
  })
  data.rank_id = data.rank.id
  data.name_ukr = `${data.number_document || data.number} ${positionUrk.join(', ')}`
  data.name_eng = `${data.number_document || data.number} ${positionEng.join(', ')}`

  if (data.hasOwnProperty('position')) {
    data.positionId = data.position.id
  }

  return data
}

export const checkrankIsSQC = (listRank, rankId) => {
  if (rankId) return listRank.find(el => el.id === rankId)?.is_dkk
  return false
}

export const removeFileFromObject = (itemArray, fileId, removeOnce = false) => {
  if (itemArray.find(el => el.id === fileId)?.is_delete || removeOnce) {
    itemArray = itemArray.filter(el => el.id !== fileId)
  } else {
    itemArray = itemArray?.map(item => {
      if (item.id === fileId) {
        if (!item.is_delete) item.is_delete = true
      }
      return item
    })
  }
  return itemArray
}
