
export default [
  {
    path: '/statistics-verification',
    name: 'statistics-verification',
    redirect: { name: 'user-verification-statistics' },
    component: () => import('@/views/StatisticsVerification/Statistics.vue'),
    children: [
      {
        path: 'excel-verification',
        name: 'excel-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ExcelTable.vue')
      },
      {
        path: 'user-verification',
        name: 'user-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'existing-doc-verification',
        name: 'existing-doc-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'sailor-photo-verification',
        name: 'sailor-photo-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'service-record-verification',
        name: 'service-record-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'line-in-service-record-verification',
        name: 'line-in-service-record-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'education-verification',
        name: 'education-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'medical-certificate-verification',
        name: 'medical-certificate-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'qualification-verification',
        name: 'qualification-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'navigator-verification',
        name: 'navigator-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'foreign-passport-verification',
        name: 'foreign-passport-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'sailor-passport-verification',
        name: 'sailor-passport-verification-statistics',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'sailor-citizen-passport-verification',
        name: 'statistics-citizen-passport-verification',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      },
      {
        path: 'sailor-isc-user-verification',
        name: 'statistics-isc-user-verification',
        component: () => import('@/views/StatisticsVerification/ItemStatisticVerification.vue')
      }
    ]
  }
]
