export default [
  {
    path: '/back-office',
    name: 'back-office',
    redirect: '/back-office/coefficients',
    component: () => import('@/views/BackOffice/BackOffice.vue'),
    children: [
      {
        path: 'coefficients',
        name: 'coefficients-backoffice',
        component: () => import('@/views/BackOffice/BackOfficeCoefficients/Table.vue')
      },
      {
        path: 'coefficients/:documentID',
        name: 'coefficients-backoffice-info',
        component: () => import('@/views/BackOffice/BackOfficeCoefficients/Document.vue')
      },
      {
        path: 'eti/courses',
        name: 'courses-backoffice',
        component: () => import('@/views/BackOffice/BackOfficeCourses/BackOfficeCourses.vue')
      },
      {
        path: 'eti/courses/:documentID',
        name: 'courses-backoffice-info',
        component: () => import('@/views/BackOffice/BackOfficeCourses/BackOfficeCoursesDocument/BackOfficeCoursesDocument.vue')
      },
      {
        path: 'eti/list',
        name: 'list-eti-backoffice',
        component: () => import('@/views/BackOffice/BackOfficeETIList/BackOfficeETIList.vue')
      },
      {
        path: 'eti/list/:documentID',
        name: 'list-eti-backoffice-info',
        component: () => import('../views/BackOffice/BackOfficeETIList/BackOfficeETIListDocument/BackOfficeETIListDocument.vue')
      },
      {
        path: 'eti/price-course',
        name: 'price-course-backoffice',
        component: () => import('@/views/BackOffice/BackOfficeCoursePrices/BackOfficeCoursePrices.vue')
      },
      {
        path: 'eti/price-course/:documentID',
        name: 'price-course-backoffice-info',
        component: () => import('@/views/BackOffice/BackOfficeCoursePrices/BackOfficeCoursePricesDocument/BackOfficeCoursePricesDocument.vue')
      },
      {
        path: 'eti/rate',
        name: 'rate-eti-backoffice',
        component: () => import('@/views/BackOffice/BackOfficeDocumentsPrice/Offline/Table.vue')
      },
      {
        path: 'dealing',
        name: 'dealing-backoffice',
        component: () => import('@/views/BackOffice/BackOfficeDealing/Table.vue')
      },
      {
        path: 'dealing/:documentID',
        name: 'dealing-backoffice-info',
        component: () => import('@/views/BackOffice/BackOfficeDealing/Document.vue')
      },
      {
        path: 'crewing',
        name: 'crewing-backoffice',
        redirect: { name: 'crewing-groups-backoffice' },
        component: () => import('@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewing.vue'),
        children: [
          {
            path: 'group/',
            name: 'crewing-groups-backoffice',
            component: () => import('@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewingGroups/BackOfficeCrewingGroups.vue')
          },
          {
            path: 'group/:groupID/company/',
            name: 'crewing-companies-backoffice',
            component: () => import('@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewingCompanies/BackOfficeCrewingCompanies.vue')
          },
          // {
          //   path: 'crewing-managers/',
          //   name: 'crewing-managers-crewingHead',
          //   component: () => import('@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewingManagers/BackOfficeCrewingManagers.vue')
          // },
          {
            path: 'group/:groupID/company/:companyID/edit',
            name: 'crewing-companies-backoffice-editing',
            component: () => import('@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewingCompanies/BackOfficeCrewingCompaniesForm.vue')
          },
          {
            path: 'group/:groupID/company/:companyID/manager',
            name: 'crewing-managers-backoffice',
            component: () => import('@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewingManagers/BackOfficeCrewingManagers.vue')
          },
          {
            path: 'group/:groupID/company/:companyID/manager/:managerID/',
            name: 'crewing-managers-backoffice-info',
            component: () => import('@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewingManagers/BackOfficeCrewingManagerDocument/BackOfficeCrewingManagerDocument.vue')
          }
        ]
      },
      {
        path: 'vnz',
        name: 'vnz-backoffice',
        component: () => import('@/views/BackOffice/BackOfficeVnz/Groups.vue'),
        children: []
      },

      {
        path: 'vnz/:vnz_id',
        name: 'list-vnz-backoffice-info',
        component: () => import('@/views/BackOffice/BackOfficeVnz/Document.vue')
      },
      {
        path: 'medical',
        name: 'medical-backoffice',
        component: () => import('@/views/BackOffice/BackOfficeMedical/Groups.vue')
      },
      {
        path: 'auto-signing-distribution',
        name: 'auto-signing-distribution-backoffice',
        component: () => import('@/views/BackOffice/AutoSigningDistribution/AutoSigningDistributionInfo.vue')
      },
      {
        path: 'auto-signing-distribution/edit',
        name: 'auto-signing-distribution-backoffice-edit',
        component: () => import('@/views/BackOffice/AutoSigningDistribution/AutoSigningDistributionForm.vue')
      },
      {
        path: 'translate-agencies',
        name: 'translate-agencies-backoffice',
        component: () => import('@/views/BackOffice/TranslateAgency/Table.vue')
      },
      {
        path: 'translate-agencies/:id',
        name: 'translate-agencies-backoffice-info',
        component: () => import('@/views/BackOffice/TranslateAgency/Document.vue')
      },
      {
        path: 'mes-agencies',
        name: 'mes-agencies-backoffice',
        component: () => import('@/views/BackOffice/NostrificationAgency/Table.vue')
      },
      {
        path: 'mes-agencies/:id',
        name: 'mes-agencies-backoffice-info',
        component: () => import('@/views/BackOffice/NostrificationAgency/Document.vue')
      },
      {
        path: 'powers-of-attorney',
        name: 'powers-of-attorney-backoffice',
        component: () => import('@/views/BackOffice/PowersOfAttorney/Table.vue')
      },
      {
        path: 'powers-of-attorney/:id',
        name: 'powers-of-attorney-backoffice-document',
        component: () => import('@/views/BackOffice/PowersOfAttorney/Document.vue')
      },
      {
        path: 'payment-statement-nostrification',
        name: 'payment-statement-nostrification-backoffice',
        component: () => import('@/views/BackOffice/PaymentStatementNostrification/Table.vue')
      },
      {
        path: 'payment-statement-nostrification/:id',
        name: 'payment-statement-nostrification-backoffice-document',
        component: () => import('@/views/BackOffice/PaymentStatementNostrification/Document.vue')
      },
      {
        path: 'directory/:dictionaryType',
        name: 'directory-backoffice',
        component: () => import('@/views/BackOffice/DirectoryBackoffice/Table.vue')
      }
    ]
  },
  {
    path: '/eti-payments',
    name: 'eti-payments',
    component: () => import('@/views/PaymentsETI/PaymentsETI.vue')
  },
  {
    path: '/sign-failed-documents',
    name: 'sign-failed-documents',
    component: () => import('@/views/Documents/SignFailedDocuments.vue')
  }
]
