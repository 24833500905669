<template lang="pug">
v-dialog(v-model="modal" :persistent="persistent" :max-width="maxWidth" :max-height="maxHeight")
  div(class="modalPackage")
    div(@click="useCloseEvent ? $emit('closeEvent') : changeModalState()" class="modalPackage-close")
      v-icon mdi-close
    div(v-if="!hideTitle" class="modalPackage-title")
      slot(name="title") {{ $t('actionConfirmation') }}
    div(v-if="showText" class="modalPackage-text")
      slot(name="text")
    div(v-if="showBody" class="modalPackage-body")
      slot(name="body")
    div(v-if="showFooter" class="modalPackage-footer")
      slot(name="footer")
</template>

<script>
export default {
  name: 'ModalWindow',
  props: {
    maxWidth: { type: String, default: '551' },
    maxHeight: { type: String, default: '90%' },
    persistent: { type: Boolean, default: false },
    hideTitle: { type: Boolean, default: false },
    useCloseEvent: { type: Boolean, default: false } // If u need to use custom method on close btn - call emit named "closeEvent"
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    showText: (context) => !!context.$slots.text,
    showBody: (context) => !!context.$slots.body,
    showFooter: (context) => !!context.$slots.footer
  },
  methods: {
    changeModalState (value) {
      this.modal = value || !this.modal
    }
  }
}
</script>

<style lang="sass">
// .v-overlay
//   background-color: rgba(29, 34, 39, 0.1) !important
//   backdrop-filter: blur(10px)
//   &__scrim
//     background-color: rgba(29, 34, 39, 0.1) !important
//     backdrop-filter: blur(10px)

.v-dialog
  position: relative
  overflow-x: hidden
  padding: 10px
  box-shadow: none
  .modalPackage
    width: 100%
    height: 100%
    padding: 28px 24px
    background-color: #FFFFFF
    border-radius: 6px
    color: #424E6D
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05)
    max-height: calc(100vh - 124px)
    &-title
      font-size: 18px
      line-height: 24px
      font-weight: 500
    &-text
      margin-top: 16px
      font-size: 14px
      line-height: 24px
      font-weight: 400
    &-body
      margin-top: 24px
    &-footer
      width: 100%
      display: flex
      justify-content: flex-end
      align-items: center
      margin-top: 40px
    &-close
      width: 34px
      height: 34px
      display: flex
      justify-content: center
      align-items: center
      position: absolute
      top: -4px
      right: -4px
      border-radius: 6px
      box-shadow: 0 3px 8px rgba(167, 174, 181, 0.4)
      cursor: pointer
      background: #FFF
</style>
