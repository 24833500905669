import api from '@/plugins/api'
import { sailor } from '@/configs/endpoints'
import { SUCCESS_CODE, STATUSES_STATEMENT_CONSTANTS, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { queryString, FromObjectToFormData, removeFileFromObject } from '@/mixins/main'
import notify from '@ananasbear/notify'

export const statePassportSection = {
  // --- SAILOR PASSPORTS --- //
  sailorPassport: {},
  sailorPassportById: {},
  sailorPassportProcessing: [],
  existSailorPassports: [],
  // --- SAILOR PASSPORT STATEMENTS--- //
  sailorPassportStatement: [],
  sailorPassportStatementById: {},
  // --- CITIZEN(CIVIL) PASSPORTS --- //
  sailorCitizenPassports: {},
  sailorCitizenPassportById: {},
  // --- FOREIGN PASSPORTS --- //
  sailorForeignPassports: {},
  foreignPassportById: {},
  // --- SAILOR FULL NAME CHANGES --- //
  sailorFullNameChanges: [],
  sailorFullNameChangesById: {}
}

export const actionsPassportSection = {
  // -------------- SAILOR PASSPORTS -------------- //
  async getSailorPassportProcessing ({ commit }, id) {
    const response = await api.get(sailor.passports.sailorPassport.choice(id))
    if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_SAILOR', { sailorPassportProcessing: response.data })
  },
  async getExistSailorPassports ({ commit }, id) {
    const response = await api.get(sailor.passports.sailorPassport.allowedToContinue(id))
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.map(item => {
        item.fullName = `${item.number_document} - ${item.port ? item.port.name_ukr : item.other_port}`
      })
      commit('SET_STATE_SAILOR', { existSailorPassports: response.data })
    }
  },
  async getSailorPassport ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.passports.sailorPassport.list(data?.id || state.sailorId) + queryString(data?.params))
    if (SUCCESS_CODE.includes(response?.code)) {
      commit('SET_STATE_SAILOR', { sailorPassport: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getSailorPassportById ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.passports.sailorPassport.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.SAILOR_PASSPORT.REJECTED) {
        const responseComment = await dispatch('getSailorPassportByIdComments', data)
        if (SUCCESS_CODE.includes(response.code)) response.data.comments = responseComment.data
      }
      commit('SET_STATE_SAILOR', { sailorPassportById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async updateSailorPassportById ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { sailorPassportById: {}, isLoading: true })
    const response = await api.patch(sailor.passports.sailorPassport.list(data.id || state.sailorId, data.documentID),
      { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (data.media?.files.length) {
        const responseMedia = await api.post(sailor.passports.sailorPassport.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.SAILOR_PASSPORT.REJECTED) {
        const responseComment = data?.comments
          ? await dispatch('setCommentRejectSailorPassportById', data)
          : await dispatch('getSailorPassportByIdComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = [responseComment.data]
      } else notify.success('editedSailorPassport')
      dispatch('getSailorPassportById', data)
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getSailorPassportByIdComments ({ state }, data) {
    const response = await api.get(sailor.passports.sailorPassport.comment(data.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) return response
  },
  async setCommentRejectSailorPassportById ({ state }, data) {
    const response = await api.post(sailor.passports.sailorPassport.comment(data.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) notify.success('documentReject')
    return response
  },
  async deleteSailorPassportById ({ state }, data) {
    const response = await api.delete(sailor.passports.sailorPassport.list(data.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) return response
  },
  async setSailorPassportWithStatement ({ commit, state, dispatch }, data) {
    const response = await api.post(sailor.passports.sailorPassport.issueDocument(data.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.passports.sailorPassport.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      await dispatch('getSailorPassport', { ...data, params: { page: 1, page_size: 10 } })
      notify.success('addedSailorPassport')
      commit('incrementBadgeCount', {
        child: 'passportDocument',
        parent: 'passportAll'
      })
      commit('incrementUserNotification', 'documents_on_verification')
    }
    return response
  },
  async setSailorPassport ({ commit, state, dispatch }, data) {
    const response = await api.post(sailor.passports.sailorPassport.list(data.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.passports.sailorPassport.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      await dispatch('getSailorPassport', data)
      notify.success('addedSailorPassport')
    }
    return response
  },
  async updateFileSailorPassport ({ commit }, data) {
    const response = await api.patch(sailor.passports.sailorPassport.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  // async updateFileSailorPassport ({ commit }, data) {
  //   const response = await api.patch(sailor.passports.sailorPassport.updateFile(data.id, data.documentID, data.fileId),
  //     { body: FromObjectToFormData(data.media) })
  //   if (SUCCESS_CODE.includes(response.code)) {
  //     notify.success('notify.success.fileHasBeenEdited')
  //   }
  //   return response
  // },

  async removeFileSailorPassport ({ commit, state }, data) {
    const response = await api.delete(sailor.passports.sailorPassport.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.sailorPassportById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { sailorPassportById: newData })
    return response
  },
  // -------------- SAILOR PASSPORT STATEMENTS-------------- //

  async getSailorPassportStatements ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.passports.sailorPassportStatement.list(data.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_SAILOR', { sailorPassportStatement: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getSailorPassportStatementById ({ state, commit, dispatch }, data) {
    const response = await api.get(sailor.passports.sailorPassportStatement.list(data.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (STATUSES_STATEMENT_CONSTANTS.SAILOR.SAILOR_PASSPORT.REJECT === response.data.status_document.id) {
        const responseComment = await dispatch('getSailorPassportStatementComments', data)
        response.data.comments = responseComment.data
      }
      commit('SET_STATE_SAILOR', { sailorPassportStatementById: response.data })
    }
    return response
  },
  async setSailorPassportStatement ({ commit, dispatch }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.post(sailor.passports.sailorPassport.statement.list(data.id), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data.media?.files.length) {
        data.documentID = response.data.id
        const responseMedia = await dispatch('uploadFilesSailorPassportStatements', data)
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (STATUSES_STATEMENT_CONSTANTS.SAILOR.SAILOR_PASSPORT.REJECT === response.data.status_document.id) {
        const responseComment = data.comments ? await dispatch('setSailorPassportStatementComments', data) : await dispatch('getSailorPassportStatementComments', data)
        response.data.comments = responseComment.data
      }
      notify.success('sailorPassportStatementAdded')
      commit('incrementBadgeCount', {
        child: 'passportStatement',
        parent: 'passportAll'
      })
      dispatch('getSailorPassportStatements', data.id)
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return SUCCESS_CODE.includes(response.code)
  },
  async updateSailorPassportStatements ({ state, commit, dispatch }, data) {
    const response = await api.patch(sailor.passports.sailorPassportStatement.list(data?.id || state.sailorId, data.documentID), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await dispatch('uploadFilesSailorPassportStatements', data)
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (STATUSES_STATEMENT_CONSTANTS.SAILOR.SAILOR_PASSPORT.REJECT === response.data.status_document.id) {
        const responseComment = data.comments
          ? await dispatch('setSailorPassportStatementComments', data)
          : await dispatch('getSailorPassportStatementComments', data)
        response.data.comments = responseComment.data
      } else notify.success('sailorPassportStatementEdited')
      response.data.behavior = { viewInfoBlock: true }
      dispatch('getApprovedSailorPassportStatements', data)
      dispatch('getSailorPassportStatementById', data)
    }
    return response
  },
  async getSailorPassportStatementComments ({ state }, data) {
    const response = await api.get(sailor.passports.sailorPassportStatement.comment(data?.id || state.sailorId, data.documentID))
    return response
  },
  async setSailorPassportStatementComments ({ dispatch }, data) {
    const response = await api.post(sailor.passports.sailorPassportStatement.comment(data.id, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.statementRejected')
      dispatch('getSailorPassportStatementById', data)
      return response
    }
  },

  async deleteSailorPassportStatementById ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.passports.sailorPassportStatement.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      if (response.data.status === STATUSES_STATEMENT_CONSTANTS.SAILOR.SAILOR_PASSPORT.DELETED) {
        commit('SET_STATE_SAILOR', { sailorPassportStatementById: response.data })
      } else dispatch('getSailorPassportStatements', { params: { page: 1, page_size: 10 } })
      commit('decrementBadgeCount', {
        child: 'passportStatement',
        parent: 'passportAll'
      })
    }
    return response
  },

  async uploadFilesSailorPassportStatements ({ state, dispatch }, data) {
    const response = await api.post(sailor.passports.sailorPassportStatement.uploadFile(data.id, data.documentID),
      { body: FromObjectToFormData(data.media), responseType: 'blob' })
    return response
  },

  async removeFileSailorPassportStatement ({ commit, state }, data) {
    const response = await api.delete(sailor.passports.sailorPassportStatement.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.sailorPassportStatementById }
    newData.photo = removeFileFromObject(newData.photo, data.fileId)
    commit('SET_STATE_SAILOR', { sailorPassportStatementById: newData })
    return response
  },
  async updateFileSailorPassportStatement ({ commit }, data) {
    const response = await api.patch(sailor.passports.sailorPassportStatement.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  // -------------- CITIZEN(CIVIL) PASSPORTS -------------- //

  async getSailorCitizenPassports ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.passports.citizen.list(data?.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_SAILOR', { sailorCitizenPassports: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getSailorCitizenPassportById ({ state, commit, dispatch }, data) {
    const response = await api.get(sailor.passports.citizen.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if ([STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.REJECTED].includes(response.data.status_document)) {
        const responseComments = await dispatch('getSailorCitizenPassportsComments', data)
        if (SUCCESS_CODE.includes(responseComments.code)) response.data.comments = responseComments.data
      }
      response.data.behavior = { viewInfoBlock: true }
      commit('SET_STATE_SAILOR', { sailorCitizenPassportById: response.data })
    }
    return response
  },
  async setSailorCitizenPassports ({ commit, state, dispatch }, data) {
    const response = await api.post(sailor.passports.citizen.list(data.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files.length) {
        const responseMedia = await api.post(sailor.passports.citizen.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      commit('incrementBadgeCount', {
        child: 'passportCitizen',
        parent: 'passportAll'
      })
      dispatch('getSailorCitizenPassports', { params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async updateSailorCitizenPassportById ({ state, commit, dispatch }, data) {
    const response = await api.patch(sailor.passports.citizen.list(data?.id || state.sailorId, data.documentID), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files.length) {
        const responseMedia = await api.post(sailor.passports.citizen.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (data.body.sex !== state.sailorInfo.sex.id) dispatch('getSailorInformation', data.id)
      if ([STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.REJECTED].includes(response.data.status_document)) {
        const responseComments = data.comments ? await dispatch('setCommentSailorCitizenPassportById', data) : await dispatch('getSailorCitizenPassportsComments', data)
        if (SUCCESS_CODE.includes(responseComments.code)) response.data.comments = responseComments.data
      }
      dispatch('getSailorCitizenPassportById', data)
    }
    return response
  },
  async setCommentSailorCitizenPassportById ({ state }, data) {
    const response = await api.post(sailor.passports.citizen.comment(data.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) notify.success('documentReject')
    response.data = [response.data]
    return response
  },
  async removeSailorCitizenPassports ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.passports.citizen.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      if (response.data.status === STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.DELETED) {
        commit('SET_STATE_SAILOR', { sailorCitizenPassportById: response.data })
      } else dispatch('getSailorCitizenPassports', { params: { page: 1, page_size: 10 } })
      commit('decrementBadgeCount', {
        child: 'passportCitizen',
        parent: 'passportAll'
      })
    }
    return response
  },
  async getSailorCitizenPassportsComments ({ state }, data) {
    const response = await api.get(sailor.passports.citizen.comment(data?.id || state.sailorId, data.documentID))
    return response
  },
  async removeFileCitizenPassport ({ commit, state }, data) {
    const response = await api.delete(sailor.passports.citizen.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.sailorCitizenPassportById }
    newData.photo = removeFileFromObject(newData.photo, data.fileId)
    commit('SET_STATE_SAILOR', { sailorCitizenPassportById: newData })
    return response
  },
  async updateFileCitizenPassport ({ commit }, data) {
    const response = await api.patch(sailor.passports.citizen.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },

  // -------------- FOREIGN PASSPORTS -------------- //
  async getInfoCheckForPhoto (context, data) {
    let formData = new FormData()
    data.forEach(el => formData.append('photo', el))
    const response = await api.post(`api/v1/integrations/beegle/foreign_passport_recoignize/`, { body: formData, responseType: 'blob' })
    return response?.data
  },
  async getSailorForeignPassports ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.passports.foreign.list(data?.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_SAILOR', { sailorForeignPassports: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getSailorForeignPassportById ({ state, commit, dispatch }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true, foreignPassportById: {} })
    const response = await api.get(sailor.passports.foreign.list(data.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if ([STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.FOREIGN.REJECTED].includes(response.data.status_document)) {
        const responseCommnet = await dispatch('getSailorForeignPassportCommnet', data)
        if (SUCCESS_CODE.includes(responseCommnet.code)) response.data.comments = responseCommnet.data
      }
      response.data.behavior = { viewInfoBlock: true }
      commit('SET_STATE_SAILOR', { foreignPassportById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async setSailorForeignPassports ({ state, commit, dispatch }, data) {
    const response = await api.post(sailor.passports.foreign.list(data.id || state.sailoId), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files.length) {
        const responseMedia = await api.post(sailor.passports.foreign.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      commit('incrementBadgeCount', {
        child: 'passportInternationalDocument',
        parent: 'passportAll'
      })
      notify.success('internationalPassport.added')
      dispatch('getSailorForeignPassports', { params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async updateSailorForeignPassportById ({ state, commit, dispatch }, data) {
    const response = await api.patch(sailor.passports.foreign.list(data.id || state.sailorId, data.documentID), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (data?.media?.files.length) {
        const responseMedia = await api.post(sailor.passports.foreign.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if ([STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.FOREIGN.REJECTED].includes(response.data.status_document)) {
        const responseCommnet = data?.comments
          ? await dispatch('setCommentRejectSailorForeignPassportById', data)
          : await dispatch('getSailorForeignPassportCommnet', data)
        if (SUCCESS_CODE.includes(responseCommnet.code)) response.data.comments = responseCommnet.data
      } else notify.success('internationalPassport.edited')
      dispatch('getSailorForeignPassportById', data)
    }
    return response
  },
  async getSailorForeignPassportCommnet (context, data) {
    const response = await api.get(sailor.passports.foreign.comment(data.id, data.documentID))
    return response
  },
  async setCommentRejectSailorForeignPassportById ({ state }, data) {
    const response = await api.post(sailor.passports.foreign.comment(data.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) notify.success('documentReject')
    response.data = [response.data]
    return response
  },
  async removeSailorForeignPassportById ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.passports.foreign.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      if (response.data.status === STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.DELETED) {
        commit('SET_STATE_SAILOR', { foreignPassportById: response.data })
      } else dispatch('getSailorForeignPassports', { params: { page: 1, page_size: 10 } })
      commit('decrementBadgeCount', {
        child: 'passportInternationalDocument',
        parent: 'passportAll'
      })
    }
    return response
  },
  async removeFileForeignPassport ({ commit, state }, data) {
    const response = await api.delete(sailor.passports.foreign.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.foreignPassportById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { foreignPassportById: newData })
    return response
  },
  async updateFileForeignPassport ({ commit }, data) {
    const response = await api.patch(sailor.passports.foreign.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },

  // -------------- SAILOR FULL NAME CHANGES -------------- //

  async getFullNameChanges ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.passports.changeOldName.list(data?.id || state.sailoId) + queryString(data?.params))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.map((item, index) => {
        item.behavior = { viewInfoBlock: true }
        item.lastRecord = index === 0 // 0 position of array is always last record
      })
      commit('SET_STATE_SAILOR', { sailorFullNameChanges: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getFullNameChangesById ({ commit, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true, sailorFullNameChangesById: {} })
    const response = await api.get(sailor.passports.changeOldName.list(data?.id || state.sailoId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }
      commit('SET_STATE_SAILOR', { sailorFullNameChangesById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
  },
  async removeChangedFullNameById ({ state, dispatch }, data) {
    const response = await api.delete(sailor.passports.changeOldName.list(data?.id || state.sailoId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      dispatch('getFullNameChanges', { ...data, params: { page: 1, page_size: 10 } })
      dispatch('getSailorInformation', data.id)
    }
    return response
  },

  async setChangeSailorFullname ({ commit, dispatch, state }, data) {
    const response = await api.post(sailor.changedOldName(state.sailorId || data.id), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.passports.changeOldName.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      notify.success('fullNameWasChanged')

      dispatch('getFullNameChanges', { ...data, params: { page: 1, page_size: 10 } })
      dispatch('getSailorInformation', data?.id || state.sailorId)
    }
    return SUCCESS_CODE.includes(response.code)
  },
  async updateChangeSailorFullname ({ commit, dispatch, state }, data) {
    const response = await api.patch(sailor.changedOldNameById(state.sailorId || data.id, data.documentID), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.passports.changeOldName.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      notify.success('fullNameWasChanged')
      response.data.behavior = { viewInfoBlock: true }
      dispatch('getFullNameChangesById', data)
      dispatch('getSailorInformation', state.sailorId || data.id)
    }
    return SUCCESS_CODE.includes(response.code)
  },
  async removeFileChangeFullName ({ commit, state }, data) {
    const response = await api.delete(sailor.passports.changeOldName.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.sailorFullNameChangesById }
    newData.photo = removeFileFromObject(newData.photo, data.fileId)
    commit('SET_STATE_SAILOR', { sailorFullNameChangesById: newData })
    return response
  },
  async updateFileChangeFullName ({ commit }, data) {
    const response = await api.patch(sailor.passports.foreign.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  }
}
