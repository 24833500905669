import { apiV1, apiV2 } from '../constants'
export const reportsV2 = {
  sqc: {
    protocol: {
      list: () => `${apiV1}/reports/list/protocol_dkk/`
    },
    statement: {
      list: () => `${apiV2}/reports/statements_sqc/`
    }
  },
  education: {
    list: () => `${apiV1}/reports/list/educ_doc/`
  },
  eti: {
    list: () => `${apiV1}/reports/list/certificate_ntz/`,
    excel: `${apiV1}/reports/xlsx/certificate_ntz/`
  },
  sailorPassport: {
    list: () => `${apiV1}/reports/list/sailor_passport/`
  },
  advanceTraining: {
    list: `${apiV1}/reports/back_office/distribution/adv_training/institution/`,
    qualification: `${apiV1}/reports/back_office/distribution/adv_training/qualification/`,
    sailor: `${apiV1}/reports/back_office/distribution/adv_training/sailor/`
  },
  crewing: {
    connect: {
      company: {
        list: `${apiV1}/reports/crewing/connect/company/`,
        excel: `${apiV1}/reports/crewing/connect/company/xlsx/`
      },
      manager: {
        list: `${apiV1}/reports/crewing/connect/manager/`,
        excel: `${apiV1}/reports/crewing/connect/manager/xlsx/`
      },
      sailor: {
        list: `${apiV1}/reports/crewing/connect/sailor/`,
        excel: `${apiV1}/reports/crewing/connect/sailor/xlsx/`
      }
    }
  },
  finance: `${apiV1}/back_off/list/packets/`,
  debtor: {
    group: {
      list: `${apiV1}/reports/back_office/receipts/group/`,
      excel: `${apiV1}/reports/back_office/receipts/group/xlsx/`
    },
    crewing: {
      list: `${apiV1}/reports/back_office/receipts/crewing/`,
      manager: {
        list: `${apiV1}/reports/back_office/receipts/crewing_manager/`,
        excel: `${apiV1}/reports/back_office/receipts/crewing/xlsx/`
      }
    },
    sailor: `${apiV1}/reports/back_office/receipts/sailor/`,
    paket: `${apiV1}/reports/back_office/receipts/packet/`,
    document: `${apiV1}/reports/back_office/receipts/document/`
  },
  distribution: {
    crewing: {
      list: (typeDoc, group) => `${apiV1}/reports/back_office/distribution/${typeDoc}/${group ? 'group/' : ''}`,
      dpd: (typeDoc, type) => `${apiV1}/reports/back_office/distribution/${typeDoc}/crewing/${type}/`,
      exel: `${apiV1}/reports/back_office/distribution/eti/sailor/xlsx/`
    },
    group: (typeDoc) => `${apiV1}/reports/back_office/distribution/${typeDoc}/crewing/`,
    manager: {
      crewingManager: (typeDoc) => `${apiV1}/reports/back_office/distribution/${typeDoc}/crewing_manager/`,
      course: (typeDoc) => `${apiV1}/reports/back_office/distribution/${typeDoc}/courses/`,
      doctor: (typeDoc) => `${apiV1}/reports/back_office/distribution/${typeDoc}/doctor/`,
      manager: (typeDoc, type) => `${apiV1}/reports/back_office/distribution/${typeDoc}/manager/${type ? `${type}/` : ''}`,
      sailor: (typeDoc) => `${apiV1}/reports/back_office/distribution/${typeDoc}/sailor/`
    },
    sailor: (typeDoc) => `${apiV1}/reports/back_office/distribution/${typeDoc}/sailor/`,
    dpdSailor: (typeDoc, type) => `${apiV1}/reports/back_office/distribution/${typeDoc}/${type}`,
    typeDocument: (typeDoc) => `${apiV1}/reports/back_office/distribution/${typeDoc}/type_document/`,
    qualification: (typeDoc) => `${apiV1}/reports/back_office/distribution/${typeDoc}/qualification/`

  }
}
