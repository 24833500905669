import api from '@/plugins/api'
import { paramsAddLimit, postFiles, queryString } from '@/mixins/main'
import { user, auth, globalConfig } from '@/configs/endpoints'
import { SUCCESS_CODE } from '@/configs/constants'
import notify from '@ananasbear/notify'
import FIDO2 from '@ananasbear/fido2'

export const USER = {
  state: () => ({
    isLoading: false,
    userList: [],
    userHistory: {},
    listDevice: [],
    listDeviceUbikey: []
  }),
  mutations: {
    SET_STATE_DATA_USER (state, data) {
      // state[data.type] = data.data
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    }
  },
  actions: {
    getAllUsers ({ commit }, url) {
      return api.get(url || 'api/v1/auth/user/?page_size=1000').then(response => {
        if ([200, 201].includes(response?.code)) {
          response.data.results.map((item) => {
            item.behavior = { viewInfoBlock: true }
            item.userFullName = `${item.last_name} ${item.first_name} ${item.userprofile.middle_name || ''}`
          })
          commit('SET_STATE_DATA_USER', { userList: response.data })
        }
        return response
      })
    },
    async getUsers ({ commit }, params) {
      const response = await api.get(`api/v1/auth/user/${params}`)
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.results.map(item => (item.userFullName = `${item.last_name} ${item.first_name} ${item.userprofile.middle_name || ''}`))
        commit('SET_STATE_DATA_USER', { userList: response.data })
      }
      return response
    },
    async postSearchUserForSupport ({ commit }, body) {
      const response = await api.post('api/v1/auth/search_for_support/', { body })
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.support_code = response.data.code
        response.data.userFullName = response.data.full_user_name
        commit('SET_STATE_DATA_USER', { userList: { results: [response.data] } })
      }
      return response
    },
    getUser ({ commit }, id) {
      return api.get(`api/v1/auth/user/${id}/`).then(response => {
        if (response?.code === 200) {
          response.data.behavior = { viewInfoBlock: true }
          response.data.userFullName = `${response.data.last_name} ${response.data.first_name} ${response.data.userprofile.middle_name || ''}`
        }
        return response
      })
    },
    async updateUser ({ commit }, data) {
      const response = await api.patch(`api/v1/auth/user/${data.documentID}/`, { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        if (data.media?.files.length) {
          const responseMedia = await postFiles(data.media.files, data.media.name, response.data.id, data.media.photo_attr)
          if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
        }
      }
      return SUCCESS_CODE.includes(response.code)
    },
    async setUser (context, data) {
      const response = await api.post(user.user, { body: data.body })
      return response
    },
    changeUserPassword (context, data) {
      return api.post(`api/v1/auth/change_password_for/${data.id}/`, { body: data.body }).then(response => {
        return response
      })
    },
    getUserHistory ({ commit }, params) { // User history
      params = paramsAddLimit(params)
      return api.get(`api/v1/auth/history/?${params}`).then(response => {
        if (response?.code === 200) {
          response.data.results.map(item => {
            item.behavior = {}
            item.sailor = item.sailor_key
            item.model = `model-${item.module.replace(/ /g, '')}`
          })
          commit('SET_STATE_DATA_USER', { userHistory: response.data })
        }
        return response
      })
    },
    async getListUserHistory ({ commit }, params) {
      commit('SET_STATE_DATA_USER', { isLoading: true })
      const response = await api.get(user.history.list + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results = response.data.results.map((item, index) => {
          item.id = index + 1
          item.model = `model-${item.module.replace(/ /g, '')}`
          return item
        })

        commit('SET_STATE_DATA_USER', { userHistory: response.data })
      }
      commit('SET_STATE_DATA_USER', { isLoading: false })
    },

    async addUserContact (context, data) {
      const response = await api.post(user.addContact(data.id), { body: data.body })
      return response
    },

    async deleteUserContact (context, data) {
      const response = await api.delete(user.deleteContact(data.id, data.contactID))
      return response
    },
    // MODULE AUTHORIZATION
    async recoveryPassword (context, body) {
      const response = await api.post(auth.general.recoveryPassword, { body })
      return response
    },
    async checkPasswordRecoveryToken (context, body) {
      const response = await api.post(auth.general.checkPasswordRecoveryToken, { body })
      return response
    },
    async  changePasswordUserWithToken (context, body) {
      const response = await api.post(auth.general.changePasswordUserWithToken, { body })
      return response
    },
    async updatePassword (context, body) {
      const response = await api.post(auth.general.updatePassword, { body })
      return response
    },
    async getGlobalSettings (context) {
      const response = await api.get(globalConfig.twoFA)
      return response
    },
    async updateGlobalAuthorization (context, body) {
      const response = await api.patch(globalConfig.twoFA, { body })
      return response
    },
    async createForUserFido2 (context, data) {
      const response = await FIDO2.registration(auth.fido2.create(data.userId), auth.fido2.complete(data.userId), { responseType: 'arrayBuffer', requestType: 'application/cbor' })
      return response
    },
    async getListDeviceWithTOTP (context, params) {
      const response = await api.get(auth.totp.listDevice + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_USER', { listDevice: response.data })
    },
    async getListDeviceWithUbikey (context, params) {
      const response = await api.get(auth.fido2.list() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_USER', { listDeviceUbikey: response.data })
    },
    async getListDeviceWithTOTPForUser (context) {
      const response = await api.get(auth.totp.listDeviceForUser)
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_USER', { listDevice: response.data })
    },
    async removeTOTPUser (context, data) {
      const response = await api.delete(auth.totp.destroy(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('notify.success.deleteTOTP')
        context.commit('SET_STATE_DATA_USER', { listDevice: context.state.listDevice.filter(el => el.id !== data.id) })
      }
      return response
    },
    async removeTOTPAdmin (context, data) {
      const response = await api.delete(auth.totp.destroyByAdmin(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('notify.success.deleteTOTP')
        context.commit('SET_STATE_DATA_USER', { listDevice: context.state.listDevice.filter(el => el.id !== data.id) })
      }
      return response
    },
    async removeUbikeyAdmin (context, data) {
      const response = await api.delete(auth.fido2.list(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('notify.success.deleteUbikey')
        context.commit('SET_STATE_DATA_USER', { listDeviceUbikey: context.state.listDeviceUbikey.filter(el => el.id !== data.id) })
      }
      return response
    },
    async removeTOTPForUser (context, data) {
      const response = await api.delete(auth.totp.destroy(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        context.commit('SET_STATE_DATA_USER', { listDevice: context.state.listDevice.filter(el => el.id !== data.id) })
      }
      return response
    }
  }
}
