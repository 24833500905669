import api from '@/plugins/api'
import { u2f } from '@/configs/endpoints'
export default {
  actions: {
    async registrationU2F (context, data) {
      const response = await api.get(u2f.create(data.id))
      return response
    },
    async createRegister (context, data) {
      const response = await api.post(u2f.create(data.id), { body: data.body })
      return response
    },
    async registerFingerFirst (context) {
      const response = await api.get(u2f.registrer)
      return response
    },
    async registerFingerSecond (context, data) {
      const response = await api.post(u2f.registrer, { body: data.body })
      return response
    }
  }
}
