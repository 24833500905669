<template lang="pug">
v-card
  v-card-title.text-uppercase.text-left {{ $t('departmentForCourseETI', { eti: row[labelName] }) }}
    span(v-if="checkAccess('backOffice')") (ID: {{ row.id }})
  v-card-text
    TableV2(
      :headers="headers"
      :items="items.eti_registry || items.results"
      :pageCount="items.count"
      :currentPage="items.current"
      :actions="actions"
      isHiddenTableSearch
      isHiddenTableCount
      isHiddenPageCount
      isHiddenSearchParams
      :querySearch="getData"
    )

</template>

<script>
import { hideDetailed } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BackOfficeCoursesInfo',
  props: {
    row: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      headers: [
        { value: 'course.id',
          text: this.$t('id'),
          sortable: false
        },
        { value: 'full_number_protocol',
          text: this.$t('numberProtocol'),
          sortable: false
        },
        { value: 'date_start',
          text: this.$t('dateEffective'),
          sortable: false
        },
        { value: 'date_end',
          text: this.$t('dateTermination'),
          sortable: false
        },
        { value: 'course',
          text: this.$t('course'),
          sortable: false
        },
        { value: 'is_disable',
          text: this.$t('status'),
          sortable: false
        },
        { value: 'status_protocol',
          text: this.$t('status_protocol'),
          sortable: false
        },
        { value: 'event',
          text: this.$t('actions'),
          class: 'mw-0',
          sortable: false
        }
      ],
      // items: this.row.item.eti_registry,
      sortBy: 'date_start',
      sortDesc: true,
      hideDetailed,
      checkAccess,
      actions: [
        {
          id: 2,
          tooltip: 'tooltip.go',
          action: (item) => this.$router.push({ name: 'courses-backoffice-info', params: { documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: (item) => item.id
        }
      ]
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      items: state => state.backoffice.etiDirectionById,
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getCourseByIdETI']),
    async getData (params) {
      const { id } = this.row
      await this.getCourseByIdETI({ id, params })
    }
  }
}
</script>
