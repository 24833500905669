import api from '@/plugins/api'

export default {
  state: () => ({
    listWearHouseMorrichservice: [],
    typeDelivery: [],
    typeDeliveryReport: [{
      type_delivery: 'morrichservice',
      name_ukr: 'Самовивіз з Моррічсервісу',
      name_eng: 'Самовивіз з Моррічсервісу'
    }, {
      type_delivery: 'novaposhta_pickup',
      name_ukr: 'Нова пошта (самовивіз)',
      name_eng: 'Нова пошта (самовивіз)'
    }, {
      type_delivery: 'novaposhta_courier',
      name_ukr: 'Нова пошта (кур’єр)',
      name_eng: 'Нова пошта (кур’єр)'
    }]
  }),
  mutations: {
    GET_STATE_DATA_DELIVERY (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    }
  },
  actions: {
    // getCityNovaPoshta (context, data) {
    // return api.get(`api/v1/delivery/novaposhta_warehouse/${data.id}/`).then(response => response)
    // },
    getWearHouseNovaPoshta (context, data) {
      return api.get(`api/v1/delivery/novaposhta_warehouse/${data.id}/`).then(response => response)
    },
    getStreetNovaPoshta (context, data) {
      return api.get(`api/v1/delivery/novaposhta_street/${data.id}/`).then(response => response)
    },
    getWearHouseMorrichservice (context) {
      return api.get(`api/v1/directory/morrichservice_office/`).then(response => {
        context.commit('GET_STATE_DATA_DELIVERY', { listWearHouseMorrichservice: response.data })
        return response
      })
    },
    getTypeDelivery (context) {
      return api.get('api/v1/directory/type_delivery/').then(response => {
        context.commit('GET_STATE_DATA_DELIVERY', { typeDelivery: response.data })
        return response
      })
    }
  },
  getters: {
    getDeliveryObject: state => data => state[data.value].find(item => item.id === data.id)
  }
}
