export default [
  {
    path: '/verification/',
    name: 'verification',
    component: () => import('@/views/Documents/DocumentsVerification/DocumentsVerification.vue'),
    children: [
      {
        path: 'qualification/',
        name: 'qualification-verification',
        component: () => import('@/views/Documents/DocumentsVerification/DocumentsVerificationTable.vue')
      },
      {
        path: 'education/',
        name: 'education-verification',
        component: () => import('@/views/Documents/DocumentsVerification/DocumentsVerificationTable.vue')
      },
      {
        path: 'medical/',
        name: 'medical-verification',
        component: () => import('@/views/Documents/DocumentsVerification/DocumentsVerificationTable.vue')
      },
      {
        path: 'sailor-passport/',
        name: 'sailor-passport-verification',
        component: () => import('@/views/Documents/DocumentsVerification/DocumentsVerificationTable.vue')
      },
      {
        path: 'international-passport/',
        name: 'international-passport-verification',
        component: () => import('@/views/Documents/DocumentsVerification/DocumentsVerificationTable.vue')
      },
      {
        path: 'service-record/',
        name: 'service-record-verification',
        component: () => import('@/views/Documents/DocumentsVerification/DocumentsVerificationTable.vue')
      },
      {
        path: 'line-service-record/',
        name: 'line-service-record-verification',
        component: () => import('@/views/Documents/DocumentsVerification/DocumentsVerificationTable.vue')
      },
      {
        path: 'navigator-certificate/',
        name: 'navigator-certificate-verification',
        component: () => import('@/views/Documents/DocumentsVerification/DocumentsVerificationTable.vue')
      },
      {
        path: 'civilPassport/',
        name: 'civil-passport-verification',
        component: () => import('@/views/Documents/DocumentsVerification/DocumentsVerificationTable.vue')
      }
    ]
  },
  {
    path: '/sailor-photo-verification',
    name: 'sailor-photo-verification',
    component: () => import('@/views/Documents/PhotoVerification/PhotoVerification.vue')
  }
]
