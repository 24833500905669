import { API } from '@ananasbear/superapi'
import processingError from '@/mixins/apiErrors'
const apiOptions = {
  publicPages: ['/login', '/crewing-registration', '/privacy-policy', '/license-agreement', '/terms', '/forgot-password', '/reset-password-by-token', '/create-password-by-token'],
  tokenKey: 'TOKEN',
  loginPath: '/login',
  processingError
}
const api = new API(apiOptions)

export default api
