import { getStatus, getDateFormat, viewDetailedBlock, showDetailed, hideDetailed, getPaymentStatus, getDirectoryObject, getDecisionStatus } from '@/mixins/main'
import { mapState, mapGetters, mapActions } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
import SearchInTable from '@/components/atoms/SearchInTable.vue'
import Popover from '@/components/atoms/Popover.vue'

import UserHistoryInfo from '@/views/History/UserHistory/UserHistoryInfo/UserHistoryInfo.vue'
import SailorRecordBookLineShortInfo from '@/views/Sailor/SailorRecordBook/SailorRecordBookLine/Info.vue'
import ReportFinanceInfo from '@/views/Report/ReportFinance/ReportFinanceInfo'
import BackOfficeCoursesList from '@/views/BackOffice/BackOfficeCourses/BackOfficeCoursesList/BackOfficeCoursesList.vue'
import BackOfficeVnzTable from '@/views/BackOffice/BackOfficeVnz/Table'
import BackOfficeVnzEdit from '@/views/BackOffice/BackOfficeVnz/Edit'
export default {
  name: 'Table',
  components: {
    Popover,
    SearchInTable,
    SailorRecordBookLineShortInfo,
    UserHistoryInfo,
    ReportFinanceInfo,
    BackOfficeCoursesList,
    BackOfficeVnzTable,
    BackOfficeVnzEdit
  },
  props: {
    viewNewDoc: Boolean,
    labelKeyAdd: String,
    loader: Boolean,
    fields: Array,
    items: Array,
    sortBy: String,
    sortAcs: Boolean,
    sortDesc: Boolean,
    deleteRow: Function,
    getDocuments: Function,
    saveExcel: Function,
    type: String,
    link: String,
    componentInfo: String,
    componentEdit: String,
    componentStatus: String,
    componentFiles: String,
    total: Object
  },
  data () {
    return {
      getStatus,
      getDateFormat,
      viewDetailedBlock,
      showDetailed,
      hideDetailed,
      getPaymentStatus,
      checkAccess,
      getDirectoryObject,
      getDecisionStatus,
      currentItems: [],
      selectedItems: [],
      checkedItems: [],
      filter: null,
      sortName: null,
      viewAdd: this.viewNewDoc,
      sortAcsBack: this.sortAcs,
      sortDescBack: this.sortDesc,
      documentMerging: false,
      allowContinueMerge: false,
      tableLoading: false,
      btnAdd: ['statementSRB', 'sailorPassport', 'education', 'student', 'qualification',
        'qualificationStatement', 'certification', 'certificationStatement', 'backOfficeCoefficient', 'backOfficeCourse',
        'sailorMedical', 'medicalStatement', 'experience', 'recordBookStatement', 'serviceRecordBook', 'educationStatement',
        'positionStatement', 'sailorFullNameChanges', 'sailorPassportStatement', 'sailorSQCStatement', 'sailorSQCProtocols',
        'backOfficeETIList', 'backOfficeCoursePrice', 'backOfficeDocumentPrices', 'backOfficeCrewingCompaniesList',
        'backOfficeCrewingManagers', 'navigatorCertificates', 'sailorInternationalPassport', 'civilPassport', 'backOfficeVnzGroup'],
      btnToSailor: ['reportCrewingManagerBackOffice', 'documentSign', 'userHistory', 'reportDistributionSailor', 'reportBOPacket',
        'etiPayments', 'reportFinance', 'crewingManagerStatements', 'photoVerification', 'reportCrewingConnectSailor'],
      btnToSailorDocument: ['statementSRB', 'qualificationPackageStatement', 'report', 'reportFinance', 'reportExcel',
        'reportDistributionGroup', 'reportBO', 'reportCrewingManagerBackOffice', 'reportBOPacket', 'reportDistributionSailor',
        'backOfficeCourse', 'backOfficeDocumentPrices', 'backOfficeFutureDocumentPrices', 'backOfficePastDocumentPrices',
        'statementETI', 'etiPayments', 'userHistory', 'menuStatementSQC',
        'statementAdvanceTraining', 'statementSP', 'backOfficeCrewingGroupsList', 'backOfficeCrewingCompaniesList',
        'backOfficeCrewingManagers', 'documentsForSinging', 'photoVerification', 'qualification-verification',
        'education-verification', 'medical-verification', 'sailor-passport-verification',
        'international-passport-verification', 'service-record-verification', 'line-service-record-verification',
        'navigator-certificate-verification', 'reportCrewingConnectSailor', 'civilPassport-verification'],
      btnToDocumentWithSailorId: ['statementSRB', 'qualificationPackageStatement', 'report', 'statementETI', 'menuStatementSQC',
        'statementAdvanceTraining', 'statementSP', 'documentsForSinging', 'qualification-verification',
        'education-verification', 'medical-verification', 'sailor-passport-verification',
        'international-passport-verification', 'service-record-verification', 'line-service-record-verification',
        'navigator-certificate-verification', 'civilPassport-verification'],
      btnToReport: ['reportBO', 'reportCrewingManagerBackOffice', 'reportDistributionGroup', 'reportBOSailor'],
      btnCrewing: ['backOfficeCrewingGroupsList', 'backOfficeCrewingCompaniesList', 'backOfficeCrewingManagers'],
      btnInfo: ['backOfficeCourse', 'backOfficeDocumentPrices', 'userHistory', 'reportFinance', 'backOfficeVnzGroup'],
      btnEdit: ['backOfficeFutureDocumentPrices', 'backOfficePastDocumentPrices', 'backOfficeVnzTable'],
      btnDelete: ['backOfficeFutureDocumentPrices', 'backOfficePastDocumentPrices'],
      btnMerge: ['education', 'qualification']
    }
  },
  computed: {
    ...mapGetters(['documentByContentType', 'typeDocQualificationByID', 'rankByID', 'positionByID', 'statusById',
      'institutionTypeByID', 'decisionByID', 'educationTypeDocumentByID', 'qualificationLevelByID',
      'educationProfessionByID', 'positionMedicalByID', 'affiliateByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      labelValue: state => state.main.lang === 'en' ? 'value_eng' : 'value',
      isTrained: state => state.main.isTrained
    })
  },
  mounted () {
  },
  watch: {
    filter (val) {
      if (this.getDocuments && !this.filter.length) {
        this.getDocuments(val)
      }
    }
  },
  methods: {
    ...mapActions(['getQualificationDocuments', 'getEducationDocs']),
    sorting (value, sortName) {
      this.sortName = sortName
      this.sortDescBack = this.sortAcsBack
      this.sortAcsBack = !this.sortDescBack
      let sort = this.sortDescBack ? '-' + sortName : sortName
      if (this.type === 'crewingManagerStatements' || this.type === 'etiPayments') {
        this.getDocuments(null, sort)
      } else {
        this.getDocuments(sort)
      }
    },

    tableRowClass (item) {
      switch (this.type) {
        case 'crewingManagerStatements':
          if (!item.date_end_proxy && checkAccess('crewingManagerStatements', 'highlightDocument')) {
            return 'empty-contract-date-end'
          }
          break
        case 'backOfficeETIList':
          if (item.is_disable) {
            return 'disable-table-row'
          } else if (item.is_red) {
            return 'red-table-row'
          }
          break
        case 'backOfficeCrewingCompaniesList':
          if (checkAccess('backOffice') && item.is_red) return 'red-table-row'
      }
      if (item.form2_sum < 0) return 'red-table-row'
    },

    startMergingDocuments () {
      this.documentMerging = !this.documentMerging

      this.tableLoading = true
      const params = new URLSearchParams({ page_size: this.documentMerging ? '1000' : '5' })
      this[this.type === 'qualification' ? 'getQualificationDocuments' : 'getEducationDocs'](params)
        .finally(() => { this.tableLoading = false })

      if (this.documentMerging) this.fields.unshift({ key: 'selectColumn', label: '' })
      else this.fields.splice(0, 1)

      this.selectedItems = []
      this.checkedItems = []
    },

    selectDocument (row) {
      const documentIndex = this.selectedItems.findIndex(item => item.index === row.index)

      if (documentIndex === -1) this.selectedItems.push(row)
      else this.selectedItems.splice(documentIndex, 1)

      if (this.type === 'education') {
        this.allowContinueMerge = this.selectedItems && this.selectedItems.length === 2 &&
          this.selectedItems[0].item.type_document === this.selectedItems[1].item.type_document
      } else {
        const countDiplomaProof = this.selectedItems.filter(value => value.item.type_document === 16)
        this.allowContinueMerge = this.selectedItems && this.selectedItems.length === 2 &&
          (countDiplomaProof.length === 2 || !countDiplomaProof.length)
      }
    },

    getSailorID (row) {
      if (row.item.sailor && row.item.sailor.id) return row.item.sailor.id
      else if (row.item.sailor && ['number', 'string'].includes(typeof row.item.sailor)) return row.item.sailor
      else if (row.item.sailor_id) return row.item.sailor_id
      else if (row.item.sailor_key) return row.item.sailor_key
      else return false
    }
  }
}
