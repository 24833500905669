<template lang="pug">
v-card
  v-card-text
    TableV2(
      :headers="headers"
      :items="itemsQualifications"
      :loading="isLoading"
      :pageCount="itemsQualifications?.length"
      componentEdit="BackOfficeVnzEdit"
      isHiddenTableSearch
      isHiddenTableCount
      isHiddenSearchParams)
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'vnz-backoffice',
  components: {
    Add: () => import('./Add.vue')
  },
  props: {
    row: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      headers: [
        { value: 'id', text: this.$t('id'), sortable: false },
        { value: 'level_qualification', text: this.$t('qualification'), sortable: false },
        { value: 'price', text: this.$t('price'), sortable: false },
        { value: 'date_start', text: this.$t('dateEffective'), sortable: false },
        { value: 'date_end', text: this.$t('dateEnd'), sortable: false },
        { value: 'event', text: this.$t('actions'), sortable: false, class: 'mw-0' }
      ]
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.backoffice.isLoading
    }),
    itemsQualifications () {
      return this.row ? this.row.qualifications : []
    }
  }
}
</script>
