import { apiV1, apiV2, apiV3 } from './constants'

export const globalConfig = {
  twoFA: `${apiV2}/accounts/manage_global_2fa/`
}

export const auth = {
  totp: {
    create: `${apiV2}/accounts/totp/create/`,
    verify: `${apiV2}/accounts/totp/verify/`,
    login: `${apiV2}/accounts/totp/login/`,
    destroy: id => `${apiV2}/accounts/totp/list_destroy/${id}/`,
    destroyByAdmin: (id) => `${apiV2}/accounts/totp/list_destroy_for_user/${id}/`,
    listDeviceForUser: `${apiV2}/accounts/totp/list_destroy/`,
    listDevice: `${apiV2}/accounts/totp/list_destroy_for_user/`,
    listDeviceByAdmin: userId => `${apiV2}/accounts/totp/user/${userId}/list_destroy/`
  },
  fido2: {
    create: (userId) => `${apiV2}/accounts/fido2/registration_for_user_begin/${userId}/`,
    complete: (userId) => `${apiV2}/accounts/fido2/registration_for_user_complete/${userId}/`,
    list: (id) => `${apiV2}/accounts/u2f/list_destroy_for_user/${id ? `${id}/` : ''}`
  },
  u2f: {
    login: `${apiV2}/accounts/u2f/login/`,
    create: `${apiV2}/accounts/u2f/create/`,
    listUbikey: `${apiV2}/accounts/u2f/list_destroy_for_user/`
  },
  general: {
    login: `${apiV2}/accounts/login/`,
    recoveryPassword: `${apiV2}/accounts/send_password_recovery_email/`,
    changePasswordUserWithToken: `${apiV2}/accounts/change_password_by_token/`,
    checkPasswordRecoveryToken: `${apiV2}/accounts/check_password_recovery_token/`,
    updatePassword: `${apiV2}/accounts/change_password/`
  }
}

export const backoffice = {
  priceForAdvTrainingInstitution: (id) => `${apiV1}/back_off/price_for_adv_training/${id ? `${id}/` : ''}`,
  priceForAdvTraining: (id) => `${apiV1}/reports/back_office/educational_institution/${id ? `${id}/` : ''}`,
  online: {
    priceForPosition: `${apiV1}/back_off/price_for_position/online/`,
    priceForPositionById: (id) => `${apiV1}/back_off/price_for_position/online/${id}/`,
    priceForPositionActualValue: `${apiV1}/back_off/price_for_position/online/actual_values/`,
    priceForPositionFutureValue: (id) => `${apiV1}/back_off/price_for_position/online/${id}/future_values/`,
    priceForPositionPastValue: (id) => `${apiV1}/back_off/price_for_position/online/${id}/past_values/`,
    coursePrice: `${apiV1}/back_off/course_price/online/`,
    coursePriceById: (id) => `${apiV1}/back_off/course_price/online/${id}/`,
    coursePriceActualValue: `${apiV1}/back_off/course_price/online/actual_values/`
  },
  medical: `${apiV1}/back_off/price_for_medical/`,
  medicalById: (id) => `${apiV1}/back_off/price_for_medical/${id}/`,
  medicalInstitution: (id) => `${apiV1}/reports/back_office/medical_institution/${id ? id + '/' : ''}`,
  medicalInstitutionById: (id) => `${apiV1}/back_off/packet/medical_institution/${id}/`,
  autoSigningDistribution: `${apiV1}/signature/signature_distribution/`,
  signFailedDocument: (id) => `${apiV1}/reports/unsigned_document/list/${id ? id + '/' : ''}`,
  translationAgency: (id) => `${apiV1}/translators/company/${id ? id + '/' : ''}`,
  NostrificationAgency: (id) => `${apiV1}/translators/company/${id ? id + '/' : ''}`,
  powersOfAttorney: {
    document: (id) => `${apiV1}/crewing/proxy_crew_manager_sailor/${id ? id + '/' : ''}`,
    comment: (id) => `${apiV1}/crewing/proxy_crew_manager_sailor/${id}/comment/`
  },
  translationAgencyPrice: (id) => `${apiV1}/back_off/price_for_translation_company/${id ? id + '/' : ''}`,
  nostrificationMesPrice: (id) => `${apiV1}/back_off/price_for_mes/${id ? id + '/' : ''}`,
  nostrificationStatementPrice: (id) => `${apiV1}/back_off/price_for_statement_nostrification/${id ? id + '/' : ''}`,
  eti: {
    direction: (id) => `${apiV1}/back_off/certificates/eti_directions/${id ? `${id}/` : ''}`
  },
  office: {
    etiProfitPart: (documentId) => `${apiV1}/back_off/eti_profit_part/${documentId ? `${documentId}/` : ''}`,
    monthRatio: (documentId) => `${apiV1}/back_off/certificates/month_ratio/${documentId ? `${documentId}/` : ''}`,
    etiRegistry: (documentId) => `${apiV1}/back_off/certificates/eti_registry/${documentId ? `${documentId}/` : ''}`,
    offline: {
      courseList: () => `${apiV1}/back_off/course_price/`,
      list: () => `${apiV1}/back_off/price_for_position/actual_values/`,
      priceForPositionFutureValue: (id) => `${apiV1}/back_off/price_for_position/${id}/future_values/`,
      priceForPositionPastValue: (id) => `${apiV1}/back_off/price_for_position/${id}/past_values/`,
      priceForPosition: (id) => `${apiV1}/back_off/price_for_position/${id ? `${id}/` : ''}`
    }
  }
}

export const cadets = {
  // studentId: `${apiV1}/cadets/student_id/`,
  studentCardId: (id) => `${apiV1}/cadets/student_id/${id}/`
}

export const reports = {
  certificateMembersSqc: `${apiV1}/reports/commission_certification_report/`,
  publishedServiceBooks: `${apiV1}/reports/list/service_record/`,
  publishedServiceBooksExcel: `${apiV1}/reports/xslx/service_record/`,
  saveReport: (token) => `${apiV1}/reports/report/${token}/`,
  cadet: `${apiV1}/reports/list/student_id/`,
  advTraining: `${apiV1}/reports/back_office/distribution/adv_training/institution/`,
  serviceRecordStatementExcel: `${apiV1}/reports/list_statement_service_record/xlsx/`,
  distribution: {
    qualification: `${apiV1}/reports/back_office/distribution/adv_training/qualification/`,
    nostrification: {
      // typeDistribution can be: "mes", "statement", "translation"
      crewing: (typeDistribution) =>
        `${apiV1}/reports/back_office/distribution/nostrification/${typeDistribution}/crewing/`,
      crewingManager: (typeDistribution) =>
        `${apiV1}/reports/back_office/distribution/nostrification/${typeDistribution}/crewing_manager/`,
      group: (typeDistribution) =>
        `${apiV1}/reports/back_office/distribution/nostrification/${typeDistribution}/group/`,
      sailor: (typeDistribution) =>
        `${apiV1}/reports/back_office/distribution/nostrification/${typeDistribution}/sailor/`,
      sailorExcel: (typeDistribution) =>
        `${apiV1}/reports/back_office/distribution/nostrification/${typeDistribution}/sailor/xlsx/`
    }
  },
  qualification: {
    statement_qual: `${apiV1}/reports/list/statement_qual/`,
    diplom: `${apiV1}/reports/list/qual_doc/`,
    excel: `${apiV1}/reports/xlsx/statement_qualification/`,
    statement_qualification: `${apiV1}/reports/list/statement_qualification/`
  },
  statementISO: {
    connect: `${apiV1}/reports/crewing/connect/statement_connect/`,
    disconnect: `${apiV1}/reports/crewing/connect/statement_disconnect/`
  },
  medicalDocuments: {
    medical: `${apiV1}/reports/medical_certificate/list/`,
    excel: `${apiV1}/reports/medical_certificate/xlsx/`
  },
  medicalStatements: {
    medical: `${apiV1}/reports/statement_medical_certificate/list/`,
    excel: `${apiV1}/reports/statement_medical_certificate/xlsx/`
  },
  replaceStatementQualification: {
    qualification: `${apiV1}/reports/list/statement_replace_qualification/`,
    excel: `${apiV1}/reports/xlsx/statement_replace_qualification/`
  },
  crewingPlusConnectSailor: {
    connectPeriod: `${apiV1}/reports/crewing/connect/period/`,
    excel: `${apiV1}/reports/crewing/connect/period/xlsx/`
  },
  packetISC: {
    statementsPosition: `${apiV1}/reports/statement/packet_item/`,
    excel: `${apiV1}/reports/statement/packet_item/xlsx/`
  },
  documentSubmitted: {
    list: `${apiV1}/verification/document_on_verification/`,
    excel: `${apiV1}/verification/document_on_verification/xlsx/`
  },
  powersOfAttorney: {
    excel: `${apiV1}/reports/back_office/proxy_report/xlsx/`,
    documents: `${apiV1}/reports/back_office/proxy_report/`
  },
  nostrification: {
    listStatement: `${apiV1}/reports/statement_nostrification/list/`,
    excel: `${apiV1}/reports/statement_nostrification/xlsx/`
  },
  documentDigitizationISC: {
    documents: `${apiV1}/reports/isc_existing_document/`,
    excel: `${apiV1}/reports/isc_existing_document/xlsx/`
  },
  debtor: {
    group: {
      excel: `${apiV1}/reports/back_office/receipts/group/xlsx/`
    },
    crewing: {
      excel: `${apiV1}/reports/back_office/receipts/crewing/xlsx/`
    }
  },
  excel: {
    list: `${apiV1}/reports/list_files/`,
    file: (token) => `${apiV1}/reports/report/${token}/`
  }
}

export const derictory = {
  direction: `${apiV1}/directory/direction/`,
  translators: `${apiV1}/translators/company/`,
  freePlaces: (branchOffice, dateMeeting) => `${apiV1}/directory/branch_office/${branchOffice}/free_places/${dateMeeting}/`,
  dictionary: {
    LevelQualification: `${apiV1}/directory/level_qualification/advanced_trainings/`,
    EtiCourse: `${apiV1}/directory/course_for_ntz`,
    Rank: `${apiV1}/directory/rank/`,
    Position: `${apiV1}/directory/position/`,
    MedPosition: `${apiV1}/directory/position_for_medical/`,
    MedInstitution: `${apiV1}/directory/medical_institution/`,
    BranchOffice: `${apiV1}/directory/branch_office/`,
    CrewingCompany: `${apiV1}/crewing/crewing/`
  }
}

export const statisticsVerification = {
  userVerification: `${apiV1}/reports/stats/verification/by_user/user/`,
  existingDocVerification: `${apiV1}/reports/stats/verification/by_user/existing_document/`,
  existingDocVerificationExcel: `${apiV1}/reports/stats/verification/by_user/existing_document/xslx/`,
  sailorPhotoVerification: `${apiV1}/reports/stats/verification/by_user/sailor_photo/`,
  serviceRecordVerification: `${apiV1}/reports/stats/verification/by_user/service_record/`,
  lineInServiceRecordVerification: `${apiV1}/reports/stats/verification/by_user/line_in_service_record/`,
  educationVerification: `${apiV1}/reports/stats/verification/by_user/education/`,
  medicalCertificateVerification: `${apiV1}/reports/stats/verification/by_user/medical/`,
  qualificationVerification: `${apiV1}/reports/stats/verification/by_user/qualification_document/`,
  navigatorVerification: `${apiV1}/reports/stats/verification/by_user/navigator/`,
  foreignPassportVerification: `${apiV1}/reports/stats/verification/by_user/foreign_passport/`,
  sailorPassportVerification: `${apiV1}/reports/stats/verification/by_user/sailor_passport/`,
  listExcel: `${apiV1}/reports/stats/list_files/`,
  sailorCitizenPassportVerification: `${apiV1}/reports/stats/verification/by_user/citizen_passport/`,
  userISCVerification: `${apiV1}/reports/stats/verification/by_user/isc_users/`,
  userISCVerificationExcel: `${apiV1}/reports/stats/verification/by_user/isc_users/xlsx/`
}

export const crewing = {
  connectStatement: `${apiV1}/crewing/isc_statement_crewing_sailor/`,
  detachStatement: `${apiV1}/crewing/isc_statement_disconnect_crewing_sailor/`,
  connectStatementById: (id) => `${apiV1}/crewing/isc_statement_crewing_sailor/${id}/`,
  detachStatementById: (id) => `${apiV1}/crewing/isc_statement_disconnect_crewing_sailor/${id}/`,
  commentStatementById: (id) => `${apiV1}/crewing/isc_statement_crewing_sailor/${id}/comment/`,
  commentDetachStatementById: (id) => `${apiV1}/crewing/isc_statement_disconnect_crewing_sailor/${id}/comment/`,
  statementsPackageISC: (id) => `${apiV1}/reports/statement/packet_item/${id ? id + '/' : ''}`,
  statementsPackageISCComment: (id) => `${apiV1}/reports/statement/packet_item/${id}/comment/`,
  manager: {
    list: (managerId) => `${apiV1}/crewing/manager/${managerId ? `${managerId}/` : ''}`,
    remove: (managerId) => `${apiV1}/crewing/manager/${managerId}/remove_manager_from_company/`
  }
}

export const history = {
  transferCrewing: `${apiV1}/crewing/crewing_transfer/`,
  transferEti: `${apiV1}/directory/eti_transfer/`
}

export const sailor = {
  newSailor: () => `${apiV2}/sailor/`,
  accessSailorToIscFunctional: (sailorId) =>
    `${apiV2}/personal_cabinet/isc/${sailorId}/change_access_to_mobile_functional/`,
  uploadPhotoSailor: (id) => `${apiV2}/sailor/${id}/photo/`,
  ETICertificationInstitutions: `${apiV1}/back_off/certificates/institution/`,
  ETICertificationInstitutionsById: (id) => `${apiV1}/back_off/certificates/institution/${id}/`,

  sailorPhotoById: (sailorId, photoId) => `${apiV2}/sailor/${sailorId}/photo/${photoId}/`,
  sailorPhoto: (sailorId) => `${apiV2}/sailor/${sailorId}/photo/`,
  sailor: (sailorId) => `${apiV2}/sailor/${sailorId}/`,
  registration: `${apiV1}/sms_auth/registration/`,
  sailorCabinet: (sailorID) => `${apiV2}/sailor/${sailorID}/token/`,
  changedMainPhone: (sailorId) => `${apiV2}/sailor/${sailorId}/change_main_phone/`,
  crew: {
    statementByid: (id) => `${apiV1}/crewing/statement_crew_manager/${id}/`
  },
  positionStatementUploadReceipts: (documentID, fileID) =>
    `${apiV1}/back_off/packet/${documentID}/document_item/${fileID}/upload_file/`,
  addContact: (sailorId) => `${apiV2}/sailor/${sailorId}/add_contact/`,
  deleteContact: (sailorId, contactId) => `${apiV2}/sailor/${sailorId}/delete_contact/${contactId}/`,
  statementISC: `${apiV1}/verification/isc_sailor/`,
  statementISCById: (id) => `${apiV1}/verification/isc_sailor/${id}/`,
  statementISCByIdComment: (id) => `${apiV1}/verification/isc_sailor/${id}/comment/`,
  checkForeignPassport: (sailorId) => `${apiV1}/verification/isc_sailor/check_foreign_passport/${sailorId}/`,
  linkExam: (statementId) => `${apiV1}/training/create_exam_in_umsel/${statementId}/`,
  changedOldName: (id) => `${apiV2}/sailor/${id}/old_name/`,
  changedOldNameById: (sailorId, id) => `${apiV2}/sailor/${sailorId}/old_name/${id}/`,
  getExamTokenAndId: (sailorId, id) => `${apiV2}/sailor/${sailorId}/statement/certificate/${id}/exam_data/`,
  newAccount: (id) => `${apiV1}/sms_auth/list_verification/${id ? `${id}/` : ''}`,
  rejectNewAccount: (id) => `${apiV1}/sms_auth/list_verification/${id}/comment/`,
  shortInfo: (id) => `${apiV2}/sailor/${id}/short/`,
  passports: {
    citizen: {
      list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/citizen_passports/${documentId ? `${documentId}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/citizen_passports/${documentId}/comment/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/citizen_passports/${documentId}/update_file/${fileId ? `${fileId}/` : ''}`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/citizen_passports/${documentId}/remove_file/${fileId ? `${fileId}/` : ''}`,
      uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/citizen_passports/${documentId}/upload_file/`

    },
    foreign: {
      list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/foreign_passports/${documentId ? `${documentId}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/foreign_passports/${documentId}/comment/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/foreign_passports/${documentId}/update_file/${fileId ? `${fileId}/` : ''}`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/foreign_passports/${documentId}/remove_file/${fileId ? `${fileId}/` : ''}`,
      uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/foreign_passports/${documentId}/upload_file/`
    },
    sailorPassport: {
      list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/sailor_passport/${documentId ? `${documentId}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/sailor_passport/${documentId}/comment/`,
      choice: (sailorId) => `${apiV3}/sailor/${sailorId}/sailor_passport/choice/`,
      allowedToContinue: (sailorId) => `${apiV3}/sailor/${sailorId}/sailor_passport/allowed_to_continue/`,
      issueDocument: (sailorId) => `${apiV3}/sailor/${sailorId}/sailor_passport/issue_document/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/sailor_passport/${documentId}/update_file/${fileId ? `${fileId}/` : ''}`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/sailor_passport/${documentId}/remove_file/${fileId ? `${fileId}/` : ''}`,
      uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/sailor_passport/${documentId}/upload_file/`,
      statement: {
        list: (sailorId) => `${apiV2}/sailor/${sailorId}/statement/sailor_passport/`
      }
    },
    changeOldName: {
      list: (sailorId, documentId) => `${apiV2}/sailor/${sailorId}/old_name/${documentId ? `${documentId}/` : ''}`,
      uploadFile: (sailorId, documentId) => `${apiV2}/sailor/${sailorId}/old_name/${documentId}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV2}/sailor/${sailorId}/old_name/${documentId}/update_file/${fileId ? `${fileId}/` : ''}`,
      removeFile: (sailorId, documentId, fileId) => `${apiV2}/sailor/${sailorId}/old_name/${documentId}/remove_file/${fileId ? `${fileId}/` : ''}`
    },
    sailorPassportStatement: {
      list: (sailorId, documentId) => `${apiV2}/sailor/${sailorId}/statement/sailor_passport/${documentId ? `${documentId}/` : ''}`,
      success: (sailorId) => `${apiV2}/sailor/${sailorId}/statement/sailor_passport/success/`,
      comment: (sailorId, documentID) => `${apiV2}/sailor/${sailorId}/statement/sailor_passport/${documentID}/comment/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV2}/sailor/${sailorId}/statement/sailor_passport/${documentId}/remove_file/${fileId ? `${fileId}/` : ''}`,
      uploadFile: (sailorId, documentId) => `${apiV2}/sailor/${sailorId}/statement/sailor_passport/${documentId}/upload_file/`,
      updateFile: (sailorId, documentID, fileId) => `${apiV2}/sailor/${sailorId}/statement/sailor_passport/${documentID}/update_file/${fileId}/`
    }
  },
  education: {
    statementATC: {
      list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/advanced_training/${documentId ? `${documentId}/` : ''}`,
      comment: (sailorId, documentID) => `${apiV3}/sailor/${sailorId}/statements/advanced_training/${documentID}/comment/`,
      createAdvancedTraining: (sailorId, documentID) => `${apiV3}/sailor/${sailorId}/statements/advanced_training/${documentID}/create_advanced_training/`,
      uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/advanced_training/${documentId}/upload_file/`,
      updateFile: (sailorId, documentID, fileId) => `${apiV3}/sailor/${sailorId}/statements/advanced_training/${documentID}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statements/advanced_training/${documentId}/remove_file/${fileId ? `${fileId}/` : ''}`
    },
    documents: {
      list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/education/${documentId ? `${documentId}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/education/${documentId}/comment/`,
      uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/education/${documentId}/upload_file/`,
      updateFile: (sailorId, documentID, fileId) => `${apiV3}/sailor/${sailorId}/education/${documentID}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/education/${documentId}/remove_file/${fileId ? `${fileId}/` : ''}`
    },
    student: {
      list: (sailorId) => `${apiV1}/cadets/students_id_per_sailor/${sailorId}/`,
      studentById: (sailorId) => `${apiV1}/cadets/student_id/${sailorId ? `${sailorId}/` : ''}`,
      uploadFile: (sailorId) => `${apiV1}/cadets/students_id/${sailorId}/upload_file/`,
      updateFile: (sailorId, fileId) => `${apiV1}/cadets/students_id/${sailorId}/update_file/${fileId}/`,
      removeFile: (sailorId, fileId) => `${apiV1}/cadets/students_id/${sailorId}/remove_file/${fileId ? `${fileId}/` : ''}`
    }
  },
  qualification: {
    replacementStatements: {
      list: (sailorId, documentID) => `${apiV3}/sailor/${sailorId}/statement/qualification_replacement/${documentID ? `${documentID}/` : ''}`,
      uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statement/qualification_replacement/${documentId}/upload_file/`,
      updateFile: (sailorId, documentID, fileId) => `${apiV3}/sailor/${sailorId}/statement/qualification_replacement/${documentID}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statement/qualification_replacement/${documentId}/remove_file/${fileId ? `${fileId}/` : ''}`,
      replaceQual: (sailorId, qualificationDocId) => `${apiV3}/sailor/${sailorId}/qualification/${qualificationDocId}/create_statement_replace/`,
      replaceProof: (sailorId, proofDiplomaId) => `${apiV3}/sailor/${sailorId}/proof_diploma/${proofDiplomaId}/create_statement_replace/`,
      replaceDocuments: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statement/qualification/${id}/related_docs/`

    },
    statement: {
      attachDocuments: (sailorId, id) =>
        `${apiV3}/sailor/${sailorId}/statements/qualification/${id}/append_document_to_statement/`,
      replaceDocuments: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statements/qualification/${id}/related_docs/`,
      list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/qualification/${documentId ? `${documentId}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/qualification/${documentId}/comment/`,
      success: (sailorId) => `${apiV3}/sailor/${sailorId}/statements/qualification/success/`,
      uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/qualification/${documentId}/upload_file/`,
      updateFile: (sailorId, documentID, fileId) => `${apiV3}/sailor/${sailorId}/statements/qualification/${documentID}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statements/qualification/${documentId}/remove_file/${fileId ? `${fileId}/` : ''}`,
      authForSaveDocument: `${apiV1}/docs/auth_statement_for_qualification/`,
      generateDocument: (token) => `api/v1/docs/generate_statement_for_qualification/${token}`
    },
    certificateOfNavigator: {
      list: (sailorId, documentId) => `${apiV2}/sailor/${sailorId}/certificate_of_navigator/${documentId ? `${documentId}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV2}/sailor/${sailorId}/certificate_of_navigator/${documentId}/commnet/`,
      uploadFile: (sailorId, documentId) => `${apiV2}/sailor/${sailorId}/certificate_of_navigator/${documentId}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV2}/sailor/${sailorId}/certificate_of_navigator/${documentId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV2}/sailor/${sailorId}/certificate_of_navigator/${documentId}/remove_file/${fileId}/`
    },
    documents: {
      diplomaForProof: (sailorId) => `${apiV3}/sailor/${sailorId}/qualification/diploma_for_proof/`,
      qualification: {
        list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/qualification/${documentId ? documentId + '/' : ''}`,
        comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/qualification/${documentId}/comment/`,
        uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/qualification/${documentId}/upload_file/`,
        updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/qualification/${documentId}/update_file/${fileId}/`,
        removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/qualification/${documentId}/remove_file/${fileId}/`,
        documentArchive: (documentId) => `${apiV1}/docs/qualification_document_archive/${documentId}/`,
        documentWatermark: (documentId) => `${apiV1}/docs/qualification_document_watermark/${documentId}/`,
        documentSignature: `${apiV1}/docs/qualifsignature`,
        documentWithoutSignature: (documentId) => `${apiV1}/docs/qualification_document_without_signature/${documentId}`
      },
      proof: {
        list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/proof_diploma/${documentId ? `${documentId}/` : ''}`,
        comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/proof_diploma/${documentId}/comment/`,
        uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/proof_diploma/${documentId}/upload_file/`,
        updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/proof_diploma/${documentId}/update_file/${fileId}/`,
        removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/proof_diploma/${documentId}/remove_file/${fileId}/`,
        documentArchive: (documentId) => `${apiV1}/docs/proof_document_archive/${documentId}/`,
        documentWatermark: (documentId) => `${apiV1}/docs/proof_document_watermark/${documentId}/`,
        documentSignature: `${apiV1}/docs/proof_signature`,
        documentWithoutSignature: (documentId) => `${apiV1}/docs/proof_document_without_signature/${documentId}`
      },
      generate_qualification_specialist_certificate: `${apiV1}/docs/generate_qualification_specialist_certificate/`,
      generate_qualification_proof_of_diploma: `${apiV1}/docs/generate_qualification_proof_of_diploma/`,
      generate_qualification_diploma: `${apiV1}/docs/generate_qualification_diploma/`
    }
  },
  eti: {
    statement: {
      list: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statements/certificate_eti/${id ? `${id}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/certificate_eti/${documentId}/comment/`,
      sendToEti: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/certificate_eti/${documentId}/send_to_eti/`,
      uploadFile: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statements/certificate_eti/${id}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statements/certificate_eti/${documentId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statements/certificate_eti/${documentId}/remove_file/${fileId}/`
    },
    certificate: {
      list: (sailorId, id) => `${apiV3}/sailor/${sailorId}/certificate_eti/${id ? `${id}/` : ''}`,
      uploadFile: (sailorId, id) => `${apiV3}/sailor/${sailorId}/certificate_eti/${id}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/certificate_eti/${documentId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/certificate_eti/${documentId}/remove_file/${fileId}/`
    }
  },
  medical: {
    certificate: {
      list: (sailorId, id) => `${apiV3}/sailor/${sailorId}/medical/${id ? `${id}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/medical/${documentId}/comment/`,
      sendToEti: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/medical/${documentId}/send_to_eti/`,
      uploadFile: (sailorId, id) => `${apiV3}/sailor/${sailorId}/medical/${id}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/medical/${documentId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/medical/${documentId}/remove_file/${fileId}/`
    },
    statement: {
      list: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statement/medical_certificate/${id ? `${id}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statement/medical_certificate/${documentId}/comment/`,
      createCertificate: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statement/medical_certificate/${documentId}/create_certificate/`,
      sendToEti: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statement/medical_certificate/${documentId}/send_to_eti/`,
      uploadFile: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statement/medical_certificate/${id}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statement/medical_certificate/${documentId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statement/medical_certificate/${documentId}/remove_file/${fileId}/`
    }
  },
  sqc: {
    protocol: {
      list: (sailorId, id) => `${apiV3}/sailor/${sailorId}/protocol_sqc/${id ? `${id}/` : ''}`,
      success: (sailorId, id) => `${apiV3}/sailor/${sailorId}/protocol_sqc/success/`,
      downloadResult: (sailorId, id) => `${apiV3}/sailor/${sailorId}/protocol_sqc/${id}/download_result/`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/protocol_sqc/${documentId}/comment/`,
      uploadFile: (sailorId, id) => `${apiV3}/sailor/${sailorId}/protocol_sqc/${id}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/protocol_sqc/${documentId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/protocol_sqc/${documentId}/remove_file/${fileId}/`,
      authProtocolDkk: `${apiV1}/docs/auth_protocol_dkk/`,
      generateConclusion: (token) => `${apiV1}/docs/generate_protocol_with_conclusion/${token}/`,
      watermark: (protocolId) => `${apiV1}/docs/protocol_watermark/${protocolId}/`
    },
    statement: {
      list: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statement/protocol_sqc/${id ? `${id}/` : ''}`,
      success: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statement/protocol_sqc/success/`,
      downloadResult: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statement/protocol_sqc/${id}/download_result/`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statement/protocol_sqc/${documentId}/comment/`,
      uploadFile: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statement/protocol_sqc/${id}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statement/protocol_sqc/${documentId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statement/protocol_sqc/${documentId}/remove_file/${fileId}/`,
      attachDocuments: (sailorId, id) =>
        `${apiV3}/sailor/${sailorId}/statement/protocol_sqc/${id}/append_document_to_statement/`,
      replaceDocuments: (sailorId, id) => `${apiV3}/sailor/${sailorId}/statement/protocol_sqc/${id}/related_docs/`,
      statementDocument: `${apiV1}/docs/auth_statement_for_dkk/`,
      generate: (token) => `${apiV1}/docs/generate_statement_for_dkk/${token}/`
    }
  },
  digitization: {
    documents: {
      list: (sailorId) => `${apiV2}/sailor/${sailorId}/documents_in_digitization_verification/`
    },
    statements: {
      list: (sailorId) => `${apiV2}/sailor/${sailorId}/statement/verification/`

    }
  },
  experience: {
    serviceRecordBook: {
      list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/service_record/${documentId ? `${documentId}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/service_record/${documentId}/comment/`,
      uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/service_record/${documentId}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/service_record/${documentId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/service_record/${documentId}/remove_file/${fileId}/`,
      statementDocument: `${apiV1}/docs/auth_statement_for_record_book/`,
      generate: (token) => `${apiV1}/docs/generate_statemenet_for_record_book/${token}/`,
      genereteSRB: (documentId) => `${apiV1}/docs/generate_service_record/${documentId}/`

    },
    statementServiceRecordBook: {
      list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/service_record/${documentId ? `${documentId}/` : ''}`,
      createServiceRecord: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/service_record/${documentId}/create_service_record/`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/service_record/${documentId}/comment/`,
      uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/statements/service_record/${documentId}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statements/service_record/${documentId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/statements/service_record/${documentId}/remove_file/${fileId}/`
    },
    serviceRecordBookLine: {
      list: (sailorId, documentId, lineId) => `${apiV3}/sailor/${sailorId}/service_record/${documentId}/line/${lineId ? `${lineId}/` : ''}`,
      comment: (sailorId, documentId, lineId) => `${apiV3}/sailor/${sailorId}/service_record/${documentId}/line/${lineId}/comment/`,
      uploadFile: (sailorId, documentId, lineId) => `${apiV3}/sailor/${sailorId}/service_record/${documentId}/line/${lineId}/upload_file/`,
      updateFile: (sailorId, documentId, lineId, fileId) => `${apiV3}/sailor/${sailorId}/service_record/${documentId}/line/${lineId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, lineId, fileId) => `${apiV3}/sailor/${sailorId}/service_record/${documentId}/line/${lineId}/remove_file/${fileId}/`
    },
    certificate: {
      list: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/experience/${documentId ? `${documentId}/` : ''}`,
      comment: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/experience/${documentId}/comment/`,
      uploadFile: (sailorId, documentId) => `${apiV3}/sailor/${sailorId}/experience/${documentId}/upload_file/`,
      updateFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/experience/${documentId}/update_file/${fileId}/`,
      removeFile: (sailorId, documentId, fileId) => `${apiV3}/sailor/${sailorId}/experience/${documentId}/remove_file/${fileId}/`
    }
  },
  packet: {
    list: (sailorPk, paketPk) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${paketPk ? `${paketPk}/` : ''}`,
    preview: (sailorPk) => `${apiV2}/back_office/sailor/${sailorPk}/packet_preview/`,
    addDocument: (sailorPk, packetPk, documentId) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${packetPk}/document_item/${documentId}/add_document/`,
    availableDocuments: (sailorPk, packetPk, documentId) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${packetPk}/document_item/${documentId}/available_documents/`,
    cancelItem: (sailorPk, packetPk, documentId) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${packetPk}/document_item/${documentId}/cancel_item/`,
    removeDocumentFile: (sailorPk, packetPk, documentId, fileId) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${packetPk}/document_item/${documentId}/remove_file/${fileId}/`,
    updateDocumentFile: (sailorPk, packetPk, documentId, fileId) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${packetPk}/document_item/${documentId}/update_file/${fileId}/`,
    uploadDocumentFile: (sailorPk, packetPk, documentId) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${packetPk}/document_item/${documentId}/upload_file/`,
    createDiplomForProof: (sailorPk, paketPk) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${paketPk}/create_diploma_for_proof/`,
    createStatementSQC: (sailorPk, paketPk) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${paketPk}/create_statement_sqc/`,
    removeFile: (sailorPk, packetPk, fileId) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${packetPk}/remove_file/${fileId}/`,
    updateFile: (sailorPk, packetPk, fileId) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${packetPk}/update_file/${fileId}/`,
    uploadFile: (sailorPk, packetPk) => `${apiV2}/back_office/sailor/${sailorPk}/packet/${packetPk}/upload_file/`,
    checkIsContinue: (sailorId) => `${apiV2}/sailor/${sailorId}/check_is_continue/`
  },
  search: `${apiV1}/sailor/search_sailor/`
}

export const u2f = {
  create: (userId) => `${apiV2}/accounts/${userId}/u2f/registry/create/`,
  registrer: `${apiV2}/accounts/u2f/create/`
}

export const user = {
  user: `${apiV1}/auth/user/`,
  addContact: (id) => `${apiV1}/auth/user/${id}/add_contact/`,
  deleteContact: (id, contactId) => `${apiV1}/auth/user/${id}/delete_contact/${contactId}/`,
  history: {
    list: `${apiV1}/auth/history/`
  }
}

export const packageStatment = {
  availableDocuments: (packetId, dependencyId) =>
    `${apiV1}/back_off/packet/${packetId}/document_item/${dependencyId}/available_documents/`,
  addDocument: (packetId, dependencyId) =>
    `${apiV1}/back_off/packet/${packetId}/document_item/${dependencyId}/add_document/`
}

export const statements = {
  statementRecordBookISC: `${apiV1}/reports/wish/statement/service_record/`,
  statementRecordBookByIdISC: (id) => `${apiV1}/reports/wish/statement/service_record/${id}/`,
  setCommentStatementSRBFromISC: (id) => `${apiV1}/reports/wish/statement/service_record/${id}/comment/`,
  nostrification: {
    statement: (id) => `${apiV1}/nostrification/statement/${id ? `${id}/` : ''}`,
    comment: (id) => `${apiV1}/nostrification/statement/${id}/comment/`,
    waitPaidTranslationStatement: (id) =>
      `${apiV1}/nostrification/statement/wait_payment_translation/${id ? `${id}/` : ''}`,
    waitPaidMESStatement: (id) => `${apiV1}/nostrification/statement/wait_payment_nostrification/${id ? `${id}/` : ''}`,
    deleteFileStatement: (statementId, fileId) =>
      `${apiV1}/nostrification/statement/${statementId}/remove_file/${fileId}/`
  },
  closeStatement: {
    internetional: (documentId) => `${apiV1}/reports/isc_existing_document/${documentId}/document_created/`
  },
  eti: `${apiV1}/reports/list/statement_eti/`,
  advanceTraining: `${apiV1}/reports/list/statement_advanced_training/`,
  sailorPassport: `${apiV1}/reports/list/statement/sailor_passport/`,
  crewingManagerStatement: (id) => `${apiV1}/crewing/statement_crew_manager_sailor/${id ? `${id}/` : ''}`,
  crewingManagerStatementUploadFiles: (id) => `${apiV1}/crewing/statement_crew_manager_sailor/${id}/upload_file/`,
  srb: `${apiV1}/reports/list_statement_service_record/`,
  statementQualificationFromPackage: `${apiV1}/reports/list_statement_qual_doc_in_packet/`,
  statementsPackageISC: {
    list: (id) => `${apiV1}/reports/statement/packet_item/${id ? id + '/' : ''}`,
    comment: (id) => `${apiV1}/reports/statement/packet_item/${id}/comment/`
  },
  sqc: `${apiV1}/reports/list/statement_dkk/`,
  crew: (id) => `${apiV1}/crewing/statement_crewing/${id ? `${id}/` : ''}`,
  paymentETI: `${apiV1}/reports/list/payment/statement_eti/`

}

export const documents = {
  nostrification: {
    documenetEducation: (id) => `${apiV1}/nostrification/education/${id ? `${id}/` : ''}`,
    сomment: (id) => `${apiV1}/nostrification/education/${id}/comment/`
  },
  digitalizationDocumentISC: {
    document: (id) => `${apiV1}/reports/isc_existing_document/${id ? `${id}/` : ''}`,
    comment: (id) => `${apiV1}/reports/isc_existing_document/${id}/comment/`,
    excel: `${apiV1}/reports/isc_existing_document/xlsx/`
  },
  medicalCertificate: (sailorId) => `${apiV3}/sailor/${sailorId}/medical/`,
  qualificationDocument: (sailorId, typeDocument) => `${apiV3}/sailor/${sailorId}/${typeDocument}/`,
  experienceDocument: (sailorId) => `${apiV3}/sailor/${sailorId}/experience_certificate/`,
  serviceRecordBook: (sailorId) => `${apiV3}/sailor/${sailorId}/service_record/`,
  serviceRecordBookLine: (sailorId, srbId) => `${apiV3}/sailor/${sailorId}/service_record/${srbId}/line/`,
  photoOnVerification: `${apiV1}/verification/sailor_photo`,
  verification: {
    sailorPassport: `${apiV1}/reports/verification/sailor_passport/`,
    internationalPassport: `${apiV1}/reports/verification/foreign_passport/`,
    education: `${apiV1}/reports/verification/education/`,
    qualification: `${apiV1}/reports/verification/qualification/`,
    navigatorCertificate: `${apiV1}/reports/verification/navigator/`,
    lineServiceRecord: `${apiV1}/reports/verification/line_in_service_record/`,
    serviceRecord: `${apiV1}/reports/verification/service_record/`,
    medical: `${apiV1}/reports/verification/medical_certificate/`,
    civilPassport: `${apiV1}/reports/verification/citizen_passport/`
  },
  listForSign: `${apiV1}/reports/list/qual_doc_on_sign/`,
  listRecordBooksForDigitization: (sailorId) => `${apiV3}/sailor/${sailorId}/service_record/for_digitization/`,
  qualDocOnSign: `${apiV1}/reports/list/qual_doc_on_sign/`
}

export const media = {
  nostrification: {
    monDocument: {
      archive: (id) => `${apiV1}/docs/mes_document/${id}/archive/`,
      wotermark: (id) => `${apiV1}/docs/mes_document/${id}/watermark/`
    },
    translationDocument: {
      archive: (id) => `${apiV1}/docs/translated_document/${id}/archive/`,
      wotermark: (id) => `${apiV1}/docs/translated_document/${id}/watermark/`
    }
  }
}
export const verification = {
  existingDocument: {
    list: (id) => `${apiV1}/verification/existing_document/${id ? `${id}/` : ''}`,
    uploadFile: (documentId) => `${apiV1}/verification/existing_document/${documentId}/upload_file/`,
    updateFile: (documentId, fileId) => `${apiV1}/verification/existing_document/${documentId}/update_file/${fileId}/`,
    removeFile: (documentId, fileId) => `${apiV1}/verification/existing_document/${documentId}/remove_file/${fileId}/`
  },
  iscExistingDocument: `${apiV1}/verification/isc_existing_document/`,
  checkDocumentInfo: (id) => `${apiV1}/verification/existing_document/${id}/check_document_info/`,
  iscCheckDocumentInfo: (id) => `${apiV1}/verification/isc_existing_document/${id}/check_document_info/`,
  createDocument: (id) => `${apiV1}/verification/existing_document/${id}/create_document/`,
  createDocumentISC: (id) => `${apiV1}/verification/isc_existing_document/${id}/create_document/`,
  clarificationOfData: {
    statement: (id) => `${apiV1}/verification/statement_clarification/${id ? `${id}/` : ''}`,
    comment: (id) => `${apiV1}/verification/statement_clarification/${id}/comment/`
  },
  existingDocumentCommnet: (id) => `${apiV1}/verification/existing_document/${id}/comment/`
}

export const packageStatement = {
  packet_preview: (sailorId) => `${apiV1}/back_off/${sailorId}/packet_preview/`,
  check_is_continue: (sailorId) => `${apiV2}/sailor/${sailorId}/check_is_continue/`,
  packet: `${apiV1}/back_off/packet/`
}
