import api from '@/plugins/api'
import { sailor } from '@/configs/endpoints'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS, STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
import { queryString, FromObjectToFormData, removeFileFromObject } from '@/mixins/main'
import notify from '@ananasbear/notify'

export const stateETISection = {
  // ------------ Certificate of ETI ----------------- //
  certificateEti: {},
  certificateEtiById: {},
  // ----------- ETI CERTIFICATE STATEMENTS --------- //
  certificationStatement: {},
  certificationStatementById: {}

}
export const actionsETISection = {
  // ------------ Certificate of ETI ----------------- //
  async getListCertificateEti ({ commit, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.eti.certificate.list(data.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_SAILOR', { certificateEti: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getCertificateEtiById ({ commit, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.eti.certificate.list(data.id || state.sailorId, data.documentID))
    response.data.behavior = { viewInfoBlock: true }
    if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_SAILOR', { certificateEtiById: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
  },
  async updateCertificateEtiById ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.patch(sailor.eti.certificate.list(data.id || state.sailorId, data.documentID), { body: data.body })
    response.data.behavior = { viewInfoBlock: true }
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.editCertificateEti')
      dispatch('getCertificateEtiById', data)
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async setCertificates ({ commit, dispatch, state }, data) {
    const response = await api.post(sailor.eti.certificate.list(data.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.eti.certificate.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
        dispatch('getListCertificateEti', { ...data, params: { page: 1, page_size: 10 } })
        commit('incrementBadgeCount', {
          child: 'certificateDocument',
          parent: 'certificateAll'
        })
      }
    }
    return response
  },
  async updateCertificates ({ state, commit }, data) {
    const response = await api.patch(sailor.eti.certificate.list(data.id || state.sailorId, data.documentID), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) commit('updateDataSailor', { type: 'certification', value: response.data })
    if (data?.media?.files?.length) {
      const responseMedia = await api.post(sailor.eti.certificate.uploadFile(data.id, data.documentID),
        { body: FromObjectToFormData(data.media) })
      if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
    }
    return response
  },
  async removeCertificate ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.eti.certificate.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.ETI.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { certificateEtiById: response.data })
        commit('decrementBadgeCount', {
          child: 'certificateDocument',
          parent: 'certificateAll'
        })
      } else dispatch('getListCertificateEti', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async removeFileCertificate ({ commit, state }, data) {
    const response = await api.delete(sailor.eti.certificate.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.certificateEtiById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { certificateEtiById: newData })
    return response
  },
  async updateFileCertificateETI ({ commit }, data) {
    const response = await api.patch(sailor.eti.certificate.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  // -------------- ETI CERTIFICATE STATEMENTS -------------- //
  async getCertificateStatements ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.eti.statement.list(data?.id || state.sailorId) + queryString(data?.params))
    if (SUCCESS_CODE.includes(response?.code)) {
      commit('SET_STATE_SAILOR', { certificationStatement: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getCertificateStatementById ({ state, commit, dispatch }, data) {
    commit('SET_STATE_SAILOR', { certificationStatementById: {}, isLoading: true })
    const response = await api.get(sailor.eti.statement.list(data.id || state.sailoId, data.documentID))
    if ([STATUSES_STATEMENT_CONSTANTS.SAILOR.ETI.IN_PROCESSING,
      STATUSES_STATEMENT_CONSTANTS.SAILOR.ETI.REJECT].includes(response.data.status_document)) {
      const responseComment = await dispatch('getCertificateStatementsComment', data)
      if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
    }
    response.data.behavior = data?.viewEditStatusBlock ? { viewEditStatusBlock: true } : { viewInfoBlock: true }
    if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_SAILOR', { certificationStatementById: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
  },
  async getCertificateStatementsComment ({ state }, data) {
    const response = await api.get(sailor.eti.statement.comment(data?.id || state.sailorId, data.documentID))
    return response
  },
  async setCertificateStatement ({ commit, state, dispatch }, data) {
    const response = await api.post(sailor.eti.statement.list(data?.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.eti.statement.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      commit('incrementBadgeCount', { child: 'certificateStatement', parent: 'certificatesAll' })
      commit('SET_DATA_STATE_DIRECTORY', { filteredETI: {} })
      await dispatch('getCertificateStatements', { ...data, params: { page: 1, page_size: 10 } })
      notify.success('etiStatementAdded')
    }
    return response
  },
  async updateCertificateStatement ({ state, commit, dispatch }, data) {
    const response = await api.patch(sailor.eti.statement.list(data?.id || state?.sailorId, data.documentID),
      { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.eti.statement.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.ETI.REJECTED) {
        const responseComment = await dispatch('getCertificateStatementsComment', data)
        if (SUCCESS_CODE.includes(responseComment.code)) {
          response.data.comments = responseComment.data
        }
      }
      dispatch('getCertificateStatementById', data)
      commit('SET_DATA_STATE_DIRECTORY', { filteredETI: {} })
      notify.success('etiStatementEdited')
    }
    return response
  },
  async sendToETICertificateStatements ({ state }, data) {
    const response = await api.post(sailor.eti.statement.sendToEti(data?.id || state?.sailorId, data.documentID))
    return response
  },
  async removeCertificateStatement ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.eti.statement.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_STATEMENT_CONSTANTS.SAILOR.ETI.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { certificationStatementById: response.data })
        commit('decrementBadgeCount', {
          child: 'certificateStatement',
          parent: 'certificateAll'
        })
      } else dispatch('getCertificateStatements', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async setSailorFileCertificateETI ({ dispatch }, data) {
    const response = await api.post(sailor.eti.statement.uploadFile(data.id, data.documentID),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.AddCopyDocument')
      dispatch('getCertificateStatementById', { ...data, viewEditStatusBlock: true })
    }
    return response
  },
  async removeFileCertificateStatement ({ commit, state }, data) {
    const response = await api.delete(sailor.eti.statement.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.certificationStatementById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { certificationStatementById: newData })
    return response
  },
  async updateFileCertificateStatementETI ({ commit }, data) {
    const response = await api.patch(sailor.eti.statement.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  }
}
