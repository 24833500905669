import api from '@/plugins/api'
import { sailor } from '@/configs/endpoints'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS, STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
import { queryString, FromObjectToFormData, removeFileFromObject } from '@/mixins/main'
import notify from '@ananasbear/notify'

export const stateMedicalSection = {
  // -------------- MEDICAL DOCUMENTS -------------- //
  medicalCertificate: {},
  medicalCertificateById: {},
  // -------------- MEDICAL STATEMENTS -------------- //
  medicalStatement: {},
  medicalStatementById: {}
}
export const actionsMedicalSection = {
  // -------------- MEDICAL DOCUMENTS -------------- //
  async getMedicalCertificates ({ commit, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.medical.certificate.list(data.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_SAILOR', { medicalCertificate: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getMedicalCertificateByID ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.medical.certificate.list(data.id || state.sailorId, data.documentID))
    response.data.behavior = { viewInfoBlock: true }
    if (SUCCESS_CODE.includes(response.code)) {
      if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.MEDICAL.REJECTED) {
        const responseComment = await dispatch('getMedicalCertificateComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      }
      commit('SET_STATE_SAILOR', { medicalCertificateById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
  },
  async updateMedicalCertificateById ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.patch(sailor.medical.certificate.list(data.id || state.sailorId, data.documentID), { body: data.body })
    response.data.behavior = { viewInfoBlock: true }
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.medical.certificate.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.MEDICAL.REJECTED) {
        const responseComment = data?.comments
          ? await dispatch('setCommentMedicalCertificateById', data)
          : await dispatch('getMedicalCertificateComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      } else notify.success('editedMedDoc')
      commit('SET_STATE_SAILOR', { medicalCertificateById: response.data })
    }
    dispatch('getMedicalCertificateByID', data)
  },
  async setMedicalCertificate ({ commit, dispatch, state }, data) {
    const response = await api.post(sailor.medical.certificate.list(data.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.medical.certificate.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      dispatch('getMedicalCertificates', { ...data, params: { page: 1, page_size: 10 } })
      commit('incrementBadgeCount', {
        child: 'medicalDocument',
        parent: 'medicalAll'
      })
    }
    return response
  },
  async removeMedicalCertificate ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.medical.certificate.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.MEDICAL.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { medicalCertificateById: response.data })
        commit('decrementBadgeCount', {
          child: 'medicalDocument',
          parent: 'medicalAll'
        })
      } else dispatch('getMedicalCertificates', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async getMedicalCertificateComments ({ state }, data) {
    const response = await api.get(sailor.medical.certificate.comment(data?.id || state.sailoId, data.documentID))
    return response
  },
  async setCommentMedicalCertificateById ({ state }, data) {
    const response = await api.post(sailor.medical.certificate.comment(data.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) notify.success('documentReject')
    response.data = [response.data]
    return response
  },
  async removeFileMedicalCertificate ({ commit, state }, data) {
    const response = await api.delete(sailor.medical.certificate.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.medicalCertificateById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { medicalCertificateById: newData })
    return response
  },
  async updateFileMedicalCertificate ({ commit }, data) {
    const response = await api.patch(sailor.medical.certificate.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  // -------------- MEDICAL STATEMENTS -------------- //
  async getMedicalStatements ({ commit, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.medical.statement.list(data.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_SAILOR', { medicalStatement: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getMedicalStatementByID ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.medical.statement.list(data.id || state.sailorId, data.documentID))
    response.data.behavior = { viewInfoBlock: true }
    if (SUCCESS_CODE.includes(response.code)) {
      if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.MEDICAL.REJECTED) {
        const responseComment = await dispatch('getMedicalStatementCommentsById', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      }
      commit('SET_STATE_SAILOR', { medicalStatementById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
  },
  async updateMedicalStatementById ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.patch(sailor.medical.statement.list(data.id || state.sailorId, data.documentID), { body: data.body })
    response.data.behavior = { viewInfoBlock: true }
    if (SUCCESS_CODE.includes(response.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.medical.statement.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.MEDICAL.REJECTED) {
        const responseComment = data?.comments
          ? await dispatch('setCommentMedicalStatementById', data)
          : await dispatch('getMedicalStatementCommentsById', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      } else notify.success('editedMedDoc')
      commit('SET_STATE_SAILOR', { medicalStatementById: response.data })
    }
    dispatch('getMedicalStatementByID', data)
  },
  async setMedicalStatement ({ commit, dispatch, state }, data) {
    const response = await api.post(sailor.medical.statement.list(data.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(sailor.medical.statement.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      dispatch('getMedicalStatements', { ...data, params: { page: 1, page_size: 10 } })
      commit('incrementBadgeCount', {
        child: 'medicalStatement',
        parent: 'medicalAll'
      })
    }
    return response
  },
  async removeMedicalStatement ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.medical.statement.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_STATEMENT_CONSTANTS.SAILOR.MEDICAL.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { medicalStatementById: response.data })
        commit('decrementBadgeCount', {
          child: 'medicalStatement',
          parent: 'medicalAll'
        })
      } else dispatch('getMedicalStatements', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async getMedicalStatementCommentsById ({ state }, data) {
    const response = await api.get(sailor.medical.statement.comment(data?.id || state.sailoId, data.documentID))
    return response
  },
  async setCommentMedicalStatementById ({ state }, data) {
    const response = await api.post(sailor.medical.statement.comment(data.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) notify.success('documentReject')
    response.data = [response.data]
    return response
  },
  async transferMedicalStatement ({ state, commit }, data) {
    const response = await api.post(sailor.medical.statement.createCertificate(data.id || state.sailorId, data.documentID), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      commit('SET_STATE_SAILOR', { medicalStatementById: response.data })
      commit('incrementBadgeCount', { component: 'medical', tab: 'medicalSum' })
    }
    return response
  },
  async removeFileMedicalStatement ({ commit, state }, data) {
    const response = await api.delete(sailor.medical.statement.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.medicalStatementById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { medicalStatementById: newData })
    return response
  },
  async updateFileMedicalStatement ({ commit }, data) {
    const response = await api.patch(sailor.medical.statement.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  }
}
