import { helpers, maxLength, maxValue, minLength, minValue, required, requiredIf, sameAs, email, numeric, not
} from 'vuelidate/lib/validators'
import { checkAccess } from '@/mixins/permissions'
import { TYPE_USER, TYPE_DOCUMENTS_CONSTANTS, COUNTRY_CONSTANTS, STATUSES_STATEMENT_CONSTANTS,
  TYPE_SAILOR_PASSPORT_PROCESSING_STATUS, STATUSES_DOCUMENT_CONSTANTS, CONTENT_TYPE } from '@/configs/constants'
import { disableDates } from '@/mixins/main'
import { checkrankIsSQC } from './main'
const alphaUA = helpers.regex('alpha', /^[0-9а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'.\- ]*$/)
const alphaUAWithSpecialCharacters = helpers.regex('alpha', /^[0-9а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'.\- ]*$/)
const alphaEN = helpers.regex('alpha', /^[a-zA-Z'.\- ]*$/)
const alphaENText = helpers.regex('alpha', /^[a-zA-Z0-9'.\- ]*$/)
const validLogin = helpers.regex('alpha', /^[a-zA-Z0-9._-]*$/)
const alphaTR = helpers.regex('alpha', /^[a-zA-Zaıоueiöübcçdfgğhklmnprsştvyz.\- ]*$/)
// const onlyNumber = helpers.regex('numeric', /^[+0-9]*$/)
const numberAndSymbol = helpers.regex('alpha', /^[0-9/]*$/)

const alphaCountry = {
  EN: alphaEN,
  UA: alphaUA,
  TR: alphaTR
}

let date = new Date()
date.setDate(date.getDate() - 7)
const sevenDaysAgoDate = date
export const validDateMeeting = (inaccessibleDates, showDateMeeting) => (value) => {
  if (showDateMeeting) return !disableDates(value, inaccessibleDates)
  else return true
}

export const authByCredits = {
  credits: {
    username: { required },
    password: { required }
  }
}

export const authBy2FA = {
  token: { required }
}

export const detachStatementComment = () => {
  return {
    comment: { required, alphaENText }
  }
}

export const citizenPassport = (context) => {
  return {
    dateIssueObject: {
      required,
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date())
    },
    dateEndObject: {
      required: requiredIf(() => context.body.passport_type === 2),
      minValue: minValue(context.dateIssueObject),
      maxValue: maxValue(new Date('2200-01-01'))
    },
    body: {
      passport_type: { required },
      last_name_ukr: { required, alphaUA },
      first_name_ukr: { required, alphaUA },
      last_name_eng: { required: requiredIf(() => context.body.passport_type === 2), alphaEN },
      first_name_eng: { required: requiredIf(() => context.body.passport_type === 2), alphaEN },
      sex: { required: requiredIf(() => context.$route.params?.documentID && ![STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.DRAFT, STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.VERIFICATION].includes(context.passport.status_document)) },
      country: { required },
      serial: { required, maxLength: maxLength(30) },
      registration_address: {
        city: {
          country: { required: requiredIf(() => context.body.registration_address.city.country) },
          region: { required: requiredIf(() => context.body.registration_address.city.country) },
          city: { required: requiredIf(() => context.body.registration_address.city.country) }
        },
        street: { required: requiredIf(() => context.body.registration_address.city.country) },
        house: { required: requiredIf(() => context.body.registration_address.city.country) },
        index: { maxLength: maxLength(7), minLength: minLength(4) }
      },
      residence_address: {
        city: {
          country: { required: requiredIf(() => context.body.residence_address.city.country) },
          region: { required: requiredIf(() => context.body.residence_address.city.country) },
          city: { required: requiredIf(() => context.body.residence_address.city.country) }
        },
        street: { required: requiredIf(() => context.body.residence_address.city.country) },
        house: { required: requiredIf(() => context.body.residence_address.city.country) },
        index: { maxLength: maxLength(7), minLength: minLength(4) }
      }
    },
    files: {
      $each: {
        size: { maxValue: maxValue(41943040) }
      }
    }
  }
}

export const userPassword = () => {
  return {
    new_password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(100)
    },
    password_confirmation: {
      required,
      notSameAsPassword: sameAs('new_password')
    }
  }
}

export const vnz = (context) => {
  return {
    body: {
      educational_institution: { required },
      level_qualification: { required },
      price: { required },
      date_start: { required }
    },
    dateStartObject: {
      required
      // minValue: minValue(new Date() + 1),
      // maxValue: null
    },
    dateEndObject: {
      minValue: minValue(context.body.date_start ? new Date(context.body.date_start) : new Date()),
      maxValue: maxValue(new Date('2200-01-01'))
    }
  }
}
export const medical = (context) => {
  return {
    body: {
      medical_institution: { required },
      medical_position: { required },
      price: { required },
      date_start: { required }
    },
    dateStartObject: {
      required
      // minValue: minValue(new Date() + 1),
      // maxValue: null
    },
    dateEndObject: {
      minValue: minValue(context.body.date_start ? new Date(context.body.date_start) : new Date()),
      maxValue: maxValue(new Date('2200-01-01'))
    }
  }
}

export const sailorCrewingInformation = () => {
  return {
    crewing: {
      manager: { required }
    },
    dateEndObject: {
      required,
      minValue: minValue(new Date()),
      maxValue: maxValue(new Date('2200-01-01'))
    }
  }
}

export const sailorCertificateETI = (context) => {
  return {
    certificate: {
      other_number: { required },
      ntz: { required: requiredIf(() => context.actionType === 'add') },
      course_training: { required },
      status_document: { required: requiredIf(() => context.actionType === 'add') }
    },
    mediaFilesArray: { required: requiredIf(() => context.actionType === 'add') },
    certificateType: { required: requiredIf(() => context.actionType === 'add') },
    dateStartObject: {
      required,
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date())
    },
    dateEndObject: {
      required,
      minValue: minValue(context.certificate.date_start ? context.dateStartObject : new Date('1900-01-01')),
      maxValue: maxValue(new Date('2200-12-31'))
    }
  }
}

export const statementSRB = context => ({
  deliveryType: { required },
  body: {
    phone_number: { required },
    morrichservice_office: { required: requiredIf(() => context.body.type_post === 'morrichservice') },
    city_id: { required: requiredIf(() => context.deliveryType.id !== 3) },
    warehouse_id: { required: requiredIf(() => context.deliveryType.id === 1) },
    street_id: { required: requiredIf(() => context.deliveryType.id === 2) },
    house: { required: requiredIf(() => context.deliveryType.id === 2) },
    apartment: { required: requiredIf(() => context.deliveryType.id === 2) }
  },
  files: {
    $each: {
      size: { maxValue: maxValue(41943040) },
      required: { required }
    }
  }
})

export const sailorStatementETIStatusChanging = (context) => ({
  body: {
    status_document: { required: requiredIf(() => !context.statement.is_payed) }
  },
  mediaFilesArray: { required: requiredIf(() => !context.statement.is_payed && context.body.is_payed) }
})
export const sailorStatementETIAdding = (context) => {
  return {
    body: {
      institution: { required },
      city: { required },
      course: { required: requiredIf(() => !context.$route.params?.documentID) }
    },
    dateMeetingObject: {
      required: requiredIf(() => !!context.$route.params?.documentID),
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date('2200-01-01'))
    }
  }
}
export const sailorStatementETIEditing = () => {
  return {
    institution: { required },
    city: { required },
    dateMeetingObject: {
      required,
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date('2200-01-01'))
    }
  }
}

export const backOfficeCrewingCompany = (context) => {
  return {
    company: {
      name_ukr: { required, alphaUA },
      name_eng: { alphaEN },
      director: { required: requiredIf(() => context.isAddingForm) },
      type_company: { required },
      group: { required: requiredIf(() => context.isEditingForm) }
    }
  }
}

export const sailorQualificationAdding = (context) => {
  return {
    body: {
      number: { required: requiredIf(() => context.$route.params.documentID && !context.requiredField.proof.includes(context.body.type_document)) },
      other_number: {
        required: requiredIf(() => !context.body.new_document && !context.requiredField.proof.includes(context.body.type_document) && !context.body.number),
        maxLength: maxLength(20),
        minLength: minLength(0)
      },
      country: {
        required: requiredIf(() => !context.body.new_document && !context.requiredField.proof.includes(context.body.type_document))
      },
      type_document: { required: requiredIf(() => context.activeTab) },
      port: {
        required: requiredIf(() => !context.body.new_document && context.body.country === COUNTRY_CONSTANTS.UKRAINE)
      },
      diploma: {
        required: requiredIf(() => !context.body.new_document && context.requiredField.proof.includes(context.body.type_document))
      },
      rank: {
        required: requiredIf(() => !context.body.new_document && !context.requiredField.proof.includes(context.body.type_document))
      },
      list_positions: {
        required: requiredIf(() => !context.body.new_document && !context.requiredField.proof.includes(context.body.type_document))
      },
      statement: {
        required: requiredIf(() => !context.activeTab && !context.$route.params.documentID)
      },
      strict_blank: {
        required: requiredIf(() => !context.body.new_document),
        minLength: minLength(6),
        maxLength: maxLength(9)
      }
    },
    dateStartObject: {
      required: requiredIf(() => !context.body.new_document),
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date())
    },
    dateEndObject: {
      required: requiredIf(() => !context.body.new_document && context.viewDateTerm),
      minValue: minValue(new Date(context.body.date_start)),
      maxValue: maxValue(new Date('2200-12-31'))
    }
  }
}
export const sailorQualificationEditing = (context) => {
  return {
    sailorDocument: {
      type_document: { required },
      number_document: {
        required: requiredIf(() => context.sailorDocument.type_document !== 16 && !context.sailorDocument.other_number),
        maxLength: maxLength(20),
        minLength: minLength(0)
      },
      other_number: {
        required: requiredIf(() => context.sailorDocument.other_number),
        maxLength: maxLength(20),
        minLength: minLength(0)
      },
      port: {
        required: requiredIf(() => !context.sailorDocument.other_port)
      },
      other_port: {
        required: requiredIf(() => context.sailorDocument.other_port)
      },
      rank: { required: requiredIf(() => context.sailorDocument.type_document !== 16) },
      list_positions: { required: requiredIf(() => context.sailorDocument.type_document !== 16) },
      strict_blank: {
        required: requiredIf(() => checkAccess('qualification', 'editStrictBlank', context.sailorDocument)),
        minLength: minLength(6),
        maxLength: maxLength(9)
      }
    },
    dateStartObject: {
      required,
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date())
    },
    dateEndObject: {
      required: requiredIf(() => [16, 21, 57, 85, 86, 88, 89].includes(context.sailorDocument.type_document.id)),
      minValue: minValue(context.dateStartObject),
      maxValue: maxValue(new Date('2200-12-31'))
    }
  }
}

export const sailorStatementSQCAdding = (context) => {
  return {
    body: {
      rank: { required: requiredIf(() => !context.$route.params?.documentID) },
      list_positions: { required: requiredIf(() => !context.$route.params?.documentID) }
    },
    typeSQC: { required: requiredIf(() => !context.$route.params?.documentID) },
    dateMeetingObject: {
      // required: requiredIf(() => !!context.$route?.params?.documentID),
      validDateMeeting: () => {
        if (context.body.date_meeting) {
          if (context.body.date_meeting === context.statement.date_meeting) {
            return true
          } else {
            return validDateMeeting(
              context.statement?.disabled_dated || [],
              checkAccess('sailorSQCStatement', 'editDateMeeting', context.statement))
          }
        } else {
          return true
        }
      }
    }
  }
}

export const sailorStatementSQCEditingStatus = (context) => {
  return {
    mediaFilesArray: {
      required: requiredIf(() => checkAccess('sailorSQCStatement', 'requiredFile', context.sailorDocument) &&
        !checkAccess('admin'))
    }
  }
}
export const sailorStatementSQCTableChanges = context => ({
  attachDocumentBody: {
    object_id: { required: requiredIf(() => context.tabs === 0) },
    dependency: { required: requiredIf(() => context.tabs === 0) }
  },
  replaceDocumentBody: {
    old_document: { required: requiredIf(() => context.tabs === 1) },
    new_document: { required: requiredIf(() => context.tabs === 1) }
  }
})

export const sailorProtocolSQCForm = (context) => {
  return {
    body: {
      statement_dkk: { required: requiredIf(() => !context.$route.params?.documentID) },
      decision: { required: requiredIf(() => !context.$route.params?.documentID) }
    },
    headCommission: { required },
    secretaryCommission: { required },
    membersCommission: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(4)
    },
    dateMeetingObject: {
      required: requiredIf(() => !context.$route.params?.documentID),
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date())
    }
  }
}

export const sailorEducationDocuments = (context) => {
  return {
    body: {
      type_document: { required },
      serial: {
        required: requiredIf(() => (checkAccess('education', 'editRegistryNumber') && context.actionType === 'edit') ||
          context.actionType === 'add')
      },
      number_document: {
        required: requiredIf(() => (checkAccess('education', 'editRegistryNumber') && context.actionType === 'edit') ||
          context.actionType === 'add'),
        maxLength: maxLength(30),
        minLength: minLength(0)
      },
      registry_number: {
        required: requiredIf(() => (checkAccess('education', 'editRegistryNumber') && context.actionType === 'edit') ||
          context.actionType === 'add')
      },
      extent: {
        required: requiredIf(() => context.body.type_document === 1 &&
          ((checkAccess('education', 'editRegistryNumber') && context.actionType === 'edit') ||
          context.actionType === 'add'))
      },
      name_nz: {
        required: requiredIf(() => (!context.body.is_international &&
          checkAccess('education', 'editRegistryNumber') &&
          context.actionType === 'edit') || context.actionType === 'add')
      },
      other_name_nz_ukr: { required: requiredIf(() => context.body.is_international) },
      qualification: {
        required: requiredIf(() => (checkAccess('education', 'editRegistryNumber') && context.actionType === 'edit') ||
          context.actionType === 'add')
      },
      speciality: {
        required: requiredIf(() => [1, 2].includes(context.body.type_document) &&
          ((checkAccess('education', 'editRegistryNumber') && context.actionType === 'edit') ||
          context.actionType === 'add'))
      }
    },
    dateIssuedObject: {
      required: requiredIf(() => (checkAccess('education', 'editRegistryNumber') && context.actionType === 'edit') ||
        context.actionType === 'add'),
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date())
    },
    dateEndObject: {
      required: requiredIf(() => context.body.type_document === 4 &&
        ((checkAccess('education', 'editRegistryNumber') && context.actionType === 'edit') ||
        context.actionType === 'add')),
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(context.body.date_issue_document ? context.dateIssuedObject : new Date('2200-01-01'))
    },
    yearTermination: {
      required: requiredIf(() => [1, 2].includes(context.body.type_document) &&
        ((checkAccess('education', 'editRegistryNumber') && context.actionType === 'edit') ||
        context.actionType === 'add')
      ),
      minValue: minValue(1900),
      maxValue: maxValue(context.body.date_issue_document ? Number(context.body.date_issue_document?.split('-')[0]) : 2200)
    }
  }
}

export const sailorMedicalCertificate = context => ({
  body: {
    number: { required },
    other_medical_institution: {
      required: requiredIf(() => context.actionType === 'edit' && context.body.is_international)
    },
    medical_institution: {
      required: requiredIf(() => !context.body.is_international)
    },
    other_doctor: {
      required: requiredIf(() => context.actionType === 'edit' && context.body.is_international)
    },
    doctor: {
      required: requiredIf(() => !context.body.is_international)
    },
    limitation: {
      required: requiredIf(() => !context.body.is_international)
    },
    position: { required }
  },
  dateIssuedObject: {
    required,
    minValue: minValue(new Date('1900-01-01')),
    maxValue: maxValue(new Date())
  },
  dateTerminateObject: {
    required,
    minValue: minValue(context.dateIssuedObject || new Date('1900-01-01')),
    maxValue: maxValue(new Date('2200-12-31'))
  }
})

export const sailorMedicalStatementTransfer = (context) => {
  return {
    body: {
      number: { required },
      limitation: { required },
      doctor: { required: requiredIf(() => checkAccess('medicalStatement', 'enterDoctor')) }
    },
    dateStartObject: {
      required,
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date('2200-12-31'))
    },
    dateEndObject: {
      required,
      minValue: minValue(context.dateStartObject),
      maxValue: maxValue(new Date('2200-01-01'))
    }
  }
}

export const sailorMedicalStatement = (context) => {
  return {
    body: {
      medical_institution: { required: requiredIf(() => context.$route.params?.documentID) },
      position: { required }
    }
  }
}

export const sailorServiceRecordBookEditing = (context) => {
  return {
    sailorDocument: {
      number: { required: requiredIf(() => !context.sailorDocument.is_international && context.sailorDocument.number) },
      other_number: { required: requiredIf(() => context.sailorDocument.is_international || !context.sailorDocument.number) },
      auth_agent_ukr: { required: requiredIf(() => !context.sailorDocument.is_international), alphaUA },
      auth_agent_eng: { required, alphaEN },
      branch_office: { required: requiredIf(() => !context.sailorDocument.is_international) },
      other_branch_office: { required: requiredIf(() => context.sailorDocument.is_international) }
    },
    dateIssueObject: {
      required,
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date())
    }
  }
}
export const ServiceRecordBookForm = (context) => {
  return {
    body: {
      other_number: { required: requiredIf(() => !context.body.new_record) },
      branch_office: { required: requiredIf(() => !context.body.new_record) },
      auth_agent_ukr: { required: requiredIf(() => context.$route.params.documentID) },
      auth_agent_eng: { required: requiredIf(() => context.$route.params.documentID) }
    },
    auth_agent: { required: requiredIf(() => context.body.new_record) },
    last_name_ukr: { required: requiredIf(() => !context.body.new_record && !context.$route.params.documentID), alphaUA },
    first_name_ukr: { required: requiredIf(() => !context.body.new_record && !context.$route.params.documentID), alphaUA },
    middle_name_ukr: { alphaUA },
    last_name_eng: { required: requiredIf(() => !context.body.new_record && !context.$route.params.documentID), alphaEN },
    first_name_eng: { required: requiredIf(() => !context.body.new_record && !context.$route.params.documentID), alphaEN },
    dateIssueObject: {
      required,
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(context.body.new_record ? new Date() : new Date())
    }
  }
}

export const sailorServiceRecordBookLine = (context) => {
  return {
    body: {
      number_vessel: { required },
      name_vessel: { required },
      type_vessel: { required },
      mode_of_navigation: { required },
      port_of_registration: { required },
      gross_capacity: { required },
      propulsion_power: { required },
      type_geu: { required },
      trading_area: { required },
      ports_input: { required },
      full_name_master: { alphaUA },
      full_name_master_eng: { required, alphaEN },
      place_start: { required },
      place_end: { required },
      position: { required },
      number_page_book: { required },
      days_repair: { required: requiredIf(() => !context.body.repair_date_to && !context.body.repair_date_from &&
          context.body.is_repaired && !context.readonlyDateNum) },
      all_responsibility: {
        $each: {
          date_from: { required: requiredIf((value) => !context.readonlyInputs && value.responsibility) },
          date_to: { required: requiredIf((value) => !context.readonlyInputs && value.responsibility) },
          days_work: { required: requiredIf((value) => !context.readonlyDateNum && value.responsibility) }
        }
      }
    },
    dateStartObject: {
      required,
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(context.body.date_end ? new Date(context.body.date_end) : new Date())
    },
    dateEndObject: {
      required,
      minValue: minValue(context.body.date_start ? new Date(context.body.date_start) : new Date('1900-01-01')),
      maxValue: maxValue(new Date())
    },
    repairedDateFromObject: {
      required: requiredIf(() => !context.body.days_repair && context.body.is_repaired &&
        !context.readonlyInputs),
      minValue: minValue(context.body.date_start ? new Date(context.body.date_start) : new Date('1900-01-01')),
      maxValue: maxValue(context.body.repair_date_to ? new Date(context.body.repair_date_to) : new Date(context.body.date_end))
    },
    repairedDateToObject: {
      required: requiredIf(() => !context.body.days_repair && context.body.is_repaired && !context.readonlyInputs),
      minValue: minValue(context.body.repair_date_from ? new Date(context.body.repair_date_from) : new Date(context.body.date_start)),
      maxValue: maxValue(context.body.date_end ? new Date(context.body.date_end) : new Date())
    }
  }
}

export const SailorStudentForm = (context) => {
  return {
    body: {
      number: { required },
      name_nz: { required },
      faculty: { required },
      education_form: { required },
      status_document: { required }
    },
    dateStartObject: {
      required,
      minValue: minValue(new Date('1991-01-01')),
      maxValue: maxValue(new Date())
    },
    dateEndObject: {
      required,
      minValue: minValue(context.dateStartObject),
      maxValue: maxValue(new Date('2200-12-31'))
    }
  }
}

export const SearchTable = () => {
  return {
    search: { minLength: minLength(4) }
  }
}

export const userRegistration = (context) => {
  let firstName = {
    required,
    maxLength: maxLength(200)
  }
  let lastName = {
    required,
    maxLength: maxLength(200)
  }
  let middleName = { maxLength: maxLength(200) }
  if (context.body.country?.value_abbr) {
    firstName[`alpha${context.body.country.value_abbr}`] = alphaCountry[context.body.country.value_abbr]
    lastName[`alpha${context.body.country.value_abbr}`] = alphaCountry[context.body.country.value_abbr]
    middleName[`alpha${context.body.country.value_abbr}`] = alphaCountry[context.body.country.value_abbr]
  }
  return {
    first_name: firstName,
    last_name: lastName,
    userprofile: {
      middle_name: middleName,
      branch_office: { required: requiredIf(() => context.body.userprofile.main_group !== TYPE_USER.MEDICAL) },
      eti_institution: { required: requiredIf(() => context.body.userprofile.main_group === TYPE_USER.REPRESENTATIVE_OF_ETI) },
      education_institution: { required: requiredIf(() => context.body.userprofile.main_group === TYPE_USER.SECRETARY_ATC) },
      translation_company: { required: requiredIf(() => [TYPE_USER.DIRECTOR_OF_AGENCY_TRANSLATION, TYPE_USER.WORKER_OF_AGENCE_TRANSLATION].includes(context.body.userprofile.main_group)) },
      city: { required },
      medical_institution: { required: requiredIf(() => context.body.userprofile.main_group === TYPE_USER.MEDICAL) },
      main_group: { required }
    },
    first_name_eng: {
      required: requiredIf(() => [TYPE_USER.MANAGER_CREWING, TYPE_USER.HEAD_CREWING, TYPE_USER.CAPTAIN].includes(context.body.userprofile.main_group)),
      alphaEN,
      maxLength: maxLength(200)
    },
    last_name_eng: {
      required: requiredIf(() => [TYPE_USER.MANAGER_CREWING, TYPE_USER.HEAD_CREWING, TYPE_USER.CAPTAIN].includes(context.body.userprofile.main_group)),
      alphaEN,
      maxLength: maxLength(200)
    },
    username: { required: requiredIf(() => !context.REGISTRY_ROLE.includes(context.body.userprofile.main_group)), validLogin },
    password: { required, minLength: minLength(8), maxLength: maxLength(100) },
    country: { required },
    region: { required },
    contact_info: {
      phone_number: { required: requiredIf(() => [TYPE_USER.MANAGER_CREWING, TYPE_USER.HEAD_CREWING, TYPE_USER.SECRETARY_ATC].includes(context.body.userprofile.main_group)) },
      telegram: { required: requiredIf(() => context.isTelegramFlag && [TYPE_USER.MANAGER_CREWING, TYPE_USER.HEAD_CREWING, TYPE_USER.SECRETARY_ATC].includes(context.body.userprofile.main_group)) },
      viber: { required: requiredIf(() => context.isViberFlag && [TYPE_USER.MANAGER_CREWING, TYPE_USER.HEAD_CREWING, TYPE_USER.SECRETARY_ATC].includes(context.body.userprofile.main_group)) },
      email: { required, email }
    },
    head_group_profile: {
      group: { required: requiredIf(() => context.body.userprofile.main_group === TYPE_USER.HEAD_GROUP) }
    },
    secretary_atc_profile: {
      profile_type: { required: requiredIf(() => [TYPE_USER.SECRETARY_ATC].includes(context.body.userprofile.main_group)) }
    }
  }
}

export const ubikey = () => ({
  dataForm: {
    login: { required }
  }
})

export const EditMainInfoSailor = (contex) => {
  return {
    sailorInfo: {
      last_name_ukr: {
        required: requiredIf(function () {
          return contex.checkAccess('main-editFullNameInfo')
        }),
        maxLength: maxLength(200),
        alphaUA
      },
      first_name_ukr: {
        required: requiredIf(function () {
          return contex.checkAccess('main-editFullNameInfo')
        }),
        maxLength: maxLength(200),
        alphaUA
      },
      last_name_eng: { required, alphaEN, maxLength: maxLength(200) },
      first_name_eng: { required, alphaEN, maxLength: maxLength(200) },
      middle_name_ukr: { maxLength: maxLength(200), alphaUA },
      sex: { required },
      email: { email },
      phoneNumber: { maxLength: maxLength(13), minLength: minLength(13) }
    },
    birthDayObject: {
      required,
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(new Date())
    }
  }
}

export const changeMainPhoneNumber = (context) => {
  return {
    phone_number: { required, maxLength: maxLength(19) }
  }
}

export const CrewingManagerEdit = (context) => {
  return {
    body: {
      last_name: { required },
      first_name: { required },
      userprofile: {
        crewing_company: { required: requiredIf(() => { return !checkAccess('crewingHead') }) },
        branch_office: { required }
      }
    },
    phone_number: {
      required: requiredIf(() => { return context.sailorDocument.type_user !== 'secretary_service' }),
      maxLength: maxLength(13),
      minLength: minLength(13)
    },
    telegram: { maxLength: maxLength(13), minLength: minLength(13) },
    viber: { maxLength: maxLength(13), minLength: minLength(13) }
  }
}

export const newCrewingManagersEdit = () => {
  return {
    sailorDocument: {
      serial_passport: { required },
      tax_number: { required, maxLength: maxLength(10), minLength: minLength(10) }
    },
    phone_number: { required, maxLength: maxLength(13), minLength: minLength(13), numeric },
    telegram: { maxLength: maxLength(13), minLength: minLength(13), numeric },
    viber: { maxLength: maxLength(13), minLength: minLength(13), numeric }
  }
}
export const addDocToPackage = (context) => {
  return {
    body: { document_id: { required }, item_status: { required } },
    mediaFilesArray: { required: requiredIf(() => context.selectedDocument?.get_info_for_statement.is_international &&
      !context.selectedDocument.photo.some(el => el.file_type === 'international_certificate')) }
  }
}

export const ValidationEmail = () => (
  {
    contact: {
      value: { required, email }
    }
  }
)

export const DocumentsPrice = (context) => (
  {
    dataForm: {
      documentType: { required },
      formType: { required },
      coming: { required, minValue: minValue(0) },
      toSQC: { required, minValue: minValue(0) },
      toQD: { required, minValue: minValue(0) },
      toTD: { required, minValue: minValue(0) },
      toSC: { required, minValue: minValue(0) },
      toCrewing: { required, minValue: minValue(0) },
      toMedical: { required, minValue: minValue(0) },
      toCEC: { required, minValue: minValue(0) },
      toPortal: { required, minValue: minValue(0) },
      toMRC: { required, minValue: minValue(0) }
    },
    dateStartObject: {
      required,
      minValue: minValue(new Date(context.dateTomorrow)),
      maxValue: maxValue(new Date('2200-01-01'))
    }
  }
)

export const coursePrice = (context) => (
  {
    dataForm: {
      course: { required },
      price: { required, minValue: minValue(0) },
      formType: { required }
    },
    dateStartObject: {
      required,
      minValue: minValue(new Date(context.dateTomorrow)),
      maxValue: maxValue(new Date('2200-01-01'))
    }
  }
)

export const replacementQualDocStatement = () => (
  {
    body: {
      status_document: { required }
    }
  }
)

export const sailorChangeFullname = (context) => (
  {
    body: {
      last_name_ukr: {
        required: requiredIf(function () {
          return context.last_name_eng
        }),
        alphaUA
      },
      first_name_ukr: {
        required: requiredIf(function () {
          return context.first_name_eng
        }),
        alphaUA
      },
      middle_name_ukr: {
        alphaUA
      },
      last_name_eng: {
        required: requiredIf(function () {
          return context.last_name_ukr
        }),
        alphaEN
      },
      first_name_eng: {
        required: requiredIf(function () {
          return context.first_name_ukr
        }),
        alphaEN
      }
    },
    dateModifiedObject: { required, maxValue: maxValue(new Date()) }
  }
)

export const addNewSailor = (context) => {
  return {
    body: {
      first_name_ukr: { required, maxLength: maxLength(200), alphaUA },
      first_name_eng: { required, maxLength: maxLength(200), alphaEN },
      last_name_ukr: { required, maxLength: maxLength(200), alphaUA },
      last_name_eng: { required, maxLength: maxLength(200), alphaEN },
      middle_name_ukr: { maxLength: maxLength(200), alphaUA },
      sex: { required },
      tax_number: { required: requiredIf(() => !context.isDontHaveInn) }
    },
    dateBirthObject: { required },
    sailorPhoto: {
      $each: {
        size: { maxValue: maxValue(41943040) }
      }
    }
  }
}

export const RejectStatementSRBFromISC = () => (
  {
    body: {
      comment: { required, alphaENText }
    }
  }
)
export const RejectFormNewAccount = () => (
  {
    body: {
      additional_info: { reason: { required } }
    }
  }
)
export const RejectFormNewAccountISC = () => (
  {
    comments: {
      additional_info: { reason: { required } },
      comment: { alphaENText }
    }
  }
)

export const createNewAccount = () => (
  {
    body: {
      sailor_id: { required },
      status_document: { required }
    }
  }
)

export const translateAgency = () => ({
  body: {
    name: { required, alphaUAWithSpecialCharacters },
    languages: { required }
  }
})

export const translateAgencyPrice = (context) => ({
  body: {
    price: { required },
    unit: { required }
  },
  dateStartObject: {
    required,
    maxValue: maxValue(new Date('2200-01-01')),
    minValue: minValue(new Date(context.dateTomorrow))
  }
})

export const nostrificationAgency = () => ({
  body: {
    name: { required }
  }
})

export const RejectForm = () => ({
  body: {
    comment: { required, alphaENText }
  }
})

export const RejectFormUkr = () => ({
  body: {
    additional_info: { reason: { required } },
    comment: { required, alphaUAWithSpecialCharacters }
  }
})

export const RejectFormDigitizationSC = () => ({
  body: {
    comment: { required, alphaUAWithSpecialCharacters }
  }
})

export const RejectFormSailorPassport = () => ({
  comments: {
    additional_info: { reason: { required } },
    comment: { required, alphaUAWithSpecialCharacters }
  }
})
export const RejectFormSailorExperience = () => ({
  comments: {
    additional_info: { reason: { required } }
  }
})

export const verificationISC = {
  medical: (context) => (
    {
      body: {
        additional_info: {
          number: { required },
          position: { required: requiredIf(() => context.isCheckDocument) },
          limitation: { required: requiredIf(() => context.isCheckDocument) },
          other_doctor: { required: requiredIf(() => context.isCheckDocument), alphaEN },
          other_medical_institution: { required: requiredIf(() => context.isCheckDocument), alphaEN }
        }
      },
      dateObjectStart: {
        required,
        // minValue: minValue(new Date('1900-01-01').toLocaleString('uk-UA', optionTimeZone)),
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(context.dateObjectEnd || new Date('2200-01-01'))
      },
      dateObjectEnd: {
        required: requiredIf(() => context.isCheckDocument),
        minValue: minValue(context.dateObjectStart || new Date('1900-01-01')),
        maxValue: maxValue(new Date('2200-01-01'))
      }
    }
  ),

  qualification: (context) => (
    {
      body: {
        additional_info: {
          country: { required: requiredIf(() => context.isCheckDocument && !context.requiredField.proof.includes(context.body.additional_info.type_document)) },
          other_port: { required: requiredIf(() => context.isCheckDocument) },
          other_number: { required: requiredIf(() => !context.requiredField.proof.includes(context.body.additional_info.type_document)) },
          type_document: { required },
          diploma: { required: requiredIf(() => context.requiredField.proof.includes(context.body.additional_info.type_document)) },
          // function_limitation: { required },
          strict_blank: { required: requiredIf(() => context.isCheckDocument) },
          rank: { required: requiredIf(() => context.isCheckDocument && !context.requiredField.proof.includes(context.body.additional_info.type_document)) },
          list_positions: { required: requiredIf(() => context.isCheckDocument && !context.requiredField.proof.includes(context.body.additional_info.type_document)) }
        }
      },
      dateObjectStart: {
        required,
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(context.dateObjectEnd || new Date())
      },
      dateObjectEnd: {
        required: requiredIf(() => context.isCheckDocument && context.requiredField.date_end.includes(context.body.additional_info.type_document)),
        minValue: minValue(context.dateObjectStart || new Date('1900-01-01'))
      }
    }
  ),

  experience: () => (
    {
      body: {
        typeExperience: { required }
      }
    }
  ),
  sailorRecordBook: (context) => (
    {
      last_name_eng: { required: requiredIf(() => context.isCheckDocument), alphaEN },
      first_name_eng: { required: requiredIf(() => context.isCheckDocument), alphaEN },
      body: {
        additional_info: {
          other_number: { required, numberAndSymbol },
          other_branch_office: { required: requiredIf(() => context.isCheckDocument) },
          blank_strict_report: { required: requiredIf(() => context.isCheckDocument), numeric }
        }
      },
      dateObjectIssued: {
        required,
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(new Date(new Date()))
      }

    }
  ),
  sailorRecordBookLine: (context) => {
    return {
      body: {
        additional_info: {
          service_record: { required: requiredIf(() => context.isCheckDocument && context.typeExperienceId === TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD) },
          name_vessel: { required: requiredIf(() => context.isCheckDocument), maxLength: maxLength(255) },
          type_vessel: { required: requiredIf(() => context.isCheckDocument) },
          port_of_registration: { required: requiredIf(() => context.isCheckDocument) },
          mode_of_navigation: { required: requiredIf(() => context.isCheckDocument) },
          type_geu: { required: requiredIf(() => context.isCheckDocument) },
          ship_owner: { required: requiredIf(() => context.isCheckDocument), maxLength: maxLength(255) },
          number_vessel: { required: requiredIf(() => context.isCheckDocument), maxLength: maxLength(255) },
          propulsion_power: { required: requiredIf(() => context.isCheckDocument), numeric },
          electrical_power: { numeric },
          all_responsibility: {
            $each: {
              date_from: {
                required: requiredIf(function (contextResponsibility) {
                  return context.isCheckDocument && !contextResponsibility.days_work
                })
              },
              date_to: {
                required: requiredIf(function (contextResponsibility) {
                  return context.isCheckDocument && !contextResponsibility.days_work
                })
              },
              days_work: {
                minValue: minValue(1),
                required: requiredIf((contextResponsibility) =>
                  context.isCheckDocument && (!contextResponsibility.date_from || !contextResponsibility.date_to))
              }
            }
          },
          refrigerating_power: { numeric },
          position: { required: requiredIf(() => context.isCheckDocument) },
          place_start: { required: requiredIf(() => context.isCheckDocument), maxLength: maxLength(255) },
          place_end: { required: requiredIf(() => context.isCheckDocument), maxLength: maxLength(255) },
          full_name_master_eng: { required: requiredIf(() => context.isCheckDocument), alphaEN },
          trading_area: { required: requiredIf(() => context.isCheckDocument) },
          ports_input: { required: requiredIf(() => context.isCheckDocument) },
          gross_capacity: { required: requiredIf(() => context.isCheckDocument), numeric },
          level_refriger_plant: {},
          number_page_book: { required: requiredIf(() => context.isCheckDocument && context.typeExperienceId === TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD) },
          repair_date_from: { required: requiredIf(() => context.isCheckDocument && context.body.additional_info.is_repaired && !context.body.additional_info.days_repair) },
          repair_date_to: { required: requiredIf(() => context.isCheckDocument && context.body.additional_info.is_repaired && !context.body.additional_info.days_repair) },
          days_repair: { minValue: minValue(1), required: requiredIf(() => context.isCheckDocument && context.body.additional_info.is_repaired && !context.body.additional_info.repair_date_from && !context.body.additional_info.repair_date_to) }
        }
      },
      selected_photos: { required: requiredIf(() => context.isCheckDocument && context.typeExperienceId === TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD) },
      dateObjectStart: {
        required,
        // minValue: minValue(new Date('1900-01-01').toLocaleString('uk-UA', optionTimeZone)),
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(context.dateObjectEnd || new Date())
      },
      dateObjectEnd: {
        required,
        minValue: minValue(context.dateObjectStart || new Date('1900-01-01')),
        maxValue: maxValue(new Date())
      },
      dateObjectRepairFrom: {
        required: requiredIf(() => context.isCheckDocument && context.body.additional_info.is_repaired && !context.body.additional_info.days_repair),
        minValue: minValue(context.dateObjectStart),
        maxValue: maxValue(context.dateObjectRepairTo || context.dateObjectEnd)
      },
      dateObjectRepairTo: {
        required: requiredIf(() => context.isCheckDocument && context.body.additional_info.is_repaired && !context.body.additional_info.days_repair),
        minValue: minValue(context.dateObjectRepairFrom || context.dateObjectStart),
        maxValue: maxValue(context.dateObjectEnd)
      }
    }
  },
  employmentHistory: (context) => (
    {
      body: {
        additional_info: {
          responsibility_work_book: { required: requiredIf(() => context.isCheckDocument) },
          place_work: { required: requiredIf(() => context.isCheckDocument) },
          days_work: { minValue: minValue(1), required: requiredIf(() => !context.body.additional_info.date_start && !context.body.additional_info.date_end) }
        }
      },
      dateStartObject: {
        required: requiredIf(() => !context.body.additional_info.days_work),
        // minValue: minValue(new Date('1900-01-01').toLocaleString('uk-UA', optionTimeZone)),
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(context.dateEndObject || new Date())
      },
      dateEndObject: {
        required: requiredIf(() => !context.body.additional_info.days_work),
        minValue: minValue(context.dateStartObject || new Date('1900-01-01')),
        maxValue: maxValue(new Date())
      }
    }
  )
}
export const sailorStatementFileCertificateISC = (context) => (
  {
    mediaFilesArray: { required }
  }
)

export const verificationSC = {
  sailorIdentityCard: (context) => (
    {
      body: {
        additional_info: {
          number_document: { required },
          country: { required: requiredIf(() => context.isCheckDocument) },
          captain: { required: requiredIf(() => context.isCheckDocument), alphaUA },
          port: { required: requiredIf(() => context.isCheckDocument) }
        }
      },
      dateStartObject: {
        required,
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(new Date())
      },
      dateEndObject: {
        required,
        minValue: minValue(context.dateStartObject || new Date('1900-01-01'))
      },
      dateRenewalObject: {
        required: requiredIf(() => context.isCheckDocument),
        minValue: minValue(context.dateEndObject || new Date('1900-01-01'))
      }
    }
  ),
  citizenPassport: (context) => (
    {
      body: {
        additional_info: {
          passport_type: { required },
          last_name_ukr: { required: requiredIf((context) => context.isCheckDocument), alphaUA },
          first_name_ukr: { required: requiredIf((context) => context.isCheckDocument), alphaUA },
          last_name_eng: { required: requiredIf(() => context.isCheckDocument && context.body.additional_info.passport_type === 2), alphaEN },
          first_name_eng: { required: requiredIf(() => context.isCheckDocument && context.body.additional_info.passport_type === 2), alphaEN },
          country: { required: requiredIf((context) => context.isCheckDocument) },
          serial: { required: requiredIf((context) => context.isCheckDocument), maxLength: maxLength(30) },
          city_registration: {
            index: { maxLength: maxLength(7), minLength: minLength(4) }
          },
          resident: {
            index: { maxLength: maxLength(7), minLength: minLength(4) }
          }
        }
      },
      dateStartObject: {
        required,
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(new Date())
      },
      dateEndObject: {
        required: requiredIf(() => context.body.additional_info.passport_type === 2),
        minValue: minValue(context.dateStartObject || new Date())
      }
    }
  ),
  foreignPassport: (context) => (
    {
      body: {
        additional_info: {
          number: { required },
          date_start: { required },
          date_end: { required },
          last_name_ukr: { required: requiredIf(() => context.isCheckDocument), alphaUA },
          first_name_ukr: { required: requiredIf(() => context.isCheckDocument), alphaUA },
          middle_name_ukr: { alphaUA },
          last_name_eng: { required: requiredIf(() => context.isCheckDocument), alphaEN },
          first_name_eng: { required: requiredIf(() => context.isCheckDocument), alphaEN },
          country: { required: requiredIf(() => context.isCheckDocument) }
        }
      },
      dateStartObject: {
        required,
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(context.dateEndObject || new Date())
      },
      dateEndObject: {
        required,
        minValue: minValue(context.dateStartObject || new Date('1900-01-01'))
      }
    }
  ),
  education: (context) => (
    {
      body: {
        additional_info: {
          type_document: { required },
          number_document: { required, maxLength: maxLength(30) },
          name_nz: { required },
          qualification: { required },
          // experied_date: { required },
          serial: { required },
          registry_number: { required: requiredIf(() => context.isCheckDocument) },
          extent: { required: requiredIf(() => context.isViewDiploma.includes(context.body.additional_info.type_document))
          },
          speciality: { required: requiredIf(() => context.isViewProfession.includes(context.body.additional_info.type_document)) },
          specialization: { required:
            requiredIf(() => context.listSpecialization.length && context.isViewSpecialization.includes(context.body.additional_info.type_document))
          },
          date_end_educ_year: {
            required: requiredIf(() => context.isCheckDocument && context.isViewYearEnd.includes(context.body.additional_info.type_document)),
            minValue: minValue(1900),
            maxValue: maxValue(context.body.additional_info.date_issue_document ? context.body.additional_info.date_issue_document.split('-')[0] : 2200)
          }
        }
      },
      dateObjectIsssued: {
        required,
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(new Date())
      },
      dateEndObject: {
        required: requiredIf(() => context.isCheckDocument && context.isViewDateEnd.includes(context.body.additional_info.type_document)),
        minValue: minValue(new Date('1900-01-01'))
      }
    }
  ),
  medical: (context) => (
    {
      body: {
        additional_info: {
          number: { required },
          position: { required },
          limitation: { required: requiredIf(() => context.isCheckDocument) },
          doctor: { required },
          medical_institution: { required }
        }
      },
      dateObjectStart: {
        required,
        // minValue: minValue(new Date('1900-01-01').toLocaleString('uk-UA', optionTimeZone)),
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(context.dateObjectEnd || new Date())
      },
      dateObjectEnd: {
        required: requiredIf(() => context.isCheckDocument),
        minValue: minValue(context.dateObjectStart || new Date('1900-01-01'))
      }
    }
  ),
  qualification: (context) => (
    {
      body: {
        additional_info: {
          country: { required: requiredIf(() => context.isCheckDocument && !context.requiredField.proof.includes(context.body.additional_info.type_document)) },
          port: { required: requiredIf(() => context.isCheckDocument) },
          other_number: { required: requiredIf(() => !context.requiredField.proof.includes(context.body.additional_info.type_document)) },
          type_document: { required },
          diploma: { required: requiredIf(() => context.requiredField.proof.includes(context.body.additional_info.type_document)) },
          // function_limitation: { required },
          strict_blank: { required: requiredIf(() => context.isCheckDocument) },
          rank: { required: requiredIf(() => context.isCheckDocument && !context.requiredField.proof.includes(context.body.additional_info.type_document)) },
          list_positions: { required: requiredIf(() => context.isCheckDocument && !context.requiredField.proof.includes(context.body.additional_info.type_document)) }
        }
      },
      dateObjectStart: {
        required,
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(context.dateObjectEnd || new Date())
      },
      dateObjectEnd: {
        required: requiredIf(() => context.isCheckDocument && context.requiredField.date_end.includes(context.body.additional_info.type_document)),
        minValue: minValue(context.dateObjectStart || new Date('1900-01-01'))
      }
    }
  ),
  experience: () => (
    {
      body: {
        typeExperience: { required }
      }
    }
  ),
  sailorRecordBook: (context) => (
    {
      last_name_ukr: { required: requiredIf(() => context.isCheckDocument), alphaUA },
      first_name_ukr: { required: requiredIf(() => context.isCheckDocument), alphaUA },
      last_name_eng: { required: requiredIf(() => context.isCheckDocument), alphaEN },
      first_name_eng: { required: requiredIf(() => context.isCheckDocument), alphaEN },
      body: {
        additional_info: {
          other_number: { required, numberAndSymbol },
          branch_office: { required },
          blank_strict_report: { required: requiredIf(() => context.isCheckDocument), numeric }
        }
      },
      dateObjectIssued: {
        required,
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(new Date())
      }
    }
  ),
  sailorRecordBookLine: (context) => {
    return {
      body: {
        additional_info: {
          service_record: { required: requiredIf(() => context.isCheckDocument && context.typeExperienceId === TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD) },
          name_vessel: { required: requiredIf(() => context.isCheckDocument), maxLength: maxLength(255) },
          type_vessel: { required: requiredIf(() => context.isCheckDocument) },
          port_of_registration: { required: requiredIf(() => context.isCheckDocument) },
          mode_of_navigation: { required: requiredIf(() => context.isCheckDocument) },
          type_geu: { required: requiredIf(() => context.isCheckDocument) },
          ship_owner: { required: requiredIf(() => context.isCheckDocument), maxLength: maxLength(255) },
          number_vessel: { required: requiredIf(() => context.isCheckDocument), maxLength: maxLength(255) },
          propulsion_power: { required: requiredIf(() => context.isCheckDocument), numeric },
          electrical_power: { numeric },
          all_responsibility: {
            $each: {
              date_from: {
                // #TODO fixed problem with validation date in each use minValue and maxValue
                required: requiredIf((contextResponsibility) => context.isCheckDocument && !contextResponsibility.days_work)
                // minValue: (value) => new Date(value) > new Date('1900-01-01'),
                // maxValue: (value, obj) => {
                //   return new Date(value) < new Date(obj.date_to)
                // }
              },
              date_to: {
                required: requiredIf((contextResponsibility) => context.isCheckDocument && !contextResponsibility.days_work)
                // minValue: (value, obj) => new Date(value) > new Date(obj.date_from),
                // maxValue: (value) => new Date(value) < new Date()
              },
              days_work: {
                minValue: minValue(1),
                required: requiredIf((contextResponsibility) =>
                  context.isCheckDocument && (!contextResponsibility.date_from || !contextResponsibility.date_to))
              }
            }
          },
          refrigerating_power: { numeric },
          position: { required: requiredIf(() => context.isCheckDocument) },
          place_start: { required: requiredIf(() => context.isCheckDocument), maxLength: maxLength(255) },
          place_end: { required: requiredIf(() => context.isCheckDocument), maxLength: maxLength(255) },
          full_name_master_eng: { required: requiredIf(() => context.isCheckDocument), alphaEN },
          full_name_master: { required: requiredIf(() => context.isCheckDocument), alphaUA },
          trading_area: { required: requiredIf(() => context.isCheckDocument) },
          ports_input: { required: requiredIf(() => context.isCheckDocument) },
          gross_capacity: { required: requiredIf(() => context.isCheckDocument), numeric },
          level_refriger_plant: {},
          number_page_book: { required: requiredIf(() => context.isCheckDocument && context.typeExperienceId === TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD) },
          days_repair: { minValue: minValue(1), required: requiredIf(() => context.isCheckDocument && context.body.additional_info.is_repaired && !context.body.additional_info.repair_date_from && !context.body.additional_info.repair_date_to) }
        }
      },
      selected_photos: { required: requiredIf(() => context.isCheckDocument && context.typeExperienceId === TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD) },
      dateObjectStart: {
        required,
        // minValue: minValue(new Date('1900-01-01').toLocaleString('uk-UA', optionTimeZone)),
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(context.dateObjectEnd || new Date())
      },
      dateObjectEnd: {
        required,
        minValue: minValue(context.dateObjectStart || new Date('1900-01-01')),
        maxValue: maxValue(new Date())
      },
      dateObjectRepairFrom: {
        required: requiredIf(() => context.isCheckDocument && context.body.additional_info.is_repaired && !context.body.additional_info.days_repair),
        minValue: minValue(context.dateObjectStart),
        maxValue: maxValue(context.dateObjectRepairTo || context.dateObjectEnd)
      },
      dateObjectRepairTo: {
        required: requiredIf(() => context.isCheckDocument && context.body.additional_info.is_repaired && !context.body.additional_info.days_repair),
        minValue: minValue(context.dateObjectRepairFrom || context.dateObjectStart),
        maxValue: maxValue(context.dateObjectEnd)
      }

    }
  },
  employmentHistory: (context) => (
    {
      body: {
        additional_info: {
          responsibility_work_book: { required: requiredIf(() => context.isCheckDocument) },
          place_work: { required: requiredIf(() => context.isCheckDocument) },
          days_work: { minValue: minValue(1), required: requiredIf(() => !context.body.additional_info.date_start && !context.body.additional_info.date_end) }
        }
      },
      dateObjectStart: {
        required: requiredIf(() => !context.body.additional_info.days_work),
        // minValue: minValue(new Date('1900-01-01').toLocaleString('uk-UA', optionTimeZone)),
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(context.dateObjectEnd || new Date())
      },
      dateObjectEnd: {
        required: requiredIf(() => !context.body.additional_info.days_work),
        minValue: minValue(context.dateObjectStart || new Date('1900-01-01')),
        maxValue: maxValue(new Date())
      }
    }
  )
}

export const sailorPassportValidation = (context) => (
  {
    body: {
      country: { required: requiredIf(() => !context.activeTab) },
      number_document: { required, maxLength: maxLength(20) },
      port: { required: requiredIf((item) => !context.activeTab && item.country === COUNTRY_CONSTANTS.UKRAINE) },
      other_port: { required: requiredIf((item) => !context.activeTab && item.country !== COUNTRY_CONSTANTS.UKRAINE) },
      captain: { required: requiredIf(() => !context.activeTab), maxLength: maxLength(255) },
      statement: { required: requiredIf(() => !!context.activeTab) },
      passport: { required: requiredIf(() => !!context.activeTab && context.body.statement.is_continue) }
    },
    dateStartObject: {
      required: requiredIf(() => !context.activeTab),
      minValue: minValue(new Date('1900-01-01')),
      maxValue: maxValue(sevenDaysAgoDate)
    },
    dateEndObject: {
      required: requiredIf(() => !context.activeTab),
      minValue: minValue(context.dateStartObject || new Date('1900-01-01')),
      maxValue: maxValue(new Date('2200-12-31'))
    },
    dateRenewalObject: {
      minValue: minValue(context.dateEndObject || new Date('1900-01-01')),
      maxValue: maxValue(new Date('2200-12-31'))
    }
  }
)

export const sailorDocumentChangedStatus = () => ({
  body: {
    status_document: { required }
  }
})

export const crewingManagerStatements = context => ({
  mediaFilesArray: {
    required: requiredIf(() => {
      return (checkAccess('crewingManager') && context.body.status_document === 67 && context.statement.status_document === 69 &&
        (!context.statement.photo || (context.statement.photo && !this.statement.photo.length)))
    })
  },
  dateEndObject: {
    required: requiredIf(() => checkAccess('crewingManager') && context.body.status_document === 67),
    minValue: minValue(new Date('1900-01-01')),
    maxValue: maxValue(new Date('2200-01-01'))
  }
})

export const newStatementCreateAccountISC = context => {
  const rules = {
    body: {
      status_document: { required },
      access_to_functional: { required: requiredIf((item) => item.status_document === STATUSES_STATEMENT_CONSTANTS.NEW_ACCOUNT_SAILOR.ISC.APPROVED) }
    }
  }
  if (context.body.status_document !== STATUSES_STATEMENT_CONSTANTS.NEW_ACCOUNT_SAILOR.ISC.REJECT) {
    if (context.isExist) {
      rules.sailorId = { required }
    }
    if (!context.isExist || (context.isExist && context.isSailorChecked && !context.isForeignPassport)) {
      rules.sailorInfo = {
        last_name_eng: { required, alphaEN },
        first_name_eng: { required, alphaEN },
        date_start: { required },
        date_end: { required }
      }
    }
  }
  return rules
}

export const accountManagement = (context) => ({
  phoneNumber: { maxLength: maxLength(13), minLength: minLength(13) },
  body: {
    secretary_atc_profile: {
      profile_type: { required: requiredIf(() => context.body.userprofile.main_group === TYPE_USER.SECRETARY_ATC) }
    }
  }
})

export const accessSailorToIscFunctional = () => (
  {
    access_to_functional: { required }
  }
)

export const changeTypeAccessEducationInstitution = () => (
  {
    body: {
      access_type: { required }
    }
  }
)

export const sailor = {
  statement: {
    sailorPassport: () => (
      {
        sailor: { required },
        type_receipt: { required },
        port: { required },
        sailor_passport: {
          required: requiredIf((item) => ([
            TYPE_SAILOR_PASSPORT_PROCESSING_STATUS.CONTINUED_IN_20_DAYS,
            TYPE_SAILOR_PASSPORT_PROCESSING_STATUS.CONTINUED_IN_7_DAYS
          ].includes(item.type_receipt)))
        }
      }
    )
  },
  experience: {
    employmentHistory: (context) => (
      {
        body: {
          additional_info: {
            responsibility_work_book: { required },
            place_work: { required },
            days_work: { minValue: minValue(1), required: requiredIf(() => !context.body.additional_info.date_start && !context.body.additional_info.date_end) }
          }
        },
        dateObjectStart: {
          required: requiredIf(() => !context.body.additional_info.days_work),
          // minValue: minValue(new Date('1900-01-01').toLocaleString('uk-UA', optionTimeZone)),
          minValue: minValue(new Date('1900-01-01')),
          maxValue: maxValue(context.dateObjectEnd || new Date())
        },
        dateObjectEnd: {
          required: requiredIf(() => !context.body.additional_info.days_work),
          minValue: minValue(context.dateObjectStart || new Date('1900-01-01')),
          maxValue: maxValue(new Date())
        }
      })
  },
  qualification: {
    statement: (context) => (
      {
        body:
          {
            rank: { required: requiredIf(() => !context.$route.params?.documentID) },
            list_positions: { required: requiredIf(() => !context.$route.params?.documentID) },
            port: { required },
            protocol_dkk: { required: requiredIf((item) => item.rank?.is_dkk && !context.$route.params?.documentID) }
          }
      }
    ),
    cerificateOfNavigation: (context) => ({
      body: {
        other_number: { required },
        issuing_authority: { required },
        authorized_official_ukr: { required, alphaUA },
        authorized_official_eng: { required, alphaEN },
        strict_blank: { required },
        type: { required },
        position: {
          required: requiredIf((context) => context.type === 'ukrainian')
        },
        ship_group: {
          required: requiredIf((context) => context.type === 'ukrainian')
        },
        radio_equipment: {
          required: requiredIf((context) => context.type === 'european')
        },
        category: {
          required: requiredIf((context) => context.type === 'european')
        },
        rivers: {
          start_area: {
            required: requiredIf((context) => context.type === 'european'),
            minValue: minValue(0),
            maxValue: maxValue(2329)
          },
          end_area: {
            required: requiredIf((context) => context.type === 'european'),
            minValue: minValue(0),
            maxValue: maxValue(2329)
          }
        }
      },
      dateStartObject: {
        required,
        minValue: minValue(new Date('1900-01-01')),
        maxValue: maxValue(new Date())
      },
      dateEndObject: {
        required: requiredIf((context) => context.type === 'european'),
        minValue: minValue(context.dateStartObject || new Date('1900-01-01')),
        maxValue: maxValue(new Date('2200-12-31'))
      }
    })
  },
  packet: {
    statement: (context) => ({
      rank: { required },
      body: {
        branch_office: { required },
        include_sailor_passport: { required },
        list_positions: { required },
        sqc_type: { required: requiredIf(() => checkrankIsSQC(context.ranksList, context.rank)) },
        type_exam: { required: requiredIf(() => context.body.sqc_type === '0') }
      }
    }
    ),
    documentForm: (context) => ({
      body: {
        branch_office: { required },
        dependencies: {
          $each: {
            content_type: { required },
            document_object: {
              course_id: { required: requiredIf((obj) => CONTENT_TYPE.STATEMENT_ETI === obj.content_type) },
              city: { required: requiredIf((obj) => CONTENT_TYPE.STATEMENT_ETI === obj.content_type) },
              institution_id: { required: requiredIf((obj) => CONTENT_TYPE.STATEMENT_ETI === obj.content_type) },
              port_id: { required: requiredIf((obj) => CONTENT_TYPE.STATEMENT_SAILOR_PASSPORT === obj.content_type) },
              type_receipt: { required: requiredIf((obj) => CONTENT_TYPE.STATEMENT_SAILOR_PASSPORT === obj.content_type) },
              position_id: { required: requiredIf((obj) => CONTENT_TYPE.STATEMENT_MEDICAL_CERTIFICATE === obj.content_type) },
              medical_institution_id: { required: requiredIf((obj) => CONTENT_TYPE.STATEMENT_MEDICAL_CERTIFICATE === obj.content_type) },
              level_qualification_id: { required: requiredIf((obj) => CONTENT_TYPE.STATEMENT_ADVANCED_TRAINING === obj.content_type) },
              educational_institution_id: { required: requiredIf((obj) => CONTENT_TYPE.STATEMENT_ADVANCED_TRAINING === obj.content_type) }
            }
          }
        }
      }
    })
  }
}

export const createPositionPacketISSC = (context) => (
  {
    body: {
      service_center: { required },
      sqc_type: { required: requiredIf(() => context.checkRank) },
      type_exam: { required: requiredIf((item) => item.sqc_type === 0) }
    }
  }
)

export const recoveryPassword = () => ({
  body: {
    email: { required, email },
    username: { required, alphaENText }
  }
})

export const changePasswordUserWithToken = () => ({
  body: {
    new_password: { required },
    confirm_password: {
      required,
      notSameAsPassword: sameAs('new_password')
    }
  }
})

export const changeOldPassword = () => ({
  body: {
    old_password: { required },
    new_password: { required, sameAsOldPassword: not(sameAs('old_password')) },
    confirm_password: {
      required,
      notSameAsPassword: sameAs('new_password')
    }
  }
})

export const totpAuthCode = () => ({
  body: {
    token: { required },
    device_name: { required }
  }
})

export const education = {
  statement: () => ({
    body: {
      level_qualification: { required },
      educational_institution: { required }
    }
  })
}

export const CreateDocumentWithStatement = () => ({
  body: {
    number_document: { required, maxLength: maxLength(30) },
    serial: { required },
    registry_number: { required }
  },
  dateStartEduc: { required },
  dateEndEduc: { required }
})
