import api from '@/plugins/api'
import i18n from '@/plugins/localization/index'
import notify from '@ananasbear/notify'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS, STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
import { queryString, addNeedFieldForViewFiles } from '@/mixins/main'
import { statements, verification, packageStatement } from '@/configs/endpoints'
export default {
  state: () => ({
    isLoading: true,
    statementServiceRecordBook: {},
    statementSQC: {},
    nostrificationStatement: {},
    statementETI: {},
    statementAdvanceTraining: {},
    statementSailorPassport: {},
    statementRecordBookByIdISC: {},
    crewingManagerStatements: {},
    crewingManagerStatement: {},
    nostrificationById: {},
    clarificationOfDataById: {},
    clarificationOfData: {},
    statementSRB: {},
    statementQualificationFromPackage: {},
    statementsPackageISCById: {},
    listSqc: {},
    listCrewStatement: {},
    crewStatementById: {},
    listPaymentETI: {},
    crewingManagerStatementById: {}
  }),
  mutations: {
    SET_DATA_STATE_STATEMENT (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    }
  },
  actions: {
    async getStatementRecordBookISC (context, params) {
      const response = await api.get(statements.statementRecordBookISC + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_STATEMENT', { statementServiceRecordBook: response.data })
    },
    async getStatementRecordBookByIdISC (context, data) {
      const response = await api.get(statements.statementRecordBookByIdISC(data.documentID) + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        const responseComments = await context.dispatch('getCommentRejectStatementSRBFromISC', { documentID: response.data.id })
        if (SUCCESS_CODE.includes(responseComments.code)) response.data.comments = responseComments.data
        context.commit('SET_DATA_STATE_STATEMENT', { statementRecordBookByIdISC: response.data })
      }
      return response
    },
    async rejectStatementSRBtoISC (context, data) {
      // eslint-disable-next-line camelcase
      const { status_document, documentID } = data
      const response = await api.patch(statements.statementRecordBookByIdISC(documentID), { body: { status_document } })
      if (SUCCESS_CODE.includes(response.code)) {
        context.dispatch('setCommentForStatementSRBFromISC', data)
      }
      return SUCCESS_CODE.includes(response.code)
    },
    async setCommentForStatementSRBFromISC (context, data) {
      const { body, documentID } = data
      const response = await api.post(statements.setCommentStatementSRBFromISC(documentID), { body })
      if (SUCCESS_CODE.includes(response.code)) return response
    },
    async getCommentRejectStatementSRBFromISC (context, data) {
      const { documentID } = data
      const response = await api.get(statements.setCommentStatementSRBFromISC(documentID))
      if (SUCCESS_CODE.includes(response.code)) return response
    },
    // NOSTRIFICATION STATEMENTS
    async getNostrificationStatement (context, params) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.get(statements.nostrification.statement() + queryString(params))
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_STATEMENT', { nostrificationStatement: response.data })
    },
    async getStatementNostrificationById (context, data) {
      context.commit('SET_DATA_STATE_STATEMENT', { nostrificationById: {}, isLoading: true })
      const response = await api.get(statements.nostrification.statement(data.id))
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
      if (SUCCESS_CODE.includes(response.code)) {
        if ([STATUSES_DOCUMENT_CONSTANTS.rejectedByCrewing].includes(response.data.status_document) ||
        [STATUSES_DOCUMENT_CONSTANTS.rejectByTransator, STATUSES_DOCUMENT_CONSTANTS.rejectByNostrification].includes(response.data.nostrification_document_status)) {
          const responseCommnet = await context.dispatch('getCommentRejectStatementNostrification', data)
          if (SUCCESS_CODE.includes(responseCommnet.code)) response.data.comments = responseCommnet.data
        }
        response.data.behavior = { viewInfoBlock: true }
        response.data.files = await addNeedFieldForViewFiles(response.data.files)

        context.commit('SET_DATA_STATE_STATEMENT', { nostrificationById: response.data })
        return response.data
      } else return {}
    },
    async getWaitPaidTranslationNostrificationStatement (context, params) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.get(statements.nostrification.waitPaidTranslationStatement() + queryString(params))
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_STATEMENT', { nostrificationStatement: response.data })
    },
    async getWaitPaidMESNostrificationStatement (context, params) {
      const response = await api.get(statements.nostrification.waitPaidMESStatement() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_STATEMENT', { nostrificationStatement: response.data })
    },
    async setRejectStatementNostrificationById (context, data) {
      const response = await api.patch(statements.nostrification.statement(data.statementId), { body: { status_document: data.status_document } })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('documentReject')
        return context.dispatch('setCommentRejectStatementNostrification', data)
      }
      return SUCCESS_CODE.includes(response.code)
    },
    async updateStatementNostrificationById (context, data) {
      const response = await api.patch(statements.nostrification.statement(data.statementId), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        notify.success('documentChanged')
        return response.data
      }
      return SUCCESS_CODE.includes(response.code)
    },
    async setCommentRejectStatementNostrification (contex, data) {
      const response = await api.post(statements.nostrification.comment(data.statementId), { body: data.body })
      return SUCCESS_CODE.includes(response.code)
    },
    async getCommentRejectStatementNostrification (contex, data) {
      const response = await api.get(statements.nostrification.comment(data.id))
      return response
    },
    async deleteFileNostrificationStatement (contex, data) {
      const response = await api.delete(statements.nostrification.deleteFileStatement(data.id, data.fileId))
      if (SUCCESS_CODE.includes(response.code)) {
        contex.dispatch('getStatementNostrificationById', data)
        notify.success('notify.success.deleteFile')
      }
    },
    // ----------CLARIFICATION OF DATA------------------//
    async getListClarificationOfData (context, params) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true, clarificationOfData: {} })
      const response = await api.get(verification.clarificationOfData.statement() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_STATEMENT', { clarificationOfData: response.data })
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    async getClarificationOfDataById (context, data) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true, clarificationOfDataById: {} })
      const response = await api.get(verification.clarificationOfData.statement(data.documentID))
      if (SUCCESS_CODE.includes(response.code)) {
        if (STATUSES_STATEMENT_CONSTANTS.clarificationOfData.rejected === response.data.status_document) {
          const responseComments = await context.dispatch('getRejectСlarificationOfDataById', data)
          if (SUCCESS_CODE.includes(responseComments.code)) response.data.comments = responseComments.data
        }
        response.data.behavior = { viewInfoBlock: true }
        context.commit('SET_DATA_STATE_STATEMENT', { clarificationOfDataById: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    async updateClarificationOfDataById (context, data) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true, clarificationOfDataById: {} })
      const response = await api.patch(verification.clarificationOfData.statement(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        if (STATUSES_STATEMENT_CONSTANTS.clarificationOfData.rejected === response.data.status_document) {
          const responseComments = await context.dispatch('getRejectСlarificationOfDataById', data)
          if (SUCCESS_CODE.includes(responseComments.code)) response.data.comments = responseComments.data
          notify.success('notify.success.statementRejected')
        } else notify.success('notify.success.statementUpdate')

        context.commit('SET_DATA_STATE_STATEMENT', { clarificationOfDataById: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    async setRejectclarificationOfDataById (context, data) {
      const response = await api.post(verification.clarificationOfData.comment(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        context.dispatch('updateClarificationOfDataById', { id: data.id, body: { status_document: data.status_document } })
      }
    },
    async getRejectСlarificationOfDataById (context, data) {
      const response = await api.get(verification.clarificationOfData.comment(data.id))
      if (SUCCESS_CODE.includes(response.code)) return response
      return []
    },
    // -----------------STATEMENT ETI-------------------- //
    async getListStatementETI ({ commit }, params) {
      commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.get(statements.eti + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_STATEMENT', { statementETI: response.data })
      commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    // -----------------STATEMENT ADVANCE TRAINING-------------------- //
    async getListStatementAdvanceTrainning ({ commit }, params) {
      commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.get(statements.advanceTraining + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_STATEMENT', { statementAdvanceTraining: response.data })
      commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    // -----------------STATEMENT SAILOR PASSPORT-------------------- //
    async getListStatementSailorPassport ({ commit }, params) {
      commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.get(statements.sailorPassport + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_STATEMENT', { statementSailorPassport: response.data })
      commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    // -----------------CREWING MANAGER STATEMENTS-------------------- //
    async getCrewingManagerStatements (context, params) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.get(statements.crewingManagerStatement() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results = response.data.results.map(item => {
          item.sailor_full_name_ukr = `${item.sailor_key.last_name_ukr} ${item.sailor_key.first_name_ukr} ${item.sailor_key.middle_name_ukr || ''}`
          return item
        })
        context.commit('SET_DATA_STATE_STATEMENT', { crewingManagerStatements: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
      return response
    },
    async getCrewingManagerStatement (context, data) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true, crewingManagerStatement: {} })
      const response = await api.get(statements.crewingManagerStatement(data.documentID))
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.behavior = { viewInfoBlock: true }
        response.data.sailor_full_name_ukr = `${response.data.sailor_key.last_name_ukr} ${response.data.sailor_key.first_name_ukr} ${response.data.sailor_key.middle_name_ukr || ''}`
        context.commit('SET_DATA_STATE_STATEMENT', { crewingManagerStatementById: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
      return response
    },
    async updateCrewingManagerStatement (context, data) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.patch(statements.crewingManagerStatement(data.documentID), { body: data.body })
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.behavior = { viewInfoBlock: true }
        response.data.sailor_full_name_ukr = `${response.data.sailor_key.last_name_ukr} ${response.data.sailor_key.first_name_ukr} ${response.data.sailor_key.middle_name_ukr || ''}`
        context.commit('SET_DATA_STATE_STATEMENT', { crewingManagerStatementById: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
      return response
    },
    async uploadFilesCrewingManagerStatement (context, data) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.post(statements.crewingManagerStatementUploadFiles(data.id), { body: data.body, responseType: 'blob' })
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
      return response
    },
    async getListStatementSRB (context, params) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.get(statements.srb + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) {
        context.commit('SET_DATA_STATE_STATEMENT', { statementSRB: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    async getListQualificationStatementFromPackage (context, params) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.get(statements.statementQualificationFromPackage + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) {
        context.commit('SET_DATA_STATE_STATEMENT', { statementQualificationFromPackage: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    async getStatementPackageISCById (context, data) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true })
      const response = await api.get(statements.statementsPackageISC.list(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        if (STATUSES_STATEMENT_CONSTANTS.PACKAGE.REJECT === response.data.status_document) {
          const responseComments = await context.dispatch('getRejectStatementPackageISCById', data)
          if (SUCCESS_CODE.includes(responseComments.code)) response.data.comments = responseComments.data
        }
        context.commit('SET_DATA_STATE_STATEMENT', { statementsPackageISCById: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    async getRejectStatementPackageISCById (context, data) {
      const response = await api.get(statements.statementsPackageISC.comment(data.id))
      if (SUCCESS_CODE.includes(response.code)) return response
      return []
    },
    async setRejectStatementPackageISCById (context, data) {
      const response = await api.post(statements.statementsPackageISC.comment(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        context.dispatch('updateClarificationOfDataById', { id: data.id, body: { status_document: data.status_document } })
      }
    },
    async updateStatementPackageISCById (context, data) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true, statementsPackageISCById: {} })
      const response = await api.patch(statements.statementsPackageISC.list(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        if (STATUSES_STATEMENT_CONSTANTS.PACKAGE.REJECT === response.data.status_document) {
          const responseComments = await context.dispatch('getRejectStatementPackageISCById', data)
          if (SUCCESS_CODE.includes(responseComments.code)) response.data.comments = responseComments.data
          notify.success('notify.success.statementRejected')
        } else notify.success('notify.success.statementUpdate')

        context.commit('SET_DATA_STATE_STATEMENT', { statementsPackageISCById: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    async checkIsContinueForPacket (context, data) {
      const response = await api.post(packageStatement.check_is_continue(data.sailorId), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) return response.data
      return {}
    },
    async previewForPacket (context, data) {
      const response = await api.post(packageStatement.packet_preview(data.sailorId), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        if (response.data.can_create_packet) notify.success('validPackageInfo')
        else notify.error('invalidPackageInfo')
        return response.data
      }
      return {}
    },
    async setPacketISC (context, data) {
      const response = await api.post(packageStatement.packet, { body: data.body })
      return SUCCESS_CODE.includes(response.code)
    },
    async getLinkStatementSQC (context, params) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true, listSqc: {} })
      const response = await api.get(statements.sqc + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_STATEMENT', { listSqc: response.data })
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    // -----------------CREWING STATEMENT ---------------------//
    async getListCrewStatement (context, params) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true, listCrewStatement: {} })
      const response = await api.get(statements.crew() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_STATEMENT', { listCrewStatement: response.data })
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    async getCrewingVerificationStatements (context, data) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true, crewStatementById: {} })
      const response = await api.get(statements.crew(data.documentID))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        context.commit('SET_DATA_STATE_STATEMENT', { crewStatementById: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    },
    async updateCrewingVerificationStatement (context, data) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true, crewStatementById: {} })
      const response = await api.patch(statements.crew(data.documentID), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        context.commit('SET_DATA_STATE_STATEMENT', { crewStatementById: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
      return response
    },
    // -------------------- PAYMENT ETI --------------------------//
    async getListPaymentETI (context, params) {
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: true, listPaymentETI: {} })
      const response = await api.get(statements.paymentETI + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.map((item) => {
          item.amount = `${item.amount} ${i18n.t('uah')}`
        })
        context.commit('SET_DATA_STATE_STATEMENT', { listPaymentETI: response.data })
      }
      context.commit('SET_DATA_STATE_STATEMENT', { isLoading: false })
    }
  }
}
