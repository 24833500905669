<template lang="pug">
    include ../../../configs/template.pug
    div.row.p-1
      div.col-6
        +select-validation('body.level_qualification', 'levelQualificationList', 'qualification', 'labelName', '["required"]')
      div.col-6
        +select-validation('body.educational_institution', 'listInstitution', 'nameInstitution', 'labelName', '["required"]')
      div.col-6
        +date-picker-validation('body.date_start', 'dateEffective', 'dateStartObject', '["required"]')(
          :min="new Date().toISOString()")
      div.col-6
        +date-picker-validation('body.date_end', 'dateEnd', 'dateEndObject', '["minValue", "maxValue"]')(
          :min="body.date_start ? new Date(body.date_start).toISOString() : new Date().toISOString()")
      div.col-6
        +field-validation('body.price', 'price', '["required"]')
      div.w-100.d-flex.justify-center.mt-3
        v-btn(@click="handleClickSave" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { mapState } from 'vuex'
import { vnz } from '@/mixins/validationRules'

const initBody = () => ({
  educational_institution: null,
  level_qualification: null,
  price: null,
  date_start: null,
  date_end: null
})
export default {
  name: 'form-edit-create-vnz',
  data () {
    return {
      body: initBody(),
      listInstitution: []
    }
  },
  props: {
    vnz: Object
  },
  validations () { return vnz(this) },
  computed: {
    ...mapState({
      institutionList: state => state.directory.institution,
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      levelQualificationList: state => state.directory.qualificationLevels.filter(qualification => qualification.type_NZ === 2),
      isLoading: state => state.backoffice.isLoading,
      success: state => state.backoffice.success
    }),
    dateStartObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    dateEndObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    }
  },
  watch: {
    'body.date_start' (val) {
      if (val) this.date_start = val?.split('-').reverse().join('-')
    },
    success (val) {
      if (val) {
        this.body = initBody()
        this.$v.$reset()
      }
    },
    'body.level_qualification' (newVal, oldVal) {
      this.listInstitution = []
      this.body.educational_institution = null
      if (newVal !== oldVal) {
        this.listInstitution = this.institutionList
      }
    }
  },
  methods: {
    handleClickSave () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.$emit('save', this.body)
    }
  },
  mounted () {
    if (this.vnz) Object.entries(this.vnz).forEach(([key, value]) => (this.body[key] = value))
  }

}
</script>
