import store from '@/store/index'

export default [
  {
    path: '/sailor/:id',
    name: 'sailor',
    props: {
      id: [Number, String]
    },
    component: () => import('@/views/Sailor/Sailor.vue'),
    redirect: '/sailor/:id/passports/sailors',
    beforeEnter: (to, from, next) => { openSailorProfile(to, from, next) },
    children: [
      {
        path: 'passports/sailors',
        name: 'passports-sailors',
        component: () => import('@/views/Sailor/SailorPassport/Table.vue')
      },
      {
        path: 'passports/sailors/:documentID',
        name: 'passports-sailors-info',
        component: () => import('@/views/Sailor/SailorPassport/Document.vue')
      },
      {
        path: 'passports/citizen',
        name: 'passports-citizen',
        component: () => import('@/views/Sailor/SailorCitizenPassport/Table.vue')
      },
      {
        path: 'passports/citizen/:documentID',
        name: 'passports-citizen-info',
        component: () => import('@/views/Sailor/SailorCitizenPassport/Document.vue')
      },
      {
        path: 'passports/international',
        name: 'passports-international',
        component: () => import('@/views/Sailor/SailorForeignPassport/Table.vue')
      },
      {
        path: 'passports/international/:documentID',
        name: 'passports-international-info',
        component: () => import('@/views/Sailor/SailorForeignPassport/Document.vue')
      },
      {
        path: 'passports/changes',
        name: 'passports-changes',
        component: () => import('@/views/Sailor/SailorFullNameChanges/Table.vue')
      },
      {
        path: 'passports/changes/:documentID',
        name: 'passports-changes-info',
        component: () => import('@/views/Sailor/SailorFullNameChanges/Document.vue')
      },
      {
        path: 'passports/statements',
        name: 'passports-statements',
        component: () => import('@/views/Sailor/SailorPassportStatement/Table.vue')
      },
      {
        path: 'passports/statements/:documentID',
        name: 'passports-statements-info',
        component: () => import('@/views/Sailor/SailorPassportStatement/Document.vue')
      },
      {
        path: 'education/documents',
        name: 'education-documents',
        component: () => import('@/views/Sailor/SailorEducation/Table.vue')
      },
      {
        path: 'education/documents/:documentID',
        name: 'education-documents-info',
        component: () => import('@/views/Sailor/SailorEducation/Document.vue')
      },
      {
        path: 'education/student',
        name: 'education-student',
        component: () => import('@/views/Sailor/SailorStudent/Table.vue')
      },
      {
        path: 'education/student/:documentID',
        name: 'education-student-info',
        component: () => import('@/views/Sailor/SailorStudent/Document.vue')
      },
      {
        path: 'education/statements',
        name: 'education-statements',
        component: () => import('@/views/Sailor/SailorEducationStatement/Table.vue')
      },
      {
        path: 'education/statements/:documentID',
        name: 'education-statements-info',
        component: () => import('@/views/Sailor/SailorEducationStatement/Document.vue')
      },
      {
        path: 'qualification/documents',
        name: 'qualification-documents',
        component: () => import('@/views/Sailor/SailorQualification/Table.vue')
      },
      {
        path: 'qualification/documents/:documentID',
        name: 'qualification-documents-info',
        component: () => import('@/views/Sailor/SailorQualification/Document.vue')
      },
      {
        path: 'qualification/documents/proof/:documentID',
        name: 'qualification-documents-proof-info',
        component: () => import('@/views/Sailor/SailorQualification/Document.vue')
      },
      {
        path: 'qualification/statements',
        name: 'qualification-statements',
        component: () => import('@/views/Sailor/SailorQualificationStatement/Table.vue')
      },
      {
        path: 'qualification/statements-replacement',
        name: 'qualification-replacement-statement',
        component: () => import('@/views/Sailor/SailorQualificationStatementReplacement/Table.vue')
      },
      {
        path: 'qualification/statements-replacement/:statementId',
        name: 'qualification-replacement-statement-info',
        component: () => import('@/views/Sailor/SailorQualificationStatementReplacement/Document.vue')
      },
      {
        path: 'qualification/statements/:documentID',
        name: 'qualification-statements-info',
        component: () => import('@/views/Sailor/SailorQualificationStatement/Document.vue')
      },
      {
        path: 'qualification/navigator',
        name: 'qualification-navigator',
        component: () => import('@/views/Sailor/SailorNavigatorCertificate/Table.vue')
      },
      {
        path: 'qualification/navigator/:documentID',
        name: 'qualification-navigator-info',
        component: () => import('@/views/Sailor/SailorNavigatorCertificate/Document.vue')
      },
      {
        path: 'certification/certificates',
        name: 'certification-certificates',
        component: () => import('@/views/Sailor/SailorCertification/Table.vue')
      },
      {
        path: 'certification/certificates/:documentID',
        name: 'certification-certificates-info',
        component: () => import('@/views/Sailor/SailorCertification/Document.vue')
      },
      {
        path: 'certification/statements',
        name: 'certification-statements',
        component: () => import('@/views/Sailor/SailorCertificationStatement/Table.vue')
      },
      {
        path: 'certification/statements/:documentID',
        name: 'certification-statements-info',
        component: () => import('@/views/Sailor/SailorCertificationStatement/Document.vue')
      },
      {
        path: 'experience/records',
        name: 'experience-records',
        component: () => import('@/views/Sailor/SailorRecordBook/Table.vue')
      },
      {
        path: 'experience/records/:documentID',
        name: 'experience-records-info',
        component: () => import('@/views/Sailor/SailorRecordBook/Document.vue')
      },
      {
        path: 'experience/records/:documentID/line/',
        redirect: { name: 'experience-records-info' }
      },
      {
        path: 'experience/records/:documentID/line/:lineID',
        name: 'experience-records-line-info',
        component: () => import('@/views/Sailor/SailorRecordBook/SailorRecordBookLine/Document.vue')
      },
      {
        path: 'experience/reference',
        name: 'experience-reference',
        component: () => import('@/views/Sailor/SailorExperience/Table.vue')
      },
      {
        path: 'experience/reference/:documentID',
        name: 'experience-reference-info',
        component: () => import('@/views/Sailor/SailorExperience/Document.vue')
      },
      {
        path: 'experience/statements',
        name: 'experience-statements',
        component: () => import('@/views/Sailor/SailorRecordBookStatement/Table.vue')
      },
      {
        path: 'experience/statements/:documentID',
        name: 'experience-statements-info',
        component: () => import('@/views/Sailor/SailorRecordBookStatement/Document.vue')
      },
      {
        path: 'sqc/statements',
        name: 'sqc-statements',
        component: () => import('@/views/Sailor/SailorSQCStatement/Table.vue')
      },
      {
        path: 'sqc/statements/:documentID',
        name: 'sqc-statements-info',
        component: () => import('@/views/Sailor/SailorSQCStatement/Document.vue')
      },
      {
        path: 'sqc/protocols',
        name: 'sqc-protocols',
        component: () => import('@/views/Sailor/SailorSQCProtocols/Table.vue')
      },
      {
        path: 'sqc/protocols/:documentID',
        name: 'sqc-protocols-info',
        component: () => import('@/views/Sailor/SailorSQCProtocols/Document.vue')
      },
      {
        path: 'medical/certificates',
        name: 'medical-certificates',
        component: () => import('@/views/Sailor/SailorMedical/Table.vue')
      },
      {
        path: 'medical/certificates/:documentID',
        name: 'medical-certificates-info',
        component: () => import('@/views/Sailor/SailorMedical/Document.vue')
      },
      {
        path: 'medical/statements',
        name: 'medical-statements',
        component: () => import('../views/Sailor/SailorMedicalStatement/Table.vue')
      },
      {
        path: 'medical/statements/:documentID',
        name: 'medical-statements-info',
        component: () => import('@/views/Sailor/SailorMedicalStatement/Document.vue')
      },
      {
        path: 'position/statements',
        name: 'position-statements',
        component: () => import('../views/Sailor/SailorPositionStatement/Table.vue')
      },
      {
        path: 'position/statements/:documentID',
        name: 'position-statements-info',
        component: () => import('@/views/Sailor/SailorPositionStatement/Document.vue')
      },
      {
        path: 'digitization/documents',
        name: 'digitization-documents',
        component: () => import('@/views/Sailor/SailorDigitization/DigitizationDocumentTable.vue')
      },
      {
        path: 'digitization/statements',
        name: 'digitization-statements',
        component: () => import('@/views/Sailor/SailorDigitization/DigitizationStatementTable.vue')
      }
    ]
  }
]

const openSailorProfile = function (to, from, next) {
  store.dispatch('getSailorInformation', to.params.id).then(() => {
    next()
  })
}
