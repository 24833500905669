import Vue from 'vue'
import Vuex from 'vuex'
import { MAIN } from './modules/main'
import { DIRECTORY } from './modules/directory'
import { SAILOR } from './modules/sailor'
import { EDS } from './modules/eds'
import { PACKET } from './modules/packet'
import BACKOFFICE from './modules/backoffice'
import { USER } from './modules/user'
import STATISTICS from './modules/statisticsVerification'
import DELIVERY from './modules/delivery'
import { CREWING } from './modules/crewing'
import { REPORT } from './modules/report'
import U2F from './modules/u2f'
import STATEMENT from './modules/statement'
import HISTORY from './modules/history'
import DOCUMENTS from './modules/documents'
// import mutations from './mutations'

Vue.use(Vuex)

export const OPTIONS = {
  method: 'GET',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Token ' + localStorage.getItem('Token')
  }
}

export default new Vuex.Store({
  // mutations: {
  //   ...mutations
  // },
  modules: {
    main: MAIN,
    directory: DIRECTORY,
    sailor: SAILOR,
    eds: EDS,
    pacet: PACKET,
    backoffice: BACKOFFICE,
    user: USER,
    statistics: STATISTICS,
    delivery: DELIVERY,
    crewing: CREWING,
    report: REPORT,
    u2f: U2F,
    statement: STATEMENT,
    history: HISTORY,
    documents: DOCUMENTS
  }
})
