import Distribution from './report/report.distribution.routes'
export default [
  {
    path: '/report',
    name: 'report',
    redirect: { name: 'sqc-report-protocol' },
    component: () => import('@/views/Report/Report.vue'),
    children: [
      {
        path: 'sqc',
        name: 'sqc-report',
        component: () => import('@/views/Report/ReportSQC/ReportSQC.vue')
      },
      {
        path: 'sqc-protocol',
        name: 'sqc-report-protocol',
        component: () => import('@/views/Report/ReportSQC/TableProtocol.vue')
      },
      {
        path: 'sqc-statement',
        name: 'sqc-report-statement',
        component: () => import('@/views/Report/ReportSQC/TableStatement.vue')
      },
      {
        path: 'cadet',
        name: 'cadet-report',
        component: () => import('@/views/Report/ReportCadet/ReportCadet.vue')
      },
      {
        path: 'education',
        name: 'education-report',
        component: () => import('@/views/Report/ReportEducation/ReportEducation.vue')
      },
      {
        path: 'education-2',
        name: 'education-report-2',
        component: () => import('@/views/Report/ReportEducation/Table.vue')
      },
      {
        path: 'qualification',
        name: 'qualification-report',
        component: () => import('@/views/Report/ReportQualification/ReportQualification.vue')
      },
      {
        path: 'qualification/statement',
        name: 'statement-qualification-report',
        component: () => import('@/views/Report/ReportQualification/ReportStatementQualification.vue')
      },
      {
        path: 'certificate',
        name: 'certificates-report',
        component: () => import('@/views/Report/ReportETI/ReportETI.vue')
      },
      {
        path: 'certificate-2',
        name: 'certificates-report-2',
        component: () => import('@/views/Report/ReportETI/Table.vue')
      },
      {
        path: 'sailorPassport',
        name: 'sailorPassport-report',
        component: () => import('@/views/Report/ReportSailorPassport/ReportSailorPassport.vue')
      },
      {
        path: 'sailor-passport',
        name: 'sailor-passport-report',
        component: () => import('@/views/Report/ReportSailorPassport/Table.vue')
      },
      {
        path: 'submitted-documents',
        name: 'submitted-documents-report',
        component: () => import('@/views/Report/ReportDocumentEntries/ReportDocumentEntries.vue')
      },
      {
        path: 'financial',
        name: 'financial-report',
        component: () => import('@/views/Report/ReportFinance/ReportFinance.vue')
      },
      {
        path: 'financial-2',
        name: 'financial-report-2',
        component: () => import('@/views/Report/ReportFinance/Table.vue')
      },
      {
        path: 'excel',
        name: 'excel-report',
        component: () => import('@/views/Report/ReportExcel/ReportExcel.vue')
      },
      {
        path: 'debtor',
        name: 'debtor-report',
        component: () => import('@/views/Report/ReportDebtor/Table.vue')
      },
      {
        path: 'debtor/group/:group',
        name: 'debtor-report-group',
        props (route) {
          return { search: route.query.search }
        },
        component: () => import('@/views/Report/ReportDebtor/TableGroup.vue')
      },
      {
        path: 'debtor/group/:group/crewing/:crewing',
        name: 'debtor-report-crewing',
        props (route) {
          return { search: route.query.search }
        },
        component: () => import('@/views/Report/ReportDebtor/TableCrewing.vue')
      },
      {
        path: 'debtor/group/:group/crewing/:crewing/crewing_manager/:manager',
        name: 'debtor-report-manager',
        props (route) {
          return { search: route.query.search }
        },
        component: () => import('@/views/Report/ReportDebtor/TableManager.vue')
      },
      {
        path: 'debtor/group/:group/crewing/:crewing/crewing_manager/:manager/sailor/:sailor_id',
        name: 'debtor-report-sailor',
        props (route) {
          return { search: route.query.search }
        },
        component: () => import('@/views/Report/ReportDebtor/TableSailor.vue')
      },
      {
        path: 'debtor/group/:group/crewing/:crewing/crewing_manager/:manager/sailor/:sailor_id/packet/:packet_item',
        name: 'debtor-report-packet',
        props (route) {
          return { search: route.query.search }
        },
        component: () => import('@/views/Report/ReportDebtor/TablePacket.vue')
      },

      {
        path: 'distribution',
        name: 'distribution-report-crewing',
        redirect: '/report/distribution/usual',
        props (route) {
          return { search: route.query.search }
        },
        component: () => import('@/views/Report/ReportDistribution/Distribution.vue'),
        children: [
          ...Distribution,
          // {
          //   path: 'usual-2/:typeDocument',
          //   name: 'distribution-usual',
          //   redirect: { name: 'distribution-usual-report-crewing-2' },
          //   component: () => import('@/views/Report/ReportDistribution/DistributionAllTypeDocument/DistributionAllTypeDocument.vue'),
          //   children: [
          //     {
          //       path: 'crewing',
          //       name: 'distribution-usual-report-crewing-2',
          //       component: () => import('@/views/Report/ReportDistribution/TableCrewing.vue')
          //     },
          //     {
          //       path: 'crewing_group/:crewing_group',
          //       name: 'distribution-usual-report-crewing-group-2',
          //       component: () => import('@/views/Report/ReportDistribution/TableGroup.vue')
          //     },
          //     {
          //       path: 'document_type/:crewing_group',
          //       name: 'distribution-usual-report-document-type-2',
          //       component: () => import('@/views/Report/ReportDistribution/TableDocType.vue')
          //     },
          //     {
          //       path: 'crewing/:crewing_group/document_type/:type',
          //       name: 'distribution-usual-report-crewing-3',
          //       component: () => import('@/views/Report/ReportDistribution/TableCrewing.vue')
          //     },
          //     {
          //       path: 'crewing/:crewing',
          //       name: 'distribution-usual-report-manager-2',
          //       component: () => import('@/views/Report/ReportDistribution/TableManager.vue')
          //     },
          //     {
          //       path: 'crewing/:crewing',
          //       name: 'distribution-usual-report-manager-3',
          //       component: () => import('@/views/Report/ReportDistribution/TableManager.vue')
          //     },
          //     {
          //       path: 'crewing/:institution',
          //       name: 'distribution-usual-report-manager-4',
          //       component: () => import('@/views/Report/ReportDistribution/TableManager.vue')
          //     },
          //     {
          //       path: 'crewing/:branch_office',
          //       name: 'distribution-usual-report-manager-6',
          //       component: () => import('@/views/Report/ReportDistribution/TableManager.vue')
          //     },
          //     {
          //       path: 'crewing/:crewing/type-documet/:type',
          //       name: 'distribution-usual-report-manager-5',
          //       component: () => import('@/views/Report/ReportDistribution/TableManager.vue')
          //     },
          //     {
          //       path: 'sailor/:manager',
          //       name: 'distribution-usual-report-sailor-2',
          //       component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
          //     },
          //     {
          //       path: 'sailor/:manager/portal/:branch_office',
          //       name: 'distribution-usual-report-sailor-6',
          //       component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
          //     },
          //     {
          //       path: 'sailor/:manager/type-document/:type',
          //       name: 'distribution-usual-report-sailor-5',
          //       component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
          //     },
          //     {
          //       path: 'sailor/:medical',
          //       name: 'distribution-usual-report-sailor-3',
          //       component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
          //     },
          //     {
          //       path: 'sailor/:institution/course/:course',
          //       name: 'distribution-usual-report-sailor-4',
          //       component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
          //     },
          //     {
          //       path: 'usual/:typeDoc/qualification/:educational_institution',
          //       name: 'distribution-usual-report-qualification-2',
          //       props (route) {
          //         return { search: route.query.search }
          //       },
          //       component: () => import('@/views/Report/ReportDistribution/TableQualification.vue')
          //     },
          //     {
          //       path: 'usual/:typeDoc/qualification/:educational_institution/sailor/:level',
          //       name: 'distribution-usual-report-qualification-sailor-2',
          //       props (route) {
          //         return { search: route.query.search }
          //       },
          //       component: () => import('@/views/Report/ReportDistribution/ReportDistributionSailor.vue')
          //     },
          //     {
          //       path: 'sailor/institution/:educational_institution/qualification/:level',
          //       name: 'distribution-usual-report-sailor-7',
          //       component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
          //     }
          //   ]
          // },
          {
            path: 'usual',
            name: 'distribution-usual-report-crewing',
            props (route) {
              return { search: route.query.search }
            },
            component: () => import('@/views/Report/ReportDistribution/ReportDistributionCrewing.vue')
          },
          {
            path: 'usual/:typeDoc/crewing_group/:firstIdEntry',
            name: 'distribution-usual-report-crewing-group',
            props (route) {
              return { search: route.query.search }
            },
            component: () => import('@/views/Report/ReportDistribution/ReportDistributionCrewingGroup.vue')
          },
          {
            path: 'usual/:typeDoc/crewing/:firstIdEntry',
            name: 'distribution-usual-report-manager',
            props (route) {
              return { search: route.query.search }
            },
            component: () => import('@/views/Report/ReportDistribution/ReportDistributionManager.vue')
          },
          {
            path: 'usual/:typeDoc/document_type/:firstIdEntry',
            name: 'distribution-usual-report-document-type',
            props (route) {
              return { search: route.query.search }
            },
            component: () => import('@/views/Report/ReportDistribution/ReportDistributionDocType.vue')
          },
          {
            path: 'usual/:typeDoc/qualification/:educational_institution',
            name: 'distribution-usual-report-qualification',
            props (route) {
              return { search: route.query.search }
            },
            component: () => import('@/views/Report/ReportDistribution/ReportDistributionQualification.vue')
          },
          {
            path: 'usual/:typeDoc/qualification/:firstIdEntry/sailor/:secondIdEntry',
            name: 'distribution-usual-report-qualification-sailor',
            props (route) {
              return { search: route.query.search }
            },
            component: () => import('@/views/Report/ReportDistribution/ReportDistributionSailor.vue')
          },
          {
            path: 'usual/:typeDoc/crewing/:firstIdEntry/sailor/:secondIdEntry',
            name: 'distribution-usual-report-sailor',
            props (route) {
              return { search: route.query.search }
            },
            component: () => import('@/views/Report/ReportDistribution/ReportDistributionSailor.vue')
          },

          {
            path: 'nostrification/:typeDocument',
            name: 'distribution-nostrification',
            redirect: { name: 'distribution-nostrification-report-group' },
            component: () => import('@/views/Report/ReportDistribution/Nostrification/Nostrification.vue'),
            children: [
              {
                path: 'group',
                name: 'distribution-nostrification-report-group',
                component: () => import('@/views/Report/ReportDistribution/Nostrification/TableGroup.vue')
              },
              {
                path: 'crewing',
                name: 'distribution-nostrification-report-crewing',
                component: () => import('@/views/Report/ReportDistribution/Nostrification/TableCrewing.vue')
              },
              {
                path: 'crewing-manager',
                name: 'distribution-nostrification-report-crewing-manager',
                component: () => import('@/views/Report/ReportDistribution/Nostrification/TableCrewingManager.vue')
              },
              {
                path: 'sailor',
                name: 'distribution-nostrification-report-sailor',
                component: () => import('@/views/Report/ReportDistribution/Nostrification/TableSailor.vue')
              }
            ]
          }
        ]
      },

      {
        path: 'crewing-connect',
        name: 'crewing-connect-company-report',
        component: () => import('@/views/Report/ReportCrewingConnect/TableCompanies.vue')
      },
      {
        path: 'crewing-connect/company/:companyID/',
        name: 'crewing-connect-manager-report',
        component: () => import('@/views/Report/ReportCrewingConnect/TableManagers.vue')
      },
      {
        path: 'crewing-connect/company/:companyID/manager/:managerID/',
        name: 'crewing-connect-sailor-report',
        component: () => import('@/views/Report/ReportCrewingConnect/TableSailors.vue')
      },
      // Advance training distribution reports below
      {
        path: 'atc-distribution-institution',
        name: 'atc-distribution-institution-report',
        component: () => import('@/views/Report/AdvanceTrainingDistribution/TableInstitutions.vue')
      },
      {
        path: 'atc-distribution-institution/:institutionID/qualification/',
        name: 'atc-distribution-qualification-report',
        component: () => import('@/views/Report/AdvanceTrainingDistribution/TableQualifications.vue')
      },
      {
        path: 'atc-distribution-institution/:institutionID/qualification/:qualificationID/sailors/',
        name: 'atc-distribution-sailor-report',
        component: () => import('@/views/Report/AdvanceTrainingDistribution/TableSailors.vue')
      },
      // certificate for members of the sqc
      {
        path: 'certificate-for-members-of-the-sqc-report',
        name: 'certificate-for-members-of-the-sqc-report',
        component: () => import('@/views/Report/ReportCertificateForMembersOfTheSqc/Table.vue')
      },
      {
        path: 'service-record-book-report',
        name: 'service-record-book-report',
        component: () => import('@/views/Report/ReportServiceRecordBook/Table.vue')
      },
      {
        path: 'statement-connect-isc-report',
        name: 'statement-connect-isc-report',
        component: () => import('@/views/Report/ReportISC/Connect.vue')
      },
      {
        path: 'statement-disconnect-isc-report',
        name: 'statement-disconnect-isc-report',
        component: () => import('@/views/Report/ReportISC/Disconnect.vue')
      },
      // medical documents and statements
      {
        path: 'medical-report',
        name: 'medical-report',
        component: () => import('@/views/Report/ReportMedical/Report.vue')
      },
      // replacement statement qualification
      {
        path: 'replacement-qualification-report',
        name: 'replacement-qualification-report',
        component: () => import('@/views/Report/ReportReplacementStatementQualification/Table.vue')
      },
      // Crewing plus report connect with sailor
      {
        path: 'crewing-plus-connect-sailor-report',
        name: 'crewing-plus-connect-sailor-report',
        component: () => import('@/views/Report/ReportCrewignPlusConnectWithSailor/Table.vue')
      },
      // statement position isc
      {
        path: 'statement-position-isc-report',
        name: 'statement-position-isc-report',
        component: () => import('@/views/Report/ReportStatementPositionFromISC/Table.vue')
      },
      // powers of attorney
      {
        path: 'powers-of-attorney-report',
        name: 'powers-of-attorney-report',
        component: () => import('@/views/Report/PowersOfAttorney/Table.vue')
      },
      {
        path: 'nostrification-report',
        name: 'nostrification-report',
        component: () => import('@/views/Report/Nostrification/Table.vue')
      },
      {
        path: 'document-digitization-isc',
        name: 'document-digitization-isc',
        component: () => import('@/views/Report/DocumentDigitizationIsc/Table.vue')
      }
    ]
  }
]
