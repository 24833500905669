import api from '@/plugins/api'
import i18n from '@/plugins/localization/index'
import notify from '@ananasbear/notify'
import { getUpdatedObject, getProcessingStatus } from '@/mixins/api'
import { setRoles } from '@/mixins/permissions'
import { getDirectoryObject, setLinksByContentType, queryString, updateStatementObj } from '@/mixins/main'
import { sailor } from '@/configs/endpoints'
import { SUCCESS_CODE, rejectNewAccount, STATUSES_STATEMENT_CONSTANTS, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { statePassportSection, actionsPassportSection } from './sailor/sailor.passports'
import { stateEducationSection, actionsEducationSection } from './sailor/sailor.education'
import { stateETISection, actionsETISection } from './sailor/sailor.eti'
import { stateQualificationSection, actionsQualificationSection } from './sailor/sailor.qualification'
import { stateMedicalSection, actionsMedicalSection } from './sailor/sailor.medical'
import { stateSQCSection, actionsSQCSection } from './sailor/sailor.sqc'
import { stateExperienceSection, actionsExperienceSection } from './sailor/sailor.experience'

export const SAILOR = {
  state: () => ({
    ...statePassportSection,
    ...stateEducationSection,
    ...stateQualificationSection,
    ...stateETISection,
    ...stateMedicalSection,
    ...stateSQCSection,
    ...stateExperienceSection,
    sailorId: 0,
    rating: 0,
    sailorSQC: true,
    editableByAgent: false,
    agentInfo: false,
    sailorVerify: false,
    availablePositions: [],
    availableRank: [],
    // Sailor documents
    sailorInfo: {},
    sailorSQCStatement: [],
    positionStatement: [],
    backOfficeETIList: {},
    backOfficeCoefficient: [],
    backOfficeCoursePrice: [],
    backOfficeDealing: [],
    newCrewingManagers: [],
    newAccounts: {},
    statementsISC: [],
    newAccountsStatistics: [],
    approvedSailorPassportStatements: [],
    reasonCancelServiceRecordStatement: [],
    sailorDigitizationDocuments: [],
    sailorDigitizationStatements: [],
    // Menu items
    verificationDocumentPhotos: [],
    sailorSecretaryATC: null,
    sailorCrewingManager: null,
    sailorSecretaryMedical: null,
    errorsDetails: {},
    isLoading: false,
    statementISCById: {},
    sailors: [],
    newAccoutById: {},
    // PAKET STATEMENTS
    paketStatements: {},
    paketStatementById: {}
  }),
  mutations: {
    SET_STATE_SAILOR (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    },
    setStateData (state, data) {
      state[data.type] = []
      state[data.type] = data.data
    },
    UPLOAD_DATA_STATE (state, data) {
      Object.entries(data).forEach(([key, value]) => {
        state[key].results = state[key].results.map(el => {
          if (el.id === value.id) return value
          return el
        })
      })
    },
    SET_AVAILABLE_POSITIONS (state, data) {
      const availableRankArray = state.ranks.filter(value => {
        for (const rankId of data.rank) {
          if (rankId === value.id && !value.is_disable) return value
        }
      })

      const availablePositionsArray = state.positions?.filter(value => {
        for (const positionId of data.position) {
          if (positionId === value.id && !value.is_disable) return value
        }
      })

      state.availableRank = availableRankArray
      state.availablePositions = availablePositionsArray
    },
    ADD_SUCCESS_QUALIFICATION_STATEMENT (state, data) {
      let record = state.successQualificationStatement.find(record => record.id === data.id)
      if (!record) {
        data = updateStatementObj(data)
        state.successQualificationStatement.push(data)
      }
    },
    addDataSailor (state, data) {
      const updatedObject = getUpdatedObject(data)
      state[data.type].push(updatedObject.value)
    },
    updateDataSailor (state, data) {
      const updatedObject = getUpdatedObject(data)
      const newArray = state[updatedObject.type]?.filter(item => item.id !== updatedObject.value.id)
      newArray && newArray.push(updatedObject.value)
      state[updatedObject.type] = newArray
    },
    deleteDataSailor (state, data) {
      state[data.type] = state[data.type].filter(item => item.id !== data.value.id)
    },
    UPDATE_DATA_SAILOR (state, data) {
      if (state[data.type].some(item => item.id === data.value.id)) {
        const itemIndex = state[data.type].findIndex(item => item.id === data.value.id)
        const newArray = state[data.type].filter(item => item.id !== data.value.id)
        newArray.splice(itemIndex, 0, data.value)
        state[data.type] = newArray
      } else state[data.type].push(data.value)
    }

  },
  actions: {
    ...actionsPassportSection,
    ...actionsEducationSection,
    ...actionsQualificationSection,
    ...actionsETISection,
    ...actionsMedicalSection,
    ...actionsSQCSection,
    ...actionsExperienceSection,
    // -- MAIN SAILOR INFO --//
    async setNewSailorAccount (context, data) {
      const response = await api.post(sailor.newSailor(), { body: data.body })
      const formData = new FormData()
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('addedNewSailor')
        if (data.media.profile.file.length) {
          formData.append(data.media.profile.name, data.media.profile.file[0])
          const responseFileProfile = await api.post(sailor.uploadPhotoSailor(response.data.id), { body: formData, requestType: 'formData' })
          if (SUCCESS_CODE.includes(responseFileProfile.code)) return response
          else notify.error('errorAddFile')
        }
      }
      return response
    },
    async setSailorPhotoVerificationStatus (context, data) {
      const response = await api.patch(sailor.sailorPhotoById(data.sailorId, data.photoId), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success(i18n.t('verifiedSailorPhoto'))
        context.dispatch('getSailorInformation', data.sailorId)
      }
      return SUCCESS_CODE.includes(response.code)
    },
    async setSailorContact (context, data) {
      const response = await api.post(sailor.addContact(context.state.sailorId), { body: data.body })
      return response
    },
    async deleteSailorContact (context, data) {
      const response = await api.delete(sailor.deleteContact(context.state.sailorId, data.contactId))
      return response
    },
    async updateSailorMainInfo (context, data) {
      const response = await api.patch(sailor.sailor(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('changedInfo')
        context.dispatch('getSailorInformation', data.id)
        context.commit('setStateData', { type: 'errorsDetails', data: {} })
      }
      return SUCCESS_CODE.includes(response.code)
    },
    async setRegisterSailorAccount (context, data) {
      const response = await api.post(sailor.registration, { body: data.body })
      return response
    },
    async getTokenOpenSailorCabinet () {
      const response = await api.get(sailor.sailorCabinet)
      return response
    },
    async setSailorMainPhone ({ state, dispatch }, body) {
      const response = await api.post(sailor.changedMainPhone(state.sailorId), { body })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success(i18n.t('notify.success.mainPhoneNumberUpdating'))
        dispatch('getSailorInformation', state.sailorId)
      }
      return SUCCESS_CODE.includes(response.code)
    },
    async saveSailorPhoto (context, data) {
      const response = await api.post(sailor.sailorPhoto(data.id), { body: data.body, responseType: data.responseType })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success(i18n.t('changedInfo'))
        context.dispatch('getSailorInformation', data.id)
      }
      return response
    },
    async updateSailorPhoto (context, data) {
      const response = await api.patch(sailor.sailorPhotoById(data.id, data.photoId), { body: data.body, responseType: data.responseType })
      if (SUCCESS_CODE.includes(response.code)) {
        console.log('object :>> ', context.state.sailorInfo)
        if (!(context.state.sailorInfo.photos.some(el => el.status_document === 34))) context.dispatch('getSailorInformation', data.id)
      }
      return response
    },
    async deleteSailorPhotoById (context, data) {
      const response = await api.delete(sailor.sailorPhotoById(data.id, data.photoId))
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success(i18n.t('deletedDocument'))
        context.dispatch('getSailorInformation', data.id)
      } else notify.error(i18n.t('cantDeleteDoc'))
    },

    async getSailorInformation ({ commit }, id) {
      await api.get(sailor.sailor(id)).then(response => {
        if (response.code === 200) {
          commit('setStateData', { type: 'sailorVerify', data: response.data.can_verify })
          commit('setStateData', { type: 'agentInfo', data: response.data.has_agent })
          commit('setStateData', { type: 'sailorSQC', data: response.data.is_dkk })
          commit('setStateData', { type: 'editableByAgent', data: response.data.can_verify })
          setRoles()
          commit('setStateData', { type: 'sailorInfo', data: response.data })
        }
      })
    },

    async getRating ({ commit }, id) {
      let rating = await api.get(`api/v2/sailor/${id}/rating/`)
      commit('SET_STATE_SAILOR', { rating: rating.data.rating })
    },
    async getAvailablePositions ({ commit }, sailorId) {
      await api.get(`api/v2/sailor/${sailorId}/available_position/`).then(response => {
        if (response?.code === 200) commit('SET_AVAILABLE_POSITIONS', response.data)
      })
    },

    async setAccessSailorToIscFunctional ({ dispatch }, data) {
      const response = await api.post(sailor.accessSailorToIscFunctional(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        await dispatch('getSailorInformation', data.id)
        notify.success('notify.success.accessToMobileFunctionalWasChange')
      }
    },

    // -------------- STUDENT CARD --------------

    updateFilesStudentCard ({ state, dispatch }, data) {
      return api.post(`api/v1/cadets/student_id/${state.sailorId}/${data.document_id}/update_file/${data.photo_id}/`, {
        body: data.body, responseType: 'blob' })
        .then(response => {
          if (response?.code === 200) dispatch('getStudentCard')
          return response
        })
    },

    // -------------- POSITION STATEMENTS --------------
    getPositionStatements ({ state, commit }, id) {
      return api.get(`api/v1/back_off/packet/sailor/${id || state.sailorId}/`).then(response => {
        if (SUCCESS_CODE.includes(response?.code)) {
          response.data.map(item => {
            item.behavior = { viewInfoBlock: true }
            item.list_positions = item.position
            item.includeSailorPass = getProcessingStatus(item.include_sailor_passport)
          })
          commit('setStateData', { type: 'positionStatement', data: response.data })
        }
        return response
      })
    },
    // updateFilesPositionStatement ({ state, dispatch }, data) {
    //   return api.post(`api/v1/back_off/packet/${data.document_id}/update_file/${data.photo_id}/`,
    //     { body: data.body, responseType: 'blob' })
    //     .then(response => {
    //       if (response?.code === 200) dispatch('getPositionStatements')
    //       return response
    //     })
    // },
    // async uploadPositionStatementPaymentReceipt ({ dispatch }, data) {
    //   return api.post(sailor.positionStatementUploadReceipts(data.documentID, data.fileID), { body: data.body }).then(response => {
    //     if (response?.code === 201) dispatch('getPositionStatements')
    //     return response
    //   })
    // },
    // ----------------------------------------------------

    async getETICertificationInstitutions ({ commit }, params) {
      await api.get(sailor.ETICertificationInstitutions + queryString(params)).then(response => {
        if (SUCCESS_CODE.includes(response.code)) {
          response.data.results.map(item => {
            item.behavior = { viewInfoBlock: true }
          })
          commit('setStateData', { type: 'backOfficeETIList', data: response.data })
        }
      })
    },
    async getETICertificationInstitutionsById ({ commit }, data) {
      const response = await api.get(sailor.ETICertificationInstitutionsById(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        return response.data
      }
      return {}
    },
    async patchETICertificationInstitutionById ({ commit }, data) {
      const response = await api.patch(sailor.ETICertificationInstitutionsById(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        commit('UPLOAD_DATA_STATE', { backOfficeETIList: response.data })
      }
      return response
    },

    async getBecomingAgentStatements ({ commit }) {
      await api.get('api/v1/crewing/statement_crew_manager/').then(response => {
        if (SUCCESS_CODE.includes(response?.code)) {
          response.data.map(item => {
            item.behavior = { viewInfoBlock: true }
          })
          commit('setStateData', { type: 'newCrewingManagers', data: response.data })
        }
      })
    },
    async updateBecomingAgentStatement (context, data) {
      const response = await api.patch(sailor.crew.statementByid(data.id), { body: data.body })
      return response
    },

    getStatementISC (context, data) {
      return api.get(sailor.statementISCById(data.id) + queryString(data?.params))
    },
    async getStatementISCById ({ state, commit, dispatch }, data) {
      commit('SET_STATE_SAILOR', { isLoading: true })
      const response = await api.get(sailor.statementISCById(data?.id))
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.behavior = { viewInfoBlock: true }
        if ([STATUSES_STATEMENT_CONSTANTS.NEW_ACCOUNT_SAILOR.ISC.REJECT].includes(response.data.status_document)) {
          const responseComment = await dispatch('getStatementISCComments', data)
          if (SUCCESS_CODE.includes(responseComment?.code)) response.data.comments = responseComment.data
        }
        commit('SET_STATE_SAILOR', { statementISCById: response.data })
      }
      commit('SET_STATE_SAILOR', { isLoading: false })
      return response
    },
    checkForeignPassport (context, id) {
      return api.get(sailor.checkForeignPassport(id))
    },
    async getStatementISCList ({ commit }, params) {
      const response = await api.get(sailor.statementISC + queryString(params))
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.results.map(item => (
          item.userFullName = `${item.last_name} ${item.first_name} ${item.middle_name || ''}`)
        )
        commit('setStateData', { type: 'statementsISC', data: response.data })
      }
      return response
    },
    async updateStatementISCById ({ commit, dispatch, state }, data) {
      commit('SET_STATE_SAILOR', { statementISCById: {}, isLoading: true })
      const response = await api.patch(sailor.statementISCById(data?.id), { body: data.body })
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.behavior = { viewInfoBlock: true }
        if ([STATUSES_STATEMENT_CONSTANTS.NEW_ACCOUNT_SAILOR.ISC.REJECT].includes(response.data.status_document)) {
          const responseComment = await dispatch('setStatementNewAccountISCByIdCommnets', data)
          if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = [responseComment.data]
        } else notify.success('notify.title.success')
        commit('SET_STATE_SAILOR', { statementISCById: response.data })
      }
      commit('SET_STATE_SAILOR', { isLoading: false })
      return response
    },
    async setStatementNewAccountISCByIdCommnets ({ dispatch }, data) {
      const response = await api.post(sailor.statementISCByIdComment(data.id), { body: data.comments })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('notify.success.statementRejected')
        dispatch('getStatementISCById', data)
        return response
      }
    },
    async getStatementISCComments (context, data) {
      const response = await api.get(sailor.statementISCByIdComment(data.id))
      return response
    },
    async getListNewAccounts ({ commit }, params) {
      await api.get(sailor.newAccount() + queryString(params)).then(response => {
        if (SUCCESS_CODE.includes(response.code)) {
          response.data.results.map(item => {
            item.behavior = { viewInfoBlock: true }
            item.sailorDateBirth = null
          })
          commit('setStateData', { type: 'newAccounts', data: response.data })
        }
      })
    },
    async getNewAccountsById ({ commit, dispatch }, data) {
      commit('SET_STATE_SAILOR', { newAccoutById: {} })
      const response = await api.get(sailor.newAccount(data.documentID))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        response.data.sailorDateBirth = null
        if ([response.data.status_document, response.data.status_document.id].includes(rejectNewAccount)) {
          const responseComment = await dispatch('getRejectNewAccountById', { documentID: response.data.id })
          response.data.comments = responseComment
        }
        commit('SET_STATE_SAILOR', { newAccoutById: response.data })
        return response.data
      }
      return {}
    },
    async updateNewAccountsById ({ commit, dispatch }, data) {
      commit('SET_STATE_SAILOR', { newAccoutById: {} })
      const response = await api.patch(sailor.newAccount(data.documentID), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        response.data.sailorDateBirth = null
        if ([response.data.status_document, response.data.status_document.id].includes(rejectNewAccount)) {
          const responseComment = await dispatch('getRejectNewAccountById', { documentID: response.data.id })
          response.data.comments = responseComment
        }
      }
      commit('SET_STATE_SAILOR', { newAccoutById: response.data })
      return {}
    },
    async setRejectNewAccountById (context, data) {
      const response = await api.post(sailor.rejectNewAccount(data.documentID), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        context.dispatch('updateNewAccountsById', { documentID: data.documentID, body: { status_document: data.status_document } })
        notify.success('rejectedStatement')
        return response.data
      }
      return []
    },
    async getRejectNewAccountById (context, data) {
      const response = await api.get(sailor.rejectNewAccount(data.documentID))
      if (SUCCESS_CODE.includes(response.code)) return response.data
      return []
    },
    async getShortInfoSailor (context, data) {
      const response = await api.get(sailor.shortInfo(data.sailorId))
      if (SUCCESS_CODE.includes(response.code)) return response.data
      return {}
    },

    async getApprovedSailorPassportStatements ({ commit }, data) {
      const response = await api.get(sailor.passports.sailorPassportStatement.success(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.map(item => {
          item.fullName = `${item.number} - ${item.port ? item.port.name_ukr : item.other_port}`
        })
        commit('SET_STATE_SAILOR', { approvedSailorPassportStatements: response.data })
      }
    },

    // -------------- NAVIGATOR CERTIFICATES --------------
    updateFilesNavigatorCertificates ({ state, dispatch }, data) {
      return api.post(`api/v2/sailor/${state.sailorId}/certificate_of_navigator/${data.document_id}/update_file/${data.photo_id}/`, {
        body: data.body, responseType: 'blob' })
        .then(response => {
          if (response?.code === 200) dispatch('getNavigatorCertificates')
          return response
        })
    },

    // -------------- VERIFICATION DOCUMENT PHOTOS --------------

    getVerificationDocumentPhotoDocument ({ commit }, id) {
      return api.get(`api/v1/reports/existing_document/${id}/`).then(response => {
        if (response.code === 200) {
          response.data.behavior = { viewInfoBlock: true }
          response.data.type_document = getDirectoryObject({ id: response.data.type_document, value: 'allDocumentTypes' })
          commit('UPDATE_DATA_SAILOR', { type: 'verificationDocumentPhotos', value: response.data })
        }
        return response
      })
    },
    updateFilesVerificationDocumentPhotoDocument ({ dispatch }, data) {
      return api.post(`api/v1/reports/existing_document/${data.document_id}/update_file/${data.photo_id}/`, { body: data.body, responseType: 'blob' })
        .then(response => {
          if (response?.code === 200) dispatch('getVerificationDocumentPhotoDocument', data.document_id)
          return response
        })
    },

    // -------------- CITIZEN(CIVIL) PASSPORTS --------------
    async updateFilesCitizenPassport ({ state, dispatch }, data) {
      const response = await api.post(sailor.passports.citizen.updateFile(data?.id || state.sailoId, data.document_id, data.photo_id), {
        body: data.body, responseType: 'blob' })
      if (SUCCESS_CODE.includes(response?.code)) dispatch('getSailorCitizenPassports', { params: { page: 1, page_size: 10 } })
      return response
    },
    // --------------DIGITIZATION DOCUMENTS-------------- //

    async getSailorDigitizationDocuments ({ state, commit }, data) { // Sailor digitization documents
      commit('SET_STATE_SAILOR', { isLoading: true })
      const response = await api.get(sailor.digitization.documents.list(data?.id || state.sailoId) + queryString(data.params))
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.results.map(item => {
          item.link = setLinksByContentType(item)
        })
        commit('SET_STATE_SAILOR', { sailorDigitizationDocuments: response.data })
      }
      commit('SET_STATE_SAILOR', { isLoading: false })
      return response
    },
    async getSailorDigitizationStatements ({ state, commit }, data) { // Sailor digitization statements
      commit('SET_STATE_SAILOR', { isLoading: true })
      const response = await api.get(sailor.digitization.statements.list(data?.id || state.sailoId) + queryString(data.params))
      if (SUCCESS_CODE.includes(response?.code)) {
        response.data.results.map(item => {
          item.link = setLinksByContentType(item)
        })
        commit('SET_STATE_SAILOR', { sailorDigitizationStatements: response.data })
      }
      commit('SET_STATE_SAILOR', { isLoading: false })
      return response
    },
    getCrewingAndSecretaryATC ({ state, commit }, id) {
      return api.get(`api/v2/sailor/${id || state.sailorId}/crewing_secretary/`).then(response => {
        if (response?.code === 200) {
          const crewingManager = response.data.find(item => item.manager_type === 0)
          commit('setStateData', { type: 'sailorCrewingManager', data: crewingManager || null })

          const secretaryATC = response.data.find(item => item.manager_type === 1)
          commit('setStateData', { type: 'sailorSecretaryATC', data: secretaryATC || null })
          const secretaryMedical = response.data.find(item => item.manager_type === 2)
          commit('setStateData', { type: 'sailorSecretaryMedical', data: secretaryMedical || null })
        }
        return response
      })
    },
    setSailorOrSecretaryConnection ({ state }, data) {
      return api.post(`api/v2/sailor/${data.id || state.sailorId}/crewing_secretary/`, { body: data.body }).then(response => {
        return response
      })
    },
    deactivateSailorOrSecretaryConnection ({ state }, id) {
      return api.delete(`api/v2/sailor/${state.sailorId}/crewing_secretary/${id}/`).then(response => {
        return response
      })
    },
    getCrewingAndSecretaryHistory ({ state, commit }, id) {
      return api.get(`api/v2/sailor/${id || state.sailorId}/manager_history/`).then(response => {
        if (response?.code === 200) response.data.sort((a, b) => a.manager_type > b.manager_type ? 1 : -1)
        return response
      })
    },
    async getLinkForSailorExam (context, data) {
      const response = await api.get(sailor.linkExam(data.statementId))
      if (SUCCESS_CODE.includes(response.code)) {
        return response
      }
      return ''
    },
    async getTokenAndIdExamEti (context, data) {
      const response = await api.get(sailor.getExamTokenAndId(data.id || context.state.sailorId, data.documentID))
      if (SUCCESS_CODE.includes(response.code)) {
        let a = document.createElement('a')
        a.href = `https://${data.domain}/login/?token=${response.data.ntz_token}&exam_id=${response.data.exam_id}`
        // a.href = `http://${'192.168.106.101:8081'}/login/?token=${response.data.ntz_token}&exam_id=${response.data.exam_id}` // local cabinet
        a.target = '_blank'
        a.click()
      }
    },
    async getDiplomas ({ commit }, id) {
      if (!id) return
      await api.get(`api/v2/sailor/${id}/qualification/diploma_for_proof/`).then(response => {
        response.data.map(value => {
          value.name_ukr = `${value.number} ${value.rank.name_ukr} (${value.status_document.name_ukr})`
          value.name_eng = `${value.number} ${value.rank.name_eng} (${value.status_document.name_eng})`
          value.rank_id = value.rank.id
        })
        commit('setStateData', {
          type: 'diplomas',
          data: response.data
        })
      })
    },

    async setSailorPositionFileCertificateETI ({ state, dispatch }, data) {
      data.url = `api/v1/sailor/photo_uploader/`
      const response = await dispatch('setUploadFile', data)
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('notify.success.AddCopyDocument')
        dispatch('getPositionStatements')
      }
      return SUCCESS_CODE.includes(response.code)
    },
    async getListSailor ({ commit }, body) {
      const response = await api.post(sailor.search, { body })
      if (SUCCESS_CODE.includes(response.code)) {
        response.data = response.data.map(item => {
          item.sailor_full_name = `${item.last_name_ukr} ${item.first_name_ukr} ${item.middle_name_ukr}`
          return item
        })
        commit('SET_STATE_SAILOR', { sailors: response.data })
      }
    },
    // PACKET STATEMENT V2
    async getPacketStatement ({ commit }, data) {
      commit('SET_STATE_SAILOR', { isLoading: true })
      const response = await api.get(sailor.packet.list(data.id) + queryString(data?.params))
      if (SUCCESS_CODE.includes(response.code)) {
        commit('SET_STATE_SAILOR', { paketStatements: response.data })
      }
      commit('SET_STATE_SAILOR', { isLoading: false })
    },
    async getPacketStatementById ({ commit, state }, data) {
      commit('SET_STATE_SAILOR', { isLoading: true, paketStatementById: {} })
      const response = await api.get(sailor.packet.list(data?.id || state.sailorId, data.documentID))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        commit('SET_STATE_SAILOR', { paketStatementById: response.data })
      }
      commit('SET_STATE_SAILOR', { isLoading: false })
    },
    async deletePaketStatementById ({ commit, dispatch, state }, data) {
      const response = await api.delete(sailor.packet.list(data?.id || state.sailorId, data.documentID))
      if (SUCCESS_CODE.includes(response.code)) {
        if (response.data.status === STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.CITIZEN.DELETED) {
          commit('SET_STATE_SAILOR', { paketStatementById: response.data })
        } else dispatch('getPacketStatementById', { params: { page: 1, page_size: 10 } })
        commit('decrementBadgeCount', {
          child: 'positionStatement',
          parent: ''
        })
        notify.success('deletedDocument')
      }
      return response
    },
    async getIsContinue (context, data) {
      const response = await api.post(sailor.packet.checkIsContinue(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        return response.data.is_continue
      }
      return false
    },
    async previewPaketStatement (context, data) {
      const response = await api.post(sailor.packet.preview(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) return response
      return {}
    },
    async createPaketStatement ({ commit, dispatch }, data) {
      const response = await api.post(sailor.packet.list(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        await dispatch('getPacketStatement', { ...data, params: { page: 1, page_size: 10 } })
        commit('SET_DATA_STATE_DIRECTORY', { filteredETI: {} })
        commit('incrementBadgeCount', {
          child: 'positionStatement',
          parent: ''
        })
        notify.success('sailorPositionStatementAdded')
      }
      return response
    },
    async updatePaketStatement (context, data) {
      const response = await api.patch(sailor.packet.list(data.id, data.documentID), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('sailorPositionStatementEdited')
      }
      return response
    }
  },
  getters: {
    sailorID (state) {
      return state.sailorId
    },
    diplomasForQualificationDocs: state => state.diplomas.filter(record => [1, 4, 5, 49].includes(record.type_document)),
    availableDiplomasByRank: state => rank => {
      if (rank) return state.diplomas.filter(diploma => diploma.rank.id === rank && (!diploma.is_type_doc_disabled || diploma.type_document.id === 1))
      else return []
    },
    validSailorPassportProcessing: state => state.sailorPassportProcessing.filter(choice => choice.id === 2),
    availablePositionsById: state => id => state.availablePositions.filter(position => position.rank === id),
    sailorDocumentByID: state => document => state[document.type].find(value => value.id === document.id),
    protocolsByRank: state => id => state.successProtocols.filter(protocol => protocol.rank.id === id),
    sailorIsCadet: state => state.student.filter(student => student.status_document.id === 55).length
  }
}
