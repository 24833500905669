import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main/Main.vue'
import { checkAccess } from '@/mixins/permissions'

import SailorRoutes from './sailor.routes'
import BackOfficeRoutes from './backoffice.routes.js'
import StatementRoutes from './statement.routes'
import VerificationRoutes from './verification.routes'
import CrewingRoutes from './crewing.routes'
import StatisticsRouter from './statisticsVerification.router'
import BackOfficePriceRoute from './backoffice.price.routes'
import ReportRoute from './report.routes'
import HistoryRoute from './history.routes'
import DocumetnRoute from './document.routes'
Vue.use(VueRouter)

const routes = [
  ...SailorRoutes,
  ...BackOfficeRoutes,
  ...StatementRoutes,
  ...VerificationRoutes,
  ...CrewingRoutes,
  ...StatisticsRouter,
  ...BackOfficePriceRoute,
  ...ReportRoute,
  ...HistoryRoute,
  ...DocumetnRoute,

  { path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/PageNotFound.vue')
  },
  {
    path: '/',
    name: 'home',
    component: Main
  },
  {
    path: '/registration',
    name: 'register',
    component: () => import('@/views/UserRegistration/UserRegistration.vue')
  },

  {
    path: '/login',
    name: 'authorization',
    component: () => import('@/views/Authorization/Login.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },
  {
    path: '/license-agreement',
    name: 'licenseAgreement',
    component: () => import('@/views/LicenseAgreement.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/Terms.vue')
  },
  {
    path: '/new-sailor',
    name: 'new-sailor',
    component: () => import('@/views/AddSailor/AddSailor.vue')
  },
  {
    path: '/directory',
    name: 'directory',
    component: () => import('@/views/Directory/Directory.vue'),
    children: [
      {
        path: '/directory/address',
        name: 'directory-address',
        component: () => import('@/views/Directory/DirectoryAddress/DirectoryAddress.vue')
      }
    ]
  },

  {
    path: '/new-accounts',
    name: 'new-accounts',
    component: () => import('@/views/NewAccounts/NewAccounts.vue')
  },
  {
    path: '/new-accounts/:documentID',
    name: 'new-accounts-info',
    component: () => import('@/views/NewAccounts/NewAccountsDocument.vue')
  },

  {
    path: '/documents-for-signing',
    name: 'documents-for-signing',
    component: () => import('@/views/Documents/DocumentsForSigning.vue')
  },
  {
    path: '/user-notification',
    name: 'user-notification',
    component: () => import('@/views/UserNotification/UserNotification.vue')
  },
  {
    path: '/documents-merging',
    name: 'documents-merging',
    props: true,
    component: () => import('@/components/molecules/DocumentsMerging.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings/Settings.vue'),
    children: [
      {
        path: 'user-setting',
        name: 'user-setting',
        component: () => import('@/views/Settings/SettingsUser.vue')
      },
      {
        path: 'sign-key',
        name: 'sign-key',
        component: () => import('@/components/atoms/AddSignature')
      }
      // {
      //   path: 'global-config-system',
      //   name: 'global-config-system',
      //   component: () => import('@/views/Settings/GlobalSystemSettings')
      // }
    ]
  },
  {
    path: '/account-management/',
    name: 'account-management',
    component: () => import('@/views/AccountManagement/AccountManagement.vue')
  },
  {
    path: '/account-management/:documentID/',
    name: 'account-management-info',
    component: () => import('@/views/AccountManagement/AccountManagementDocument.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/Authorization/PasswordRecaveryForm')
  },
  {
    path: '/reset-password-by-token',
    name: 'reset-password-by-token',
    component: () => import('@/views/Authorization/ChangePasswordByToken')
  },
  {
    path: '/create-password-by-token',
    name: 'create-password-by-token',
    component: () => import('@/views/Authorization/ChangePasswordByToken')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'verification') {
    next(checkAccess('verifierBackOfficeFirst')
      ? { name: 'sailor-passport-verification' } : { name: 'education-verification' })
  } else if (to.name === 'settings') {
    next(checkAccess('backOffice') ? { name: 'sign-key' } : { name: 'change-password' })
  } else next()
})
export default router
