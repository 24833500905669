import api from '@/plugins/api'
import { sailor } from '@/configs/endpoints'
import { SUCCESS_CODE, STATUSES_STATEMENT_CONSTANTS, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { queryString, getFiles, FromObjectToFormData, removeFileFromObject } from '@/mixins/main'
import notify from '@ananasbear/notify'
export const stateQualificationSection = {
  // --- QUALIFICATION REPLACEMENT STATEMENT --- //
  replacementStatements: {},
  replacementStatementById: {},
  // --- QUALIFICATION STATEMENTS --- //
  successQualificationStatement: [],
  qualificationStatement: {},
  qualificationStatementById: {},
  // --- NAVIGATOR CERTIFICATES --- //
  navigatorCertificates: [],
  navigatorCertificateById: {},
  // --- QUALIFICATION DOCUMENTS --- //
  diplomas: [],
  qualification: {},
  qualificationById: {}
}

export const actionsQualificationSection = {
  // --- QUALIFICATION REPLACEMENT STATEMENT --- //
  async getReplacementStatementsSailor ({ commit, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.qualification.replacementStatements.list(data?.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_SAILOR', { replacementStatements: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
  },
  async getReplacementStatementSailorById ({ commit, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true, replacementStatementById: {} })
    const response = await api.get(sailor.qualification.replacementStatements.list(data?.id || state.sailorId, data.statementId))
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }
      commit('SET_STATE_SAILOR', { replacementStatementById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
  },
  async setStatementReplaceQualification ({ state }, data) {
    const response = await api.post(sailor.qualification.replacementStatements.replaceQual(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('statement-replacement-documents-send')
      return response
    }
  },
  async setStatementReplaceProof ({ state }, data) {
    const response = await api.post(sailor.qualification.replacementStatements.replaceProof(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('statement-replacement-documents-send')
      return SUCCESS_CODE.includes(response.code)
    }
  },

  // -------------- QUALIFICATION STATEMENTS --------------

  async getSuccessQualificationStatement ({ commit, state }, data) {
    const response = await api.get(sailor.qualification.statement.success(data?.id || state.sailorId))
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.map(value => {
        return updateStatementObj(value)
      })
    }
    commit('SET_STATE_SAILOR', { successQualificationStatement: response.data })
  },
  async getQualificationStatements ({ state, commit }, data) {
    const response = await api.get(sailor.qualification.statement.list(data.id) + queryString(data.params))
    if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_SAILOR', { qualificationStatement: response.data })
  },
  async getQualificationStatementById ({ state, commit, dispatch }, data) {
    const response = await api.get(sailor.qualification.statement.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.STATEMENT.REJECTED) {
        const responseComment = await dispatch('getQualificationStatementComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) {
          response.data.comments = responseComment.data
        }
      }
      commit('SET_STATE_SAILOR', { qualificationStatementById: response.data })
    }
    return response
  },
  async getQualificationStatementComments ({ state }, data) {
    const response = await api.get(sailor.qualification.statement.comment(data?.id || state.sailorId, data.documentID))
    return response
  },
  async removeQualificationStatementById ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.qualification.statement.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.STATEMENT.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { qualificationStatementById: response.data })
      } else {
        dispatch('getProtocolsForQualification', data.id)
        commit('decrementBadgeCount', { child: 'qualificationStatement', parent: 'qualificationAll' })
        dispatch('getQualificationStatements', { ...data, params: { page: 1, page_size: 10 } })
      }
    }
    return response
  },
  async attachNewDocumentsToStatementQualification ({ state, dispatch }, data) {
    const response = await api.post(sailor.qualification.statement.attachDocuments(state.sailorId, data.id), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) dispatch('getQualificationStatementById', { documentID: data.id })
    return response
  },
  async replaceDocumentsInStatementQualification ({ state, dispatch }, data) {
    const response = await api.patch(sailor.qualification.statement.replaceDocuments(state.sailorId, data.id), { body: data.body })
    if (SUCCESS_CODE.includes(response?.code)) dispatch('getQualificationStatementById', { documentID: data.id })
    return response
  },
  async setQualificationStatement ({ dispatch, state }, data) {
    const response = await api.post(sailor.qualification.statement.list(data?.id || state?.sailorId),
      { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.qualification.statement.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      await dispatch('getQualificationStatements', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async updateQualificationStatement ({ commit, dispatch, state }, data) {
    const response = await api.patch(sailor.qualification.statement.list(data?.id || state?.sailorId, data.documentID),
      { body: data.body })

    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.qualification.statement.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }

      if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.STATEMENT.REJECTED) {
        const responseComment = data?.comments
          ? await dispatch('setQualificationStatementComments', data)
          : await dispatch('getQualificationStatementComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) {
          response.data.comments = responseComment.data
        }
      }

      if (response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.STATEMENT.APPROVED) {
        commit('ADD_SUCCESS_QUALIFICATION_STATEMENT', response.data)
      }
      dispatch('getQualificationStatementById', data)
    }
    return response
  },
  async setQualificationStatementComments ({ state }, data) {
    const response = await api.post(sailor.qualification.statement.comment(data.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('documentReject')
      response.data = [{ ...response.data }]
    }
    return response
  },
  async saveQualificationDocument (context, data) {
    const response = await api.post(sailor.qualification.statement.authForSaveDocument, { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      getFiles(sailor.qualification.statement.generateDocument(response.data.token), data.number, data.type)
    }
  },
  async removeFileQualificationStatement ({ commit, state }, data) {
    const response = await api.delete(sailor.qualification.statement.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.qualificationStatementById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { qualificationStatementById: newData })
    return response
  },
  async updateFileQualificationStatement ({ commit }, data) {
    const response = await api.patch(sailor.qualification.statement.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  // -------------- NAVIGATOR CERTIFICATES --------------
  async getNavigatorCertificates ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.qualification.certificateOfNavigator.list(data?.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_SAILOR', { navigatorCertificates: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getNavigatorCertificateById ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.qualification.certificateOfNavigator.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      commit('SET_STATE_SAILOR', { navigatorCertificateById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async setNavigatorCertificates ({ commit, state, dispatch }, data) {
    const response = await api.post(sailor.qualification.certificateOfNavigator.list(data?.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.qualification.certificateOfNavigator.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      commit('incrementBadgeCount', { child: 'navigatorCertificate', parent: 'qualificationAll' })
      await dispatch('getNavigatorCertificates', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async updateNavigatorCertificates ({ state, dispatch }, data) {
    const response = await api.patch(sailor.qualification.certificateOfNavigator.list(data?.id || state?.sailorId, data.documentID),
      { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.qualification.certificateOfNavigator.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      dispatch('getNavigatorCertificateById', data)
    }
    return response
  },
  async removeNavigatorCertificates ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.qualification.certificateOfNavigator.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.CERTIFICATE_OF_NAVIGATOR.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { qualificationStatementById: response.data })
        commit('decrementBadgeCount', {
          child: 'navigatorCertificate',
          parent: 'qualificationAll'
        })
      } else dispatch('getNavigatorCertificates', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async removeFileNavigatorCertificates ({ commit, state }, data) {
    const response = await api.delete(sailor.qualification.certificateOfNavigator.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.navigatorCertificateById }
    newData.photo = removeFileFromObject(newData.photo, data.fileId)
    commit('SET_STATE_SAILOR', { navigatorCertificateById: newData })
    return response
  },
  async updateFileNavigatorCertificates ({ commit }, data) {
    const response = await api.patch(sailor.qualification.certificateOfNavigator.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  // -------------- QUALIFICATION DOCUMENTS --------------

  async getQualifications ({ state }, data) {
    const response = await api.get(sailor.qualification.documents.qualification.list(data?.id || state.sailorId) + queryString(data.params))
    return response
  },

  async getQualificationDiplomas ({ state, commit }, data) {
    const response = await api.get(sailor.qualification.documents.diplomaForProof(data?.id || state.sailorId))
    if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_SAILOR', { diplomas: response.data })
  },
  async getQualificationDocuments ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.qualification.documents.qualification.list(data?.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.results = response.data.results.reduce((acc, cur) => {
        acc.push(cur)
        if (cur.proofs_of_diploma?.length) acc.push(...cur.proofs_of_diploma)
        return acc
      }, [])
      response.data.results.map(item => {
        item.link = item.type_document === 16 ? 'qualification-documents-proof-info' : 'qualification-documents-info'
        item.allowMerge = response.data.results.filter(val => val.number === item.number).length > 1
      })
      commit('SET_STATE_SAILOR', { qualification: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getQualificationDocumentsByID ({ state, commit, dispatch }, data) {
    const response = await api.get(sailor.qualification.documents.qualification.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.REJECTED) {
        const responseComment = await dispatch('getQualificationDocumentComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) {
          response.data.comments = responseComment.data
        }
      }
      commit('SET_STATE_SAILOR', { qualificationById: response.data })
    }
    return response
  },
  async getQualificationDocumentComments ({ state }, data) {
    const response = await api.get(sailor.qualification.documents.qualification.comment(data?.id || state.sailorId, data.documentID))
    return response
  },
  async setQualificationDocuments ({ commit, state, dispatch }, data) {
    const response = await api.post(sailor.qualification.documents.qualification.list(data?.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.qualification.documents.qualification.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      commit('incrementBadgeCount', { child: 'qualificationDocument', parent: 'qualificationAll' })
      await dispatch('getSuccessQualificationStatement', { ...data, params: { page: 1, page_size: 10 } })
      await dispatch('getQualificationDocuments', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async updateQualificationDocuments ({ state, commit, dispatch }, data) {
    const response = await api.patch(sailor.qualification.documents.qualification.list(data?.id || state?.sailorId, data.documentID),
      { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.qualification.documents.qualification.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.REJECTED) {
        const responseComment = data?.comments
          ? await dispatch('setQualificationDocumentComments', data)
          : await dispatch('getQualificationDocumentComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) {
          response.data.comments = responseComment.data
        }
      }
      dispatch('getQualificationDocumentsByID', { ...data })
    }
    return response
  },
  async removeQualificationDocuments ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.qualification.documents.qualification.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { qualificationStatementById: response.data })
        commit('decrementBadgeCount', {
          child: 'qualificationDocument',
          parent: 'qualificationAll'
        })
      } else {
        dispatch('getSuccessQualificationStatement', { ...data, params: { page: 1, page_size: 10 } })
        dispatch('getQualificationDocuments', { ...data, params: { page: 1, page_size: 10 } })
      }
    }
    return response
  },
  async setQualificationDocumentComments ({ state }, data) {
    const response = await api.post(sailor.qualification.documents.qualification.comment(data.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('documentReject')
      response.data = [{ ...response.data }]
    }
    return response
  },
  async removeFileQualification ({ commit, state }, data) {
    const response = await api.delete(sailor.qualification.documents.qualification.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.qualificationById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { qualificationById: newData })
    return response
  },
  async updateFileQualification ({ commit }, data) {
    const response = await api.patch(sailor.qualification.documents.qualification.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  // ------PROOF ----- //

  async getQualificationDiplomaProofs ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(sailor.qualification.documents.proof.list(data?.id || state.sailorId) + queryString(data.params))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.results = response.data.results.reduce((acc, cur) => {
        acc.push(cur)
        if (cur.proofs_of_diploma?.length) acc.push(...cur.proofs_of_diploma)
        return acc
      }, [])
      response.data.results.map(item => {
        item.link = item.type_document === 16 ? 'qualification-documents-proof-info' : 'qualification-documents-info'
        item.allowMerge = response.data.results.filter(val => val.number === item.number).length > 1
      })
      commit('SET_STATE_SAILOR', { qualification: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getQualificationDiplomaProofByID ({ state, commit, dispatch }, data) {
    const response = await api.get(sailor.qualification.documents.proof.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.REJECTED) {
        const responseComment = await dispatch('getQualificationDiplomaProofComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) {
          response.data.comments = responseComment.data
        }
      }
      commit('SET_STATE_SAILOR', { qualificationById: response.data })
    }
    return response
  },

  async setQualificationDiplomaProof ({ commit, state, dispatch }, data) {
    const response = await api.post(sailor.qualification.documents.proof.list(data?.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.qualification.documents.proof.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      commit('incrementBadgeCount', { child: 'qualificationDocument', parent: 'qualificationAll' })
      await dispatch('getQualificationDocuments', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async updateQualificationDiplomaProof ({ state, commit, dispatch }, data) {
    const response = await api.patch(sailor.qualification.documents.proof.list(data?.id || state?.sailorId, data.documentID),
      { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.qualification.documents.proof.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.REJECTED) {
        const responseComment = data?.comments
          ? await dispatch('setQualificationDiplomaProofComments', data)
          : await dispatch('getQualificationDiplomaProofComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) {
          response.data.comments = responseComment.data
        }
      }
      commit('SET_STATE_SAILOR', { qualificationById: response.data })
    }
    return response
  },
  async removeQualificationDiplomaProof ({ state, commit, dispatch }, data) {
    const response = await api.delete(sailor.qualification.documents.proof.list(data?.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { qualificationStatementById: response.data })
        commit('decrementBadgeCount', {
          child: 'qualificationDocument',
          parent: 'qualificationAll'
        })
      } else dispatch('getQualificationDocuments', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async getQualificationDiplomaProofComments ({ state }, data) {
    const response = await api.get(sailor.qualification.documents.proof.comment(data?.id || state.sailorId, data.documentID))
    return response
  },
  async setQualificationDiplomaProofComments ({ state }, data) {
    const response = await api.post(sailor.qualification.documents.proof.comment(data.id || state.sailorId, data.documentID), { body: data.comments })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('documentReject')
      response.data = [{ ...response.data }]
    }
    return response
  },
  async removeFileProof ({ commit, state }, data) {
    const response = await api.delete(sailor.qualification.documents.proof.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.qualificationById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { qualificationById: newData })
    return response
  },
  async updateFileProof ({ commit }, data) {
    const response = await api.patch(sailor.qualification.documents.proof.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  // -------- SAVE DOCUMENTS --------//
  async getDocumentForSave (context, data) {
    const response = await api.post(sailor.qualification.documents[data.link], { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      getFiles(response.data.url, data.number)
    } else notify.error('uploadPhotoToDocument')
  },
  async getArchiveDocument (context, data) {
    const response = await api.get(sailor.qualification.documents[data.typeDocument].documentArchive(data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      getFiles(response.data.url, data.fileName, data.contentType)
    }
  },
  async getDocumentWithWotermark (context, data) {
    const response = await api.get(sailor.qualification.documents[data.typeDocument].documentWatermark(data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      getFiles(response.data.url, data.fileName, data.contentType)
    }
  },
  async getDocumentWithWotermarkWithSignature (context, data) {
    const response = await api.get(sailor.qualification.documents[data.typeDocument].documentWithoutSignature(data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      getFiles(response.data.url, data.fileName, data.contentType)
    }
  },
  async setSignQualificationDocument (context, data) {
    const response = await api.post(sailor.qualification.documents[data.typeDocument].documentSignature, { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) notify.success('signed')
  }

}
const updateStatementObj = (data) => {
  let positionUrk = []
  let positionEng = []
  data.list_positions.map(value => {
    positionUrk.push(value.name_ukr)
    positionEng.push(value.name_eng)
  })
  data.rank_id = data.rank.id
  data.name_ukr = `${data.number_document || data.number} ${positionUrk.join(', ')}`
  data.name_eng = `${data.number_document || data.number} ${positionEng.join(', ')}`

  if (data.hasOwnProperty('position')) {
    data.positionId = data.position.id
  }

  return data
}
