export default [
  {
    path: 'usual-2/:typeDocument',
    name: 'distribution-usual',
    redirect: { name: 'distribution-usual-report-crewing-table' },
    component: () => import('@/views/Report/ReportDistribution/DistributionAllTypeDocument/DistributionAllTypeDocument.vue'),
    children: [
      {
        path: 'crewing',
        name: 'distribution-usual-report-crewing-table',
        component: () => import('@/views/Report/ReportDistribution/TableCrewing.vue')
      },
      {
        path: 'crewing/:crewing_group/document_type/:type',
        name: 'distribution-usual-report-crewing-dcoument-type-table',
        component: () => import('@/views/Report/ReportDistribution/TableCrewing.vue')
      },
      {
        path: 'crewing_group/:crewing_group',
        name: 'distribution-usual-report-crewing-group-table',
        component: () => import('@/views/Report/ReportDistribution/TableGroup.vue')
      },
      {
        path: 'document_type/:crewing_group',
        name: 'distribution-usual-report-document-type-table',
        component: () => import('@/views/Report/ReportDistribution/TableDocType.vue')
      },
      {
        path: 'crewing/:crewing',
        name: 'distribution-usual-report-manager-crewing',
        component: () => import('@/views/Report/ReportDistribution/TableManager.vue')
      },
      {
        path: 'crewing/:medical',
        name: 'distribution-usual-report-manager-medical',
        component: () => import('@/views/Report/ReportDistribution/TableManager.vue')
      },
      {
        path: 'crewing/:institution',
        name: 'distribution-usual-report-manager-institution',
        component: () => import('@/views/Report/ReportDistribution/TableManager.vue')
      },
      {
        path: 'crewing/:branch_office',
        name: 'distribution-usual-report-manager-branch-office',
        component: () => import('@/views/Report/ReportDistribution/TableManager.vue')
      },
      {
        path: 'crewing/:crewing/type-document/:type',
        name: 'distribution-usual-report-manager-type-document',
        component: () => import('@/views/Report/ReportDistribution/TableManager.vue')
      },
      {
        path: 'usual/:typeDoc/qualification/:educational_institution',
        name: 'distribution-usual-report-qualification-educational-institution',
        component: () => import('@/views/Report/ReportDistribution/TableQualification.vue')
      },
      {
        path: 'sailor/:manager',
        name: 'distribution-usual-report-sailor-manager',
        component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
      },
      {
        path: 'sailor/:manager/portal/:branch_office',
        name: 'distribution-usual-report-sailor-portal',
        component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
      },
      {
        path: 'sailor/:manager/type-document/:type',
        name: 'distribution-usual-report-sailor-type-document',
        component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
      },
      {
        path: 'sailor/:medical',
        name: 'distribution-usual-report-sailor-medical',
        component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
      },
      {
        path: 'sailor/:institution/course/:course',
        name: 'distribution-usual-report-sailor-institution-course',
        component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
      },
      {
        path: 'sailor/institution/:educational_institution/qualification/:level',
        name: 'distribution-usual-report-sailor-educational-institution-qualification',
        component: () => import('@/views/Report/ReportDistribution/TableSailor.vue')
      }
    ]
  }
]
