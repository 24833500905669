import api from '@/plugins/api'
import { sailor } from '@/configs/endpoints'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS, STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
import { queryString, FromObjectToFormData, getFiles, removeFileFromObject } from '@/mixins/main'
import notify from '@ananasbear/notify'

export const stateExperienceSection = {
  // -------------- SERVICE RECORD BOOKS -------------- //
  serviceRecordBook: {},
  serviceRecordBookById: {},
  // -------------- LINES IN SERVICE RECORD BOOK -------------- //
  serviceRecordBookLines: {},
  serviceRecordBookLineById: {},
  // -------------- EXPERIENCE CERTIFICATES -------------- //
  experience: {},
  experienceById: {},
  // -------------- RECORD BOOK STATEMENTS -------------- //
  recordBookStatement: {},
  recordBookStatementById: {}
}
export const actionsExperienceSection = {
  // -------------- SERVICE RECORD BOOKS -------------- //
  async getRecordBooks ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(
      sailor.experience.serviceRecordBook.list(data?.id || state.sailorId) + queryString(data.params)
    )
    if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_SAILOR', { serviceRecordBook: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getRecordBookByID ({ state, commit, dispatch }, data) {
    commit('SET_STATE_SAILOR', { serviceRecordBookById: {} })
    const response = await api.get(
      sailor.experience.serviceRecordBook.list(data?.id || state.sailorId, data.documentID)
    )
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      const statuses = [
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.REJECTED,
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.IN_PROCESSING
      ]
      if (statuses.includes(response.data.status_document)) {
        const responseComment = await dispatch('getRecordBookCommentsById', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      }
      commit('SET_STATE_SAILOR', { serviceRecordBookById: response.data })
    }
    return response
  },
  async setCommentServiceRecordBookById ({ state }, data) {
    const response = await api.post(
      sailor.experience.serviceRecordBook.comment(data.id || state.sailorId, data.documentID),
      { body: data.comments }
    )
    if (SUCCESS_CODE.includes(response.code) &&
      data.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.REJECTED
    ) notify.success('documentReject')
    response.data = [response.data]
    return response
  },
  async getRecordBookCommentsById ({ state }, data) {
    const response = await api.get(
      sailor.experience.serviceRecordBook.comment(data?.id || state.sailorId, data.documentID)
    )
    return response
  },
  async setRecordBook ({ commit, dispatch, state }, data) {
    const response = await api.post(sailor.experience.serviceRecordBook.list(data.id || state.sailorId), {
      body: data.body
    })
    if (SUCCESS_CODE.includes(response?.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(
          sailor.experience.serviceRecordBook.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) }
        )
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      dispatch('getRecordBooks', { ...data, params: { page: 1, page_size: 10 } })
      commit('incrementBadgeCount', {
        child: 'recordBookDocument',
        parent: 'experienceAll'
      })
      commit('incrementUserNotification', 'documents_on_verification')
      notify.success('addedRecordBook')
    }
    return response
  },
  async updateRecordBookById ({ commit, dispatch, state }, data) {
    const response = await api.patch(
      sailor.experience.serviceRecordBook.list(data?.id || state?.sailorId, data.documentID),
      { body: data.body }
    )
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (data.media?.files?.length) {
        const responseMedia = await api.post(sailor.experience.serviceRecordBook.uploadFile(data.id, data.documentID), {
          body: FromObjectToFormData(data.media)
        })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      const statuses = [
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.REJECTED,
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.IN_PROCESSING
      ]
      if (statuses.includes(response.data.status_document)) {
        data.status_document = response.data.status_document
        const responseCommnets = data.comments
          ? dispatch('setCommentServiceRecordBookById', data)
          : dispatch('getRecordBookCommentsById', data)

        if (SUCCESS_CODE.includes(responseCommnets.code)) {
          response.data.comments = responseCommnets.data
        }

        if (
          data.body.status_document ===
            STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.VERIFICATION &&
          response.data.status_document !==
            STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.VERIFICATION
        ) {
          commit('decrementUserNotification', 'documents_on_verification')
        }
      }
      notify.success('notify.success.srbEdited')
      dispatch('getRecordBookByID', data)
    }
    return response
  },
  async removeRecordBook ({ state, commit, dispatch }, data) {
    const response = await api.delete(
      sailor.experience.serviceRecordBook.list(data?.id || state.sailorId, data.documentID)
    )
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { serviceRecordBookById: response.data })
        commit('decrementBadgeCount', {
          child: 'recordBookDocument',
          parent: 'experienceAll'
        })
        dispatch('getRecordBookByID', data)
      } else dispatch('getRecordBooks', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async removeFileRecordBook ({ commit, state }, data) {
    const response = await api.delete(
      sailor.experience.serviceRecordBook.removeFile(data.id, data.documentID, data.fileId)
    )
    let newData = { ...state.serviceRecordBookById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { serviceRecordBookById: newData })
    return response
  },
  async updateFileRecordBook ({ commit }, data) {
    const response = await api.patch(
      sailor.experience.serviceRecordBook.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) }
    )
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  async getStatementDocumentSRB (context, data) {
    const response = await api.post(sailor.experience.serviceRecordBook.statementDocument, { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      await getFiles(sailor.experience.serviceRecordBook.generate(response.data.token), data.nameBook, data.type)
    }
  },
  async generateDccumentSRB (context, data) {
    const response = await api.get(sailor.experience.serviceRecordBook.genereteSRB(data.documentID))
    if (SUCCESS_CODE.includes(response.code)) {
      await getFiles(response.data.url, data.nameBook)
    }
  },
  // -------------- LINES IN SERVICE RECORD BOOK -------------- //
  async getRecordBookLinesEntry ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(
      sailor.experience.serviceRecordBookLine.list(data?.id || state.sailorId, data.documentID) +
        queryString(data.params)
    )
    if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_SAILOR', { serviceRecordBookLines: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getRecordBookLineEntryByID ({ state, commit, dispatch }, data) {
    commit('SET_STATE_SAILOR', { serviceRecordBookLineById: {} })
    const response = await api.get(
      sailor.experience.serviceRecordBookLine.list(data?.id || state.sailorId, data.documentID, data.lineID)
    )
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      let statuses = [
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.REJECTED,
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.IN_PROCESSING
      ]
      if (statuses.includes(response.data.status_document)) {
        const responseComment = await dispatch('getRecordBookLineEntryCommentsById', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      }
      commit('SET_STATE_SAILOR', { serviceRecordBookLineById: response.data })
    }
    return response
  },
  async getRecordBookLineEntryCommentsById ({ state }, data) {
    const response = await api.get(
      sailor.experience.serviceRecordBookLine.comment(data?.id || state.sailorId, data.documentID, data.lineID)
    )
    return response
  },
  async removeRecordBookLineEntry ({ state, commit, dispatch }, data) {
    const response = await api.delete(
      sailor.experience.serviceRecordBookLine.list(data?.id || state.sailorId, data.documentID, data.lineID)
    )
    if (SUCCESS_CODE.includes(response?.code)) {
      if (
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.DELETED === response.data.status_document
      ) {
        commit('SET_STATE_SAILOR', { serviceRecordBookLineById: response.data })
      } else dispatch('getRecordBookLinesEntry', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async setRecordBookLineEntry ({ commit, dispatch, state }, data) {
    const response = await api.post(
      sailor.experience.serviceRecordBookLine.list(data.id || state.sailorId, data.documentID),
      { body: data.body }
    )
    if (SUCCESS_CODE.includes(response?.code)) {
      if (data?.media?.files?.length) {
        const responseMedia = await api.post(
          sailor.experience.serviceRecordBookLine.uploadFile(data.id, data.documentID, response.data.id),
          { body: FromObjectToFormData(data.media) }
        )
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      dispatch('getRecordBookLinesEntry', { ...data, params: { page: 1, page_size: 10 } })
      commit('incrementUserNotification', 'documents_on_verification')
      notify.success('addedRecordBook')
    }
    return response
  },
  async updateRecordBookLineEntry ({ commit, dispatch, state }, data) {
    const response = await api.patch(
      sailor.experience.serviceRecordBookLine.list(data?.id || state?.sailorId, data.documentID, data.lineID),
      { body: data.body }
    )
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }

      if (data.media?.files?.length) {
        const responseMedia = await api.post(
          sailor.experience.serviceRecordBookLine.uploadFile(data.id, data.documentID, data.lineID),
          { body: FromObjectToFormData(data.media) }
        )
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      const statuses = [
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.REJECTED,
        STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.IN_PROCESSING
      ]

      if (statuses.includes(response.data.status_document)) {
        const responseCommnets = data.comments
          ? dispatch('setCommentServiceRecordBookLineById', data)
          : dispatch('getRecordBookLineEntryCommentsById', data)

        if (SUCCESS_CODE.includes(responseCommnets.code)) response.data.comments = responseCommnets.data

        if (
          data.body.status_document ===
            STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.VERIFICATION &&
          response.data.status_document !==
            STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK_LINE.VERIFICATION
        ) {
          commit('decrementUserNotification', 'documents_on_verification')
        }
      }
      dispatch('getRecordBookLineEntryByID', data)
    }
    return response
  },
  async setCommentServiceRecordBookLineById ({ state }, data) {
    const response = await api.post(
      sailor.experience.serviceRecordBookLine.comment(data.id || state.sailorId, data.documentID, data.lineID),
      { body: data.comments }
    )
    if (SUCCESS_CODE.includes(response.code)) notify.success('documentReject')
    response.data = [response.data]
    return response
  },
  async removeFileRecordBookLine ({ commit, state }, data) {
    const response = await api.delete(
      sailor.experience.serviceRecordBookLine.removeFile(data.id, data.documentID, data.lineID, data.fileId)
    )
    let newData = { ...state.serviceRecordBookLineById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { serviceRecordBookLineById: newData })
    return response
  },
  async updateFileRecordBookLine ({ commit }, data) {
    const response = await api.patch(
      sailor.experience.serviceRecordBookLine.updateFile(data.id, data.documentID, data.lineID, data.fileId),
      { body: FromObjectToFormData(data.media), responseType: 'blob' }
    )
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  // -------------- EXPERIENCE CERTIFICATES -------------- //
  async getExperienceReferences ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(
      sailor.experience.certificate.list(data?.id || state.sailorId) + queryString(data.params)
    )
    if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_SAILOR', { experience: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getExperienceReferencesById ({ state, commit, dispatch }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(
      sailor.experience.certificate.list(data?.id || state.sailorId, data.documentID) + queryString(data?.params)
    )
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (
        [
          STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.INVALID,
          STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.IN_PROCESSING
        ].includes(response.data.status_document)
      ) {
        const responseComment = await dispatch('getExperienceReferencesByIdComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      }
      commit('SET_STATE_SAILOR', { experienceById: response.data })
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getExperienceReferencesByIdComments ({ state }, data) {
    const response = await api.get(sailor.experience.certificate.comment(data.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response.code)) return response
  },
  async setExperienceReferencesByIdComments ({ commit, state }, data) {
    const response = await api.post(sailor.experience.certificate.comment(data.id || state.sailorId, data.documentID), {
      body: data.comments
    })
    if (SUCCESS_CODE.includes(response.code)) {
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.INVALID === data.body.status_document) {
        notify.success('notify.success.rejectExperience')
      }
      response.data = [response.data]
      return response
    }
  },
  async deleteExperienceCertificateById ({ commit, dispatch, state }, data) {
    const response = await api.delete(sailor.experience.certificate.list(data.id || state.sailorId, data.documentID))
    if (SUCCESS_CODE.includes(response?.code)) {
      if (STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.DELETED === response.data.status_document) {
        commit('SET_STATE_SAILOR', { serviceRecordBookLineById: response.data })
      } else dispatch('getExperienceReferences', { ...data, params: { page: 1, page_size: 10 } })
      commit('decrementBadgeCount', {
        child: 'experienceDocument',
        parent: 'experienceAll'
      })
    }
    return response
  },
  async setExperienceCertificate ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.post(sailor.experience.certificate.list(data.id || state.sailorId), { body: data.body })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data.media?.files.length) {
        const responseMedia = await api.post(sailor.experience.certificate.uploadFile(data.id, response.data.id), {
          body: FromObjectToFormData(data.media)
        })
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      dispatch('getExperienceReferences', { ...data, params: { page: 1, page_size: 10 } })

      commit('incrementBadgeCount', {
        child: 'experienceDocument',
        parent: 'experienceAll'
      })
      commit('incrementUserNotification', 'documents_on_verification')
      notify.success('addedExperienceDoc')
      commit('SET_STATE_SAILOR', { isLoading: false })
    }
    return response
  },
  async updateExperienceCertificateById ({ commit, dispatch, state }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.patch(sailor.experience.certificate.list(data?.id || state.sailorId, data.documentID), {
      body: data.body
    })
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (
        [
          STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.IN_PROCESSING,
          STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.INVALID
        ].includes(response.data.status_document)
      ) {
        const responseComment = data.comments
          ? await dispatch('setExperienceReferencesByIdComments', data)
          : await dispatch('getExperienceReferencesByIdComments', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      }
      if (
        data.body.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.VERIFICATION &&
        response.data.status_document !== STATUSES_DOCUMENT_CONSTANTS.SAILOR.EXPERIENCE.CERTIFICATE.VERIFICATION
      ) {
        commit('decrementUserNotification', 'documents_on_verification')
      }
      dispatch('getExperienceReferencesById', data)
    }
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async removeFileExperienceCertificate ({ commit, state }, data) {
    const response = await api.delete(sailor.experience.certificate.removeFile(data.id, data.documentID, data.fileId))
    let newData = { ...state.experienceById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { experienceById: newData })
    return response
  },
  async updateFileExperienceCertificate ({ commit }, data) {
    const response = await api.patch(sailor.experience.certificate.updateFile(data.id, data.documentID, data.fileId), {
      body: FromObjectToFormData(data.media)
    })
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  },
  // -------------- RECORD BOOK STATEMENTS -------------- //
  async getRecordBookStatement ({ state, commit }, data) {
    commit('SET_STATE_SAILOR', { isLoading: true })
    const response = await api.get(
      sailor.experience.statementServiceRecordBook.list(data?.id || state.sailorId) + queryString(data.params)
    )
    if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_SAILOR', { recordBookStatement: response.data })
    commit('SET_STATE_SAILOR', { isLoading: false })
    return response
  },
  async getRecordBookStatementById ({ state, commit, dispatch }, data) {
    commit('SET_STATE_SAILOR', { recordBookStatementById: {} })
    const response = await api.get(
      sailor.experience.statementServiceRecordBook.list(data?.id || state.sailorId, data.documentID)
    )
    if (SUCCESS_CODE.includes(response?.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (
        response.data.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.REJECTED
      ) {
        const responseComment = await dispatch('getRecordBookStatementCommentsById', data)
        if (SUCCESS_CODE.includes(responseComment.code)) response.data.comments = responseComment.data
      }
      commit('SET_STATE_SAILOR', { recordBookStatementById: response.data })
    }
    return response
  },
  async removeRecordBookStatement ({ state, commit, dispatch }, data) {
    const response = await api.delete(
      sailor.experience.statementServiceRecordBook.list(data?.id || state.sailorId, data.documentID)
    )
    if (SUCCESS_CODE.includes(response?.code)) {
      if (
        STATUSES_STATEMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.DELETED === response.data.status_document
      ) {
        commit('SET_STATE_SAILOR', { recordBookStatementById: response.data })
      } else dispatch('getRecordBookStatement', { ...data, params: { page: 1, page_size: 10 } })
      commit('decrementBadgeCount', {
        child: 'recordBookStatement',
        parent: 'experienceAll'
      })
      commit('decrementUserNotification', 'statement_service_record')
    }
    return response
  },
  async removeFileRecordBookStatement ({ commit, state }, data) {
    const response = await api.delete(
      sailor.experience.statementServiceRecordBook.removeFile(data.id, data.documentID, data.fileId)
    )
    let newData = { ...state.recordBookStatementById }
    newData.files = removeFileFromObject(newData.files, data.fileId, data.removeOnce)
    commit('SET_STATE_SAILOR', { recordBookStatementById: newData })
    return response
  },
  async setCommentServiceRecordBookStatementById ({ state }, data) {
    const response = await api.post(
      sailor.experience.statementServiceRecordBook.comment(data.id || state.sailorId, data.documentID),
      { body: data.comments }
    )
    if (SUCCESS_CODE.includes(response.code)) notify.success('documentReject')
    response.data = [response.data]
    return response
  },
  async getRecordBookStatementCommentsById ({ state }, data) {
    const response = await api.get(
      sailor.experience.statementServiceRecordBook.comment(data?.id || state.sailorId, data.documentID)
    )
    return response
  },
  async updateRecordBookStatement ({ commit, dispatch, state }, data) {
    const response = await api.patch(
      sailor.experience.statementServiceRecordBook.list(data?.id || state?.sailorId, data.documentID),
      { body: data.body }
    )
    if (SUCCESS_CODE.includes(response.code)) {
      response.data.behavior = { viewInfoBlock: true }
      if (data.media?.files?.length) {
        const responseMedia = await api.post(
          sailor.experience.statementServiceRecordBook.uploadFile(data.id, data.documentID),
          { body: FromObjectToFormData(data.media) }
        )
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      const statuses = [STATUSES_STATEMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.REJECTED]

      if (statuses.includes(response.data.status_document)) {
        const responseCommnets = data.comments
          ? dispatch('setCommentServiceRecordBookStatementById', data)
          : dispatch('getRecordBookStatementCommentsById', data)

        if (SUCCESS_CODE.includes(responseCommnets.code)) {
          response.data.comments = responseCommnets.data
        }

        if (
          data.body?.status_document ===
            STATUSES_STATEMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.VERIFICATION &&
          response.data?.status_document !==
            STATUSES_STATEMENT_CONSTANTS.SAILOR.EXPERIENCE.SERVICE_RECORD_BOOK.VERIFICATION
        ) {
          commit('decrementUserNotification', 'documents_on_verification')
        }
      }
      dispatch('getRecordBookStatementById', data)
    }
    return response
  },
  async createServiceRecordFromStatement ({ commit, dispatch, state }, data) {
    const response = await api.post(
      sailor.experience.statementServiceRecordBook.createServiceRecord(data?.id || state.sailorId, data?.documentID),
      { body: data.body }
    )
    if (SUCCESS_CODE.includes(response.code)) {
      commit('incrementBadgeCount', {
        child: 'recordBookStatement',
        parent: 'experienceAll'
      })
      dispatch('getRecordBookStatementById', data)
    }
    return response
  },
  async createServiceRecordStatement ({ commit, dispatch, state }, data) {
    const response = await api.post(sailor.experience.statementServiceRecordBook.list(data.id || state.sailorId), {
      body: data.body
    })
    if (SUCCESS_CODE.includes(response.code)) {
      if (data.media?.files?.length) {
        const responseMedia = await api.post(
          sailor.experience.statementServiceRecordBook.uploadFile(data.id, response.data.id),
          { body: FromObjectToFormData(data.media) }
        )
        if (!SUCCESS_CODE.includes(responseMedia.code)) notify.error('errorAddFile')
      }
      commit('incrementBadgeCount', {
        child: 'recordBookStatement',
        parent: 'experienceAll'
      })
      commit('incrementUserNotification', 'statement_service_record')
      dispatch('getRecordBookStatement', { ...data, params: { page: 1, page_size: 10 } })
    }
    return response
  },
  async updateFileServiceRecordBookStatement ({ commit }, data) {
    const response = await api.patch(
      sailor.experience.statementServiceRecordBook.updateFile(data.id, data.documentID, data.fileId),
      { body: FromObjectToFormData(data.media) }
    )
    if (SUCCESS_CODE.includes(response.code)) {
      notify.success('notify.success.fileHasBeenEdited')
    }
    return response
  }
}
