import Vue from 'vue'
import api from '@/plugins/api'
// const successCode = [ 200, 201, 204 ]
import { blobToFile } from '@/mixins/main'
export const MAIN = {
  state: () => ({
    activePage: { name: '', access: true },
    token: localStorage.getItem('Token'),
    lang: 'bo',
    user: {},
    permissions: [],
    permissionsReport: {},
    version: '',
    badgesCount: {},
    userNotification: {},
    dataPagination: {},
    registerCode: { status: false, code: null },
    signatureKey: { status: false, key: null },
    isContractNeeded: false,
    maradHost: false,
    crewingHost: false,
    morrichserviceHost: false,
    flyairxpressHost: false,
    isTrained: true,
    allPermissions: [],
    security_code: null,
    page_size: [
      { id: 1, count: 5 },
      { id: 2, count: 10 },
      { id: 3, count: 20 },
      { id: 4, count: 50 },
      { id: 5, count: 100 }
    ]
  }),
  mutations: {
    SET_STATE_DATA_MAIN (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    },
    UPDATE_PAGINATION_DATA (state, data) {
      Vue.set(state.dataPagination, data.value, { ...data, total: Math.ceil(data.count / (data.limit || 20)) })
    },
    setUserGroupProfile (state, data) {
      state.user.group = data
    },
    setActivePage (state, data) {
      state.activePage = data
    },

    setCountBadges (state, data) {
      state.badgesCount = {
        passportAll: data.passports.sum,
        passportStatement: data.passports.statement_sailor_passport,
        passportDocument: data.passports.passport_sailor,
        passportCitizen: data.passports.passport,
        passportInternationalDocument: data.passports.foreign_passport,
        passportOldName: data.passports.old_name,
        educationAll: data.education.sum,
        educationStatement: data.education.statement_adv_training,
        educationDocument: data.education.main,
        studentCard: data.education.student,
        qualificationAll: data.dpo_documents.sum,
        qualificationDocument: data.dpo_documents.qual_doc,
        qualificationStatement: data.dpo_documents.statement_qual_doc,
        qualificationReplacementStatement: data.dpo_documents.statement_replace_qualification,
        navigatorCertificate: data.dpo_documents.certificate_of_navigator,
        certificateAll: data.ntz.sum,
        certificateDocument: data.ntz.certificate,
        certificateStatement: data.ntz.statement_eti,
        experienceAll: data.experience.sum,
        experienceDocument: data.experience.experience_doc,
        recordBookDocument: data.experience.service_record,
        recordBookStatement: data.experience.statement_service_record,
        sqcAll: data.dkk.sum,
        sqcDocument: data.dkk.protocol_dkk,
        sqcStatement: data.dkk.statement_dkk,
        medicalAll: data.medical_sertificate.sum,
        medicalDocument: data.medical_sertificate.medical_sertificate,
        medicalStatement: data.medical_sertificate.statement_med_cert,
        positionStatement: data.packet_item
      }
    },
    incrementBadgeCount (state, data) {
      state.badgesCount[data.child]++
      state.badgesCount[data.parent]++
    },
    decrementBadgeCount (state, data) {
      state.badgesCount[data.child]--
      state.badgesCount[data.parent]--
    },
    setCitizenPassportBadgeCount (state, data) {
      state.badgesCount.passportCitizen = data
    },

    incrementUserNotification (state, data) {
      state.userNotification[data]++
      state.userNotification.amount++
    },
    decrementUserNotification (state, data) {
      state.userNotification[data]--
      state.userNotification.amount--
    },

    setHostName (state) {
      state.flyairxpressHost = ['googie.com.ua'].includes(location.hostname)
      state.crewingHost = ['crew.org.ua', 'crew.stg.disoft.dev'].includes(location.hostname)
      state.morrichserviceHost = location.hostname === 'sec.morrichservice.com.ua'
      state.maradHost = !['sec.morrichservice.com.ua', 'crew.org.ua', 'crew.stg.disoft.dev',
        'googie.com.ua'].includes(location.hostname)
    }
  },
  actions: {
    getUserInfo ({ commit }) {
      return api.get('api/v1/auth/get_user_info/').then(response => commit('SET_STATE_DATA_MAIN', { user: response.data }))
    },
    getUserPermissions ({ commit }) {
      return api.get('api/v1/auth/get_user_permissions/').then(response => {
        commit('SET_STATE_DATA_MAIN', { permissions: response.data.permissions })
        if (response.data.groups.length) commit('setUserGroupProfile', response.data.groups[0].id)
      })
    },
    async getUserPermissionReport ({ commit }) {
      await api.get('api/v1/auth/branch_office_restr/')
        .then(response => commit('SET_STATE_DATA_MAIN', { permissionsReport: response.data }))
    },
    async getVersion ({ commit }) {
      await api.get('api/v1/auth/version/').then(response => {
        if (response.data.length) commit('SET_STATE_DATA_MAIN', { version: `beta v.${response.data[0].full_version}` })
      })
    },
    async getCountBadges ({ commit }, sailorID) {
      await api.get(`api/v2/sailor/${sailorID}/count_docs/`).then(response => commit('setCountBadges', response.data))
    },
    // async getAllPermissions ({ commit }) {
    //   await api.get('api/v1/auth/permissions/').then(response => {
    //     if (response.code === 200) commit('SET_STATE_DATA_MAIN', { type: 'allPermissions', data: response.data })
    //   })
    // },
    getSpecificUserPermissions ({ commit }, id) {
      return api.get(`api/v1/auth/user/${id}/permissions/`).then(response => {
        return response
      })
    },
    async getSecurityCode (context) {
      return api.get('api/v1/auth/support_code/').then((response) => response)
    },
    async getFile (context, link) {
      const response = await api.get(link, { responseType: 'blob' })
      if ([200, 201].includes(response.code)) return blobToFile(response.data)
    },
    async setUploadFile (context, data) {
      let body = new FormData()
      Object.entries(data.body).forEach(([key, value]) => {
        if (['files', 'photo'].includes(key)) value.forEach(file => body.append(key, file))
        else body.append(key, value)
      })
      const response = await api.post(data.url, { body, requestType: 'formData' })
      return response
    }
  }
}
