import api from '@/plugins/api'
import { backoffice } from '@/configs/endpoints'
import notify from '@ananasbear/notify'
import i18n from '@/plugins/localization/index'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { queryString, setLinksByContentType, clearBody } from '@/mixins/main'

export default {
  state: () => ({
    vnz: [],
    qualifications: [],
    isLoading: false,
    success: false,
    documentsPriceOnline: [],
    coursePriceOnline: [],
    coursePrice: {},
    documentsPriceOnlinePast: {},
    documentsPriceOnlineFuture: {},
    medical: [],
    autoSigningDistribution: [],
    signFailedDocument: {},
    translationAgency: {},
    translationAgencyPricePrices: [],
    nostrificationMesPricePrices: [],
    nostrificationStatementPricePrices: [],
    powersOfAttorney: {},
    etiDirection: {},
    etiDirectionById: {},
    // --------------BACKOFFICE V2----------- //
    coefficient: [],
    coefficientById: {},
    dealing: [],
    dealingById: {},
    offlinePriceOfDocuments: [],
    documentsPriceOfflinePast: {},
    documentsPriceOfflineFuture: {}
  }),
  mutations: {
    SET_DATA_STATE_BACKOFFICE (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    },
    UPDATE_ARRAY_DATA_STATE_BACKOFFICE (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key].push(value)))
    },
    DELETE_DATA_BACKOFFICE_RESULTS (state, data) {
      state[Object.entries(data)[0][0]].results = state[Object.entries(data)[0][0]].results.filter(item => {
        return item.id !== Object.entries(data)[0][1]
      })
    },
    DELETE_ARRAY_DATA_BACKOFFICE (state, data) {
      state[Object.entries(data)[0][0]] = state[Object.entries(data)[0][0]].filter(item => {
        return item.id !== Object.entries(data)[0][1]
      })
    }
  },
  actions: {
    async getPriceForAdvTraining (context, params) {
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      params.have_qualifications = true
      const response = await api.get(backoffice.priceForAdvTraining() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) {
        context.commit('SET_DATA_STATE_BACKOFFICE', { vnz: response.data, isLoading: false })
      }
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
    },
    async updateAccessTypeForAdvTraining (context, data) {
      const response = await api.patch(backoffice.priceForAdvTraining(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) return response
      return {}
    },
    async getInstitution (context, id) {
      const response = await api.get(backoffice.priceForAdvTraining(id)).then((response) => response)
      response.data.behavior = { viewInfoBlock: true }
      return SUCCESS_CODE.includes(response.code) ? response.data : notify.warning('notify.title.error')
    },
    async setPriceForAdvTraining (context, data) {
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.post(backoffice.priceForAdvTrainingInstitution(), { body: data.body })
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false, success: SUCCESS_CODE.includes(response?.code) })
      SUCCESS_CODE.includes(response.code) && notify.success(i18n.t('notify.vnzSuccess'))
    },
    async updatePriceForAdvTraining (context, data) {
      const response = await api.patch(backoffice.priceForAdvTrainingInstitution(data.id), { body: data.body })
      SUCCESS_CODE.includes(response.code) && notify.success(i18n.t('notify.vnzSuccess'))
    },
    async getPriceForMedicalInstitution (context, params) {
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.medicalInstitution() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) {
        context.commit('SET_DATA_STATE_BACKOFFICE', { medical: response.data, isLoading: false })
      }
    },
    async updateStateMedicalInstitution (context, data) {
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.patch(backoffice.medicalInstitution(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        if (data.body.hasOwnProperty('is_disabled')) {
          notify.success(`notify.success.${data.body.is_disabled ? 'deactiveMedInstitution' : 'activeMedInstitution'}`)
        }
        context.dispatch('getPriceForMedicalInstitution', data.params)
      }
    },
    async setPriceForMedicalInstitution (context, data) {
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.post(backoffice.medical, { body: data.body })
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false, success: SUCCESS_CODE.includes(response?.code) })
      SUCCESS_CODE.includes(response.code) && notify.success(i18n.t('notify.medicalSuccess'))
    },
    async updatePriceForMedicalInstitution (context, data) {
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.patch(backoffice.medicalById(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success(i18n.t('notify.medicalSuccess'))
        setTimeout(() => {
          context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
          window.location.reload()
        }, 500)
      }
    },
    // Price Documents //
    async getDocumentsPriceOnlineActualValue (context, params) {
      const response = await api.get(backoffice.online.priceForPositionActualValue + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.map(item => {
          item.typeDocName = `${item.type_of_form === 'First' ? i18n.t('First') : i18n.t('Second')} ${item.type_document.value}`
        })
        response.data.sort((a, b) => {
          if (a.type_document.value < b.type_document.value) {
            return -1
          }
        })
        context.commit('SET_DATA_STATE_BACKOFFICE', { documentsPriceOnline: response.data })
      }
    },
    async getDocumentsPricePastValue (context, data) {
      const response = await api.get(backoffice.online.priceForPositionPastValue(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.map(item => {
          item.behavior = {}
          item.allowDelete = new Date(item.date_start) > new Date()
          // condition for actual value below
          item.allowEdit = !((!item.date_end || new Date(item.date_end) >= new Date()) && new Date(item.date_start) <= new Date())
        })
        context.commit('SET_DATA_STATE_BACKOFFICE', { documentsPriceOnlinePast: response.data })
      }
    },
    async getDocumentsPriceFutureValue (context, data) {
      const response = await api.get(backoffice.online.priceForPositionFutureValue(data.id) + queryString(data.filter))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.map(item => {
          item.behavior = {}
          item.allowDelete = new Date(item.date_start) > new Date()
          // condition for actual value below
          item.allowEdit = !((!item.date_end || new Date(item.date_end) >= new Date()) && new Date(item.date_start) <= new Date())
        })
        context.commit('SET_DATA_STATE_BACKOFFICE', { documentsPriceOnlineFuture: response.data })
      }
    },
    async postDocumentPriceOnline (context, data) {
      const response = await api.post(backoffice.online.priceForPosition, { body: data.body })
      return response
    },
    async patchDocumentPriceOnline (context, data) {
      const response = await api.patch(backoffice.online.priceForPositionById(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        context.dispatch('getDocumentsPriceOnlineActualValue')
        context.dispatch('getDocumentsPricePastValue', { id: data.id })
        context.dispatch('getDocumentsPriceFutureValue', { id: data.id })
        return response
      }
    },
    async deleteDocumentPriceOnline (context, data) {
      const response = await api.delete(backoffice.online.priceForPositionById(data.id))
      return response
    },
    // COURSE PRICE //
    async getCoursesPriceOnline (context) {
      const response = await api.get(backoffice.online.coursePrice)
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_BACKOFFICE', { coursePriceOnline: response.data })
    },
    async getCoursePriceOnline (context, data) {
      const response = await api.get(backoffice.online.coursePriceById(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        response.data.allowDelete = new Date(response.data.date_start) > new Date()
        response.data.allowEdit = !((!response.data.date_end || new Date(response.data.date_end) >= new Date()) && new Date(response.data.date_start) <= new Date())
        context.commit('SET_DATA_STATE_BACKOFFICE', { coursePrice: response.data })
        return response.data
      }
    },
    async postCoursePriceOnline (context, data) {
      const response = await api.post(backoffice.online.coursePrice, { body: data.body })
      context.commit('UPDATE_ARRAY_DATA_STATE_BACKOFFICE', { coursePriceOnline: response.data })
      return response
    },
    async patchCoursePriceOnline (context, data) {
      const response = await api.patch(backoffice.online.coursePriceById(data.id), { body: data.body })
      response.data.behavior = { viewInfoBlock: true }
      response.data.allowDelete = new Date(response.data.date_start) > new Date()
      response.data.allowEdit = !((!response.data.date_end || new Date(response.data.date_end) >= new Date()) && new Date(response.data.date_start) <= new Date())
      context.commit('SET_DATA_STATE_BACKOFFICE', { coursePrice: response.data })
      return response
    },
    async deleteCoursePriceOnline (context, data) {
      const response = await api.delete(backoffice.online.coursePriceById(data.id))
      return response
    },

    async getAutoSigningDistribution ({ commit }, params) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.autoSigningDistribution + queryString(params))
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_BACKOFFICE', { autoSigningDistribution: response.data })
      return response
    },
    async updateAutoSigningDistribution ({ commit }, body) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.patch(backoffice.autoSigningDistribution, { body })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      return response
    },

    async getSingFailedDocuments ({ commit }, params) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.signFailedDocument() + queryString(params))
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.map(item => {
          item.link = setLinksByContentType(item)
        })
        commit('SET_DATA_STATE_BACKOFFICE', { signFailedDocument: response.data })
      }
      return response
    },

    async removeSingFailedDocument ({ commit }, id) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.delete(backoffice.signFailedDocument(id), { responseType: 'text' })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE.includes(response.code)) commit('DELETE_DATA_BACKOFFICE_RESULTS', { signFailedDocument: id })
      return response
    },

    // TRANSLATE AGENCY
    async getTranslationAgencies ({ commit }, params) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.translationAgency() + queryString(params))
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) commit('SET_DATA_STATE_BACKOFFICE', { translationAgency: response.data })
      return response
    },
    async getTranslationAgency ({ commit }, id) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.translationAgency(id))
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) response.data.behavior = { viewInfoBlock: true }
      return response
    },
    async setTranslationAgency ({ commit, dispatch }, body) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.post(backoffice.translationAgency(), { body })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) dispatch('getTranslationAgencies')
      return response
    },
    async updateTranslationAgency ({ commit }, data) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.patch(backoffice.translationAgency(data.id), { body: data.body })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      return response
    },
    async removeTranslationAgency ({ commit, dispatch }, id) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.delete(backoffice.translationAgency(id), { responseType: 'text' })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      return response
    },

    // TRANSLATE AGENCY PRICES
    async getTranslationAgencyPrices ({ commit }, params) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.translationAgencyPrice() + queryString(params))
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) {
        response.data.map(item => {
          item.isHiddenEdit = (!item.date_end && (new Date() > new Date(item.date_start))) || item.date_end
        })
        commit('SET_DATA_STATE_BACKOFFICE', { translationAgencyPricePrices: response.data })
      }
      return response
    },
    async setTranslationAgencyPrice ({ commit, dispatch }, body) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.post(backoffice.translationAgencyPrice(), { body })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) {
        dispatch('getTranslationAgencyPrices', { translation_company: response.data.translation_company })
      }
      return response
    },
    async updateTranslationAgencyPrice ({ commit, dispatch }, data) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.patch(backoffice.translationAgencyPrice(data.id), { body: data.body })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) {
        dispatch('getTranslationAgencyPrices', { translation_company: response.data.translation_company })
      }
      return response
    },
    // NOSTRIFICATION AGENCY
    async getNostrificationMesPricePrises ({ commit }, params) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.nostrificationMesPrice() + queryString(params))
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      response.data.results.map(item => {
        item.isHiddenEdit = (!item.date_end && (new Date() > new Date(item.date_start))) || item.date_end
      })
      if (SUCCESS_CODE?.includes(response.code)) commit('SET_DATA_STATE_BACKOFFICE', { nostrificationMesPricePrices: response.data })
      return response
    },
    async setNostrificationMesPrice ({ commit, dispatch }, body) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.post(backoffice.nostrificationMesPrice(), { body })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) dispatch('getNostrificationMesPricePrises')
      return response
    },
    async updateNostrificationMesPrice ({ commit, dispatch }, data) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.patch(backoffice.nostrificationMesPrice(data.id), { body: data.body })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) dispatch('getNostrificationMesPricePrises')
      return response
    },
    async removeNostrificationMesPrice ({ commit, dispatch }, id) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.delete(backoffice.nostrificationMesPrice(id))
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) dispatch('getNostrificationMesPricePrises')
      return response
    },
    // PAYMENT STATEMENT NOSTRIFICATION
    async getNostrificationStatementPricePrises ({ commit }, params) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.nostrificationStatementPrice() + queryString(params))
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      response.data.results.map(item => {
        item.isHiddenEdit = (!item.date_end && (new Date() > new Date(item.date_start))) || item.date_end
      })
      if (SUCCESS_CODE?.includes(response.code)) commit('SET_DATA_STATE_BACKOFFICE', { nostrificationStatementPricePrices: response.data })
      return response
    },
    async setNostrificationStatementPrice ({ commit, dispatch }, body) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.post(backoffice.nostrificationStatementPrice(), { body })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) dispatch('getNostrificationStatementPricePrises')
      return response
    },
    async updateNostrificationStatementPrice ({ commit, dispatch }, data) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.patch(backoffice.nostrificationStatementPrice(data.id), { body: data.body })
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) dispatch('getNostrificationStatementPricePrises')
      return response
    },
    async removeNostrificationStatementPrice ({ commit, dispatch }, id) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.delete(backoffice.nostrificationStatementPrice(id))
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      if (SUCCESS_CODE?.includes(response.code)) dispatch('getNostrificationStatementPricePrises')
      return response
    },
    // POWERS OF ATTORNEY
    async getPowersOfAttorney (context, params) {
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.powersOfAttorney.document() + queryString(clearBody(params)))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_BACKOFFICE', { powersOfAttorney: response.data })
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
    },
    async getPowersOfAttorneyById (context, data) {
      const response = await api.get(backoffice.powersOfAttorney.document(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        if (STATUSES_DOCUMENT_CONSTANTS.rejectedPowersOfAttorney === response.data.status_document) {
          const responseComment = await context.dispatch('getCommentPowersOfAttorneyById', { id: response.data.id })
          response.data.comments = responseComment
        }
        return response.data
      }
      return {}
    },
    async uploadPowersOfAttorneyById (context, data) {
      const response = await api.patch(backoffice.powersOfAttorney.document(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('documentChanged')
        return response.data
      }
      return {}
    },
    async setRejectPowersOfAttorneyById (context, data) {
      const response = await api.post(backoffice.powersOfAttorney.comment(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        context.dispatch('uploadPowersOfAttorneyById', { id: data.id, body: { status_document: data.status_document } })
      }
      return SUCCESS_CODE.includes(response.code)
    },
    async getCommentPowersOfAttorneyById (context, data) {
      const response = await api.get(backoffice.powersOfAttorney.comment(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        return response.data
      }
      return []
    },
    async getCoursesETI (context, params) {
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.eti.direction() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_BACKOFFICE', { etiDirection: response.data })
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
    },
    async getCourseByIdETI (context, data) {
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.eti.direction(data.id) + queryString(data?.params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_DATA_STATE_BACKOFFICE', { etiDirectionById: response.data })
      context.commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
    },
    // BACKOFFICE V2 //
    async getListCoefficient ({ commit }, data) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.office.etiProfitPart() + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) {
        commit('SET_DATA_STATE_BACKOFFICE', { coefficient: response.data })
      }
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
    },
    async getCoefficientById ({ commit }, data) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.office.etiProfitPart(data.documentID))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        commit('SET_DATA_STATE_BACKOFFICE', { coefficientById: response.data })
      }
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
    },
    async deleteCoefficientById ({ commit }, data) {
      const response = await api.delete(backoffice.office.etiProfitPart(data.documentID))
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('coefficientDelete')
        commit('DELETE_ARRAY_DATA_BACKOFFICE', { coefficient: data.documentID })
      }
      return response
    },
    async createCoefficient ({ commit }, data) {
      const response = await api.post(backoffice.office.etiProfitPart(), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('coefficientAdd')
        commit('UPDATE_ARRAY_DATA_STATE_BACKOFFICE', { coefficient: response.data })
      }
      return response
    },
    async updateCoefficientById ({ commit }, data) {
      const response = await api.patch(backoffice.office.etiProfitPart(data.documentID), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('coefficientEdit')
        response.data.behavior = { viewInfoBlock: true }
        commit('SET_DATA_STATE_BACKOFFICE', { coefficientById: response.data })
      }
      return response
    },
    // ----- DEALING ----- //
    async getListDealing ({ commit }, data) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.office.monthRatio() + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) {
        commit('SET_DATA_STATE_BACKOFFICE', { dealing: response.data })
      }
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
    },
    async getDealingById ({ commit }, data) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.office.monthRatio(data.documentID))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        commit('SET_DATA_STATE_BACKOFFICE', { dealingById: response.data })
      }
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
    },
    async updateDealingById ({ commit, dispatch }, data) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.put(backoffice.office.monthRatio(data.documentID), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        notify.success('notify.success.updatedInformation')
        dispatch('getDealingById', data)
      }
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
      return response
    },
    // ------- ETI REGISTRY --------- //
    async getListETIRegistry ({ state }, data) {
      const response = await api.get(backoffice.office.etiRegistry() + queryString(data.params))
      response.data.map(val => {
        val.ratio = null
        state.dealingById.ntz_ratio.map(value => {
          if (value.ntz.id === val.institution.id) {
            val.ratio = value.ratio * 100
          }
        })
        val.consider = val.ratio !== null
        val.error = false
      })
      return response
    },
    // ------- Offline prise of documents ------ //
    async getListOfflinePriceOfDocuments ({ commit }, data) {
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: true })
      const response = await api.get(backoffice.office.offline.list() + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data = response.data.map(item => {
          item.typeDocName = `${item.type_of_form === 'First' ? i18n.t('First') : i18n.t('Second')} ${item.type_document.value}`
          return item
        }).sort((a, b) => {
          if (a.type_document.value < b.type_document.value) {
            return -1
          }
        })
        commit('SET_DATA_STATE_BACKOFFICE', { offlinePriceOfDocuments: response.data })
      }
      commit('SET_DATA_STATE_BACKOFFICE', { isLoading: false })
    },
    async getListOfflineDocumentsPricePastValue (context, data) {
      const response = await api.get(backoffice.office.offline.priceForPositionPastValue(data.id) + queryString(data.filter))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.map(item => {
          item.behavior = {}
          item.allowDelete = new Date(item.date_start) > new Date()
          // condition for actual value below
          item.allowEdit = !((!item.date_end || new Date(item.date_end) >= new Date()) && new Date(item.date_start) <= new Date())
        })
        //
        // context.commit('SET_DATA_STATE_BACKOFFICE', { documentsPriceOfflinePast: response.data })
        return response.data
      }
      return {}
    },
    async getListOfflineDocumentsPriceFutureValue (context, data) {
      const response = await api.get(backoffice.office.offline.priceForPositionFutureValue(data.id) + queryString(data.filter))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.map(item => {
          item.behavior = {}
          item.allowDelete = new Date(item.date_start) > new Date()
          // condition for actual value below
          item.allowEdit = !((!item.date_end || new Date(item.date_end) >= new Date()) && new Date(item.date_start) <= new Date())
        })
        return response.data

        // context.commit('SET_DATA_STATE_BACKOFFICE', { documentsPriceOfflineFuture: response.data })
      }
      return {}
    },
    async patchDocumentPriceOffline (context, data) {
      const response = await api.patch(backoffice.office.offline.priceForPosition(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        context.dispatch('getListOfflinePriceOfDocuments')
        context.dispatch('getListOfflineDocumentsPricePastValue', { id: data.id })
        context.dispatch('getListOfflineDocumentsPriceFutureValue', { id: data.id })
        notify.success('priceEtiEdited')
        return response
      }
    },
    async postDocumentPriceOffline ({ dispatch }, data) {
      const response = await api.post(backoffice.office.offline.priceForPosition(), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        await dispatch('getListOfflinePriceOfDocuments', { params: { page: 1, page_size: 10 } })
        notify.success('priceEtiAdded')
      }
      return response
    }
  }
}
