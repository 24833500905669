export const SUCCESS_CODE = [200, 201, 204]
export const apiV1 = 'api/v1'
export const apiV2 = 'api/v2'
export const apiV3 = 'api/v3'
export const TYPE_USER = {
  MEDICAL: 'Мед. працівник',
  CAPTAIN: 'Капітан порту',
  REPRESENTATIVE_OF_ETI: 'Представник НТЗ',
  SECRETARY_ATC: 'Секретар КПК',
  SECRETARY_SQC: 'Секретар ДКК',
  SECRETARY_SC: 'Секретар СЦ',
  HEAD_CREWING: 'Голова крюінгу',
  MANAGER_CREWING: 'Крюінг-менеджер',
  HEAD_GROUP: 'Голова групи',
  DIRECTOR_OF_AGENCY_TRANSLATION: 'Директор компанії перекладачів',
  WORKER_OF_AGENCE_TRANSLATION: 'Перекладач',
  AUTHORIZED_PERSON_SRB: 'Уповноважена особа (ПКМ)',
  ADMINISTRATOR: 'Адміністратор',
  ANALYST: 'Аналітик',
  HEAD_ITCS: 'Глава ІПДМ',
  ACCOUNTANT: 'Бухгалтер',
  MARAD: 'Марад',
  DIRECTOR_MES: 'Директор МОН'
}
export const REGISTRY_ROLE = [
  TYPE_USER.MEDICAL,
  TYPE_USER.CAPTAIN,
  TYPE_USER.AUTHORIZED_PERSON_SRB,
  TYPE_USER.ADMINISTRATOR,
  TYPE_USER.ANALYST,
  TYPE_USER.HEAD_ITCS,
  TYPE_USER.ACCOUNTANT,
  TYPE_USER.MARAD
]
export const USER_USE_SIGN = [
  TYPE_USER.CAPTAIN,
  TYPE_USER.AUTHORIZED_PERSON_SRB,
  TYPE_USER.DIRECTOR_OF_AGENCE_TRANSLATION,
  TYPE_USER.DIRECTOR_MES
]

export const statusExpired = 7
export const rejectNewAccount = 36
export const approveNewAccount = 41
export const verificationNewAccount = 34

export const STATEMENT_POSITION_SAILOR = [0, 2]
export const validStatusQualificationDoc = 19
export const contacts = {
  phone_number: { id: 1, name_ukr: 'Телефон', name_eng: 'Phone' },
  email: { id: 2, name_ukr: 'e-mail', name_eng: 'e-mail' },
  viber: { id: 3, name_ukr: 'Вайбер', name_eng: 'Viber' },
  telegram: { id: 4, name_ukr: 'Телеграм', name_eng: 'Telegram' }
}

export const FOR_CALIDATION_OTHER_COUNTRY_PHONE = [
  { abbr: 'UA', length: 13, dial_code: '+380', mask: '## ## ## ###' },
  { abbr: 'AU', length: 12, dial_code: '+61', mask: '## #### ####' },
  { abbr: 'AT', length: 13, dial_code: '+43', mask: '## ## ## ###' },
  { abbr: 'BE', length: 12, dial_code: '+32', mask: '## #### ####' },
  { abbr: 'CA', length: 12, dial_code: '+1', mask: '## #### ####' },
  { abbr: 'CN', length: 14, dial_code: '+86', mask: '' },
  { abbr: 'EE', length: 12, dial_code: '+372', mask: '## #### ####' },
  { abbr: 'FI', length: 13, dial_code: '+358', mask: '## ## ## ###' },
  { abbr: 'FR', length: 12, dial_code: '+33', mask: '## #### ####' },
  { abbr: 'DE', length: 14, dial_code: '+49', mask: '#### #######' },
  { abbr: 'HK', length: 12, dial_code: '+852', mask: '## #### ####' },
  { abbr: 'IN', length: 13, dial_code: '+91', mask: '## ## ## ###' },
  { abbr: 'ID', length: 14, dial_code: '+62', mask: '#### #######' },
  { abbr: 'IL', length: 13, dial_code: '+972', mask: '## ## ## ###' },
  { abbr: 'IT', length: 13, dial_code: '+39', mask: '## ## ## ###' },
  { abbr: 'KZ', length: 12, dial_code: '+77', mask: '## #### ####' },
  { abbr: 'LV', length: 12, dial_code: '+371', mask: '## #### ####' },
  { abbr: 'LT', length: 12, dial_code: '+370', mask: '## #### ####' },
  { abbr: 'MY', length: 12, dial_code: '+60', mask: '## #### ####' },
  { abbr: 'M#', length: 13, dial_code: '+52', mask: '## ## ## ###' },
  { abbr: 'NL', length: 12, dial_code: '+31', mask: '## #### ####' },
  { abbr: 'NZ', length: 13, dial_code: '+64', mask: '## ## ## ###' },
  { abbr: 'NZ', length: 13, dial_code: '+47', mask: '' },
  { abbr: 'PH', length: 13, dial_code: '+63', mask: '## ## ## ###' },
  { abbr: 'PL', length: 12, dial_code: '+48', mask: '## #### ####' },
  { abbr: 'PT', length: 13, dial_code: '+351', mask: '## ## ## ###' },
  { abbr: 'RO', length: 12, dial_code: '+40', mask: '## #### ####' },
  { abbr: 'RU', length: 12, dial_code: '+7', mask: '## #### ####' },
  { abbr: 'ES', length: 12, dial_code: '+34', mask: '## #### ####' },
  { abbr: 'SE', length: 12, dial_code: '+46', mask: '## #### ####' },
  { abbr: 'GB', length: 13, dial_code: '+44', mask: '## ## ## ###' },
  { abbr: 'US', length: 12, dial_code: '+1', mask: '## #### ####' }
]

export const radioButtonReportISC = [
  {
    id: 1,
    label: 'radioButtonsLabel.connectStatement',
    value: 'statement-connect-isc-report'
  },
  {
    id: 2,
    label: 'radioButtonsLabel.disconnectStatement',
    value: 'statement-disconnect-isc-report'
  }
]

export const initFilterReportSearchV2 = () => ({
  page: '1',
  page_size: '10',
  sailor_search: null,
  decision: null,
  direction: null,
  date_meeting: null,
  date_issued: null,
  branch_office: null,
  protokol_number: null,
  statement_number: null,
  commissioner_type: null,
  status_document: null,
  search: null,
  number: null,
  sailor_id: null,
  sailor_name: null,
  date_start: null,
  date_start__gte: null,
  date_start__lte: null,
  date_end: null,
  date_create: null,
  from_date: null,
  to_date: null,
  date_transfer: null,
  from_date_transfer: null,
  to_date_transfer: null,
  issued_by: null,
  sailor_birth: null,
  education_institution: null,
  faculty: null,
  has_statement: null,
  passed_educ_exam: null,
  educ_with_dkk: null,
  rank: null,
  position: null,
  country: null,
  port: null,
  type_document: null,
  create_at: null,
  type_user: null,
  id: null,
  crewing: null,
  manager: null,
  institution_name: null,
  institution_id: null,
  author: null,
  from_set_verification_at: null,
  to_set_verification_at: null,
  is_created_by: null,
  manager_search: null,
  nostrification_document_status: null,
  event_date: null,
  from_event_date: null,
  to_event_date: null,
  type_exam: null,
  item_status: null,
  date_not_bought: null,
  payment_datetime: null,
  payment_from_datetime: null,
  payment_to_datetime: null,
  crewing_group: null,
  crewing_company: null,
  date_processing: null,
  from_date_processing: null,
  to_date_processing: null,
  from_date_create: null,
  to_date_create: null,
  from_date_meeting: null,
  to_date_meeting: null,
  course: null,
  from_date_end_meeting: null,
  to_date_end_meeting: null,
  is_payed: null,
  serial: null,
  registry_number: null,
  extent: null,
  qualification: null,
  speciality: null,
  specialization: null,
  type_delivery: null,
  user: null,
  sailor_key: null,
  company_name: null,
  pay_date: null,
  from_pay_date: null,
  to_pay_date: null,
  protocol_number: null,
  protocol_year: null,
  direction_abbr: null,
  statement_branch_office: null,
  committe_head: null,
  commissioner: null,
  experience_required: null,
  document_property: null,
  has_crewing: null,
  statement_year: null,
  ces_exam: null,
  is_continue: null,
  has_protocol: null,
  is_new_document: null,
  price_f1_eq: null,
  price_f2_eq: null,
  price_f1_gte: null,
  price_f1_lte: null,
  price_f2_gte: null,
  price_f2_lte: null
})

export const STATUSES_DOCUMENT_CONSTANTS = {
  rejectByTransator: 130,
  rejectedByCrewing: 127,
  rejectByNostrification: 134,
  documentCreated: 47,
  createdPowersOfAttorney: 12,
  rejectedPowersOfAttorney: 68,
  approvedPowersOfAttorney: 67,
  waitingForTranslation: 129,
  waitingSignMon: 138,
  waitingForNostrification: 133,
  rejectedByNostrificator: 134,
  inProgressNostrification: 135,
  waitingNostrificatorPayment: 136,
  rejectedByTranslator: 130,
  inProgressTranslation: 131,
  waitingTranslatorPayment: 132,
  waitingSignTranslator: 137,
  waitingTranslation: 129,
  draft: 122,
  InProcessing: 21,
  rejectDigitalizationDocumentISC: 37,
  DIGITIZATION_DOCUMENT: {
    SC: {
      REJECT: 37
    }
  },
  SAILOR: {

    PASSPORTS: {
      FOREIGN: {
        REJECTED: 71,
        APPROVED: 70,
        DRAFT: 74
      },
      CITIZEN: {
        VERIFICATION: 34,
        APPROVED: 70,
        REJECTED: 71,
        DRAFT: 74,
        DELETED: 86
      },
      SAILOR_PASSPORT: {
        REJECTED: 37,
        DELETED: 86
      }
    },
    EDUCATION: {
      DOCUMENTS: {
        REJECTED: 13,
        VERIFICATION: 34,
        DELETED: 86
      }
    },
    QUALIFICATION: {
      VALIDED: 2,
      VERIFICATION: 34,
      REJECTED: 37,
      DELETED: 86
    },
    ETI: {
      DELETED: 86
    },
    MEDICAL: {
      VERIFICATION: 34,
      REJECTED: 37,
      DELETED: 86
    },
    PROTOCOL_SQC: {
      DELETED: 86
    },
    EXPERIENCE: {
      SERVICE_RECORD_BOOK: {
        REJECTED: 13,
        IN_PROCESSING: 14,
        VERIFICATION: 34,
        DELETED: 86
      },
      SERVICE_RECORD_BOOK_LINE: {
        IN_PROCESSING: 5,
        APPROVED: 9,
        REJECTED: 10,
        VERIFICATION: 34,
        DELETED: 86
      },
      CERTIFICATE: {
        IN_PROCESSING: 5,
        APPROVED: 9,
        INVALID: 10,
        VERIFICATION: 34,
        DELETED: 86
      }
    }
  }
}

export const STATUSES_STATEMENT_CONSTANTS = {
  inProcess: 25,
  documentCreated: 47,
  statementCreated: 139,
  CREATED_BY_SAILOR: 42,
  CREATED_IN_ETI: 114,
  WAIT_FOR_DIGITIZATION: 121,
  draft: 74,
  DRAFT_122: 122,
  VERIFICATION: 34,
  eti: {
    inProgress: 63,
    canceles: 91
  },
  clarificationOfData: {
    rejected: 23,
    approved: 24
  },
  SAILOR_PASSPORT: {
    INVALID: 37
  },
  NEW_ACCOUNT_SAILOR: {
    ISC: {
      APPROVED: 41,
      REJECT: 36,
      VERIFICATION: 34
    }
  },
  PACKAGE: {
    REJECT: 23,
    DOCUMENT_CREATED: 47,
    CANCEL: 73,
    CREATED: 139
  },
  SAILOR: {
    SRB: {
      STATEMENT: 49,
      DELETE: 86
    },
    QUALIFICATION: {
      STATEMENT: {
        REJECTED: 23,
        APPROVED: 24,
        IN_PROGRESS: 25,
        VERIFICATION: 34,
        DELETED: 86
      },
      CERTIFICATE_OF_NAVIGATOR: {
        DRAFT: 74,
        DELETED: 86
      }
    },
    ETI: {
      REJECT: 62,
      IN_PROCESSING: 63
    },
    SAILOR_PASSPORT: {
      REJECT: 71,
      DELETED: 86
    },
    EDUCATION: {
      STATEMENT_ATC: {
        REJECTED: 79,
        DELETED: 86,
        APPROVED: 78,
        PASSED_EXAM: 141
      }
    },
    MEDICAL: {
      REJECTED: 76,
      DELETED: 86
    },
    SQC: {
      REJECTED: 23,
      APPROVED: 24,
      IN_PROCESSING: 25,
      VERIFICATION: 34,
      DELETED: 86
    },
    EXPERIENCE: {
      SERVICE_RECORD_BOOK: {
        REJECTED: 49,
        VERIFICATION: 34,
        DELETED: 86
      }
    }
  },
  CREATE_STATEMENT: 139,
  CREWING_CONECT_STATEMENT: {
    REJECT: 68
  }
}

export const TYPE_DOCUMENTS_CONSTANTS = {
  SAILOR_IDENTITY_CARD: 1,
  CITIZEN_PASSPORT: 2,
  FOREIGN_PASSPORT: 3,
  EDUCATION: 4,
  QUALIFICATION: 5,
  PROOF_OF_DIPLOMA: 6,
  CERTIFICATE_OF_NAVIGATOR: 7,
  CERTIFICATE_ETI: 8,
  SERVICE_RECORD: 9,
  PROTOCOL_SQC: 10,
  MEDICAL_CERTIFICATE: 11,
  EXPERIENCE_IN_SERVICE_RECORD: 12,
  EXPERIENCE_CERTIFICATE: 13,
  EXPERIENCE_HISTORY: 14
}

export const TYPE_DOCUMENT_NZ = {
  DIPLOMA_OF_HIGHER_EDUCATION: 1,
  DIPLOMA_OF_QUALIFIED_WORKER: 2,
  CERTIFICATE_OF_IMPROVING_QUALIFICATION: 3,
  CERTIFICATE_OF_AWARDING_IMPROVING_OF_TRADE_QUALIFICATION: 4
}

export const TYPE_QUALIFICATION_NZ = [
  {
    TYPE_DOCUMENT: [TYPE_DOCUMENT_NZ.DIPLOMA_OF_HIGHER_EDUCATION],
    TYPE_NZ: 5
  },
  {
    TYPE_DOCUMENT: [TYPE_DOCUMENT_NZ.CERTIFICATE_OF_IMPROVING_QUALIFICATION],
    TYPE_NZ: 2
  },
  {
    TYPE_DOCUMENT: [TYPE_DOCUMENT_NZ.DIPLOMA_OF_QUALIFIED_WORKER,
      TYPE_DOCUMENT_NZ.CERTIFICATE_OF_AWARDING_IMPROVING_OF_TRADE_QUALIFICATION],
    TYPE_NZ: 3
  }
]

export const COUNTRY_CONSTANTS = {
  UKRAINE: 2
}

export const TYPE_CONDITIONAL_CONSTANTS = {
  ASSIGNMENT_OF_RANK_SQC: 0,
  CONFIRM_RANK_SQC: 1,
  CONFIRMATION_WITH_THE_ASSIGNMENT_POSITION: 2,
  EXPERIENCE: 3
}

export const TYPE_SAILOR_PASSPORT_PROCESSING_STATUS = {
  DO_NOT_NEED: 0,
  NEED_IN_20_DAYS: 1,
  NEED_IN_7_DAYS: 2,
  CONTINUED_IN_20_DAYS: 3,
  CONTINUED_IN_7_DAYS: 4
}

export const TYPE_ACCESS_OF_SYSTEM = {
  FREE: 0,
  PAID: 1,
  PLUSE_PAID: 2
}

export const TYPE_DOCUMENT_TITLE = {
  MEDICAL: 'Медичне свідоцтво',
  ETI_CERTIFICATE: ['Свідоцтво НТЗ', 'Сертифікат НТЗ']
}

export const LINK_AUTHENTICATOR_GOOGLE = {
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
  APP_STORE: 'https://apps.apple.com/us/app/google-authenticator/id388497605'
}

export const CONST_TYPE_EDUCATION_INSTITUTION = {
  CERTIFICATION_TRAINING: 2
}

export const CONTENT_TYPE = {
  STATEMENT_ETI: 'statementeti',
  STATEMENT_SAILOR_PASSPORT: 'statementsailorpassport',
  STATEMENT_MEDICAL_CERTIFICATE: 'statementmedicalcertificate',
  STATEMENT_ADVANCED_TRAINING: 'statementadvancedtraining'
}
