import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/localization/index'
import vuetify from '@/plugins/vuetify'
import VueSwal from 'vue-swal'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Unicon from 'vue-unicons'
import { uniPlusCircle, uniCancel, uniPen, uniMultiply, uniAngleDown, uniAngleUp, uniCheck, uniPlus, uniInfoCircle,
  uniRefresh, uniScenery, uniUserCircle, uniFileUploadAlt, uniSync, uniBell, uniArrowCircleRight, uniCheckCircle,
  uniUpload, uniTrashAlt, uniFolderCheck, uniBookOpen, uniEnvelopeAdd, uniPadlock, uniUnlockAlt, uniKeySkeleton } from 'vue-unicons/src/icons'
import { logo, logoMain, dcc, experience, qualification, medical, passports, graduation, ntzcerts, positionStatement,
  arrowRight, digitizationDocument } from '@/custom-icons'
import Multiselect from 'vue-multiselect'
import VueTheMask from 'vue-the-mask'
import VueNumeric from 'vue-numeric'
import Clipboard from 'v-clipboard'
import VueMask from 'v-mask'
import { sync } from 'vuex-router-sync'

import { Authorization } from '@ananasbear/auth'
import { EDSign } from '@ananasbear/eds'
import notify from '@ananasbear/notify'
import validate from '@ananasbear/validation'
import geolocation from '@ananasbear/geolocation'
import api from './plugins/api'

import Table from './components/layouts/Table/Table.vue'
import TableV2 from './components/layouts/Table/TableV2.vue'
import ValidationAlert from '@/components/atoms/FormComponents/ValidationAlert/ValidationAlert.vue'
import Paginate from '@/components/atoms/Paginate.vue'
import ReportSearch from '@/components/molecules/ReportSearch/ReportSearch.vue'
import ModalWindow from '@/components/atoms/ModalWindow.vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/assets/css/authg.css'
import '@/assets/sass/main.sass'
import '@/assets/scss/main.scss'
import 'vuesax/dist/vuesax.css'
import '@/assets/sass/media/media.sass'
import '@/assets/css/main.css'
import '@/assets/css/bootstrap.css'
import '@/assets/css/bootstrap-extended.css'
import '@/assets/css/vertical-menu.css'
import '@/assets/css/core.css'
import '@/assets/sass/seafarer/main.sass'

Unicon.add([uniPlusCircle, uniCancel, uniPen, uniMultiply, uniAngleDown, uniAngleUp, uniCheck, uniPlus, uniInfoCircle,
  uniRefresh, uniScenery, uniUserCircle, logo, logoMain, dcc, experience, qualification, medical, passports, graduation,
  ntzcerts, uniFileUploadAlt, uniSync, uniBell, uniArrowCircleRight, positionStatement, uniCheckCircle, uniUpload,
  uniTrashAlt, uniFolderCheck, uniBookOpen, arrowRight, uniEnvelopeAdd, uniPadlock, uniUnlockAlt, uniKeySkeleton,
  digitizationDocument ])

Vue.use(router)
Vue.use(VueSwal)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuelidate)
Vue.use(Unicon)
Vue.component('multiselect', Multiselect)
Vue.use(VueTheMask)
Vue.use(VueNumeric)
Vue.use(Clipboard)
Vue.use(VueMask, {
  placeholders: {
    C: /[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ']/
  }
})

Vue.component('Table', Table)
Vue.component('TableV2', TableV2)
Vue.component('ValidationAlert', ValidationAlert)
Vue.component('Paginate', Paginate)
Vue.component('ReportSearch', ReportSearch)
Vue.component('ModalWindow', ModalWindow)

sync(store, router)

const authorizationOptions = {
  tokenKey: 'Token',
  urls: {
    totp: {
      create: 'api/v2/accounts/totp/create/',
      verify: 'api/v2/accounts/totp/verify/',
      login: 'api/v2/accounts/totp/login/'
    },
    u2f: {
      login: 'api/v2/accounts/u2f/login/',
      create: 'api/v2/accounts/u2f/create/',
      authorization: {
        options: `api/v2/accounts/fido2/begin_authorization/`,
        authorization: `api/v2/accounts/fido2/complete_authorization/`
      }
    },
    general: { login: 'api/v2/accounts/login/' }
  }
}
Vue.prototype.$api = api
Vue.prototype.$notification = notify
Vue.prototype.$auth = new Authorization(authorizationOptions)
Vue.prototype.$valid = validate
Vue.prototype.$eds = new EDSign({ proxy: process.env.VUE_APP_CIFRA || 'https://api.cifra.com.ua/api/v1/proxy/' })
Vue.prototype.$geo = geolocation

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
