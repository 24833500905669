import en from './en.json'
import ua from './ua.json'
import bo from './back_office.json'

import Vue from 'vue'
import VueI18n from 'vue-i18n'

let locale = 'uk'
let fallbackLocale = 'uk'
let locales = {
  // current locale
  locale,

  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'uk',
    flag: 'ua',
    label: 'Ukrainian',
    messages: ua
  }, {
    code: 'bo',
    flag: 'ua',
    label: 'Ukrainian',
    messages: bo
  }]
}

Vue.use(VueI18n)
const messages = {}
locales.availableLocales.forEach((l) => { messages[l.code] = l.messages })
export const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages,
  silentFallbackWarn: true
})
i18n.locale = location.hostname === 'googie.com.ua' ? 'bo' : 'ua'
let sessionLang = sessionStorage.getItem('sessionLang')
sessionLang && (i18n.locale = sessionLang)
i18n.locales = locales.availableLocales
export default i18n
