import api from '@/plugins/api.js'
import notify from '@ananasbear/notify'
import { documents, media, verification } from '@/configs/endpoints'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { getFiles, postFiles, queryString, clearBody, FromObjectToFormData } from '@/mixins/main'
import i18n from '@/plugins/localization/index'

export default {
  state: () => ({
    digitalDocument: {},
    sailorDigitalDocument: {},
    photoVerification: {},
    itemVerificationDocument: {},
    listDocumentForSign: {},
    listRecordBooksForDigitization: [],
    isLoading: true
  }),
  mutations: {
    SET_DATA_STATE_DOCUMENT (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    }
  },
  actions: {
    async getDocumentNostrificationById (context, data) {
      const response = await api.get(documents.nostrification.documenetEducation(data.documentId))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        return response.data
      } else return {}
    },
    async saveDocumentNostrification (context, data) {
      const responseFile = await api.get(media.nostrification[data.typeDocument][data.typeFile](data.id))
      if (SUCCESS_CODE.includes(responseFile.code)) {
        const response = await getFiles(responseFile.data.url, data.fileName, data.contentType)
        if (SUCCESS_CODE.includes(response.code)) return notify.success(`notify.success.${data.typeDocument}.${data.typeFile}`)
      }
      notify.error('notify.error.downloadFile')
    },
    async getListDigitalizationDocument (context, params) {
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await api.get(documents.digitalizationDocumentISC.document() + queryString(clearBody(params)))
      if (SUCCESS_CODE.includes(response.code)) {
        context.commit('SET_DATA_STATE_DOCUMENT', { digitalDocument: response.data })
      }
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getDigitalizationDocumentISCById (context, data) {
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await api.get(documents.digitalizationDocumentISC.document(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.rejectDigitalizationDocumentISC) {
          response.data.comments = await context.dispatch('getCommentRejectDigitalizationDocumentISCId', { id: data.id })
        }
        context.commit('SET_DATA_STATE_DOCUMENT', { sailorDigitalDocument: response.data })
      }
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async uploadDigitalizationDocumentISCById ({ commit, dispatch }, data) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await api.patch(documents.digitalizationDocumentISC.document(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.rejectDigitalizationDocumentISC) {
          data.comments.id = data.id
          response.data.comments = await dispatch('setCommentRejectDigitalizationDocumentISCId', data.comments)
          notify.success('documentReject')
        } else notify.success('notify.success.updatedDocument')
        commit('SET_DATA_STATE_DOCUMENT', { sailorDigitalDocument: response.data })
      }
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
      return response
    },
    async getCommentRejectDigitalizationDocumentISCId (context, data) {
      const response = await api.get(documents.digitalizationDocumentISC.comment(data.id))
      if (SUCCESS_CODE.includes(response.code)) return response.data
      return []
    },
    async saveVerificationDocumentISC ({ dispatch, commit }, data) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await dispatch(data.method, data)
      if (SUCCESS_CODE.includes(response.code)) {
        await dispatch('getDigitalizationDocumentISCById', { id: data.id })
      }
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
      return response
    },
    async setCommentRejectDigitalizationDocumentISCId (context, data) {
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await api.post(documents.digitalizationDocumentISC.comment(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) return [response.data]
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
      return []
    },
    async getInfoAboutDocumentForDigitizationISC (context, data) {
      const response = await api.post(verification.iscCheckDocumentInfo(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) return true
      return false
    },
    async setDigitizationDocumentISC ({ commit }, data) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await api.post(verification.createDocumentISC(data.id), { body: data.data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        if (data.data.media?.files.length) await postFiles(data.data.media.files, data.data.media.name, response.data.document_id)
        notify.success('notify.success.createdDocument')
      }
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      return response
    },
    // -----------------DOCUMENT SAILOR PASSPORT-------------------- //
    async getListDocumentPhotoOnVerification ({ commit }, params) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await api.get(documents.photoOnVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DOCUMENT', { photoVerification: response.data })
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    // -----------------DOCUMENT VERIFICATION ----------------------- //
    async getListSailorPassportOnVerification ({ commit }, params) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true, itemVerificationDocument: {} })
      const response = await api.get(documents.verification.sailorPassport + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DOCUMENT', { itemVerificationDocument: response.data })
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getListInternationalPassportOnVerification ({ commit }, params) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true, itemVerificationDocument: {} })
      const response = await api.get(documents.verification.internationalPassport + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DOCUMENT', { itemVerificationDocument: response.data })
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getListEducationOnVerification ({ commit }, params) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true, itemVerificationDocument: {} })
      const response = await api.get(documents.verification.education + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DOCUMENT', { itemVerificationDocument: response.data })
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getListQualificationOnVerification ({ commit }, params) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true, itemVerificationDocument: {} })
      const response = await api.get(documents.verification.qualification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DOCUMENT', { itemVerificationDocument: response.data })
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getListNavigatorCertificateOnVerification ({ commit }, params) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true, itemVerificationDocument: {} })
      const response = await api.get(documents.verification.navigatorCertificate + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DOCUMENT', { itemVerificationDocument: response.data })
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getListLineServiceRecordOnVerification ({ commit }, params) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true, itemVerificationDocument: {} })
      const response = await api.get(documents.verification.lineServiceRecord + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DOCUMENT', { itemVerificationDocument: response.data })
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getListServiceRecordOnVerification ({ commit }, params) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true, itemVerificationDocument: {} })
      const response = await api.get(documents.verification.serviceRecord + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DOCUMENT', { itemVerificationDocument: response.data })
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getListMedicalOnVerification ({ commit }, params) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true, itemVerificationDocument: {} })
      const response = await api.get(documents.verification.medical + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DOCUMENT', { itemVerificationDocument: response.data })
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getListCitizenPassportOnVerification ({ commit }, params) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true, itemVerificationDocument: {} })
      const response = await api.get(documents.verification.civilPassport + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_DATA_STATE_DOCUMENT', { itemVerificationDocument: response.data })
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    // --------- DIGITIZATION DOCUMENTS SC ------------------- //
    async getListDigitalizationDocumentSC (context, params) {
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await api.get(verification.existingDocument.list() + queryString(clearBody(params)))
      if (SUCCESS_CODE.includes(response.code)) {
        context.commit('SET_DATA_STATE_DOCUMENT', { digitalDocument: response.data })
      }
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getDigitalizationDocumentSCById (context, data) {
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: true, sailorDigitalDocument: {} })
      const response = await api.get(verification.existingDocument.list(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.rejectDigitalizationDocumentSC ||
          response.data.status_document.id === STATUSES_DOCUMENT_CONSTANTS.DIGITIZATION_DOCUMENT.SC.REJECT) {
          response.data.comments = await context.dispatch('getCommentRejectDigitalizationDocumentSCId', { id: data.id })
        }
        context.commit('SET_DATA_STATE_DOCUMENT', { sailorDigitalDocument: response.data })
      }
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async updateFileDigitalizationDocumentSCById ({ commit }, data) {
      const response = await api.patch(verification.existingDocument.updateFile(data.statementId, data.fileId),
        { body: FromObjectToFormData(data.media) })
      if (SUCCESS_CODE.includes(response.code)) {
        notify.success('notify.success.fileHasBeenEdited')
      }
      return response
    },

    async removeFileDigitalizationDocumentSCById ({ commit, state }, data) {
      const response = await api.delete(verification.existingDocument.removeFile(data.statementId, data.fileId))
      return response
    },
    async uploadDigitalizationDocumentSCById (context, data) {
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await api.patch(verification.existingDocument.list(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.behavior = { viewInfoBlock: true }
        if (response.data.status_document === STATUSES_DOCUMENT_CONSTANTS.DIGITIZATION_DOCUMENT.SC.REJECT ||
          response.data.status_document.id === STATUSES_DOCUMENT_CONSTANTS.DIGITIZATION_DOCUMENT.SC.REJECT) {
          data.comments.id = data.id
          response.data.comments = await context.dispatch('setCommentRejectDigitalizationDocumentSCId', data)
          notify.success(i18n.t('notify.success.statementRejected'))
        } else notify.success(i18n.t('notify.success.updatedDocument'))
        context.commit('SET_DATA_STATE_DOCUMENT', { sailorDigitalDocument: response.data })
      }
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async setCommentRejectDigitalizationDocumentSCId (context, data) {
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await api.post(verification.existingDocumentCommnet(data.id), { body: data.comments.body })
      if (SUCCESS_CODE.includes(response.code)) return [response.data]
      context.commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
      return []
    },
    async getCommentRejectDigitalizationDocumentSCId (context, data) {
      const response = await api.get(verification.existingDocumentCommnet(data.id))
      if (SUCCESS_CODE.includes(response.code)) return response.data
      return []
    },
    async getInfoAboutDocumentForDigitizationSC (context, data) {
      const response = await api.post(verification.checkDocumentInfo(data.id), { body: data.body })
      if (SUCCESS_CODE.includes(response.code)) return true
      return false
    },
    async setDigitizationDocumentSC ({ dispatch }, data) {
      const response = await api.post(verification.createDocument(data.id), { body: data.data.body })
      if (SUCCESS_CODE.includes(response.code)) {
        if (data.data.media?.files.length) await postFiles(data.data.media.files, data.data.media.name, response.data.document_id)
        notify.success('notify.success.createdDocument')
      }
      return response
    },
    async saveVerificationDocumentSC ({ dispatch, commit }, data) {
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: true })
      const response = await dispatch(data.method, data)
      if (SUCCESS_CODE.includes(response.code)) {
        await dispatch('getDigitalizationDocumentSCById', { id: data.id })
      }
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
      return response
    },
    async getDocumentsForSinging ({ commit }, params) {
      const response = await api.get(documents.qualDocOnSign + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) {
        commit('SET_DATA_STATE_DOCUMENT', { listDocumentForSign: response.data })
      }
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    },
    async getRecordBookrsForDigitization ({ commit }, data) {
      const response = await api.get(documents.listRecordBooksForDigitization(data.id))
      if (SUCCESS_CODE.includes(response.code)) {
        commit('SET_DATA_STATE_DOCUMENT', { listRecordBooksForDigitization: response.data })
      }
      commit('SET_DATA_STATE_DOCUMENT', { isLoading: false })
    }
  }
}
