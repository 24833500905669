export default [
  {
    path: '/digitalization-document-isc',
    name: 'digitalization-document-isc',
    component: () => import('@/views/Documents/DigitalizationISC/Table.vue')
  },
  {
    path: '/digitalization-document-isc/:statementId',
    name: 'digitalization-document-isc-info',
    component: () => import('@/views/Documents/DigitalizationISC/Document.vue')
  },
  {
    path: '/digitalization-document-sc',
    name: 'digitalization-document-sc',
    component: () => import('@/views/Documents/DigitizationSC/Table.vue')
  },
  {
    path: '/digitalization-document-sc/:statementId',
    name: 'digitalization-document-sc-info',
    component: () => import('@/views/Documents/DigitizationSC/Document.vue')
  }
]
