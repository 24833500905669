import api from '@/plugins/api'
import { reports, statisticsVerification as endpoints } from '@/configs/endpoints'
import { SUCCESS_CODE } from '@/configs/constants'
import { downloadBlob, queryString } from '@/mixins/main'
import notify from '@ananasbear/notify'

export default {
  state: () => ({
    itemsVerification: {},
    isLoad: true,
    excels: {}
  }),

  mutations: {
    SET_STATE_DATA_STATISTICS (state, data) {
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    }
  },

  actions: {
    async getUserVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.userVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getExistingDocVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.existingDocVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getSailorPhotoVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.sailorPhotoVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getServiceRecordVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.serviceRecordVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getLineInServiceRecordVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.lineInServiceRecordVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getEducationVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.educationVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getMedicalCertificateVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.medicalCertificateVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getQualificationVerification (context, params) {
      const response = await api.get(endpoints.qualificationVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getNavigatorVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.navigatorVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getForeignPassportVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.foreignPassportVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getSailorPassportVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.sailorPassportVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getSailorCitizenPassportVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.sailorCitizenPassportVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getListStatisticsReportExcel (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.listExcel + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { excels: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    },
    async getDocumentReportExcel (context, data) {
      const response = await api.get(reports.saveReport(data.token), { responseType: 'blob' })
      if (SUCCESS_CODE.includes(response.code)) {
        await downloadBlob(response.data, data.file_name)
        notify.success('notify.success.downloadFile')
      }
    },
    async getStatisticExel (context, data) {
      const response = await api.get(endpoints[data.nameLink] + 'xlsx/' + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) notify.success('notify.success.reportCreate')
    },
    async getUserISCVerification (context, params) {
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: true })
      const response = await api.get(endpoints.userISCVerification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_STATISTICS', { itemsVerification: response.data, isLoad: false })
      context.commit('SET_STATE_DATA_STATISTICS', { isLoad: false })
    }
  }
}
