import store from '@/store'
import { canChangeStatus } from '@/mixins/main'

const p = {}
const r = {}

export const setPermissions = () => {
  p.groupProfileId = store.state.main.user.group
  p.superAdmin = store.state.main.permissions.includes('superAdmin')
  p.mainInfoRO = store.state.main.permissions.includes('readMainInfo')
  p.mainInfoW = store.state.main.permissions.includes('writeMainInfo')
  p.newUserC = store.state.main.permissions.includes('createUsers')
  p.sailorC = store.state.main.permissions.includes('createSeafarer')
  p.ratingRO = store.state.main.permissions.includes('ratingSailor')
  p.ratingW = store.state.main.permissions.includes('changeRating')
  p.sailorWithoutTaxC = store.state.main.permissions.includes('createSeafarerWithoutTaxNumber')
  p.userHiystoryRO = store.state.main.permissions.includes('readUserHistory')
  p.packageQualificationStatementRO = store.state.main.permissions.includes('readListApplicationFromPacket')
  p.checkDocumentsW = store.state.main.permissions.includes('writeCheckDocuments')
  p.authorRO = store.state.main.permissions.includes('readAuthorCreate')
  p.checkNewAccountsW = store.state.main.permissions.includes('checkNewAccounts')
  p.verificationAuthorRO = store.state.main.permissions.includes('readAuthorApprov')
  p.postVerificationW = store.state.main.permissions.includes('writePostVerificationDocuments')
  p.add_sailorcontact = store.state.main.permissions.includes('add_sailorcontact')
  p.delete_sailorcontact = store.state.main.permissions.includes('delete_sailorcontact')

  // REPORTS
  p.reportSqcProtocolRO = store.state.main.permissions.includes('reportSqcProtocol') || store.state.main.permissions.includes('reportProtocolSQC')
  p.reportSqcStatementRO = store.state.main.permissions.includes('readReportApplicationSQC')
  p.reportStudentRO = store.state.main.permissions.includes('readStudentReport')
  p.reportCertificateRO = store.state.main.permissions.includes('readCertificatesReport')
  p.reportSailorPassportRO = store.state.main.permissions.includes('readReportSailorPassport')
  p.reportEducationRO = store.state.main.permissions.includes('readGraduationReport')
  p.reportQualificationRO = store.state.main.permissions.includes('readReportQualificationDocument')
  p.reportListExcel = store.state.main.permissions.includes('readReportListOfFiles')
  p.reportFinanceRO = store.state.main.permissions.includes('readFinancialReport')
  p.reportSubmittedDocumentRO = store.state.main.permissions.includes('readSubmittedDocumentsReport')
  p.reportSailorPassportStatementsRO = store.state.main.permissions.includes('readReportStatementSailorPassport')
  p.reportCrewingSailorConnectRO = store.state.main.permissions.includes('view_report_crewing_sailor')
  p.distributionReportRO = store.state.main.permissions.includes('view_distribution_report')

  p.postVerificationRO = store.state.main.permissions.includes('readPostVerification')
  p.crewingManagerStatementFromSailorRO = store.state.main.permissions.includes('readCrewingManagerStatementFromSailor')
  p.crewingManagerStatementRO = store.state.main.permissions.includes('readCrewManagerStatement')
  p.crewingManagerVerificationDocsRO = store.state.main.permissions.includes('readCrewingManagerVerificationDocs')
  p.crewingCompanyRO = store.state.main.permissions.includes('readCrewingCompany')
  p.vnzRO = store.state.main.permissions.includes('readVnz')
  p.packetPriceRO = store.state.main.permissions.includes('readPriceForPosition')
  p.crewingManagerInfoRO = store.state.main.permissions.includes('readCrewingManagerInfo')
  p.verificationCommentW = store.state.main.permissions.includes('writeCommentForVerification')
  p.etiStatementsRO = store.state.main.permissions.includes('readReportApplicationETI')
  p.mergeEducationDocuments = store.state.main.permissions.includes('merge_education_documents')
  p.mergeQualificationDocuments = store.state.main.permissions.includes('merge_qualification_documents')
  p.mergeSailors = store.state.main.permissions.includes('mergeSeafarer')
  p.etiPaymentsRO = store.state.main.permissions.includes('readPaymentsETI')
  p.advanceTrainingStatementsRO = store.state.main.permissions.includes('readReportApplicationATC')
  p.openSailorCabinetProfile = store.state.main.permissions.includes('view_sailor_token')

  p.sailorPassportRO = store.state.main.permissions.includes('readSeafarerPassport')
  p.sailorPassportC = store.state.main.permissions.includes('createSeafarerPassport')
  p.createDocumentFromExistingDocument = store.state.main.permissions.includes('create_document_from_existing_document')
  p.newSailorPassportC = store.state.main.permissions.includes('createNewSailorPassport')
  p.sailorPassportW = store.state.main.permissions.includes('writeSeafarerPassport')
  p.sailorPassportWS = store.state.main.permissions.includes('writeSeafarerPassportStatus')
  p.sailorPassportD = store.state.main.permissions.includes('deleteSeafarerPassport')
  p.sailorPassportPreVerificationW = store.state.main.permissions.includes('writeSeafarerPassportPreVerification')
  p.sailorPassportPreVerificationWS = store.state.main.permissions.includes('writeSeafarerPassportPreVerificationStatus')

  p.civilPassportRO = store.state.main.permissions.includes('view_passport')
  p.civilPassportC = store.state.main.permissions.includes('add_passport')
  p.civilPassportW = store.state.main.permissions.includes('change_passport')
  p.civilPassportWS = store.state.main.permissions.includes('change_status_citizenpassport')
  p.civilPassportD = store.state.main.permissions.includes('delete_passport')
  p.civilPassportVerification = store.state.main.permissions.includes('view_report_verification_passport')

  p.fullNameChangesRO = store.state.main.permissions.includes('readSurnameChanges')
  p.fullNameChangesC = store.state.main.permissions.includes('createSurnameChanges')
  p.fullNameChangesW = store.state.main.permissions.includes('writeSurnameChanges')
  p.fullNameChangesD = store.state.main.permissions.includes('deleteSurnameChanges')

  p.sailorPassportStatementRO = store.state.main.permissions.includes('readSeafarerPassportApplication')
  p.sailorPassportStatementC = store.state.main.permissions.includes('createSeafarerPassportApplication')
  p.sailorPassportStatementWS = store.state.main.permissions.includes('writeSeafarerPassportApplicationStatus')
  p.sailorPassportStatementD = store.state.main.permissions.includes('deleteSeafarerPassportApplication')

  p.educationRO = store.state.main.permissions.includes('readGraduation')
  p.educationC = store.state.main.permissions.includes('createGraduation')
  p.educationW = store.state.main.permissions.includes('writeGraduation')
  p.educationWS = store.state.main.permissions.includes('writeGraduationStatus')
  p.educationD = store.state.main.permissions.includes('deleteGraduation')
  p.educationPhotoC = store.state.main.permissions.includes('addPhotoGraduation')
  p.educationFromExcelC = store.state.main.permissions.includes('createEducationDocsFromExel')
  p.educationPreVerificationW = store.state.main.permissions.includes('writeGraduationPreVerification')
  p.educationPreVerificationWS = store.state.main.permissions.includes('writeGraduationPreVerificationStatus')

  p.studentRO = store.state.main.permissions.includes('readStudentsID')
  p.studentC = store.state.main.permissions.includes('createStudentsID')
  p.studentW = store.state.main.permissions.includes('writeStudentsID')
  p.studentWS = store.state.main.permissions.includes('writeStudentsIDStatus')
  p.studentD = store.state.main.permissions.includes('deleteStudentsID')

  p.educationStatementRO = store.state.main.permissions.includes('readStatementAdvancedTraining')
  p.educationStatementC = store.state.main.permissions.includes('createStatementAdvancedTraining')
  p.educationStatementW = store.state.main.permissions.includes('writeStatementAdvancedTraining')
  p.educationStatementWS = store.state.main.permissions.includes('writeStatementAdvancedTrainingStatus')
  p.educationStatementT = store.state.main.permissions.includes('createAdvancedTraining')
  p.educationStatementD = store.state.main.permissions.includes('deleteStatementAdvancedTraining')

  p.qualificationRO = store.state.main.permissions.includes('readQualification')
  p.qualificationC = store.state.main.permissions.includes('createQualification')
  p.existQualificationC = store.state.main.permissions.includes('createExistsQualification')
  p.qualificationW = store.state.main.permissions.includes('writeQualification')
  p.qualificationLimitationsW = store.state.main.permissions.includes('edit_qualification_limitation')
  p.qualificationWS = store.state.main.permissions.includes('writeQualificationStatus')
  p.qualificationWAS = store.state.main.permissions.includes('writeQualificationInAnyStatus')
  p.qualificationD = store.state.main.permissions.includes('deleteQualification')
  p.qualificationPreVerificationW = store.state.main.permissions.includes('writeQualificationPreVerification')
  p.qualificationPreVerificationWS = store.state.main.permissions.includes('writeQualificationPreVerificationStatus')

  p.qualificationStatementRO = store.state.main.permissions.includes('readQualificationApplication')
  p.qualificationStatementC = store.state.main.permissions.includes('createQualificationApplication')
  p.qualificationStatementW = store.state.main.permissions.includes('writeQualificationApplication')
  p.qualificationStatementWS = store.state.main.permissions.includes('writeQualificationApplicationStatus')
  p.qualificationStatementWAS = store.state.main.permissions.includes('writeQualificationInApplicationAnyStatus')
  p.qualificationStatementD = store.state.main.permissions.includes('deleteQualificationApplication')

  p.add_statement_replace_qualification = store.state.main.permissions.includes('add_statement_replace_qualification')
  p.view_statement_replace_qualification = store.state.main.permissions.includes('view_statement_replace_qualification')
  p.edit_statement_replace_qualification = store.state.main.permissions.includes('edit_statement_replace_qualification')
  p.change_status_statement_replace_qualification = store.state.main.permissions.includes('change_status_statement_replace_qualification')
  p.delete_statement_replace_qualification = store.state.main.permissions.includes('delete_statement_replace_qualification')
  p.view_report_statement_replace_qualification = store.state.main.permissions.includes('view_report_statement_replace_qualification')

  p.etiCertificatesRO = store.state.main.permissions.includes('readCertificates')
  p.etiCertificatesC = store.state.main.permissions.includes('addCertificatesETI')
  p.etiCertificatesWS = store.state.main.permissions.includes('writeCertificatesStatus')

  p.etiStatementRO = store.state.main.permissions.includes('readCertificateApplication')
  p.etiStatementC = store.state.main.permissions.includes('createCertificationApplication')
  p.etiStatementW = store.state.main.permissions.includes('writeCertificationApplication')
  p.etiStatementWS = store.state.main.permissions.includes('writeCertificationApplicationStatus')
  p.etiStatementD = store.state.main.permissions.includes('deleteCertificationApplication')

  p.etiCoursePriceRO = store.state.main.permissions.includes('readCoursePrice')
  p.etiCoursePriceW = store.state.main.permissions.includes('writeCoursePrice')

  p.etiRatioRO = store.state.main.permissions.includes('readETIProfitPart')
  p.etiRatioW = store.state.main.permissions.includes('writeETIProfitPart')

  p.etiInstitutionListRO = store.state.main.permissions.includes('readInstitutionListETI')

  p.etiCourseListRO = store.state.main.permissions.includes('readETIRegistry')
  p.etiCourseListC = store.state.main.permissions.includes('createETIRegistry')
  p.etiCourseListW = store.state.main.permissions.includes('writeETIRegistry')
  p.etiCourseListD = store.state.main.permissions.includes('deleteETIRegistry')

  p.etiDealingRO = store.state.main.permissions.includes('readDealingETI')
  p.sqcStatementApprovedW = store.state.main.permissions.includes('readApplicationSQCApproved')
  p.sqcStatementProcessW = store.state.main.permissions.includes('readApplicationSQCProccess')
  p.sqcStatementFromCabinetRO = store.state.main.permissions.includes('readApplicationSQCCreatedFromPA')
  p.serviceRecordBookRO = store.state.main.permissions.includes('readRecordBook')
  p.existServiceRecordBookC = store.state.main.permissions.includes('createExistRecordBook')

  p.newServiceRecordBookC = store.state.main.permissions.includes('createNewRecordBook')
  p.serviceRecordBookW = store.state.main.permissions.includes('writeRecordBook')
  p.serviceRecordBookWS = store.state.main.permissions.includes('writeRecordBookStatus')
  p.serviceRecordBookD = store.state.main.permissions.includes('deleteRecordBook')
  p.serviceRecordBookPreVerificationW = store.state.main.permissions.includes('writeRecordBookPreVerification')
  p.serviceRecordBookPreVerificationWS = store.state.main.permissions.includes('writeRecordBookPreVerificationStatus')

  p.serviceRecordBookStatementRO = store.state.main.permissions.includes('readApplicationRecordBook')
  p.serviceRecordBookStatementW = store.state.main.permissions.includes('writeApplicationRecordBook')
  p.serviceRecordBookStatementD = store.state.main.permissions.includes('deleteApplicationRecordBook')

  p.experienceRO = store.state.main.permissions.includes('readExperience')
  p.experienceNotConventionalRO = store.state.main.permissions.includes('readExperienceNotConventional')
  p.experiencePreVerificationW = store.state.main.permissions.includes('writeExperiencePreVerification')
  p.experiencePreVerificationWS = store.state.main.permissions.includes('writeExperiencePreVerificationStatus')
  p.experienceC = store.state.main.permissions.includes('createExperience')
  p.experienceW = store.state.main.permissions.includes('writeExperience')
  p.experienceWS = store.state.main.permissions.includes('writeExperienceStatus')
  p.experienceNotConventionalC = store.state.main.permissions.includes('createExperienceNotConventional')
  p.experienceNotConventionalW = store.state.main.permissions.includes('writeExperienceNotConventional')
  p.experienceNotConventionalWS = store.state.main.permissions.includes('writeExperienceNotConventionalStatus')
  p.experienceD = store.state.main.permissions.includes('deleteExperience')

  p.recordBookLineC = store.state.main.permissions.includes('createRecordBookEntry')
  p.recordBookLineW = store.state.main.permissions.includes('writeRecordBookEntry')
  p.recordBookLineWS = store.state.main.permissions.includes('writeRecordBookEntryStatus')
  p.recordBookLineD = store.state.main.permissions.includes('deleteRecordBookEntry')

  p.sqcStatementRO = store.state.main.permissions.includes('readApplicationSQC')
  p.sqcStatementC = store.state.main.permissions.includes('createApplicationSQC')
  p.sqcStatementWS = store.state.main.permissions.includes('writeApplicationSQCStatus')
  p.sqcStatementRejectedWS = store.state.main.permissions.includes('writeApplicationSQCStatusRejected')
  p.sqcStatementPaymentW = store.state.main.permissions.includes('writeApplicationSQCPayment')
  p.sqcStatementCadetWS = store.state.main.permissions.includes('writeApplicationSQCStatusCadet')
  p.sqcStatementDateMeetingW = store.state.main.permissions.includes('writeApplicationSQCChangeDateMeeting')
  p.sqcStatementPreVerificationW = store.state.main.permissions.includes('writeApplicationSQCPreVerification')
  p.sqcStatementPreVerificationWS = store.state.main.permissions.includes('writeApplicationSQCPreVerificationStatus')
  p.sqcStatementD = store.state.main.permissions.includes('deleteApplicationSQC')

  p.sqcProtocolRO = store.state.main.permissions.includes('readProtocolSQC')
  p.sqcProtocolC = store.state.main.permissions.includes('createProtocolSQC')
  p.sqcProtocolW = store.state.main.permissions.includes('writeProtocolSQC')
  p.sqcProtocolWS = store.state.main.permissions.includes('writeProtocolSQCStatus')
  p.sqcProtocolD = store.state.main.permissions.includes('deleteProtocolSQC')
  p.sqcProtocolRegenerationW = store.state.main.permissions.includes('writeRegenerateProtocolSQC')
  p.sqcProtocolStampW = store.state.main.permissions.includes('writeStamp')

  p.medicalRO = store.state.main.permissions.includes('readMedical')
  p.medicalC = store.state.main.permissions.includes('createMedical')
  p.medicalW = store.state.main.permissions.includes('writeMedical')
  p.medicalWS = store.state.main.permissions.includes('writeMedicalStatus')
  p.medicalD = store.state.main.permissions.includes('deleteMedical')
  p.medicalPreVerificationW = store.state.main.permissions.includes('writeMedicalPreVerification')
  p.medicalPreVerificationWS = store.state.main.permissions.includes('writeMedicalPreVerificationStatus')

  p.medicalStatementRO = store.state.main.permissions.includes('readStatementMedicalCertificate')
  p.medicalStatementC = store.state.main.permissions.includes('createStatementMedicalCertificate')
  p.medicalStatementW = store.state.main.permissions.includes('writeStatementMedicalCertificate')
  p.medicalStatementWS = store.state.main.permissions.includes('writeStatementMedicalCertificateStatus')
  p.medicalStatementD = store.state.main.permissions.includes('deleteStatementMedicalCertificate')
  p.medicalStatementT = store.state.main.permissions.includes('writeStatementMedicalToDocument')

  p.packetServiceRO = store.state.main.permissions.includes('readPacketService')
  p.packetServiceC = store.state.main.permissions.includes('createPacketService')
  p.packetServiceW = store.state.main.permissions.includes('writePacketService')
  p.packetServiceD = store.state.main.permissions.includes('deletePacketService')

  p.documentForSingingRO = store.state.main.permissions.includes('read_qualification_documents_on_sign')

  p.crewingManagerStatementW = store.state.main.permissions.includes('writeCrewingManagerStatement')

  p.crewingManagerStatementFromSailorW = store.state.main.permissions.includes('writeCrewingManagerStatementFromSailor')
  p.crewingManagerStatementFromSailorA = store.state.main.permissions.includes('applyCrewingManagerStatementFromSailor')

  p.backOfficeETIListC = store.state.main.permissions.includes('createInstitutionListETI')
  p.backOfficeETIListW = store.state.main.permissions.includes('writeInstitutionListETI')
  p.backOfficeDocumentsPriceW = store.state.main.permissions.includes('writePriceForPosition')
  p.backOfficeDealingW = store.state.main.permissions.includes('backOfficeDealingW')

  p.backOfficeCrewingCompanyC = store.state.main.permissions.includes('add_crewingcompany')
  p.backOfficeCrewingCompanyAddManager = store.state.main.permissions.includes('add_manager_to_crewing_company')
  p.backOfficeCrewingCompanyRemoveManager = store.state.main.permissions.includes('remove_manager_from_crewing_company')

  p.verifySailorPhoto = store.state.main.permissions.includes('verify_sailor_photo')
  p.archiveDeletedSailorPhoto = store.state.main.permissions.includes('archive_delete_sailor_photo')

  p.navigatorCertificate = {
    ro: store.state.main.permissions.includes('view_certificateofnavigator'),
    c: store.state.main.permissions.includes('add_certificateofnavigator'),
    w: store.state.main.permissions.includes('change_certificateofnavigator'),
    ws: store.state.main.permissions.includes('change_status_certificateofnavigator'),
    d: store.state.main.permissions.includes('delete_certificateofnavigator')
  }
  p.internationalPassport = {
    ro: store.state.main.permissions.includes('view_foreignpassport'),
    c: store.state.main.permissions.includes('add_foreignpassport'),
    w: store.state.main.permissions.includes('change_foreignpassport'),
    ws: store.state.main.permissions.includes('change_status_foreignpassport'),
    d: store.state.main.permissions.includes('delete_foreignpassport')
  }
  p.crewingVerification = {
    ro: store.state.main.permissions.includes('view_statementcrewing'),
    ws: store.state.main.permissions.includes('change_statementcrewing'),
    d: store.state.main.permissions.includes('delete_statementcrewing')
  }
  p.sailorPassportVerification = store.state.main.permissions.includes('view_report_verification_sailorpassport')
  p.internationalPassportVerification = store.state.main.permissions.includes('view_report_verification_foreignpassport')
  p.educationVerification = store.state.main.permissions.includes('view_report_verification_education')
  p.qualificationVerification = store.state.main.permissions.includes('view_report_verification_qualificationdocument')
  p.recordBookVerification = store.state.main.permissions.includes('view_report_verification_servicerecord')
  p.lineRecordBookVerification = store.state.main.permissions.includes('view_report_verification_lineinservicerecord')
  p.medicalVerification = store.state.main.permissions.includes('view_report_verification_medicalcertificate')
  p.navigatorCertificateVerification = store.state.main.permissions.includes('view_report_verification_certificateofnavigator')
  p.serviceRecordBookReport = store.state.main.permissions.includes('view_report_servicerecord')
  p.existingdocument = { // Document's photo verification from personal cabinet
    view: store.state.main.permissions.includes('view_report_existingdocument')
  }
}

export const setRoles = () => {
  r.sailorRating = store.state.sailor.rating
  r.userID = store.state.main.user.id
  r.commissioner = store.state.main.user.commissioner
  r.sailorIsSQC = store.state.sailor.sailorSQC
  r.sailorPreVerification = store.state.sailor.sailorVerify
  r.existSailorAgent = store.state.sailor.agentInfo
  r.editableByAgent = store.state.sailor.editableByAgent
  r.crewingManager = store.state.main.user.type_user === 'crewing_manager'
  r.dpd = store.state.main.user.type_user === 'dpd'
  r.crewingHead = store.state.main.user.type_user === 'crewing_head'
  r.secretaryService = store.state.main.user.type_user === 'secretary_service'
  r.backOffice = store.state.main.user.type_user === 'back_office'
  r.morrichservice = store.state.main.user.type_user === 'morrichservice'
  r.medical = store.state.main.user.type_user === 'medical'
  r.secretarySQC = store.state.main.user.type_user === 'secretary_sqc'
  r.etiWorker = store.state.main.user.type_user === 'eti_employee'
  r.secretaryATC = store.state.main.user.type_user === 'secretary_atc'
  r.harborMaster = store.state.main.user.type_user === 'harbor_master'
  r.groupHead = store.state.main.user.type_user === 'group_head'
  r.verifier = store.state.main.user.type_user === 'verifier'
  r.registry = store.state.main.user.type_user === 'registry'
  r.verifierBackOfficeFirst = store.state.main.user.type_user === 'verifier_back_office_1'
  r.verifierBackOfficeSecond = store.state.main.user.type_user === 'verifier_back_office_2'
  r.support = store.state.main.user.type_user === 'support'
}

export const checkAccess = (typeRule = null, typeDocument = null, sailorDocument = null, photo = null) => {
  if (!Object.keys(p).length) return false
  if (p.superAdmin) return true
  switch (typeRule) {
    case 'admin':
      return p.superAdmin
    case 'verifierBackOfficeFirst':
      return r.verifierBackOfficeFirst
    case 'verifierBackOfficeSecond':
      return r.verifierBackOfficeSecond
    case 'crewingManager':
      return r.crewingManager
    case 'secretaryService':
      return r.secretaryService
    case 'backOffice':
      return r.backOffice
    case 'support':
      return r.support
    case 'etiWorker':
      return r.etiWorker
    case 'crewingHead':
      return r.crewingHead
    case 'marad':
      return r.morrichservice
    case 'medical':
      return r.medical
    case 'harborMaster':
      return r.harborMaster
    case 'groupHead':
      return r.groupHead
    case 'menuItem-crewingManagerInfo':
      return p.crewingManagerInfoRO
    case 'menuItem-sailor':
      return p.mainInfoRO
    case 'add_sailorcontact':
      return p.add_sailorcontact
    case 'delete_sailorcontact':
      return p.delete_sailorcontact
    case 'menuLabel-documents':
      return p.sqcStatementApprovedW || p.sqcStatementProcessW || p.sqcStatementProcessW ||
        p.serviceRecordBookStatementRO || p.serviceRecordBookStatementW || p.etiStatementsRO || p.etiPaymentsRO ||
        p.crewingManagerVerificationDocsRO || p.checkDocumentsW || p.packageQualificationStatementRO || p.reportSqcProtocolRO ||
        p.reportSqcStatementRO || p.reportStudentRO || p.reportEducationRO || p.reportQualificationRO || p.reportCertificateRO ||
        p.reportListExcel || p.userHiystoryRO || p.advanceTrainingStatementsRO || p.reportSailorPassportRO ||
        p.reportSubmittedDocumentRO
    case 'menuLabel-admin':
      return p.etiRatioRO || p.etiCourseListRO || p.etiCoursePriceRO || p.packetPriceRO || p.etiInstitutionListRO ||
        p.etiDealingRO || p.crewingCompanyRO || p.crewingManagerStatementRO || p.crewingManagerStatementFromSailorRO
    case 'menuLabel-settings':
      return p.newUserC || p.superAdmin || r.support
    case 'menuItem-statementSQC':
      return p.sqcStatementApprovedW || p.sqcStatementProcessW || p.sqcStatementProcessW
    case 'menuItem-documentsVerification':
      return p.sailorPassportVerification || p.internationalPassportVerification || p.educationVerification || p.qualificationVerification ||
        p.recordBookVerification || p.lineRecordBookVerification || p.medicalVerification || p.navigatorCertificateVerification
    case 'menuItem-statementETI':
      return p.etiStatementsRO
    case 'menuItem-statementSP':
      return p.reportSailorPassportStatementsRO
    case 'menuItem-statementAdvanceTraining':
      return p.advanceTrainingStatementsRO
    case 'tab-statementSQCApproved':
      return p.sqcStatementApprovedW
    case 'tab-statementSQCProcess':
      return p.sqcStatementProcessW
    case 'tab-statementSQCFromPA':
      return p.sqcStatementFromCabinetRO
    case 'tab-statementServiceRecordBook':
      return p.serviceRecordBookStatementRO || p.serviceRecordBookStatementW
    case 'tab-statementServiceRecordBookISC':
      return r.backOffice
    case 'menuItem-postVerificationDocuments':
      return p.postVerificationRO
    case 'menuItem-crewingManagerVerificationDocs':
      return p.crewingManagerVerificationDocsRO
    case 'menuItem-statementISC':
      return r.backOffice
    case 'menuItem-packageStatementISC':
    case 'menuItem-nostrification':
      return r.backOffice
    case 'menuItem-verificationAccount':
      return p.checkNewAccountsW
    case 'menuItem-documentsForSigning':
      return p.documentForSingingRO
    case 'menuItem-report':
      return p.reportSqcProtocolRO || p.reportSqcStatementRO || p.reportStudentRO || p.reportEducationRO ||
        p.reportQualificationRO || p.reportCertificateRO || p.reportListExcel || p.reportSailorPassportRO ||
        p.reportSubmittedDocumentRO
    case 'menuItem-photoVerification':
      return p.verifySailorPhoto || p.archiveDeletedSailorPhoto
    case 'sqc-report':
    case 'sqc-report-protocol':
    case 'sqc-report-statement':
      return p.reportSqcProtocolRO || p.reportSqcStatementRO
    case 'sqc-protocol-report':
      return p.reportSqcProtocolRO
    case 'sqc-statement-report':
      return p.reportSqcStatementRO
    case 'hideSqcStatementReportFields':
      // TODO: remove this permission when we will not check Back Office role and change it to '!r.crewingManager' condition
      return !r.crewingManager
    case 'cadet-report':
      return p.reportStudentRO
    case 'education-report':
      return p.reportEducationRO
    case 'qualification-report':
      return p.reportQualificationRO
    case 'certificates-report':
      return p.reportCertificateRO
    case 'sailorPassport-report':
      return p.reportSailorPassportRO
    case 'financial-report':
      return p.reportFinanceRO
    case 'submitted-documents-report':
      return p.reportSubmittedDocumentRO
    case 'crewing-sailor-report':
      return p.reportCrewingSailorConnectRO
    case 'distribution-report':
      return p.distributionReportRO
    case 'excel-report':
      return p.reportListExcel
    case 'certificate-for-members-of-the-sqc-report':
      return r.backOffice || r.verifierBackOfficeFirst || r.verifierBackOfficeSecond
    case 'service-record-book-report':
      return p.serviceRecordBookReport
    case 'menuItem-upload':
      return p.educationFromExcelC
    case 'menuItem-userHistory':
      return p.userHiystoryRO
    case 'menuItem-crewHistory':
    case 'menuItem-etiHistory':
      return r.backOffice
    case 'menuItem-etiRatio':
      return p.etiRatioRO
    case 'menuItem-etiCourse':
      return p.etiCourseListRO
    case 'menuItem-priceEtiCourse':
      return p.etiCoursePriceRO
    case 'menuItem-pricePacket':
      return p.packetPriceRO
    case 'menuItem-crewingManagerStatement':
      return p.crewingManagerStatementRO
    case 'menuItem-crewingManagerStatementFromSailor':
      return p.crewingManagerStatementFromSailorRO
    case 'menuItem-etiInstitution':
      return p.etiInstitutionListRO
    case 'menuItem-etiDealing':
      return p.etiDealingRO
    case 'menuItem-newUser':
      return p.newUserC
    case 'menuItem-crewingCompanies':
      return p.crewingCompanyRO
    case 'menuItem-vnz':
      return p.vnzRO
    case 'menuItem-medical':
    case 'menuItem-mes-agencies':
    case 'menuItem-nostrification-agencies':
    case 'menuItem-translate-agencies':
    case 'menuItem-powers-of-attorney':
    case 'payment-statement-nostrification':
    case 'menuItem-auto-signing-distribution': return r.backOffice
    case 'menuItem-backOffice':
      return p.etiRatioRO || p.etiCourseListRO || p.etiCoursePriceRO || p.packetPriceRO || p.etiInstitutionListRO ||
        p.etiDealingRO || p.crewingCompanyRO
    case 'menuItem-qualificationPackageStatement':
      return p.packageQualificationStatementRO
    case 'menuItem-etiPayments':
      return p.etiPaymentsRO
    case 'menuItem-statistics-verification':
      return r.backOffice
    case 'menuItem-signFailedDocuments': return r.backOffice || r.verifierBackOfficeFirst || r.verifierBackOfficeSecond || r.support
    case 'tab-sailorPassport':
      return p.sailorPassportRO
    case 'tab-internationalPassport':
      return p.internationalPassport.ro
    case 'tab-fullNameChanges':
      return p.fullNameChangesRO
    case 'tab-sailorPassportStatement':
      return p.sailorPassportStatementRO
    case 'tab-education':
      return p.educationRO
    case 'tab-student':
      return p.studentRO
    case 'tab-educationStatement':
      return p.educationStatementRO
    case 'tab-qualification':
      return p.qualificationRO
    case 'tab-qualificationStatement':
      return p.qualificationStatementRO
    case 'tab-navigatorCertificates':
      return p.navigatorCertificate.ro
    case 'tab-etiCertificate':
      return p.etiCertificatesRO || p.etiCertificatesC
    case 'tab-etiStatement':
      return p.etiStatementRO
    case 'tab-experience':
      return p.experienceRO || p.experienceNotConventionalRO
    case 'tab-serviceRecordBook':
      return p.serviceRecordBookRO
    // case 'tab-statementServiceRecordBook':
    //   return p.serviceRecordBookStatementW || r.morrichservice
    case 'tab-sqcProtocol':
      return p.sqcProtocolRO
    case 'tab-sqcStatement':
      return p.sqcStatementRO
    case 'tab-medical':
      return p.medicalRO
    case 'tab-medicalStatement':
      return p.medicalStatementRO
    case 'tab-positionStatement':
      return p.packetServiceRO

    case 'main-addSailor':
      return p.sailorC && !r.crewingManager
    case 'main-addSailorWithoutTaxNumber':
      return p.sailorWithoutTaxC
    // case 'main-agent':
    //   return r.crewingManager
    // case 'main-agentsSailor':
    //   return r.crewingManager || r.crewingHead || r.secretaryService || r.morrichservice
    case 'main-sailorsList':
      return !r.crewingManager && !r.crewingHead && !r.secretaryService && !r.morrichservice
    case 'main-search':
      return r.crewingManager || r.crewingHead || r.secretaryService
    case 'main-editInfo':
      return p.mainInfoW
    case 'main-editFullNameInfo':
      return r.backOffice
    case 'main-editFullNameInfoEng':
      return r.backOffice || r.support
    case 'main-editSailorPhoto':
      return p.mainInfoW || r.crewingManager
    case 'main-verifySailorPhoto':
      return p.verifySailorPhoto
    case 'main-ratingAll':
      return p.ratingRO || p.ratingW
    case 'main-ratingW':
      return p.ratingW
    case 'signDocument':
      return r.commissioner
    case 'document-author-view':
      return p.checkDocumentsW || p.authorRO
    case 'verification-author-view':
      return p.verificationAuthorRO
    case 'user-history':
      return p.userHiystoryRO
    case 'verification-comment':
      return p.verificationCommentW
    case 'displaySearch':
      return !r.etiWorker && !r.secretaryATC
    case 'documents-merging':
      return p.mergeEducationDocuments || p.mergeQualificationDocuments
    case 'sailors-merging':
      return p.mergeSailors

    case 'statementSRB':
      switch (typeDocument) {
        case 'sailorLink':
          return true
        case 'create':
          return false
        case 'edit':
          return false
        case 'editStatus':
          return sailorDocument.status.id !== 12 && sailorDocument.status.id !== 47
        case 'files':
          return true
        case 'delete':
          return p.superAdmin
      }
      break
    case 'newAccounts':
      switch (typeDocument) {
        case 'sailorLink':
          return sailorDocument.item.sailor || sailorDocument.item.sailor_id
        case 'create':
          return false
        case 'edit':
          return true
        case 'editStatus':
          return false
        case 'view_file':
          return true
        case 'reject':
          return true
        case 'delete':
          return false
      }
      break
    case 'openSailorCabinetProfile':
      return p.openSailorCabinetProfile
    case 'sailorPassport':
      switch (typeDocument) {
        case 'create':
          return p.sailorPassportC || p.newSailorPassportC
        case 'edit':
          return (p.sailorPassportPreVerificationW && sailorDocument.status_document === 74) ||
            (p.sailorPassportW && r.dpd && ([34, 7, 19, 21].includes(sailorDocument.status_document))) ||
            (p.sailorPassportW && !r.dpd && sailorDocument.status_document === 74) ||
            (p.sailorPassportW && r.support && [42, 74, 34, 122].includes(sailorDocument.status_document))
        case 'editStatus':
          return ((p.sailorPassportPreVerificationWS && [34, 74].includes(sailorDocument.status_document) && r.verifierBackOfficeSecond) ||
            (p.sailorPassportWS && [34, 42].includes(sailorDocument.status_document)) ||
            (p.postVerificationW && sailorDocument.status_document === 60)) && canChangeStatus(sailorDocument.status_document)
        case 'add_file':
          return store.state.main.permissions.includes('add_file_sailorpassport')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_sailorpassport')
        case 'delete':
          return p.sailorPassportD && (r.crewingManager || r.verifierBackOfficeSecond) && sailorDocument.status_document === 74
        case 'deleteFile':
          return p.sailorPassportD && ((r.crewingManager && sailorDocument.status_document === 74 && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'preVerification':
          return p.sailorPassportPreVerificationWS && r.verifierBackOfficeSecond
        case 'verificationSteps':
          return p.sailorPassportWS
        case 'addExistPassport':
          return p.sailorPassportC || p.createDocumentFromExistingDocument
        case 'addNewPassport':
          return p.newSailorPassportC
        case 'showDateRenewal':
          return (!r.dpd && !sailorDocument.date_renewal) ||
            (r.dpd && [21, 34].includes(sailorDocument.status_document)) ||
            (r.support && sailorDocument.status_document === 34)
      }
      break
    case 'civilPassport':
      switch (typeDocument) {
        case 'view':
          return p.civilPassportRO
        case 'create':
          return p.civilPassportC
        case 'edit':
          return p.civilPassportW
        case 'editStatus':
          return p.civilPassportWS
        case 'delete':
          return p.civilPassportD
        case 'add_file':
          return store.state.main.permissions.includes('add_file_passport')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_passport')
        case 'deleteFile':
          return false
      }
      break
    case 'sailorInternationalPassport':
      switch (typeDocument) {
        case 'create':
          return p.internationalPassport.c
        case 'edit':
          return (p.internationalPassport.w && [34, 42].includes(sailorDocument.status_document)) || (r.verifierBackOfficeSecond && [34, 74].includes(sailorDocument.status_document))
        case 'editStatus':
          return (p.internationalPassport.ws && canChangeStatus(sailorDocument.status_document)) ||
            (r.verifierBackOfficeSecond && (sailorDocument.status_document === 74 || sailorDocument.status_document === 34))
        case 'add_file':
          return store.state.main.permissions.includes('add_file_foreignpassport')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_foreignpassport')
        case 'delete':
          return p.internationalPassport.d
        case 'deleteFile':
          return p.internationalPassport.d && ((r.crewingManager && sailorDocument.status_document === 74 && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'verificationSteps':
          return p.internationalPassport.ws
      }
      break
    case 'sailorFullNameChanges':
      switch (typeDocument) {
        case 'create':
          return p.fullNameChangesC
        case 'edit':
          return p.fullNameChangesW && sailorDocument.lastRecord
        case 'view_file':
          return store.state.main.permissions.includes('view_file_oldname')
        case 'add_file':
          return store.state.main.permissions.includes('add_file_oldname')
        case 'delete':
          return p.fullNameChangesD
        case 'deleteFile':
          return p.fullNameChangesD && (!photo.isDeleted || (r.backOffice && photo.isDeleted))
      }
      break
    case 'sailorPassportStatement':
      switch (typeDocument) {
        case 'create':
          return p.sailorPassportStatementC
          // return (!r.dpd && p.sailorPassportStatementC) || (!r.existSailorAgent && r.dpd && p.sailorPassportStatementC)
        case 'editStatus':
          return p.sailorPassportStatementWS && (sailorDocument.status_document.id === 72 ||
            sailorDocument.status_document.id === 42) && canChangeStatus(sailorDocument.status_document.id)
        case 'view_file':
          return store.state.main.permissions.includes('view_file_statementsailorpassport')
        case 'add_file':
          return store.state.main.permissions.includes('add_file_statementsailorpassport')
        case 'delete':
          return p.sailorPassportStatementD
        case 'deleteFile':
          return p.sailorPassportStatementD && (!photo.isDeleted || (r.backOffice && photo.isDeleted))
      }
      break
    case 'education':
      switch (typeDocument) {
        case 'create': return p.educationC
        case 'edit':
          return p.educationPhotoC || (p.educationPreVerificationW && sailorDocument.status_document === 74) ||
            (p.educationW && ((sailorDocument.status_document === 74 && !r.registry) || (sailorDocument.status_document === 34 && r.registry))) ||
            (p.educationW && r.support && [74, 34, 122].includes(sailorDocument.status_document))
        case 'editStatus':
          return ((p.educationPreVerificationWS && sailorDocument.status_document === 74 && (r.verifierBackOfficeSecond || r.verifierBackOfficeFirst)) ||
            (p.educationPreVerificationWS && r.verifierBackOfficeFirst) ||
            (p.educationWS && [2, 34].includes(sailorDocument.status_document)) ||
            (p.educationWS && [2, 4].includes(sailorDocument.type_document) && [14, 34].includes(sailorDocument.status_document)) ||
            (p.postVerificationW && sailorDocument.status_document === 60)) && canChangeStatus(sailorDocument.status_document)
        case 'view_file': return store.state.main.permissions.includes('view_file_education')
        case 'add_file': return store.state.main.permissions.includes('add_file_education')
        case 'delete':
          return p.educationD && (r.crewingManager || r.verifierBackOfficeSecond) && sailorDocument.status_document === 74
        case 'deleteFile':
          return p.educationD && ((r.crewingManager && sailorDocument.status_document === 74 && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'preVerification':
          return r.verifierBackOfficeSecond || r.verifierBackOfficeFirst
        case 'editRegistryNumber':
          return !p.educationPhotoC
        case 'verificationSteps':
          return (p.educationWS && sailorDocument.status_document !== 37) ||
            (p.educationWS && sailorDocument.status_document === 37 && [2, 4].includes(sailorDocument.type_document))
        case 'merging': return p.mergeEducationDocuments
      }
      break
    case 'student':
      switch (typeDocument) {
        case 'create':
          return p.studentC
        case 'edit':
          return p.studentW
        case 'editStatus':
          return p.studentWS && canChangeStatus(sailorDocument.status_document.id)
        case 'view_file':
          return store.state.main.permissions.includes('view_file_studentid')
        case 'add_file':
          return store.state.main.permissions.includes('add_file_studentid')
        case 'delete':
          return p.studentD
        case 'deleteFile':
          return p.studentD && (!photo.isDeleted || (r.backOffice && photo.isDeleted))
      }
      break
    case 'educationStatement':
      switch (typeDocument) {
        case 'create':
          return p.educationStatementC
        case 'edit':
          return p.educationStatementW && (sailorDocument.status_document.id === 80 || sailorDocument.status_document.id === 42)
        case 'editStatus':
          return p.educationStatementWS && (sailorDocument.status_document.id === 80 ||
            sailorDocument.status_document.id === 42) && canChangeStatus(sailorDocument.status_document.id)
        case 'view_file':
          return store.state.main.permissions.includes('view_file_statementadvancedtraining')
        case 'add_file':
          return store.state.main.permissions.includes('add_file_statementadvancedtraining')
        case 'delete':
          return p.educationStatementD
        case 'deleteFile':
          return p.educationStatementD && (!photo.isDeleted || (r.backOffice && photo.isDeleted))
        case 'transfer':
          return (p.educationC || p.educationStatementT) && sailorDocument.status_document.id === 78
      }
      break
    case 'qualification':
      switch (typeDocument) {
        case 'create':
          return p.qualificationC || p.existQualificationC
        case 'createNewQualification':
          return !p.existQualificationC
        case 'edit':
          return p.qualificationWAS ||
            (p.qualificationPreVerificationW && sailorDocument.status_document === 74) ||
            (p.qualificationW && [37, 20, 34, 14, 74].includes(sailorDocument.status_document) && !sailorDocument.new_document) ||
            (p.qualificationWS && p.qualificationC && [21, 74].includes(sailorDocument.status_document)) ||
            ((r.secretaryService || r.crewingManager) && sailorDocument.status_document === 74) ||
            (p.qualificationW && r.support && [74, 34, 122].includes(sailorDocument.status_document))
        case 'editStatus':
          return ((p.qualificationPreVerificationWS && sailorDocument.status_document === 74 && (r.verifierBackOfficeSecond || r.verifierBackOfficeFirst)) ||
            (p.qualificationWS && p.groupProfileId === 37 && (sailorDocument.status_document === 19 ||
              sailorDocument.status_document === 21 || sailorDocument.status_document === 2)) ||
            (p.qualificationWS && p.groupProfileId !== 37 && (sailorDocument.status_document === 34 || sailorDocument.status_document === 14 ||
              sailorDocument.status_document === 19 || (r.editableByAgent && sailorDocument.status_document === 74))) ||
            (p.postVerificationW && sailorDocument.status_document === 60)) && canChangeStatus(sailorDocument.status_document)
        case 'editStrictBlank':
          return p.qualificationWAS || (p.qualificationWS && p.qualificationC &&
            sailorDocument.type_document !== 3 && sailorDocument.status_document === 21) ||
            (p.qualificationPreVerificationW && sailorDocument.status_document === 74)
        case 'showDocumentSavingBtn':
          return (new Date(sailorDocument.date_start) >= new Date('2021-03-20')) &&
            (r.harborMaster || r.dpd)
        case 'editLimitations':
          return p.qualificationLimitationsW && (!sailorDocument.signatures_info ||
            (sailorDocument.signatures_info && !sailorDocument.signatures_info.length))
        case 'view_file': return store.state.main.permissions.includes('view_file_qualificationdocument')
        case 'add_file': return store.state.main.permissions.includes('add_file_qualificationdocument')
        case 'delete':
          return (p.qualificationD && (r.crewingManager || r.verifierBackOfficeSecond) && sailorDocument.status_document === 74) ||
            (p.qualificationD && !r.crewingManager)
        case 'deleteFile':
          return p.qualificationD && ((r.crewingManager && sailorDocument.status_document === 74 && !photo.isDeleted) ||
            (!r.crewingManager && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'preVerification':
          return r.verifierBackOfficeSecond || r.verifierBackOfficeFirst
        case 'verificationSteps':
          return p.qualificationWS && sailorDocument.status_document !== 37
        case 'merging':
          return p.mergeQualificationDocuments
      }
      break
    case 'qualificationStatement':
      switch (typeDocument) {
        case 'create':
          return (p.qualificationStatementC && r.dpd && !r.existSailorAgent) || (p.qualificationStatementC && !r.dpd)
        case 'edit':
          return p.qualificationStatementW && sailorDocument.status_document.id === 25
        case 'editStatus':
          return (p.qualificationStatementWAS || (sailorDocument.status_document.id === 87 && sailorDocument.status_dkk.have_all_docs && r.morrichservice) ||
            (p.qualificationStatementWS && sailorDocument.status_dkk.have_all_docs)) && canChangeStatus(sailorDocument.status_document.id)
        case 'view_file':
          return store.state.main.permissions.includes('view_file_statementqualification')
        case 'add_file':
          return store.state.main.permissions.includes('add_file_statementqualification')
        case 'delete':
          return p.qualificationStatementD
        case 'deleteFile':
          return p.qualificationStatementD && ((r.crewingManager && sailorDocument.status_document.id === 74 && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'showPortEditing':
          return !r.support
        case 'showDocumentUploading':
          return r.crewingManager || r.secretaryService || r.dpd || r.support || r.backOffice
        case 'viewExperienceTable':
          return sailorDocument.status_document.id !== 37 // p.sailorDocument !== 37
        case 'viewPayment':
          return sailorDocument.status_dkk.have_all_docs && !(sailorDocument.status_document.id === 87 && r.morrichservice)
        case 'viewStatus':
          return sailorDocument.is_payed && !(sailorDocument.status_document.id === 87 && r.morrichservice)
        case 'maradVerification':
          return sailorDocument.status_document.id === 87 && r.morrichservice
      }
      break
    case 'navigatorCertificates':
      switch (typeDocument) {
        case 'create':
          return p.navigatorCertificate.c
        case 'edit':
          return p.navigatorCertificate.w
        case 'editStatus':
          return p.navigatorCertificate.ws && canChangeStatus(sailorDocument.status_document.id)
        case 'add_file':
          return store.state.main.permissions.includes('add_file_certificateofnavigator')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_certificateofnavigator')
        case 'delete':
          return p.navigatorCertificate.d
        // case 'deleteFile':
        //   return p.educationStatementD && (!photo.isDeleted || (r.backOffice && photo.isDeleted))
      }
      break
    case 'certification':
      switch (typeDocument) {
        case 'create':
          return p.etiCertificatesC
        case 'editStatus':
          return p.etiCertificatesWS && sailorDocument.status_document.id === 42 && canChangeStatus(sailorDocument.status_document.id)
        case 'delete':
          return p.superAdmin
        case 'viewTypeCertificate':
          return store.state.main.permissions.includes('view_type_certificateeti')
      }
      break
    case 'certificationStatement':
      switch (typeDocument) {
        case 'create':
          return p.etiStatementC
        case 'edit':
          return p.etiStatementW && sailorDocument.status_document.id === 63
        case 'editStatus':
          return p.etiStatementWS && sailorDocument.status_document.id === 63 && canChangeStatus(sailorDocument.status_document.id)
        case 'delete':
          return p.etiStatementD
        case 'add_file':
          return store.state.main.permissions.includes('add_file_statementeti')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_statementeti')
      }
      break
    case 'serviceRecordBook':
      switch (typeDocument) {
        case 'create':
          return p.newServiceRecordBookC || (p.existServiceRecordBookC && !r.dpd) ||
            (p.existServiceRecordBookC && r.dpd && !r.sailorIsSQC &&
              !store.state.sailor.qualification.find(item => [7, 19, 33].includes(item.status_document)) &&
              store.state.sailor.qualificationStatement.length)
        case 'createExistDoc':
          return (p.existServiceRecordBookC && !r.dpd) || (p.existServiceRecordBookC && r.dpd && !r.sailorIsSQC &&
            !store.state.sailor.qualification.find(item => [7, 19, 33].includes(item.status_document)) &&
            store.state.sailor.qualificationStatement.length) || p.createDocumentFromExistingDocument
        case 'createNewDoc':
          return p.newServiceRecordBookC
        case 'edit':
          return (p.serviceRecordBookPreVerificationW && sailorDocument.status_document === 74) ||
            (p.serviceRecordBookW && sailorDocument.status_document === 74) ||
            (p.serviceRecordBookW && r.support && [74, 34, 122].includes(sailorDocument.status_document)) ||
            (p.serviceRecordBookWS && [14, 34].includes(sailorDocument.status_document) && !r.verifier)
        case 'editStatus':
          return ((p.serviceRecordBookPreVerificationWS && [14, 74, 34].includes(sailorDocument.status_document) && r.verifierBackOfficeSecond) ||
            (p.serviceRecordBookWS && ([14, 34].includes(sailorDocument.status_document) || (r.editableByAgent && sailorDocument.status_document === 74))) ||
            (p.postVerificationW && sailorDocument.status_document === 60)) && canChangeStatus(sailorDocument.status_document)
        case 'add_file':
          return store.state.main.permissions.includes('add_file_servicerecord')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_servicerecord')
        case 'delete':
          return p.serviceRecordBookD && (r.crewingManager || r.verifierBackOfficeSecond) && sailorDocument.status_document === 74
        case 'deleteFile':
          return p.serviceRecordBookD && ((r.crewingManager && sailorDocument.status_document === 74 && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'preVerification':
          return r.verifierBackOfficeSecond || r.backOffice
        case 'viewRecord':
          return !r.dpd
        case 'addRecord':
          return (p.recordBookLineC && [2, 34, 74].includes(sailorDocument.status_document) && !r.dpd) ||
            (p.recordBookLineC && [2, 34, 74].includes(sailorDocument.status_document) && r.dpd && !r.sailorIsSQC &&
              !store.state.sailor.qualification.find(item => [7, 19, 33].includes(item.status_document)) &&
              store.state.sailor.qualificationStatement.length)
        case 'verificationSteps':
          return p.serviceRecordBookWS
      }
      break
    case 'serviceRecordBookLine':
      switch (typeDocument) {
        case 'edit':
          return (p.experiencePreVerificationW && sailorDocument.status_document === 74) ||
            (p.recordBookLineW && [74, 14].includes(sailorDocument.status_document)) ||
            (p.recordBookLineW && r.support && [74, 34, 122].includes(sailorDocument.status_document))
        case 'editStatus':
          return ((p.experiencePreVerificationWS && [5, 74, 34].includes(sailorDocument.status_document) && r.verifierBackOfficeSecond) ||
            (p.recordBookLineWS && sailorDocument.status_document === 34) ||
            (r.editableByAgent && sailorDocument.status_document === 74) ||
            (p.postVerificationW && [60, 9].includes(sailorDocument.status_document)) ||
            (r.userID === 188 && [10, 34].includes(sailorDocument.status_document))) &&
            canChangeStatus(sailorDocument.status_document)
        case 'add_file':
          return store.state.main.permissions.includes('add_file_lineinservicerecord')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_lineinservicerecord')
        case 'delete':
          return p.recordBookLineD && (r.crewingManager || r.verifierBackOfficeSecond) && sailorDocument.status_document === 74
        case 'deleteFile':
          return p.recordBookLineD && ((r.crewingManager && sailorDocument.status_document === 74 && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'preVerification':
          return r.verifierBackOfficeSecond
        case 'verificationSteps':
          return p.recordBookLineWS || r.userID === 188
      }
      break
    case 'experience':
      switch (typeDocument) {
        case 'create':
          // return p.experienceC || p.experienceNotConventionalC
          return ((p.experienceC || p.experienceNotConventionalC) && !r.dpd) ||
            (r.dpd && (p.experienceC || p.experienceNotConventionalC) && !r.sailorIsSQC &&
              !store.state.sailor.qualification.find(item => [7, 19, 33].includes(item.status_document.id)) &&
              store.state.sailor.qualificationStatement.length)
        case 'edit':
          return ((p.experiencePreVerificationW || p.recordBookLineW || p.experienceW || p.experienceNotConventionalW) && sailorDocument.status_document === 74) ||
            (p.experienceW && r.support && [74, 34, 122].includes(sailorDocument.status_document))
        case 'editStatus':
          return ((p.experiencePreVerificationWS && [5, 34, 74].includes(sailorDocument.status_document) && r.verifierBackOfficeSecond) ||
            ((p.experienceWS || p.recordBookLineWS || p.experienceNotConventionalWS) &&
              (sailorDocument.status_document === 34 || (r.editableByAgent && sailorDocument.status_document === 74))) ||
            (p.postVerificationW && (sailorDocument.status_document === 60 || sailorDocument.status_document === 9))) &&
            canChangeStatus(sailorDocument.status_document)
        case 'add_file':
          return store.state.main.permissions.includes('add_file_experience')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_experience')
        case 'delete':
          return p.experienceD && (r.crewingManager || r.verifierBackOfficeSecond) && sailorDocument.status_document === 74
        case 'deleteFile':
          return p.experienceD && ((r.crewingManager && sailorDocument.status_document === 74 && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'preVerification':
          return r.verifierBackOfficeSecond // !p.experiencePreVerificationWS && !r.verifierBackOfficeSecond && sailorDocument.status_document !== 74
        case 'checkDocumentType':
          return p.experienceC || p.createDocumentFromExistingDocument
        case 'verificationSteps':
          return p.experienceWS || p.recordBookLineWS || p.experienceNotConventionalWS
      }
      break
    case 'recordBookStatement':
      switch (typeDocument) {
        case 'create':
          return p.serviceRecordBookStatementW
        case 'edit':
          return p.serviceRecordBookStatementW && ((sailorDocument.status_document.id !== 12 && sailorDocument.status_document.id !== 47) ||
            (sailorDocument.status_document.id === 87 && r.medical))
        case 'delete':
          return p.serviceRecordBookStatementD
        case 'add_file':
          return store.state.main.permissions.includes('add_file_statementservicerecord')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_statementservicerecord')
        case 'deleteFile':
          return p.serviceRecordBookStatementD && (!photo.isDeleted || (r.backOffice && photo.isDeleted))
        case 'transfer':
          return p.serviceRecordBookStatementW && sailorDocument.status_document.id !== 47
        case 'changeStatusToRejected':
          return p.serviceRecordBookStatementW && sailorDocument.status_document.id !== 49
      }
      break
    case 'sailorSQCStatement':
      switch (typeDocument) {
        case 'create':
          return p.sqcStatementC
        case 'edit':
          return p.sqcStatementC || (p.sqcStatementPreVerificationW && (sailorDocument.status_document === 74 ||
              sailorDocument.status_document === 24 || sailorDocument.status_document === 25)) ||
            (p.sqcStatementDateMeetingW && sailorDocument.status_document === 24)
        case 'editStatus':
          return (((p.sqcStatementWS && sailorDocument?.status_dkk?.have_all_docs && sailorDocument.is_payed) ||
            (r.secretarySQC && (sailorDocument.status_document === 42 || sailorDocument.status_document === 25))) ||
            (p.sqcStatementCadetWS && (sailorDocument.status_document === 25 || sailorDocument.status_document === 42) &&
              sailorDocument?.status_dkk?.not_have_educ_doc && sailorDocument.is_cadet) ||
            (p.sqcStatementPaymentW && sailorDocument?.status_dkk?.have_all_docs) ||
            (p.sqcStatementPreVerificationWS && sailorDocument.status_document === 74) ||
            (sailorDocument.status_document === 87 && r.morrichservice && sailorDocument?.status_dkk?.have_all_docs) ||
            p.sqcStatementRejectedWS) && canChangeStatus(sailorDocument.status_document)
        case 'add_file':
          return store.state.main.permissions.includes('add_file_statementsqc')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_statementsqc')
        case 'delete':
          return p.sqcStatementD && (r.crewingManager || r.verifierBackOfficeSecond) && sailorDocument.status_document === 74
        case 'deleteFile':
          return p.sqcStatementD && ((r.crewingManager && sailorDocument.status_document === 74 && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'regeneration':
          return r.backOffice && sailorDocument.has_related_docs
        case 'showPayment':
          return p.sqcStatementPaymentW && sailorDocument.status_dkk.have_all_docs && sailorDocument.status_document !== 74
        case 'showStudentStatuses':
          return p.sqcStatementCadetWS && (sailorDocument.status_document === 25 || sailorDocument.status_document === 42) &&
            sailorDocument.status_dkk.not_have_educ_doc && sailorDocument.is_cadet
        case 'showStatuses':
          return p.sqcStatementWS && sailorDocument.status_dkk.have_all_docs && sailorDocument.status_document !== 74
        case 'requiredFile':
          return sailorDocument.status_document === 87 && r.morrichservice
        case 'showSuccessButton':
          return (p.sqcStatementPaymentW && sailorDocument.status_dkk.have_all_docs && sailorDocument.status_document !== 74) ||
            (p.sqcStatementCadetWS && (sailorDocument.status_document === 25 || sailorDocument.status_document === 42) &&
              sailorDocument.status_dkk.not_have_educ_doc && sailorDocument.is_cadet) ||
            (p.sqcStatementWS && sailorDocument.status_dkk.have_all_docs && sailorDocument.status_document !== 74) ||
            ((p.sqcStatementPreVerificationWS && r.verifierBackOfficeSecond && sailorDocument.status_document === 74) ||
              (sailorDocument.status_document === 87 && r.morrichservice))
        case 'showRejectButton':
          return r.secretarySQC && (sailorDocument.status_document === 42 || sailorDocument.status_document === 25) && !sailorDocument.created_by_crewing_manager
        case 'showSaveLabel':
          return !(p.sqcStatementPreVerificationWS && r.verifierBackOfficeSecond && sailorDocument.status_document === 74) ||
            !(sailorDocument.status_document === 87 && r.morrichservice)
        case 'preVerification':
          return r.verifierBackOfficeSecond
        case 'editDateMeeting':
          return (p.sqcStatementDateMeetingW && sailorDocument.status_document === 24)
      }
      break
    case 'sailorSQCProtocols':
      switch (typeDocument) {
        case 'create':
          return p.sqcProtocolC
        case 'edit':
          return p.superAdmin
        case 'editStatus':
          return p.sqcProtocolWS && sailorDocument.decision && canChangeStatus(sailorDocument.status_document)
        case 'add_file':
          return store.state.main.permissions.includes('add_file_protocolsqc')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_protocolsqc')
        case 'delete':
          return p.sqcProtocolD
        case 'deleteFile':
          return p.sqcProtocolD && (!photo.isDeleted || (r.backOffice && photo.isDeleted))
        case 'regeneration':
          return p.sqcProtocolRegenerationW && sailorDocument.is_printeble && sailorDocument.decision
        case 'signature':
          return !sailorDocument.signing.sign_status && (sailorDocument.signing.sign_access || p.sqcProtocolStampW)
        case 'download':
          return !r.support && sailorDocument.is_printeble && sailorDocument.decision
        case 'downloadWithStamps':
          return !r.support && sailorDocument.is_printeble && sailorDocument.decision && sailorDocument.cifra_uuid &&
             sailorDocument.status_document !== 89
      }
      break
    case 'sailorMedical':
      switch (typeDocument) {
        case 'create':
          return p.medicalC
        case 'edit':
          return (p.medicalPreVerificationW && sailorDocument.status_document === 74) ||
            ((p.medicalW || r.medical) && sailorDocument.status_document === 74) ||
            (p.medicalW && r.support && [74, 34, 122].includes(sailorDocument.status_document))
        case 'editStatus':
          return ((p.medicalPreVerificationWS && (sailorDocument.status_document === 74 || sailorDocument.status_document === 74) && r.verifierBackOfficeSecond) ||
            (p.medicalWS && (sailorDocument.status_document === 34 ||
              (r.editableByAgent && sailorDocument.status_document === 74)) && !r.medical) ||
            (r.medical && sailorDocument.status_document === 77) ||
            (p.postVerificationW && sailorDocument.status_document === 60)) && canChangeStatus(sailorDocument.status_document)
        case 'add_file':
          return store.state.main.permissions.includes('add_file_medicalcertificate')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_medicalcertificate')
        case 'delete':
          return p.medicalD && (r.crewingManager || r.verifierBackOfficeSecond) && sailorDocument.status_document === 74
        case 'deleteFile':
          return p.medicalD && ((r.crewingManager && sailorDocument.status_document === 74 && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'preVerification':
          return r.verifierBackOfficeSecond
        case 'verificationSteps':
          return p.medicalWS && !r.medical
      }
      break
    case 'medicalStatement':
      switch (typeDocument) {
        case 'create':
          return p.medicalStatementC
        case 'edit':
          return p.medicalStatementW && (sailorDocument.status_document === 77 || sailorDocument.status_document === 42)
        case 'editStatus':
          return ((p.medicalStatementWS && (sailorDocument.status_document === 77 || sailorDocument.status_document === 42)) ||
            (r.medical && sailorDocument.status_document === 77)) && canChangeStatus(sailorDocument.status_document)
        case 'transfer':
          return (p.medicalStatementT && sailorDocument.status_document === 75 && sailorDocument.is_payed) ||
            (r.medical && sailorDocument.status_document === 75 && sailorDocument.is_payed)
        case 'add_file':
          return store.state.main.permissions.includes('add_file_statementmedicalcertificate')
        case 'view_file':
          return store.state.main.permissions.includes('view_file_statementmedicalcertificate')
        case 'delete':
          return p.medicalStatementD
        case 'deleteFile':
          return p.medicalStatementD && ((r.crewingManager && sailorDocument.status_document === 74 && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'enterDoctor':
          return !r.medical
      }
      break
    case 'positionStatement':
      switch (typeDocument) {
        case 'create':
          return (p.packetServiceC && (r.crewingManager || r.backOffice || r.morrichservice)) || r.verifierBackOfficeSecond
        case 'createAfterPreview':
          return !r.secretaryService
        case 'createSingleDocs':
          return !r.morrichservice || !r.secretaryService
        case 'edit':
          return p.packetServiceW && (r.crewingManager || r.backOffice || r.morrichservice) && !sailorDocument.is_payed
        case 'files':
          return true
        case 'delete':
          return p.packetServiceD && (r.crewingManager || r.backOffice || r.morrichservice)
        case 'deleteFile':
          return p.packetServiceD && ((r.crewingManager && !photo.isDeleted) || (r.backOffice && photo.isDeleted))
        case 'changeRating':
          return sailorDocument.is_payed && ((r.crewingManager && r.sailorRating !== 4) || (r.backOffice && p.ratingW))
      }
      break
    case 'newCrewingManagers':
      switch (typeDocument) {
        case 'edit':
          return p.crewingManagerStatementW
        case 'editStatus':
          return p.crewingManagerStatementW
        case 'files':
          return true
      }
      break
    case 'crewingManagerStatements':
      switch (typeDocument) {
        case 'sailorLink':
          return (r.crewingManager || r.secretaryService) && sailorDocument.status_document.id === 67
        case 'edit':
          return (p.crewingManagerStatementFromSailorW && sailorDocument.status_document.id === 69) ||
            (p.crewingManagerStatementFromSailorA && sailorDocument.status_document.id === 82) ||
            (r.secretaryService && !sailorDocument.date_end_proxy)
        case 'files':
          return true
        case 'showCrewing':
          return !r.morrichservice
        case 'highlightDocument': // highlight document without termination date
          return r.secretaryService
      }
      break
    case 'backOfficeCoefficient':
      switch (typeDocument) {
        case 'create':
        case 'edit':
        case 'delete':
          return p.etiRatioW
      }
      break
    case 'backOfficeCoursePrice':
      switch (typeDocument) {
        case 'create':
        case 'edit':
        case 'delete':
          return p.p.etiCoursePriceW
      }
      break
    case 'backOfficeETIList':
      switch (typeDocument) {
        case 'create':
          return p.backOfficeETIListC
        case 'edit':
          return p.backOfficeETIListW
      }
      break
    case 'backOfficeCourse':
      switch (typeDocument) {
        case 'create':
          return p.etiCourseListC
      }
      break
    case 'backOfficeCourseLine':
      switch (typeDocument) {
        case 'edit':
          return p.etiCourseListW
        case 'delete':
          return p.etiCourseListD
      }
      break
    case 'backOfficeDocumentPrices':
      switch (typeDocument) {
        case 'create':
          return p.backOfficeDocumentsPriceW
        case 'edit':
          return false
      }
      break
    case 'backOfficeFutureDocumentPrices':
      switch (typeDocument) {
        case 'edit':
        case 'delete':
          return p.backOfficeDocumentsPriceW
      }
      break
    case 'backOfficePastDocumentPrices':
      switch (typeDocument) {
        case 'edit':
          return p.backOfficeDocumentsPriceW && sailorDocument.allowEdit
        case 'delete':
          return p.backOfficeDocumentsPriceW && sailorDocument.allowDelete
      }
      break
    case 'backOfficeDealing':
      switch (typeDocument) {
        case 'edit':
          return p.backOfficeDealingW
        case 'delete':
          return false
      }
      break
    case 'backOfficeCrewingManager':
      switch (typeDocument) {
        case 'create':
          return p.backOfficeCrewingCompanyAddManager
        case 'removeCrewingManager':
          return p.backOfficeCrewingCompanyRemoveManager
        case 'edit':
        case 'editGroup':
          return !r.crewingHead
      }
      break
    case 'backOfficeCrewingCompaniesList':
      switch (typeDocument) {
        case 'create':
          return p.backOfficeCrewingCompanyC
      }
      break
    case 'backOfficeVnz':
      switch (typeDocument) {
        case 'create':
          return p.backOfficeCrewingCompanyC
      }
      break
    case 'addEDS':
      return r.harborMaster
    case 'crewingVerification':
      switch (typeDocument) {
        case 'view':
          return p.crewingVerification?.ro
        case 'editStatus':
          return p.crewingVerification.ws
        case 'files':
          return true
        case 'delete':
          return p.crewingVerification.d
      }
      break
    case 'sailorPassportVerification':
      return p.sailorPassportVerification
    case 'internationalPassportVerification':
      return p.internationalPassportVerification
    case 'civilPassportVerification':
      return p.civilPassportVerification
    case 'educationVerification':
      return p.educationVerification
    case 'qualificationVerification':
      return p.qualificationVerification
    case 'recordBookVerification':
      return p.recordBookVerification
    case 'lineRecordBookVerification':
      return p.lineRecordBookVerification
    case 'medicalVerification':
      return p.medicalVerification
    case 'navigatorCertificateVerification':
      return p.navigatorCertificateVerification

    case 'verificationDocumentPhotos': // Document's photo verification from personal cabinet
      switch (typeDocument) {
        case 'view': return p.existingdocument.view || r.verifierBackOfficeSecond
      }
      break
    case 'accountManagement':
      if (r.support) return r.support
      switch (typeDocument) {
        case 'view':
        case 'edit':
        case 'changePassword':
          return r.backOffice
        case 'files':
          return true
      }
      break
    case 'documentManipulation': return r.support || r.backOffice || r.verifierBackOfficeFirst || r.verifierBackOfficeSecond
    case 'downloadFile':
      return !r.support
    case 'statement_replace_qualification':
      switch (typeDocument) {
        case 'add':
          return p.add_statement_replace_qualification
        case 'view':
          return p.view_statement_replace_qualification
        case 'edit':
          return p.edit_statement_replace_qualification
        case 'editStatus':
          return p.change_status_statement_replace_qualification
        case 'delete':
          return p.delete_statement_replace_qualification
        case 'report':
          return p.view_report_statement_replace_qualification
      }
      break
  }
}
