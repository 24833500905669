export default [
  { path: '/back-office-price',
    name: 'back-office-price',
    redirect: '/back-office-price/offline/eti/rate',
    component: () => import('@/views/BackOffice/BackOfficeDocumentsPrice/Document.vue'),
    children: [
      {
        path: 'offline/eti/rate',
        name: 'backoffice-offline-rate',
        component: () => import('@/views/BackOffice/BackOfficeDocumentsPrice/Offline/Table.vue')
      },
      {
        path: 'offline/eti/price-course',
        name: 'backoffice-offline-price-course',
        component: () => import('@/views/BackOffice/BackOfficeCoursePrices/BackOfficeCoursePrices.vue')
      },
      {
        path: 'offline/eti/price-course/:documentID',
        name: 'price-course-backoffice-offline-info',
        component: () => import('@/views/BackOffice/BackOfficeCoursePrices/BackOfficeCoursePricesDocument/BackOfficeCoursePricesDocument.vue')
      },
      {
        path: 'online/eti/rate',
        name: 'backoffice-online-rate',
        component: () => import('@/views/BackOffice/BackOfficeDocumentsPrice/Online/Table.vue')
      },
      {
        path: 'online/eti/price-course',
        name: 'backoffice-online-price-course',
        component: () => import('@/views/BackOffice/BackOfficeCoursePrices/BackOfficeCoursePricesOnline/BackOfficeCoursePricesOnline.vue')
      },
      {
        path: 'online/eti/price-course/:id',
        name: 'backoffice-online-price-course-info',
        component: () => import('@/views/BackOffice/BackOfficeCoursePrices/BackOfficeCoursePricesOnline/BackOfficeCoursePricesOnlineDocument.vue')
      }
    ]
  }
]
