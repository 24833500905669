<template lang="pug">
v-app(v-if="loaded" id="app")
  title {{ pageTitle }}
  div(v-if="pagesWithoutMenu.includes(location)")
    router-view
  Marad(v-if="(maradHost || flyairxpressHost)  && activePage.access && !dialog")
  div(v-else-if="$auth.isAuth()").no-permission
    div {{ $t('noPermission') }}
    div.d-flex
      v-btn(@click="$router.go(-1)") {{ $t('back') }}
      v-btn(@click="logout" color="secondary").ml-5 {{ $t('logout') }}
  v-dialog(width="700" v-model="dialog" persistent)
    ChangePassword(@closeForm="dialog=false")
</template>

<script>
// import Crewing from '@/components/pages/Crewing.vue'
// import Morrichservice from '@/components/pages/Morrichservice.vue'
import FlyAirxpreeFavicon from '@/assets/img/flyAirFavicon.svg'
import { checkAccess, setPermissions, setRoles } from '@/mixins/permissions'
import { goBack } from '@/mixins/main'
import { mapState } from 'vuex'

export default {
  name: 'App',
  data () {
    return {
      loadedPermissions: false,
      loadedRole: false,
      pagesWithoutMenu: ['/login', '/crewing-registration', '/privacy-policy', '/license-agreement', '/terms',
        '/forgot-password', '/reset-password-by-token', '/create-password-by-token'],
      events: ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'],
      warnTimeout: null,
      logoutTimeout: null,
      goBack,
      checkAccess,
      dialog: false
    }
  },
  components: {
    // Crewing,
    // Morrichservice,
    Marad: () => import('@/components/pages/Marad.vue'),
    ChangePassword: () => import('@/views/Settings/ChangePassword.vue')
  },
  watch: {
    user (val) {
      if (val.should_change_password) this.dialog = true
    }
  },
  computed: {
    ...mapState({
      maradHost: state => state.main.maradHost,
      crewingHost: state => state.main.crewingHost,
      morrichserviceHost: state => state.main.morrichserviceHost,
      flyairxpressHost: state => state.main.flyairxpressHost,
      activePage: state => state.main.activePage,
      user: state => state.main.user
    }),
    loaded () {
      return this.loadedPermissions && this.loadedRole
    },
    location () {
      return window.location.pathname
    },
    pageTitle () {
      const favicon = document.querySelector("link[rel~='icon']")
      switch (true) {
        case this.flyairxpressHost:
          favicon.setAttribute('href', FlyAirxpreeFavicon)
          return this.$i18n.t('flyAirExpress')
        case this.crewingHost:
          return this.$i18n.t('crewing')
        case this.morrichserviceHost:
          return this.$i18n.t('morrichservice')
        default:
          return this.$i18n.t('autonomousSystem')
      }
    }
  },
  created () {
    if (this.$auth.isAuth()) {
      this.$store.dispatch('getUserPermissions')
        .then(() => {
          setPermissions()
          this.getBackOfficeDocuments()
          this.loadedPermissions = true
        })
        .catch(() => {
          this.loadedPermissions = true
        })
      this.$store.dispatch('getUserInfo')
        .then(() => {
          setRoles()
          this.loadedRole = true
        })
        .catch(() => {
          this.loadedRole = true
        })
    } else {
      this.loadedPermissions = true
      this.loadedRole = true
    }
  },
  mounted () {
    this.u2f = !!typeof window.u2f
    this.$store.commit('setHostName')
    this.$auth.isAuth() && this.loadData()
    if (!this.$auth.isAuth() && !this.pagesWithoutMenu.includes(this.location)) window.location.replace('/login')
    if (!this.pagesWithoutMenu.includes(this.location)) this.events.forEach(event => window.addEventListener(event, this.resetTimeout))
  },
  beforeCreate () {
    if (!this.$auth.isAuth()) {
      this.$store.commit('setActivePage', { name: 'authorization', access: false })
    }
  },
  beforeDestroy () {
    this.events.forEach(event => window.removeEventListener(event, this.resetTimeout))
  },
  methods: {
    resetTimeout () {
      clearTimeout(this.warnTimeout)
      this.warnTimeout = setTimeout(() => { // Set timeout on "Are you still here?" warning
        this.$swal({ title: this.$i18n.t('areYouStillHere'), icon: 'warning', buttons: this.$i18n.t('yes') })
          .then(confirmation => {
            if (confirmation) this.resetTimeout()
          })
      }, 900000) // 15 min

      clearTimeout(this.logoutTimeout)
      this.logoutTimeout = setTimeout(() => { // Set timeout on logout function
        localStorage.removeItem('TOKEN')
        document.cookie = 'TOKEN' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        window.location = '/login'
      }, 1200000) // 20 min
    },
    loadData () {
      this.$store.dispatch('getTypeDelivery')
      this.$store.dispatch('getWearHouseMorrichservice')
      this.$store.dispatch('getVersion')
      this.$store.dispatch('getSex')
      this.$store.dispatch('getCountry')
      this.$store.dispatch('getRegion')
      this.$store.dispatch('getAffiliate')
      this.$store.dispatch('getTypeDoc')
      this.$store.dispatch('getStatusDocs')
      this.$store.dispatch('getRanks')
      this.$store.dispatch('getPositions')
      this.$store.dispatch('getCrewingManagers')
      this.$store.dispatch('getPorts')
      this.$store.dispatch('getSolutions')
      this.$store.dispatch('getPositionsFunctions')
      this.$store.dispatch('getPositionsLimitations')
      this.$store.dispatch('getExtent')
      this.$store.dispatch('getNameInstitution')
      this.$store.dispatch('getProfession')
      this.$store.dispatch('getQualification')
      this.$store.dispatch('getSpecialization')
      this.$store.dispatch('getFaculties')
      this.$store.dispatch('getEducationForm')
      this.$store.dispatch('getCourses')
      this.$store.dispatch('getETI')
      this.$store.dispatch('getPositionForMedical')
      this.$store.dispatch('getMedicalInstitution')
      this.$store.dispatch('getDoctors')
      this.$store.dispatch('getCommissioners')
      this.$store.dispatch('getShipGroups')
      this.$store.dispatch('getRadioEquipment')
      this.$store.dispatch('getIssuingAuthority')
      this.$store.dispatch('getRivers')
      this.$store.dispatch('getTypeDocQual')
      this.$store.dispatch('getTypeShip')
      this.$store.dispatch('getModeShipping')
      this.$store.dispatch('getTypeGEU')
      this.$store.dispatch('getResponsibility')
      this.$store.dispatch('getResponsibilityWorkBook')
      this.$store.dispatch('getPositionsOnShip')
      this.$store.dispatch('getDeliveryCity')
      this.$store.dispatch('getAllAccrualTypeDoc')
      this.$store.dispatch('getUserPermissionReport')
      // this.$store.dispatch('getAllUsers')
      this.$store.dispatch('getRejectionReasons')
      this.$store.dispatch('getUnsignedDocumentReasons')
      this.$store.dispatch('getLanguages')
    },
    getBackOfficeDocuments () {
      if (checkAccess('menuItem-newUser')) this.$store.dispatch('getRegistrationPermissions')
      if (checkAccess('menuItem-crewingCompanies')) {
        this.$store.dispatch('getCrewingCompanies')
        this.$store.dispatch('getCrewingGroups')
      }
    },
    logout () {
      localStorage.removeItem('TOKEN')
      document.cookie = 'TOKEN' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      window.location = '/login'
      sessionStorage.clear()
    }
  }
}
</script>
