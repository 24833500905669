import api from '@/plugins/api'
import { queryString, getFiles } from '@/mixins/main'
import { reports } from '@/configs/endpoints'
import { reportsV2 } from '@/configs/endpoints/index'
import { SUCCESS_CODE } from '@/configs/constants'
import notify from '@ananasbear/notify'
import i18n from '@/plugins/localization/index'

export const REPORT = {
  state: () => ({
    advanceTrainingDistribution: {},
    certificateMembersSqc: {},
    publishedSRBs: {},
    isLoadingReport: false,
    cadets: {},
    isLoading: true,
    connectISC: {},
    disconnectISC: {},
    medical_documents: {},
    medical_statemets: {},
    replacementStatementQualification: {},
    crewingPlusConnectWithSailor: {},
    statementPositionFromISC: {},
    listDocumentSubmitted: {},
    powersOfAttorney: {},
    nostrification: {},
    documentDigitizationISC: {},
    distributionNostrificationGroup: {
      results: {}
    },
    distributionNostrificationCrewing: {
      results: {}
    },
    distributionNostrificationCrewingManager: {
      results: {}
    },
    distributionNostrificationSailor: {
      results: {}
    },
    distributionNostrificationSailorExcel: {
      results: {}
    },
    listExcelReport: [],
    listProtocolsSqc: {},
    listStatementSqc: {},
    listEducation: {},
    listCertificateETI: {},
    listSailorPassportReport: {},
    // crewing connect
    listCrewingCompanies: {},
    listCrewingManagers: [],
    listCrewingSailors: [],
    listFinance: {},
    // DEBTOR
    listDebtorGroups: {},
    listDebtorCrewing: {},
    listDebtorManagers: {},
    listDebtorSailors: {},
    listDebtorPakets: {},
    // DISTRIBUTION
    listDistributionCrewing: {},
    listDistributionGroup: {},
    listDistributionManager: {},
    listDistributionSailor: {},
    listDistributionDocType: {},
    listDistributionQualification: {}
  }),
  mutations: {
    SET_STATE_DATA_REPORT (state, data) {
      state[data.type] = data.data
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    }
  },
  actions: {
    async getListAdvanceTrainingDistribution ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.advanceTraining.list + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) {
        commit('SET_STATE_DATA_REPORT', { advanceTrainingDistribution: response.data })
      }
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getListAdvanceTrainingQualificationDistribution ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.advanceTraining.qualification + queryString(data.params))
      if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_DATA_REPORT', { advanceTrainingDistribution: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
      return response
    },
    async getListAdvanceTrainingSailorDistribution ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.advanceTraining.sailor + queryString(data.params))
      if (SUCCESS_CODE.includes(response?.code)) commit('SET_STATE_DATA_REPORT', { advanceTrainingDistribution: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
      return response
    },
    async getReportCertificationMembersSQC (context, params) {
      const response = await api.get(reports.certificateMembersSqc + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { certificateMembersSqc: response.data })
    },
    async getReportPublishedSRB (context, params) {
      const response = await api.get(reports.publishedServiceBooks + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { publishedSRBs: response.data })
    },
    async getReportPublishedSRBExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.publishedServiceBooksExcel + queryString(params, ['page', 'page_size']), { responseType: 'blob' })
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getReportCadet (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.cadet + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { cadets: response.data })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getServiceRecordStatementExcel ({ commit }, params) {
      commit('SET_STATE_DATA_REPORT', { isLoading: true })
      const response = await api.get(`${reports.serviceRecordStatementExcel}?${params}`)
      commit('SET_STATE_DATA_REPORT', { isLoading: false })
      return response
    },
    // ------ REPORT DISTRIBUTION ---- //
    async getReportDistributionQualification (context, data) {
      const response = await api.get(reports.distribution.qualification + '?' + data.params)
      return response
    },
    // ------ REPORT QUALIFICATION ------//
    async getReportQualification (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoading: true })
      const response = await api.get(reports.qualification.diplom + queryString(params))
      context.commit('SET_STATE_DATA_REPORT', { isLoading: false })
      return response.data
    },
    async getReportStatementQualification (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoading: true })
      const response = await api.get(reports.qualification.statement_qualification + queryString(params))
      context.commit('SET_STATE_DATA_REPORT', { isLoading: false })
      return response.data
    },
    async getReportStatementQualificationExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.qualification.excel + queryString(params, ['page', 'page_size']), { responseType: 'blob' })
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getConnectStatementISO (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.statementISO.connect + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { connectISC: response.data })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getDisconnectStatementISO (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.statementISO.disconnect + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { disconnectISC: response.data })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // medical
    async getMedicalCertificatesReport (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.medicalDocuments.medical + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { medical_documents: response.data, isLoadingReport: false })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getMedicalStatementReport (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.medicalStatements.medical + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { medical_statemets: response.data, isLoadingReport: false })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getReportCertificatesMedicalExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.medicalDocuments.excel + queryString(params, ['page', 'page_size']), { responseType: 'blob' })
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getReportStatementsMedicalExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.medicalStatements.excel + queryString(params, ['page', 'page_size']), { responseType: 'blob' })
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getReportReplacementStatementsExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.replaceStatementQualification.excel + queryString(params, ['page', 'page_size']), { responseType: 'blob' })
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getReportReplacementStatements (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.replaceStatementQualification.qualification + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { replacementStatementQualification: response.data })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // Report connect crewing plus with sailor
    async getCrewingPlusConnectWithSailor (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.crewingPlusConnectSailor.connectPeriod + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { crewingPlusConnectWithSailor: response.data, isLoadingReport: false })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getCrewingPlusConnectWithSailorExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.crewingPlusConnectSailor.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getStatementsPositionFromISCExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.packetISC.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getStatementsPositionFromISC (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.packetISC.statementsPosition + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { statementPositionFromISC: response.data, isLoadingReport: false })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // DOCUMENT SUBMITTED
    async getListSubmittedDocumentsReport (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.documentSubmitted.list + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.map(item => {
          switch (item.content_type) {
            case 'servicerecord':
              item.typeDoc = i18n.t('model-ServiceRecord')
              item.link = 'experience-records-info'
              break
            case 'experienceline':
              item.typeDoc = i18n.t('model-ExperienceDoc')
              item.link = 'experience-reference-info'
              break
            case 'lineinservicerecord':
              item.typeDoc = i18n.t('model-LineInServiceRecord')
              item.link = 'experience-records-line-info'
              break
            case 'qualificationdocument':
              item.typeDoc = i18n.t('model-QualificationDocument')
              item.link = 'qualification-documents-info'
              break
            case 'proofofworkdiploma':
              item.typeDoc = i18n.t('model-ProofOfWorkDiploma')
              item.link = 'qualification-documents-info'
              break
            case 'higheducation':
              item.typeDoc = i18n.t('model-Education')
              item.link = 'education-documents-info'
              break
            case 'qualify_worker':
              item.typeDoc = i18n.t('qualificationWorkerDiploma')
              item.link = 'education-documents-info'
              break
            case 'upper_qualify':
              item.typeDoc = i18n.t('advanceTrainingCertificate')
              item.link = 'education-documents-info'
              break
            case 'worker_qualify':
              item.typeDoc = i18n.t('workQualificationCertificate')
              item.link = 'education-documents-info'
              break
            case 'medicalcertificate':
              item.typeDoc = i18n.t('model-MedicalCertificate')
              item.link = 'medical-certificates-info'
              break
            case 'sailorpassport':
              item.typeDoc = i18n.t('model-SailorPassport')
              item.link = 'passports-sailors-info'
              break
            case 'foreignpassport':
              item.typeDoc = i18n.t('internationalPassport.name')
              item.link = 'passports-international-info'
              break
          }
        })
        context.commit('SET_STATE_DATA_REPORT', { listDocumentSubmitted: response.data, isLoadingReport: false })
      }
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getListSubmittedDocumentsReportExel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.documentSubmitted.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // REPORT POWERS OF ATTORNEY
    async getPowersOfAttorneyReportExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.powersOfAttorney.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getPowersOfAttorneyReport (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.powersOfAttorney.documents + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { powersOfAttorney: response.data, isLoadingReport: false })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // REPORT DOCUMENTS ON DIGITIZATION FROM ISC
    async getDocumentDigitizationISCExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.documentDigitizationISC.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getDocumentDigitizationISC (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.documentDigitizationISC.documents + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { documentDigitizationISC: response.data, isLoadingReport: false })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // REPORT NOSTRIFICATIO
    async getNostrificationReportExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.nostrification.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getNostrificationReport (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reports.nostrification.listStatement + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { nostrification: response.data, isLoadingReport: false })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // DISTRIBUTION NOSTRIFICATION //
    async getDistributionNostrificationGroup ({ commit }, data) {
      const response = await api.get(reports.distribution.nostrification.group(data.typeDocument) + queryString(data?.params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { distributionNostrificationGroup: response.data })
    },
    async getDistributionNostrificationCrewing ({ commit }, data) {
      const response = await api.get(reports.distribution.nostrification.crewing(data.typeDocument) + queryString(data?.params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { distributionNostrificationCrewing: response.data })
    },
    async getDistributionNostrificationCrewingManager ({ commit }, data) {
      const response = await api.get(reports.distribution.nostrification.crewingManager(data.typeDocument) + queryString(data?.params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { distributionNostrificationCrewingManager: response.data })
    },
    async getDistributionNostrificationSailor ({ commit }, data) {
      const response = await api.get(reports.distribution.nostrification.sailor(data.typeDocument) + queryString(data?.params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { distributionNostrificationSailor: response.data })
    },
    async getDistributionNostrificationSailorExcel (context, data) {
      const response = await api.get(reports.distribution.nostrification.sailorExcel(data.typeDocument) + queryString(data?.params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
    },
    async getListExcelReport (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoading: true, listExcelReport: [] })
      const response = await api.get(reports.excel.list + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { listExcelReport: response.data })
      context.commit('SET_STATE_DATA_REPORT', { isLoading: false })
    },
    async saveExcelReportDocument (context, data) {
      const response = await getFiles(reports.excel.file(data.token), data.file_name, data.format)
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('notify.success.excelReport'))
    },
    // Protocols SQC
    async getListProtocolsSQC (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.sqc.protocol.list() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { listProtocolsSqc: response.data })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // Statement SQC
    async getListStatementSQC (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.sqc.statement.list() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { listStatementSqc: response.data })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // EDUCATION
    async getListEducationReport (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.education.list() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { listEducation: response.data })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // Certificate ETI
    async getListCertificateETIReport (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.eti.list() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { listCertificateETI: response.data })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getCertificateETIReportExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.eti.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // SAILOR PASSPORT
    async getListSailorPassportReport (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.sailorPassport.list() + queryString(params))
      if (SUCCESS_CODE.includes(response.code)) context.commit('SET_STATE_DATA_REPORT', { listSailorPassportReport: response.data })
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // Crewing connect
    // COMPANY
    async getListCrewingCompanies ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.crewing.connect.company.list + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { listCrewingCompanies: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getCrewingCompaniesReportExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.crewing.connect.company.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // MANAGER
    async getListCrewingManagers ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.crewing.connect.manager.list + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { listCrewingManagers: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getCrewingManagersReportExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.crewing.connect.manager.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // SAILOR
    async getListCrewingSailors ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.crewing.connect.sailor.list + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { listCrewingSailors: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getCrewingSailorsReportExcel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.crewing.connect.sailor.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // FINANCE
    async getListFinance ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.finance + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { listFinance: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // DEBTOR
    // GROUPS
    async getListDebtorGroups ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.debtor.group.list + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.page_form2_distribution = response.data.results.page_form2_sum - response.data.results.page_form2_profit
        response.data.results.global_form2_distribution = response.data.results.global_form2_sum - response.data.results.global_form2_profit
        commit('SET_STATE_DATA_REPORT', { listDebtorGroups: response.data })
      }
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getDeptorGroupsReportExel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.debtor.group.excel + queryString(params, ['page', 'page_size']))
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // CREWING
    async getListDebtorCrewing ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.debtor.crewing.list + queryString(data.params))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.page_form2_distribution = response.data.results.page_form2_sum - response.data.results.page_form2_profit
        response.data.results.global_form2_distribution = response.data.results.global_form2_sum - response.data.results.global_form2_profit
        commit('SET_STATE_DATA_REPORT', { listDebtorCrewing: response.data })
      }
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // CREWING MANAGER
    async getListDebtorCrewingManagers ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.debtor.crewing.manager.list + queryString(data.params, ['group']))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.page_form2_distribution = response.data.results.page_form2_sum - response.data.results.page_form2_profit
        response.data.results.global_form2_distribution = response.data.results.global_form2_sum - response.data.results.global_form2_profit
        commit('SET_STATE_DATA_REPORT', { listDebtorManagers: response.data })
      }
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    async getDeptorCrewingReportExel (context, params) {
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.debtor.crewing.manager.excel + `${params ? '?' : ''}` + params)
      if (SUCCESS_CODE.includes(response.code)) notify.success(i18n.t('excelStatement'))
      context.commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // Manager
    async getListDebtorManager ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.debtor.sailor + queryString(data.params, ['group', 'crewing']))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.page_form2_distribution = response.data.results.page_form2_sum - response.data.results.page_form2_profit
        response.data.results.global_form2_distribution = response.data.results.global_form2_sum - response.data.results.global_form2_profit
        commit('SET_STATE_DATA_REPORT', { listDebtorSailors: response.data })
      }
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // Sailor
    async getListDebtorSailor ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.debtor.paket + queryString(data.params, ['group', 'crewing', 'manager']))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.page_form2_distribution = response.data.results.page_form2_sum - response.data.results.page_form2_profit
        response.data.results.global_form2_distribution = response.data.results.global_form2_sum - response.data.results.global_form2_profit
        commit('SET_STATE_DATA_REPORT', { listDebtorSailors: response.data })
      }
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // Packet
    async getListDebtorSailorPacket ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true })
      const response = await api.get(reportsV2.debtor.document + queryString(data.params, ['group', 'crewing', 'manager', 'sailor_id']))
      if (SUCCESS_CODE.includes(response.code)) {
        response.data.results.page_form2_distribution = response.data.results.page_form2_sum - response.data.results.page_form2_profit
        response.data.results.global_form2_distribution = response.data.results.global_form2_sum - response.data.results.global_form2_profit
        commit('SET_STATE_DATA_REPORT', { listDebtorPakets: response.data })
      }
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // DISTRIBUTION
    // CREWING
    async getListDistributionCrewing ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true, listDistributionCrewing: {} })
      const response = await api.get(
        reportsV2.distribution.crewing[data.link](data.params.typeDocument,
          data?.params?.type || ['sqc', 'crewing_manager', 'dpd'].includes(data.params.typeDocument)) + queryString(
          data.params, ['typeDocument', 'type', 'type_document']))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { listDistributionCrewing: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // GROUP
    async getListDistributionGroup ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true, listDistributionGroup: {} })
      const response = await api.get(reportsV2.distribution.group(data.params.typeDocument, ['sqc', 'crewing_manager', 'dpd'].includes(data.params.typeDocument)) + queryString(data.params, ['typeDocument']))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { listDistributionGroup: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // MANAGER
    async getListDistributionManager ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true, listDistributionManager: {} })
      const response = await api.get(reportsV2.distribution.manager?.[data.link](data.params.typeDocument, data.params?.type) + queryString(data.params, ['typeDocument', 'type']))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { listDistributionManager: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // SAILOR
    async getListDistributionSailor ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true, listDistributionSailor: {} })
      const response = await api.get(reportsV2.distribution[data.link](data.params.typeDocument, data?.params?.type) + queryString(data.params, ['typeDocument', 'type']))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { listDistributionSailor: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // DocType
    async getListDistributionDocType ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true, listDistributionDocType: {} })
      const response = await api.get(reportsV2.distribution.typeDocument(data.params.typeDocument) + queryString(data.params, ['typeDocument']))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { listDistributionDocType: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    },
    // Qualification
    async getListDistributionQualification ({ commit }, data) {
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: true, listDistributionQualification: {} })
      const response = await api.get(reportsV2.distribution.qualification(data.params.typeDocument) + queryString(data.params, ['typeDocument']))
      if (SUCCESS_CODE.includes(response.code)) commit('SET_STATE_DATA_REPORT', { listDistributionQualification: response.data })
      commit('SET_STATE_DATA_REPORT', { isLoadingReport: false })
    }
  }
}
